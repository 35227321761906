
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PurchaseRQ } from './DTO/purchaseRQ.model';
import { Config } from '../config';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PurchaseDataService, PurchaseDetailsDataService } from './DTO/purchasedata.model';
import { SetManifestationRQ } from './DTO/setManifestationRQ.model';
import { Resposta } from './DTO/setManifestationRS.model';
import { SendTaxAuditEmail } from './purchaseauditsdetail/DTO/sendTaxAuditEmail.model';
import { UndoManifestationRQ } from './DTO/undoManifestationRQ.model';
import { DefaultResponse } from '../_models/default-response';
import { DomeResponse } from '../_models/domeResponse.model';
import { RetornoDfe, RetornoDfePayload } from './DTO/retornosDfe.model';

@Injectable({
	providedIn: "root"
})
export class PurchaseService {
	constructor(private http: HttpClient) {
	}

	ngOnInit(): void {
	}

	GetPurchases(_request: PurchaseRQ): Observable<PurchaseDataService[]> {
		var request = {
			header: {},
			body: _request
		}

		return this.http
			.post<PurchaseDataService[]>(Config.DOMEApi.GetPurchases, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}


	GetPurchasesReport(_request: PurchaseRQ): Observable<any[]> {
		var request = {
			header: {},
			body: _request
		}

		return this.http
			.post<PurchaseDataService[]>(Config.DOMEApi.GetPurchasesReport, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	GetPurchaseDetails(p: any): Observable<PurchaseDetailsDataService> {
		var request = {
			header: {},
			body: {
				Chave: p.data.chave
			}
		}

		return this.http
			.post<PurchaseDetailsDataService>(Config.DOMEApi.GetPurchaseDetails, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	SetManifestation(_request: SetManifestationRQ): Observable<DefaultResponse> {
		var request = {
			header: {},
			body: _request
		}

		return this.http
			.put<DefaultResponse>(Config.DOMEApi.SetManifestation, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	SetManifestationList(_request: SetManifestationRQ[]): Observable<DefaultResponse> {
		var request = {
			header: {},
			body: _request
		}

		return this.http
			.put<DefaultResponse>(Config.DOMEApi.SetManifestationList, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	UndoManifestation(_request: UndoManifestationRQ): Observable<DefaultResponse> {
		return this.http
			.put<DefaultResponse>(Config.DOMEApi.UndoManifestation, _request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	SendTaxAuditEmail(_request: SendTaxAuditEmail): Observable<Resposta> {
		var request = {
			header: {},
			body: _request
		}

		return this.http
			.post<Resposta>(Config.DOMEApi.SendTaxAuditEmail, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	DownloadDanfe(chaveNfe: string): Observable<any> {
		var request = { chaveNfe };
		return this.http.post<any>(Config.DOMEApi.DownloadDanfe, request)
			.pipe(
				map(
					data => { return data; }
				)
			);
	}

	DownloadXml(chaveNfe: string, documentType: number): any {
		var request = { chaveNfe, documentType };
		return this.http.post(Config.DOMEApi.PurchasesDownloadXml, request, { observe: 'response', responseType: 'blob' });
	}

	InsertCfop(payload: InsertCfopRQ) {
		return this.http.post<any>(Config.DOMECloud.ManageCfop, payload)
			.pipe(
				map(
					data => { return data; }
				)
			);
	}

	UpdateCfop(payload: InsertCfopRQ, id: string) {
		return this.http.put<any>(`${Config.DOMECloud.ManageCfop}/${id}`, payload)
			.pipe(
				map(
					data => { return data; }
				)
			);
	}

	ListCfop(cnpj: string) {
		return this.http.get<any>(`${Config.DOMECloud.ManageCfop}/${cnpj}`)
			.pipe(
				map(
					data => { return data; }
				)
			);
	}

	DeleteCfop(id: string) {
		return this.http.delete<any>(`${Config.DOMECloud.ManageCfop}/${id}`)
			.pipe(
				map(
					data => { return data; }
				)
			);
	}

	getRelationships() {
		return this.http.get<any>(`${Config.DOMECloud.Relationships}`);
	}

	saveRelationship(payload: any) {
		return this.http.post<any>(`${Config.DOMECloud.Relationships}`, payload);
	}

	updateRelationship(payload: any, id: string) {
		return this.http.put<any>(`${Config.DOMECloud.Relationships}/${id}`, payload);
	}

	deleteRelationship(id: string) {
		return this.http.delete<any>(`${Config.DOMECloud.Relationships}/${id}`);
	}

	ListDocuments(cnpj: string, statusProcessamento: number, dateIn: string, dateOut: string) {
		return this.http.get<any>(`${Config.DOMECloud.FiscalDocuments}?cnpj=${cnpj}&statusProcessamento=${statusProcessamento}
		${dateIn && dateOut ? `&DataEmissaoInicio=${dateIn}&DataEmissaoFim=${dateOut}` : ''}`)
			.pipe(
				map(
					data => { return data; }
				)
			);
	}

	GetSugestion(payload: any) {
		return this.http.post<any>(`${Config.DOMECloud.GetSugestion}`, payload)
			.pipe(
				map(
					data => { return data; }
				)
			);
	}

	SaveDocument(payload: SaveDocumentRQ) {
		return this.http.post<any>(`${Config.DOMECloud.FiscalDocuments}/efetivadocumento`, payload)
			.pipe(
				map(
					data => { return data; }
				)
			);
	}

	DownloadConvertedXml(chaveNfe: string): any {
		return this.http.get(`${Config.DOMECloud.FiscalDocuments}/downloadnotaefetivada?chave=${chaveNfe}`, { observe: 'response', responseType: 'blob' });
	}

	GetSupplier() {
		return this.http.get<any>(`${Config.DOMECloud.Supplier}`);
	}

	saveSupplier(payload: any) {
		return this.http.post<any>(`${Config.DOMECloud.Supplier}`, payload);
	}

	updateSupplier(payload: any, id: string) {
		return this.http.put<any>(`${Config.DOMECloud.Supplier}/${id}`, payload);
	}

	deleteSupplier(id: string) {
		return this.http.delete<any>(`${Config.DOMECloud.Supplier}/${id}`);
	}

	getCsvArchive() {
		return this.http.get<any>(`${Config.DOMECloud.Supplier}/consultamodeloarquivocsv`);
	}

	uploadCsv(data) {
		return this.http.post<any>(`${Config.DOMECloud.Supplier}/importarfornecedores`, data);
	}

	massRemanifestation(data) {
		return this.http.post<any>(`${Config.DOMEApi.Vacina}/reprocessarvacinas`, data);
	}

	remanifestation(data) {
		return this.http.post<any>(`${Config.DOMEApi.Vacina}/reprocessarvacina`, data);
	}

	getDfeValues(payload: RetornoDfePayload) {
		return this.http.post<DomeResponse<RetornoDfe>>(Config.DOMECloud.ConsultaRetornoDfe, payload);
	}
}

