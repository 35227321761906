
import { OnInit, Component, Input, ElementRef, ViewChildren, QueryList, EventEmitter, Output, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TimelineElement } from './DTO/timeline-element';

@Component({
  selector: 'horizontal-timeline',
  templateUrl: './horizontal-timeline.component.html',
  styleUrls: ['./horizontal-timeline.component.scss'],
})

export class HorizontalTimelineComponent implements AfterViewInit {
  private _timelineElements: TimelineElement[];
  selectedIndex: number = 0;
  @ViewChild('eventsWrapper') eventsWrapper: ElementRef;
  @ViewChildren('tooltip') tooltips: QueryList<ElementRef>
  @Output() eventMoveMonthClick = new EventEmitter<boolean>();
  @Input() enabledMonthTitleBtn: boolean = false;

  eventsWrapperWidth: number = 0;
  prevLinkInactive: boolean = true;
  nextLinkInactive: boolean = false;
  private _timelineWrapperWidth = 720;
  private _eventsMinDistance: number = 80;
  private monthprev: string;
  private monthnext: string;
  svgIconDownload: string = "download";
  classComponentDownload: string = "mat-icon";
  tooltipDownload: string = 'Download do arquivo';
  tooltipHelp: string = 'Entenda o cálculo';

  @Input() currentDate: Date = new Date();

  get timelineElements(): TimelineElement[] {
    return this._timelineElements;
  }

  @Input()
  set timelineElements(value: TimelineElement[]) {
    this._timelineElements = value;
    this.initView();
  }

  @Input()
  set eventsMinDistance(value: number) {
    this._eventsMinDistance = value;
    this.initView();
  }

  constructor(private _cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.initView();
  }

  private initView(): void {
    if (this._timelineElements && this._timelineElements.length) {
      for (let i = 0; i < this._timelineElements.length; i++) {
        if (this._timelineElements[i].selected) {
          this.selectedIndex = i;
          break;
        }
      }
    }
    if (this._timelineElements && this._timelineElements.length > 0) {
      this.monthprev = new Date(new Date().setMonth(this.currentDate.getMonth() - 1)).toLocaleDateString('pt-br', { month: 'long' }) + "/" + new Date(new Date(this.currentDate).setMonth(this.currentDate.getMonth() - 1)).getFullYear().toString();
      this.monthnext = new Date(new Date().setMonth(this.currentDate.getMonth() + 1)).toLocaleDateString('pt-br', { month: 'long' }) + "/" + new Date(new Date(this.currentDate).setMonth(this.currentDate.getMonth() + 1)).getFullYear().toString();
    }
  }

  updateSlide(timelineTotWidth: number, forward: boolean) {
    let translateValue = HorizontalTimelineComponent.getTranslateValue(this.eventsWrapper.nativeElement);

    if (forward) {
      this.translateTimeline(translateValue - this._timelineWrapperWidth + this._eventsMinDistance, this._timelineWrapperWidth - timelineTotWidth)
    } else {
      this.translateTimeline(translateValue + this._timelineWrapperWidth - this._eventsMinDistance, null);
    }
  }

  private static getTranslateValue(timeline: Element) {
    let timelineStyle = window.getComputedStyle(timeline);
    let timelineTranslate = timelineStyle.getPropertyValue('-webkit-transform') ||
      timelineStyle.getPropertyValue('-moz-transform') ||
      timelineStyle.getPropertyValue('-ms-transform') ||
      timelineStyle.getPropertyValue('-o-transform') ||
      timelineStyle.getPropertyValue('transform');

    let translateValue = 0;
    if (timelineTranslate.indexOf('(') >= 0) {
      let timelineTranslateStr = timelineTranslate
        .split('(')[1]
        .split(')')[0]
        .split(',')[4];
      translateValue = Number(timelineTranslateStr);
    }

    return translateValue;
  }

  translateTimeline(value: number, totWidth: number | null) {
    // only negative translate value
    value = (value > 0) ? 0 : value;
    // do not translate more than timeline width
    value = (!(totWidth === null) && value < totWidth) ? totWidth : value;
    HorizontalTimelineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateX', value + 'px');
    // update navigation arrows visibility
    this.prevLinkInactive = value === 0;
    this.nextLinkInactive = value === totWidth;
  }

  private static setTransformValue(element: any, property: any, value: any) {
    element.style['-webkit-transform'] = property + '(' + value + ')';
    element.style['-moz-transform'] = property + '(' + value + ')';
    element.style['-ms-transform'] = property + '(' + value + ')';
    element.style['-o-transform'] = property + '(' + value + ')';
    element.style['transform'] = property + '(' + value + ')';
  }

  onScrollClick(event: Event, forward: boolean) {
    event.preventDefault();
    if (forward) {
      this.eventMoveMonthClick.emit(true);
    }
    else {
      this.eventMoveMonthClick.emit(false);
    }

    this._cdr.detectChanges();
  }
}