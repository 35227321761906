import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { Actions } from 'src/app/sales/DTO/actions.model';
import { ModalService } from 'src/app/shared/modal/modal.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatIconRegistry } from '@angular/material';
import { CheckboxColumn, LabelPositionEnum } from 'src/app/shared/grid/DTO/checkboxColumn.model';
import { LabelColumn } from 'src/app/shared/grid/DTO/labelColumn.model';
import { DomSanitizer } from '@angular/platform-browser';
import { DomeDictionary } from './../../dictionary';
import { ConformityService } from '../conformity.service';

@Component({
	selector: 'app-conformityextractreports',
	templateUrl: './conformityextractreports.component.html',
	styleUrls: ['./conformityextractreports.component.scss']
})
export class ConformityExtractReportsComponent implements OnInit {
	@ViewChild('gridconformityExtraction') gridconformityExtraction: GridComponent;
	@ViewChild('dwnldLnk') dwnldLnk: ElementRef;

	@Output() eventRefreshGrid = new EventEmitter();

	icons: IconGrid[] = [];
	ConformityReportsGridColumns: GridColumn[] = [];
	ConformityExtractReportsData: any[] = [];
	mathContent: string;
	positionTaxCalendar: number = 0;
	tooltipDownload: string = 'Download do arquivo';
	enabledRefresh: boolean;
	classRefresh: string;
	titleRefresh: string;

	constructor(
		private ngxLoader: NgxUiLoaderService,
		private toastr: ToastrService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private conformityService: ConformityService
	) {
		this.populateTable();
	}

	ngOnInit() {
	}

	populateTable() {
		this.icons.push(this.createNewIcon('mathematics', '../../assets/svg/mathematics.svg'));
		this.icons.push(this.createNewIcon('download', '../../assets/svg/download.svg'));
		this.enabledRefresh = true;
		this.classRefresh = "div-refresh";
		this.matIconRegistry.addSvgIcon(
			'refresh',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/refresh.svg')
		);
		this.matIconRegistry.addSvgIcon("download", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg/download.svg"));
		this.ConformityReportsGridColumns.push(this.createNewGridColumn(300, 'nome', 'Nome', (element: any) => `${element.nome}`, false, false, false, null, "", 300));
		this.ConformityReportsGridColumns.push(this.createNewGridColumn(300, 'relatorio', 'Relatório', (element: any) => `${element.relatorio}`, false, false, false, null, "", 300));
		this.ConformityReportsGridColumns.push(this.createNewGridColumn(300, 'datasolicitacao', 'Data da Solicitação', (element: any) => `${element.datasolicitacao}`, false, false, false, null, "", 300));
		this.ConformityReportsGridColumns.push(this.createNewGridColumn(300, 'action', 'Download', (element: any) => `${element.actions}`, false, true, false, null, "iconaction-align-start"));
	}

	createNewIcon(_name: string, _url: string, _tooltip: string = ''): IconGrid {
		return {
			name: _name,
			url: _url,
			tooltip: _tooltip
		}
	}

	createNewCheckBox(
		_checked: any = false,
		_indeterminate: boolean = false,
		_labelPosition: LabelPositionEnum = LabelPositionEnum.after,
		_disabled: boolean = false,
		_labeltext: string = ''
	): CheckboxColumn {
		return {
			checked: _checked,
			disabled: _disabled,
			indeterminate: _indeterminate,
			labelPosition: _labelPosition.toString(),
			labeltext: _labeltext,
		}
	}

	createNewGridColumn(_width: number,
		_columnDef: string,
		_header: string,
		_cell: any,
		_enabledIcon: boolean = false,
		_enabledAction: boolean = false,
		_enabledCheckbox: boolean = false,
		_checkbox: CheckboxColumn = null,
		_classAlignAction: string = "",
		_widthmax: number = 0,
	): GridColumn {
		return {
			width: _width,
			columnDef: _columnDef,
			header: _header,
			cell: _cell,
			checkbox: _checkbox,
			enabledCheckbox: _enabledCheckbox,
			enabledIcon: _enabledIcon,
			enabledAction: _enabledAction,
			classAlignAction: _classAlignAction,
			widthmax: _widthmax
		}
	}

	createNewAction(_iconclass: string,
		_iconfunction: any,
		_icongrid: IconGrid,
		_iconClassComponent: string = '',
		_tooltip: string = ''): Actions {
		return {
			iconclass: _iconclass,
			iconfunction: _iconfunction,
			iconGrid: _icongrid,
			iconClassComponent: _iconClassComponent,
			tooltip: _tooltip,
			hasMenu: false
		}
	}

	createLabel(
		_label: string,
		_class: string
	): LabelColumn {
		return {
			class: _class,
			label: _label
		}
	}

	refreshDataSource(_dataSource: any) {
		console.log(_dataSource)
		if (_dataSource.length > 0) {
			this.ConformityExtractReportsData = _dataSource;
		}
		else {
			this.ConformityExtractReportsData = [];
		}

		this.gridconformityExtraction.refreshDateSource(this.ConformityExtractReportsData);
	}

	downloadFile(relatorio) {
		if (relatorio.data.id != '' && relatorio.data.statusProcessamento == 2) {
			this.ngxLoader.start();
			this.conformityService.DownloadReport({ id: relatorio.data.id }).subscribe((res: any) => {
				var blob = new Blob([res.body], { type: relatorio.data.formato });
				var link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `${relatorio.data.nomeArquivo ? relatorio.data.nomeArquivo : relatorio.data.id}`;
				link.click();
				this.toastr.info("Download Realizado com sucesso!", "")
				this.ngxLoader.stop();
			}, (err: any) => {
				this.toastr.info(DomeDictionary.ArquivoIndisponivel, "");
				this.ngxLoader.stop();
			});
		}
		else {
			this.toastr.info(DomeDictionary.ArquivoIndisponivel, "");
		}
	}

	StartDataClicked(): void {
		this.ngxLoader.start();
	}

	FinishDataClicked(): void {
		this.ngxLoader.stop();
	}

	RefreshGridClicked(): void {
		this.eventRefreshGrid.emit();
	}
}
