import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Config } from '../config';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { DefaultResponse } from '../_models/default-response';
import { ManageUsersRQ } from './DTO/manageUsersRQ';
import { ManageUsersRS } from './DTO/manageUsersRS';
import { UserDocument } from './DTO/UserDocument';
import { isNullOrUndefined } from 'util';
import { GetUserRS } from './DTO/GetUserRS';
import { GetUserRQ } from './DTO/GetUserRQ';
import { SaveUserRQ } from './DTO/SaveUserRQ';
import { UserHistoryRS } from './DTO/userHistoryRS';
import { UserHistoryRQ } from './DTO/userHistoryRQ';

@Injectable({
	providedIn: "root"
})
export class ManageUsersService {
	private _pageMode: string = 'edit';
	private _isNewUser: boolean = false;
	private _currentUser: UserDocument;
	private _emitters: any[];

	constructor(private http: HttpClient) {
		this._currentUser = {
			acessos: null,
			alterarSenha: null,
			ativo: null,
			cnpjMatriz: null,
			dataCriacao: null,
			email: null,
			id: null,
			nomeCompleto: null,
			idPerfil: null,
			idPerfilCloud: null,
			senha: null,
			ultimaAtualizacao: null,
			usuario: null
		};
	}
	ngOnInit(): void {
	}

	GetPerfil() {
		return this.http.get(Config.DOMECloud.Perfil);
	}

	GetManageableUsers(request: ManageUsersRQ): Observable<ManageUsersRS> {
		return this.http
			.post<ManageUsersRS>(Config.DOMEApi.GetManageableUsers, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	GetPageMode() {
		return this._pageMode;
	}

	SetPageMode(pageMode: string) {
		this._pageMode = pageMode
	}

	GetIsNewUser() {
		return this._isNewUser;
	}

	SetIsNewUser(isNewUser: boolean) {
		this._isNewUser = isNewUser
	}

	GetCurrentUser() {
		return this._currentUser;
	}

	SetCurrentUser(changedUserData: UserDocument) {
		if (isNullOrUndefined(changedUserData)) {
			this._currentUser = {
				acessos: null,
				alterarSenha: null,
				ativo: null,
				cnpjMatriz: null,
				dataCriacao: null,
				email: null,
				id: null,
				nomeCompleto: null,
				idPerfilCloud: null,
				idPerfil: null,
				senha: null,
				ultimaAtualizacao: null,
				usuario: null
			};
		}
		else {
			this._currentUser = {
				acessos: changedUserData.acessos,
				alterarSenha: changedUserData.alterarSenha,
				ativo: changedUserData.ativo,
				cnpjMatriz: changedUserData.cnpjMatriz,
				dataCriacao: changedUserData.dataCriacao,
				email: changedUserData.email,
				id: changedUserData.id,
				nomeCompleto: changedUserData.nomeCompleto,
				idPerfil: changedUserData.idPerfil,
				idPerfilCloud: changedUserData.idPerfil.toString(),
				senha: changedUserData.senha,
				ultimaAtualizacao: changedUserData.ultimaAtualizacao,
				usuario: changedUserData.usuario
			};
		}
	}

	SetEmitters(emitters: any[]) {
		this._emitters = emitters;
	}

	GetEmitterByCnpj(cnpj: string) {
		return this._emitters.find(x => x.cnpj === cnpj);
	}

	GetUserData(id: string): Observable<GetUserRS> {
		let request: GetUserRQ = {
			id: id
		}

		return this.http
			.post<GetUserRS>(Config.DOMEApi.GetUserData, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	SaveNewUserAsync(request: SaveUserRQ): Observable<DefaultResponse> {
		return this.http
			.post<DefaultResponse>(Config.DOMEApi.SaveNewUserAsync, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	UpdateUserAsync(request: SaveUserRQ): Observable<DefaultResponse> {
		return this.http
			.post<DefaultResponse>(Config.DOMEApi.UpdateUserAsync, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}

	GetUserHistoryAsync(request: UserHistoryRQ): Observable<UserHistoryRS> {
		return this.http
			.post<UserHistoryRS>(Config.DOMEApi.GetUserHistory, request)
			.pipe(
				map(data => {
					return data;
				})
			);
	}
}
