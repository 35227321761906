import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { EChartOption } from "echarts";
import { ValueSummary } from "./../DTO/dashboard.model";
import { Router } from "@angular/router";
import { abbreviateNumber } from "src/app/_helpers/number.helper";
import { getTooltipMultiSeries } from "src/app/_helpers/chart.helper";

@Component({
  selector: "app-chart-insights",
  templateUrl: "./chart-insights.component.html",
  styleUrls: ["./chart-insights.component.scss"]
})
export class ChartInsightsComponent implements OnInit, OnChanges {
  @Input() sale: ValueSummary[];
  @Input() insight: ValueSummary[];

  ngOnChanges(changes: SimpleChanges) {
    this.updateChartOption();
  }

  saleSeries: number[];
  insightsSeries: number[];
  mergeOption: any;
  chartOption: EChartOption = this.getChartOption();

  constructor(private router: Router) { }

  ngOnInit() { }

  getChartOption(): any {
    var me = this;
    return {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow"
        },
        formatter: function (params: any, ticket, callback) {
          let value = params.seriesName == "Divergências" ? params.value : me.saleSeries[params.dataIndex];

          let axisLabel = params.name;
          return `${axisLabel}<br /> ${params.seriesName}: ${value}`;
        }
      },
      legend: {
        x: "right",
        data: ["Emissões", "Divergências"],
        selectedMode: false,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: [
        {
          type: "category",
          axisLine: {
            lineStyle: {
              opacity: 0.1
            }
          },
          data: []
        }
      ],
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            opacity: 0.1
          }
        },
        splitLine: {
          lineStyle: {
            color: "#ebebeb"
          }
        },
        axisLabel: {
          formatter: (value, index) => {
            return abbreviateNumber(value);
          }
        }
      },
      series: [
        {
          name: "Divergências",
          type: "bar",
          stack: "insights",
          data: [],
          itemStyle: {
            color: "#4DD0E1"
          }
        },
        {
          name: "Emissões",
          type: "bar",
          stack: "insights",
          data: [],
          itemStyle: {
            color: "#4BA5B1"
          }
        }
      ]
    };
  }

  updateChartOption() {
    let xAxix = this.getXAxix();
    this.saleSeries = this.getSaleSeries();
    this.insightsSeries = this.getInsightsSeriesSeries();

    if (this.saleSeries == null || this.insightsSeries == null) {
      return;
    }

    let saleSeriesCurrent = this.saleSeries.map((item, index) => {
      return item - this.insightsSeries[index];
    });

    this.mergeOption = {
      xAxis: { data: xAxix },
      series: [{ data: this.insightsSeries }, { data: saleSeriesCurrent }]
    };
  }

  getXAxix(): string[] {
    if (!this.sale) return;

    this.sale.sort(function (a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    return this.sale.map(p => p.name);
  }

  getSaleSeries(): number[] {
    if (!this.sale) return;

    this.sale.sort(function (a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    return this.sale.map(p => p.value);
  }

  getInsightsSeriesSeries(): number[] {
    if (!this.insight) return;

    this.insight.sort(function (a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    return this.insight.map(p => p.value);
  }
  goInsights() {
    this.router.navigate(["/insights"]);
  }
}
