import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login/login.service';
import { Component, OnInit } from '@angular/core';
import { ModalService } from '../shared/modal/modal.service';

@Component({
  selector: 'app-complete-registration-modal',
  templateUrl: './complete-registration-modal.component.html',
  styleUrls: ['./complete-registration-modal.component.scss']
})
export class CompleteRegistrationModalComponent implements OnInit {

  public regimeTributario = [
    { id: 'SimplesNacional', name: 'Simples Nacional' },
    { id: 'LucroReal', name: 'Lucro Real' },
    { id: 'LucroPresumido', name: 'Lucro Presumido' }
  ];

  constructor(
    private modalService: ModalService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit() {
  }


  public closeModal(id) {
    this.modalService.close(id)
  }

  public updateEmitterData() {

    this.ngxLoader.start();

    this.loginService.dadosEmpresaPendente.substitutoTributarioICMS = JSON.parse(this.loginService.dadosEmpresaPendente.substitutoTributarioICMS);

    this.loginService.updateEmitterData().subscribe((d: any) => {
      if (d.sucesso) {
        this.ngxLoader.stop();
        this.toastr.success('Dados atualizados com sucesso!');
        this.closeModal('open-complete-registration');
      } else {
        this.ngxLoader.stop();
        this.toastr.error(d.mensagem || 'Ocorreu um erro desconhecido, verifique seus dados e tente novamente');
      }
    }, (er) => {
      this.ngxLoader.stop();
      this.toastr.error(er.mensagem || 'Ocorreu um erro desconhecido, verifique seus dados e tente novamente');
    });
  }

}
