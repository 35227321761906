
export function formatMoney(value: number) {
    return value.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
};

export function formatMoneyPrefix(value: number, prefix: string = 'R$ ') {
    return `${prefix}${formatMoney(value)}`;
};

export function formatInt(value: number) {
    return value.toLocaleString('pt-BR', { maximumFractionDigits: 0 })
};

export function abbreviateNumber(value: number) {
    let milhar = value >= 1000;
    if (milhar) {
        value = value / 1000;
        return formatInt(value) + "k";
    }
    return formatInt(value);
};