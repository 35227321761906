export interface CheckboxColumn{
    checked:boolean;
    indeterminate:boolean;
    labelPosition:string;
    disabled:boolean;
    labeltext:string;
    class?:string;
}

export enum LabelPositionEnum{
    after=0,
    before=1
}