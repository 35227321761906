export class EmitterDetail {
    doc: string;
    docType: number;
    telefone: string;
    uf: string;
    municipio: string;
    cep: string;
    bairro: string;
    logradouro: string;
    numero: string;
    email: string;
    ie: string;
}
