import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FaqService } from './../faq.service';
import { FaqResponse } from './../DTO/faq.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DomeDictionary } from 'src/app/dictionary';

@Component({
  selector: 'app-faq-duvidas',
  templateUrl: './faq-duvidas.component.html',
  styleUrls: ['./faq-duvidas.component.scss']
})
export class FaqDuvidasComponent implements OnInit {
  constructor(private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private faqService: FaqService) { }

  listaFaq: FaqResponse[];
  currentIndex: number = 0;

  ngOnInit() {
    this.CarregarFaq();
  }

  CarregarFaq() {
    this.ngxLoader.start();

    this.faqService.GetFaq().subscribe(data => {
      this.listaFaq = data;
      this.ngxLoader.stop();
    }, () => {
      this.toastr.info(DomeDictionary.ErroConexaoServidor, "");
      this.ngxLoader.stop();
    });
  }

  onClickItem(index: number) {
    this.currentIndex = index;
  }

  getConteudo() {
    if (this.listaFaq && this.listaFaq.length > this.currentIndex) {
      return this.listaFaq[this.currentIndex].conteudo;
    }
  }

  getTitulo() {
    if (this.listaFaq && this.listaFaq.length > this.currentIndex) {
      return this.listaFaq[this.currentIndex].titulo;
    }
  }
}
