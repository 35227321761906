export class DomeDictionary {

    static ArquivoIndisponivel = "Arquivo indisponível para download.";
    static CompraSemChaveAcesso = "Essa compra não possui uma chave de acesso.";
    static ConfirmacaoPagamento = "Confirmação de pagamento realizado com sucesso.";
    static ConfirmacaoTransmissao = "Confirmação de transmissão realizado com sucesso.";
    static ConfirmaAlteracaoManifestacao = "Deseja alterar a manifestação desta nota? Essa ação só pode ser realizada uma vez e não pode ser desfeita.";
    static ConfirmaManifestarAceite = "Deseja realizar a manifestação de aceite/confirmação da nota?";
    static ConfirmaManifestarRecusa = "Deseja realizar a manifestação de recusa da nota?";
    static ConfirmaDesconhecimento = "Deseja realizar o desconhecimento total da nota?";
    static ConfirmaAceiteInsight = "Deseja realizar o aceite do Insight Tributário?"
    static ConfirmaRecusaInsight = "Deseja recusar este Insight Tributário?";
    static DadosInvalidos = 'Dados inválidos ou vazios.';
    static DeclaracaoFiscalEnviada = "Declarações fiscais enviadas com sucesso.";
    static DownloadRealizado = "Download do arquivo com sucesso.";
    static EmailEnviado = "E-mail enviado.";
    static ErroConexaoServidor = "Erro ao acessar o servidor, tente novamente mais tarde.";
    static ErroSolicitarArquivo = "Erro ao solicitar arquivo.";
    static ErroEnviarAceiteInsight = "Erro ao enviar aceite de insight.";
    static ExistemNotasSelecionadas = 'Existem itens da grid selecionados, neste caso a ação de manifestação deve ser realizada pela barra superior de ação.';
    static ExistemInsightsSelecionados = 'Existem itens da grid selecionados, neste caso a ação de aceite tributário deve ser realizada pela barra superior de ação.';
    static InformeEmailDestinatario = "Informe o e-mail do destinatário.";
    static InformeTodosCampos = "Informe todos os campos.";
    static ManifestacaoSeraProcessada = "A manifestação será processada.";
    static ManifestacoesSeraoProcessadas = "As manifestações serão processadas.";
    static NotaSemChaveAcesso = "Essa nota não possui uma chave de acesso.";
    static SelecioneUmaLinha = "Selecione pelo menos uma linha na lista.";
    static SenhaAlterada = "Senha alterada com sucesso.";
    static SessaoExpirou = 'Sua sessão expirou! Faça login novamente.';
    static FalhaSolicitacaoDAS = "Falha na solicitação de geração da DAS.";
    static SolicitacaoEmAndamento = "Solicitação em andamento.";
    static ErroInterno = "Erro interno ao tentar realizar essa operação";
    static ErroDownload = "Falha ao tentar realizar o download";

    static ArquivoSolicitado = (mesano) => (`Arquivo solicitado com sucesso para o período ${mesano}.`);
    static ConfirmaManifestarAceiteLote = (quantidade) => (`Deseja realizar a manifestação de aceite/confirmação das ${quantidade} notas selecionadas?`);
    static ConfirmaManifestarRecusaLote = (quantidade) => (`Deseja realizar a manifestação de recusa das ${quantidade} notas selecionadas?`);
    static ConfirmaDesconhecimentoLote = (quantidade) => (`Deseja realizar o desconhecimento total das ${quantidade} notas selecionadas?`);
    static ConfirmaAceiteInsightLote = (quantidade) => (`Deseja realizar o aceite dos ${quantidade} Insights Tributários selecionados?`)
    static ConfirmaRecusaInsightLote = (quantidade) => (`Deseja realizar a recusa dos ${quantidade} Insights Tributários selecionados?`)
    static ErroEnviarManifestacao = (erro) => (`Erro ao enviar manifestação:: ${erro}.`)
	static ErroEnviarManifestacoes = (erro) => (`Erro ao enviar as manifestações: ${erro}.`)
	static FalhaRealizarOperacao = (errorId) => (`Falha ao realizar operação. Realize uma nova tentativa. Caso persista o problema informe o código de erro ${errorId} ao suporte para maiores informações.`);
	static ConfirmarOperacao = "Deseja continuar com a operação?";
    static SucessoOperacao = "Operação realizada com sucesso.";
    static SemPermissao = "Acesso negado. Usuário sem permissão.";
}
