import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/map'

export interface Message {
    type: MessageType;
    payload?: any;
}

export enum MessageType {
    ATUALIZAR_MENU,
    ATUALIZA_HEADER = 'ATUALIZA_HEADER'
}

export type MessageCallback = (payload: any) => void;

@Injectable({
    providedIn: "root"
  })
export class EventsService {
    private handler = new Subject<Message>();

    constructor() { }

    /**
     * Broadcasts message to subscribers
     */
    broadcast(type: MessageType, payload: any = null) {
        this.handler.next({ type, payload });
    }

    /**
     * Handles subscription to MessageTypes messages
     */
    subscribe(type: MessageType, callback: MessageCallback): Subscription {
        return this.handler
            .filter(message => message.type === type)
            .map(message => message.payload)
            .subscribe(callback);
    }
}