import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, EventEmitter } from "@angular/core";
import { Actions, Menu, ActionMenu } from 'src/app/sales/DTO/actions.model';
import { GridColumn } from '../shared/grid/DTO/gridColumn.model';
import { PurchaseData, PurchaseDataService, PurchaseDetailsDataService, TaxAuditDetail } from './DTO/purchasedata.model';
import { MatTableDataSource, MatIconRegistry, MatTabNav } from '@angular/material';
import { IconGrid } from '../shared/grid/DTO/icongrid.model';
import { DatePipe, formatCurrency } from '@angular/common';
import { PurchaseService } from './purchase.service';
import { trigger, state, style, transition, animate } from '@angular/animations'
import { DomSanitizer } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PurchaseRQ } from './DTO/purchaseRQ.model';
import { SetManifestationRQ } from './DTO/setManifestationRQ.model';
import { GridComponent } from '../shared/grid/grid.component';
import { PurchaseNteDetailComponent } from './purchasentedetail/purchasentedetail.component';
import { PurchaseItensDetailComponent } from './purchaseitensdetail/purchaseitensdetail.component';
import { PurchaseManifestsDetailComponent } from './purchasemanifestsdetail/purchasemanifestsdetail.component';
import { PurchaseEventsDetailComponent } from './purchaseeventsdetail/purchaseeventsdetail.component';
import { PurchaseAuditsDetailComponent } from './purchaseauditsdetail/purchaseauditsdetail.component';
import { PurchaseCteDetailComponent } from './purchasectedetail/purchasectedetail.component';
import { tabs } from '../shared/tabs/DTO/tabs.model';
import { TabsComponent } from '../shared/tabs/tabs.componet';
import { DashBoardPurchaseRQ } from '../modelstransport/dashboardpuchaseRQ';
import { FilterComponent } from '../shared/filter/filter.component';
import { LoginService } from '../login/login.service';
import { NFeMod, Role, StatusManifestacao } from '../_models/enums';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../shared/modal/modal.service';
import { CreateNewGridColumn, CreateGridAction, CreateNewIcon, CreateNewCheckBox, CreateNewCheckBoxColumn, CreateNewGridColumnCheckbox, CreateGridActionMenu, CreateGridMenu } from '../shared/grid/grid.helper';
import { LabelPositionEnum } from '../shared/grid/DTO/checkboxColumn.model';
import { DefaultResponse } from '../_models/default-response';
import { DomeDictionary } from './../dictionary';
import { PurchaseemitterdetailComponent } from "./purchaseemitterdetail/purchaseemitterdetail.component";
import { PurchaseItenDetailModal } from './purchaseitemdetailmodal/DTO/purchaseitensdetailmodal.model';
import { saveAs } from 'file-saver';
import xlsx, { IJsonSheet, ISettings } from "json-as-xlsx"

import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { FormControl } from "@angular/forms";
import { UserService } from "../user/user.service";
import { catchError } from "rxjs/operators";

// const classmanifest: string[] = ['circle-yellow', 'circle-yellow', 'circle-grey', 'circle-green', 'circle-red', 'circle-orange', 'circle-black', 'circle-red'];
const classmanifest: string[] = ['circle-green', 'circle-red', 'circle-orange', 'circle-yellow', 'circle-grey', 'circle-black', 'circle-black', 'circle-red'];
const classtaxauditor: string[] = ['circle-yellow', 'done-green', 'icon-lx-exclamation-2', 'priority-high', 'circle-yellow', 'circle-yellow', 'circle-black'];

@Component({
	selector: 'app-purchase',
	templateUrl: './purchase.component.html',
	styleUrls: ['./purchase.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('toggleSearch', [
			state('visible', style({ opacity: 1 })),
			transition('hidden=>visible', [
				style({ opacity: 0, transform: 'translate(-30px,-10px)' }),
				animate('500ms 0s ease-in-out')
			]),
			state('hidden', style({ opacity: 0 })),
			transition('visible=>hidden', [
				style({ opacity: 1, transform: 'translate(30px,10px)' }),
				animate('500ms 0s ease-in-out')
			])
		])
	]
})

export class PurchaseComponent implements OnInit {
	searchbarState = 'hidden';
	dataSource: MatTableDataSource<PurchaseData>;
	purchasesFull: PurchaseDataService[];
	purchaseDetails: PurchaseDetailsDataService;
	dataSourceAudits: TaxAuditDetail;
	purchaseItemDetails: PurchaseDetailsDataService;
	purchases: PurchaseData[] = [];
	purchaseSelected: PurchaseData;
	itemArray: PurchaseItenDetailModal[] = [];
	itemSelected: PurchaseItenDetailModal;
	selected = new FormControl(0);
	icons: IconGrid[] = [];

	SelectedType = 'data-emissao';

	gridcolumns: GridColumn[] = [];
	purchaseItemsGridColumns: GridColumn[] = [];
	purchaseEventsGridColumns: GridColumn[] = [];
	purchaseAuditsGridColumns: GridColumn[] = [];
	purchaseItemDetailsGridColumns: GridColumn[] = [];
	columnsCfop: GridColumn[] = [];
	columnsDocuments: GridColumn[] = [];
	columnsRelationships: GridColumn[] = [];
	columnsConvertedDocuments: GridColumn[] = [];
	errorsColumn: GridColumn[] = []

	title: string;
	titleEnabled: boolean;
	classRefresh: string;
	titleRefresh: string;
	enabledRefresh: boolean;
	hiddenCheckboxOptions: boolean = true;
	cfopList: any = [];
	dynamicTabs: tabs[];
	tabsnfe: string[] = ["Nota", "Ítens", "Eventos", "Manifestações", "Auditor tributário", "Cte"];
	tabscte: string[] = ["Cte", "Ítens", "Eventos", "Manifestações", "Auditor tributário"];
	finalidades = [
		'Compra Para Comercialização',
		'Compra Para Industrialização',
		'Compra De Combustível Para Indústria',
		'Compra De Combustível Para Comércio',
		'Aquisição De Frete Para Indústria',
		'Aquisição De Frete Para Comércio',
		'Compra De Energia Elétrica',
		'Serviço De Comunicação',
		'Compra Para Uso e Consumo',
		'Transferência Recebida Para Indústria',
		'Transferência Recebida Para Comércio',
	];
	typeFilter: number;
	datepicketIn: Date;
	datepicketFin: Date;
	classTitle: string = "grid-Title";
	CfopPayload = this.resetCfopPayload();
	@ViewChild('ctedetail') cteDetail: PurchaseCteDetailComponent;
	@ViewChild('cteNTEdetail') cteNTEDetail: PurchaseCteDetailComponent;
	@ViewChild('manifestsdetail') manifestsDetail: PurchaseManifestsDetailComponent;
	@ViewChild('eventsdetail') eventsDetail: PurchaseEventsDetailComponent;
	@ViewChild('itensdetail') itensDetail: PurchaseItensDetailComponent;
	@ViewChild('itensdetail') itensDetailX: PurchaseItensDetailComponent;
	@ViewChild('emitterDetail') emitterDetail: PurchaseemitterdetailComponent;
	@ViewChild('ntedetail') nteDetail: PurchaseNteDetailComponent;
	@ViewChild('tabgroup') tabgroup: any;
	@ViewChild('tabs') tabs: TabsComponent;
	@ViewChild('gridpurchasecteitems') gridPurchasecteitems: GridComponent;
	@ViewChild('typedocument') typeDocument: ElementRef;
	@ViewChild('matTabs') matTabs: MatTabNav;
	@ViewChild('filter') filter: FilterComponent;
	@ViewChild('filterConverted') filterConverted: FilterComponent;
	@ViewChild('firstTabNfe') firstTabNfe: ElementRef;
	@ViewChild('gridpurchase') gridPurchase: GridComponent;
	@ViewChild('gridDocuments') gridDocuments: GridComponent;
	@ViewChild('gridConvertedDocuments') gridConvertedDocuments: GridComponent
	@ViewChild('gridRelationships') gridRelationships: GridComponent;
	@ViewChild('gridCfop') gridCfop: GridComponent;
	@ViewChild('gridSuppliers') gridSuppliers: GridComponent;
	@ViewChild('gridErrors') gridErrors: GridComponent;

	DashBoardPurchaseParameter: DashBoardPurchaseRQ;
	CfopAction: string;
	cnpj: string;
	fiscalDocuments: any = [];
	selectedNFe: any = {};
	isLoggedInDomeCloud: string = '';
	relationships: any;
	relationshipPayload: any = {
		"cnpjFornecedor": "",
		"finalidade": "",
		"razaoSocialFornecedor": "",
		"converterAutomaticamente": null
	};
	suppliersColumn: any[];
	supplierList: any;

	regimeTributario = [
		{ id: 'SimplesNacional', name: 'Simples Nacional' },
		{ id: 'LucroReal', name: 'Lucro Real' },
		{ id: 'LucroPresumido', name: 'Lucro Presumido' }
	];

	caracteristicaTributaria = [
		{ id: 'Industria', name: 'Industria' },
		{ id: 'Distribuidor', name: 'Distribuidor' },
		{ id: 'Atacadista', name: 'Atacadista' },
		{ id: 'Varejista', name: 'Varejista' },
		{ id: 'ProdutorRuralPessoaJuridica', name: 'Produtor Rural Pessoa Jurídica' },
		{ id: 'ProdutorRuralPessoaFisica', name: 'Produtor Rural Pessoa Física' },
		{ id: 'PessoaJuridicaNaoContribuinteIcms', name: 'Pessoa Jurídica Não Contribuinte Icms' },
		{ id: 'PessoaFisicaNaoContribuinteIcms', name: 'Pessoa Física Não Contribuinte Icms' },
	];
	SupplierAction: any;
	SupplierPayload: any = this.resetSupplierPayload();

	errorsData: any = [];
	csvFile: any;
	successCsv: any = [];
	errorsCsv: any[];

	statusNota: string = '';
	manifestacao: string = '';
	numeroInicial: number = null;
	numeroFinal: number = null;

	manifestation = [
		"Aceite",
		"Recusa",
		"Desconhecimento",
		"Aguardando Manifestação",
		"Ciência da Operação",
		"Erro"
	]
	hiddenReprocess: boolean = true;
	showDfe: boolean;

	constructor(private datePipe: DatePipe,
		private purchaseService: PurchaseService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private toastr: ToastrService,
		private ngxLoader: NgxUiLoaderService,
		private loginService: LoginService,
		private coolDialogs: NgxCoolDialogsService,
		private modalService: ModalService,
		private userService: UserService
	) {
	}

	ngOnInit() {
		this.loginService.CheckRolebyUser(Role.NOTA_DE_ENTRADA);
		this.isLoggedInDomeCloud = this.loginService.user.accessTokenDomeCloud;
		this.cnpj = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf })[0];
		if (sessionStorage.DashBoardPurchaseParameter) {
			this.DashBoardPurchaseParameter = <DashBoardPurchaseRQ>JSON.parse(sessionStorage.DashBoardPurchaseParameter);
			this.typeFilter = this.DashBoardPurchaseParameter.filterType;
			this.filter.dateIn.setValue(this.DashBoardPurchaseParameter.dateIn);
			this.filter.dateOut.setValue(this.DashBoardPurchaseParameter.dateFin);
			this.filter.typeFilter = this.typeFilter;
			this.typeDocument.nativeElement.value = this.DashBoardPurchaseParameter.typeDocument;
			sessionStorage.removeItem("DashBoardPurchaseParameter");
		}
		else {
			this.typeFilter = 1;
		}

		this.ChangeDocumentType();
		this.GetPurchases();
		this.CreateIcons();
		this.startTableConfigs();
		this.populatePurchaseTable();
		this.populateSuppliersTable();
		this.populateErrorsTable();
		if (this.isLoggedInDomeCloud) {
			this.populateRelationshipsTable();
			this.populateDocumentsTable();
			this.populateCfopTable();
			this.getRelationships();
			this.ListDocuments(1);
			this.ListDocuments(5);
			this.ListSuppliers();
			this.ngxLoader.start();
			this.userService.GetCompleteEmitterRegistration(this.cnpj)
				.pipe(catchError(error => {
					this.ngxLoader.stop();
					throw new error;
				}))
				.subscribe(data => {
					if (data.consultaDfe) {
						this.showDfe = true;
					}
					this.ngxLoader.stop();
				});
		}
	}

	CreateIcons() {
		this.matIconRegistry.addSvgIcon(
			'refresh',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/refresh.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'thumb-up',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/thumb_up.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'thumb-down',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/thumb_down.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'Unaware-Operation',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/Unaware_Operation.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'download',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/download.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'file-pdf-regular',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/file-pdf-regular.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'hand-point-up-regular',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/hand-point-up-regular.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'file-code-solid',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/file-code-solid.svg')
		);
	}

	extractReport(type: string) {
		let param = this.filter.GetFilterData();
		let request: PurchaseRQ = {
			datein: param.dateIn,
			dateout: param.dateOut,
			selectedType: this.SelectedType,
			CNPJ: [],
			typeDocument: this.typeDocument.nativeElement.value,
			StatusNota: this.statusNota ? this.statusNota : null,
			Manifestacao: this.manifestacao ? +this.manifestacao : null,
			NumeroInicial: this.numeroInicial ? this.numeroInicial : null,
			NumeroFinal: this.numeroFinal ? this.numeroFinal : null
		}

		if (this.numeroFinal && (this.numeroFinal > this.numeroFinal)) {
			this.toastr.info("O intervalo de número de nota selecionado está incorreto, por favor corrija os dados e tente novamente.", "")
			return;
		}

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });

		this.StartDataClicked();

		this.purchaseService.GetPurchasesReport(request).subscribe(
			data => {

				if (data.length == 0) {
					this.FinishDataClicked();
					this.toastr.info("O filtro selecionado não retornou nenhum registro para o relatório.", "")
					return;
				}

				if (type == 'xlsx') {
					this.downloadXlsxReport(data)
				} else {
					this.downloadPdfReport(data);
				}

				this.FinishDataClicked();
			}, error => {
				this.FinishDataClicked();
				if (error.erros) {
					this.toastr.info(error.erros[0].descricao, "");
				} else if (error.message) {
					if (error.name === "HttpErrorResponse") {
						this.toastr.info(DomeDictionary.ErroConexaoServidor, "")
					} else {
						this.toastr.info(error.message, "")
					}
				}
			});
	}

	downloadPdfReport(data) {
		const doc = new jsPDF({
			orientation: 'landscape'
		});
		const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
		const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

		const emitter = this.loginService.user.economicGroup.emitters[0];


		doc.setTextColor('#411e5a');
		doc.setFontSize(20);
		doc.text('DOME - Relatório de Manifestação', pageWidth / 2, 15, { align: 'center' });

		doc.setTextColor('#000000');
		doc.setFontSize(12);
		doc.text(`Razão Social: ${emitter.razaoSocialNomeCompleto}`, 14, 33, { align: 'left' });
		doc.text(`CNPJ: ${emitter.cnpjCpf}`, 14, 42, { align: 'left' });

		autoTable(doc, {
			head: [['Número', 'Data de Emissão', 'Status', 'Manifestação', 'Emitente', 'Valor', 'Chave da Nota']],
			body:
				data.map((registro) => [
					registro.numero,
					new Date(registro.dataEmissao).toLocaleDateString('pt-BR'),
					registro.status,
					this.manifestation[registro.manifestacao],
					registro.emitente ? registro.emitente : (registro.emissor ? registro.emissor.nome : ''),
					registro.valor,
					registro.chaveNfe
				]),
			startY: 50
		})

		doc.save(`RelatorioManifestacao_${new Date().getTime()}.pdf`)

	}

	downloadXlsxReport(data) {

		const emitter = this.loginService.user.economicGroup.emitters[0];

		let xlsxData: IJsonSheet[] = [
			{
				sheet: "Relatório",
				columns: [
					{ label: 'Número', value: 'numero' },
					{ label: 'Data de Emissão', value: (row: any) => new Date(row.dataEmissao).toLocaleDateString('pt-BR') },
					{ label: 'Status', value: 'status' },
					{ label: 'Manifestação', value: (row: any) => this.manifestation[row.manifestacao] },
					{ label: 'Emitente', value: (row: any) => row.emitente || row.emissor.nome },
					{ label: 'Valor', value: 'valor' },
					{ label: 'Chave da Nota', value: 'chaveNfe' }
				],
				content: data.map(nf => {
					if (!nf.emissor) {
						nf.emissor = {
							nome: ''
						}
					}
					return nf;
				})
			}
		]

		let settings: ISettings = {
			fileName: `RelatorioManifestacao_${new Date().getTime()}`,
			extraLength: 4,
			writeMode: "writeFile",
		}

		xlsx(xlsxData, settings);

	}

	startTableConfigs() {
		this.title = "MANIFESTAÇÃO DE DESTINATÁRIO";
		this.enabledRefresh = true;
		this.titleRefresh = "Atualização";
		this.classRefresh = "div-refresh";

		this.icons.push(CreateNewIcon('circle-green', '../assets/svg/circle_green.svg', 'Manifestação Realizada'));
		this.icons.push(CreateNewIcon('circle-red', '../assets/svg/circle_red.svg', 'Manifestação não realizada/Desacordo'));
		this.icons.push(CreateNewIcon('circle-black', '../assets/svg/circle_black.svg', 'Falha na Operação'));
		this.icons.push(CreateNewIcon('circle-orange', '../assets/svg/circle_orange.svg', 'Manifestação de desconhecimento'));
		this.icons.push(CreateNewIcon('circle-grey', '../assets/svg/circle_grey.svg', 'Manifestação em processo'));
		this.icons.push(CreateNewIcon('circle-yellow', '../assets/svg/circle_yellow.svg', 'Operação Pendente'));
		this.icons.push(CreateNewIcon('done-green', '../assets/svg/done_green.svg', 'Nota Fiscal sem ocorrências'));
		this.icons.push(CreateNewIcon('priority-high', '../assets/svg/priority_high.svg', 'Nota Fiscal possui ocorrências de advertências'));
		this.icons.push(CreateNewIcon('icon-lx-exclamation-2', '../assets/svg/clear_red.svg', 'Nota Fiscal com divergências'));
	}

	populateCfopTable() {
		this.columnsCfop = [];
		this.columnsCfop.push(CreateNewGridColumn(0, 'codigoSaida', 'Código Saída', (element: any) => `${element.codigoSaida}`));
		this.columnsCfop.push(CreateNewGridColumn(120, 'codigoEntrada', 'Código Entrada ', (element: any) => `${element.codigoEntrada}`));
		this.columnsCfop.push(CreateNewGridColumn(380, 'cstIcmsEntrada', 'CST ICMS Entrada', (element: any) => `${element.cstIcmsEntrada}`));
		this.columnsCfop.push(CreateNewGridColumn(0, 'cstIpiEntrada', 'CST IPI Entrada', (element: any) => `${element.cstIpiEntrada}`));
		this.columnsCfop.push(CreateNewGridColumn(160, 'cstPisCofinsEntrada', 'CST PIS/Cofins Entrada', (element: any) => `${element.cstPisCofinsEntrada}`));
		this.columnsCfop.push(CreateNewGridColumn(0, 'acao', 'Ações', (element: any) => `${element.actions}`, false, true));
		this.StartDataClicked();
		this.purchaseService.ListCfop(this.cnpj).subscribe((data: any) => {
			console.log(data);
			this.cfopList = data.registro.map(cfop => {
				return {
					...cfop,
					actions: [
						CreateGridAction('pencil', 'editar', CreateNewIcon('pencil', '../assets/svg/pencil.svg'), 'mat-icon icon-design', 'Editar CFOP'),
						CreateGridAction('clear_red', 'excluir', CreateNewIcon('clear_red', '../assets/svg/clear_red.svg'), 'mat-icon icon-design', 'Excluir CFOP'),
					]
				}
			});
			this.FinishDataClicked();
			this.gridCfop.refreshDateSource(this.cfopList, this.columnsCfop);
		}, (err) => {
			console.log(err);
			this.FinishDataClicked();
		});
	}

	ListSuppliers() {
		if (this.isLoggedInDomeCloud) {
			this.StartDataClicked();
			this.purchaseService.GetSupplier().subscribe((data: any) => {
				this.supplierList = data.registro.map(cfop => {
					return {
						...cfop,
						actions: [
							CreateGridAction('pencil', 'editar', CreateNewIcon('pencil', '../assets/svg/pencil.svg'), 'mat-icon icon-design', 'Editar Fornecedor'),
						]
					}
				});
				this.FinishDataClicked();
				this.gridSuppliers.refreshDateSource(this.supplierList, this.suppliersColumn);
			}, (err) => {
				this.FinishDataClicked();
			});
		}
	}

	populateSuppliersTable() {
		this.suppliersColumn = [];
		this.suppliersColumn.push(CreateNewGridColumn(200, 'ativo', 'Ativo', (element: any) => `${element.ativo ? 'Sim' : 'Não'}`));
		this.suppliersColumn.push(CreateNewGridColumn(350, 'razao', 'Razão Social', (element: any) => `${element.razaoSocial}`));
		this.suppliersColumn.push(CreateNewGridColumn(250, 'cnpj', 'CNPJ', (element: any) => `${element.cnpjFornecedor}`));
		this.suppliersColumn.push(CreateNewGridColumn(250, 'regime', 'Regime Tributário', (element: any) => `${this.regimeTributario[element.regimeTributario] ? this.regimeTributario[element.regimeTributario].name : '-'}`));
		this.suppliersColumn.push(CreateNewGridColumn(250, 'caracteristica', 'Característica Tributária', (element: any) => `${this.caracteristicaTributaria[element.caracteristicaTributaria] ? this.caracteristicaTributaria[element.caracteristicaTributaria].name : '-'}`));
		this.suppliersColumn.push(CreateNewGridColumn(250, 'substituto', 'Substituto ICMS', (element: any) => `${element.substitutoTributarioIcms ? 'Sim' : 'Não'}`));
		this.suppliersColumn.push(CreateNewGridColumn(0, 'acao', 'Ações', (element: any) => `${element.actions}`, false, true));
	}

	getCsvModel() {
		this.purchaseService.getCsvArchive().subscribe(d => {
			const blob = new Blob([d.registro], { type: 'text/csv' })
			saveAs(blob, "modelo_fornecedores.csv");
		});
	}

	ManageSupplier(event) {
		if (event.action === 'editar') {
			this.SupplierAction = event.action;
			this.SupplierPayload = { ...event.data };
			this.modalService.open('open-new-fornecedor');
		}
	}

	OpenNewCfop() {
		this.CfopAction = 'salvar';
		this.cfopList = this.resetCfopPayload();
		this.modalService.open('open-new-cfop');
	}


	OpenNewFornecedor() {
		this.SupplierAction = 'salvar';
		this.SupplierPayload = this.resetSupplierPayload();
		this.modalService.open('open-new-fornecedor');
	}

	OpenCSVModal() {
		this.modalService.open('open-csv-modal');
	}

	OpenNewRelationship() {
		this.modalService.open('open-new-relationship');
		this.relationshipPayload = {};
	}

	ManageRelationship(event) {
		if (event.action === 'editar') {
			this.relationshipPayload = { ...event.data };
			this.modalService.open('open-new-relationship');
		} else {
			this.ConfirmAction("Deseja excluir este Relacionamento?", () => {
				this.StartDataClicked();
				this.purchaseService.deleteRelationship(event.data.id).subscribe((data: any) => {
					this.FinishDataClicked();
					this.getRelationships();
				})
			});
		}
	}

	handleManageSupplier() {

		for (let key in this.SupplierPayload) {
			if (this.SupplierPayload[key] === null || this.SupplierPayload[key] === '') {
				this.toastr.error('Preencha todos os campos para continuar')
				return;
			}
		}

		this.SupplierPayload.ativo = this.verifyAutomaticConvertion(this.SupplierPayload.ativo);
		this.SupplierPayload.caracteristicaTributaria = +this.SupplierPayload.caracteristicaTributaria;
		this.SupplierPayload.regimeTributario = +this.SupplierPayload.regimeTributario;
		this.SupplierPayload.substitutoTributarioIcms = this.verifyAutomaticConvertion(this.SupplierPayload.substitutoTributarioIcms);


		this.StartDataClicked();

		if (this.SupplierAction === 'editar') {
			this.SupplierPayload.emPreCadastro = false;
			this.purchaseService.updateSupplier(this.SupplierPayload, this.SupplierPayload.id).subscribe(
				(data: any) => {
					this.modalService.close('open-new-fornecedor');
					this.ListSuppliers();
					this.SupplierPayload = this.resetSupplierPayload();
				},
				((err) => {
					const keys = Object.keys(err.errors);
					if (keys.length > 0) {
						this.toastr.error(err.errors[keys[0]][0]);
					} else {
						this.toastr.error('Ocorreu um erro desconhecido ao Editar fornecedor, por favor tente novamente')
					}
					this.FinishDataClicked();
				}),
				() => {
					this.FinishDataClicked();
				});
		} else {
			this.purchaseService.saveSupplier(this.SupplierPayload).subscribe((data: any) => {
				this.modalService.close('open-new-fornecedor');
				this.ListSuppliers();
				this.SupplierPayload = this.resetSupplierPayload();
			},
				((err) => {
					const keys = Object.keys(err.errors);
					if (keys.length > 0) {
						this.toastr.error(err.errors[keys[0]][0]);
					} else {
						this.toastr.error('Ocorreu um erro desconhecido ao criar fornecedor, por favor tente novamente')
					}
					this.FinishDataClicked();
				}),
				() => {
					this.FinishDataClicked();
				});
		}
	}

	ManageCfop(event) {
		if (event.action === 'editar') {
			this.CfopAction = event.action;
			this.CfopPayload = { ...event.data };
			this.modalService.open('open-new-cfop');
		} else {
			this.ConfirmAction("Deseja excluir este CFOP?", () => {
				this.StartDataClicked();
				this.purchaseService.DeleteCfop(event.data.id).subscribe((data: any) => {
					this.populateCfopTable();
				});
			});
		}
	}

	populateErrorsTable() {
		this.errorsColumn = [];
		this.errorsColumn.push(CreateNewGridColumn(150, 'linha', 'Linha', (element: any) => `${element.linha}`));
		this.errorsColumn.push(CreateNewGridColumn(650, 'motivo', 'Motivo', (element: any) => `${element.motivo}`));
	}

	saveFile(event) {
		this.csvFile = event.target.files[0];
	}

	uploadCsv() {

		const file: File = this.csvFile;

		let formData: FormData = new FormData();

		formData.append('arquivo', new Blob([file], { type: 'text/csv' }), file.name);

		this.errorsData = [];
		this.successCsv = [];
		this.errorsCsv = [];
		this.StartDataClicked();
		return this.purchaseService.uploadCsv(formData).subscribe(d => {

			this.successCsv = d.registro
				.filter((erro) => !erro.erro);

			const errors = d.registro
				.filter((erro) => erro.erro);

			this.errorsCsv = errors;


			if (errors.length != d.registro.length) {
				this.ListSuppliers();
			}

			if (errors.length > 0) {
				const errorsTableData = [];

				errors.forEach((erro) => {
					erro.erros.forEach(err => {
						errorsTableData.push({
							motivo: err,
							linha: erro.indentificacaoLinha,
						});
					});
				});
				this.errorsData = errorsTableData;
				this.gridErrors.refreshDateSource(this.errorsData, this.errorsColumn);
				this.FinishDataClicked();
			} else {
				this.errorsData = [];
				this.successCsv = [];
				this.csvFile = null;
				this.CloseModal('open-csv-modal')
				this.ListSuppliers();
				this.toastr.success('Fornecedores cadastrados com sucesso.');
			}
		});

	}

	HandleManageCfop() {

		if (this.cfopList.find(cfop => cfop.codigoSaida == this.CfopPayload.codigoSaida && cfop.id != this.CfopPayload.id)) {
			this.toastr.error(`Esta regra de conversão já está cadastrada`, 'Ops!');
			return;
		}

		if (!this.isCfopValid()) {
			return;
		};

		if (!this.CfopPayload.cnpj) {
			this.CfopPayload.cnpj = this.cnpj;
		}

		this.StartDataClicked();

		if (this.CfopAction === 'editar') {
			this.purchaseService.UpdateCfop(this.CfopPayload, this.CfopPayload.id).subscribe((data: any) => {
				this.modalService.close('open-new-cfop');
				this.populateCfopTable();
				this.CfopPayload = this.resetCfopPayload();
				this.FinishDataClicked();
			}, () => {
				this.FinishDataClicked();
			});
		} else {
			this.purchaseService.InsertCfop(this.CfopPayload).subscribe((data: any) => {
				this.modalService.close('open-new-cfop');
				this.populateCfopTable();
				this.CfopPayload = this.resetCfopPayload();
			}, () => {
				this.FinishDataClicked();
			});
		}
	}

	isCfopValid() {
		const acceptedChar = {
			codigoEntrada: {
				char: ['1', '2', '3'],
				field: 'Codigo Entrada'
			},
			codigoSaida: {
				char: ['5', '6', '7'],
				field: 'Codigo Saída'
			}
		}
		for (let key in acceptedChar) {
			if (!acceptedChar[key].char.includes(this.CfopPayload[key].toString()[0])) {
				this.FinishDataClicked();
				this.toastr.error(`O campo ${acceptedChar[key].field} deve começar com o dígito "${acceptedChar[key].char[0]}, ${acceptedChar[key].char[1]} ou ${acceptedChar[key].char[2]}"`, 'Ops!');
				return false;
			}
			if (this.CfopPayload[key].toString().length != 4) {
				this.FinishDataClicked();
				this.toastr.error(`O campo ${acceptedChar[key].field} deve ter 4 dígitos`, 'Ops!');
				return false;
			}
			if (!this.CfopPayload[key].toString().match(/^[0-9]+$/)) {
				this.FinishDataClicked();
				this.toastr.error(`O campo ${acceptedChar[key].field} deve conter somente números`, 'Ops!');
				return false;
			}
		}
		return true;
	}

	resetCfopPayload() {
		return {
			codigoEntrada: '',
			codigoSaida: '',
			cstIcmsEntrada: '',
			cstIpiEntrada: '',
			cstPisCofinsEntrada: '',
			cnpj: '',
			id: ''
		}
	}

	resetSupplierPayload() {
		return {
			cnpjFornecedor: '',
			razaoSocial: '',
			regimeTributario: '',
			caracteristicaTributaria: '',
			substitutoTributarioIcms: '',
			ativo: '',
			emPreCadastro: false
		}
	}

	populatePurchaseTable() {
		if (this.hasRole(Role.COL_AUDITOR)) {
			this.purchaseItemsGridColumns.push(CreateNewGridColumn(60, 'tax_auditor', 'Auditor tributário', (element: any) => CreateGridAction(element.tax_auditor.iconclass, element.tax_auditor.iconfunction, element.tax_auditor.iconGrid, element.tax_auditor.iconClassComponent, element.tax_auditor.tooltip), true));
		}
		this.purchaseItemsGridColumns.push(CreateNewGridColumn(60, 'numero', 'Nº', (element: any) => `${element.numero}`));
		this.purchaseItemsGridColumns.push(CreateNewGridColumn(100, 'codigo', 'Código do Produto', (element: any) => `${element.codigo}`));
		this.purchaseItemsGridColumns.push(CreateNewGridColumn(450, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
		this.purchaseItemsGridColumns.push(CreateNewGridColumn(80, 'quantidade', 'Quantidade', (element: any) => `${element.quantidade}`));
		this.purchaseItemsGridColumns.push(CreateNewGridColumn(105, 'valor_unitario', 'Valor unitário', (element: any) => `${element.valor_unitario}`));
		this.purchaseItemsGridColumns.push(CreateNewGridColumn(150, 'valor_total', 'Total', (element: any) => `${element.valor_total}`));
		this.purchaseItemsGridColumns.push(CreateNewGridColumn(100, 'item_details', 'Detalhes', (element: any) => `${element.actions}`, false, true));

		this.purchaseEventsGridColumns.push(CreateNewGridColumn(125, 'tipo', 'Tipo do evento', (element: any) => `${element.tipo}`));
		this.purchaseEventsGridColumns.push(CreateNewGridColumn(120, 'data', 'Data/Hora', (element: any) => `${element.data}`));
		this.purchaseEventsGridColumns.push(CreateNewGridColumn(380, 'id', 'ID evento', (element: any) => `${element.id}`));
		this.purchaseEventsGridColumns.push(CreateNewGridColumn(0, 'status', 'Status', (element: any) => `${this.checkIcon(element.status)}`, true));
		this.purchaseEventsGridColumns.push(CreateNewGridColumn(0, 'status_sefaz', 'Status no SEFAZ', (element: any) => `${element.status_sefaz}`));
		this.purchaseEventsGridColumns.push(CreateNewGridColumn(160, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
		this.purchaseEventsGridColumns.push(CreateNewGridColumn(0, 'eventActions', 'Ações', (element: any) => `${element.actions}`, false, true));

		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(60, 'id', 'Nº item', (element: any) => `${element.id}`));
		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(200, 'codProdItem', 'Código Produto', (element: any) => `${element.codProdItem}`));
		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(450, 'descricaoItem', 'Descrição item', (element: any) => `${element.descricaoItem}`));
		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(300, 'descricaoErrors', 'Ocorrência', (element: any) => `${element.descricaoError}`));
		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(100, 'codErrors', 'Código Atual', (element: any) => `${element.original}`));
		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(300, 'sugestaoErrors', 'Sugestão Auditoria', (element: any) => `${element.sugestaoError}`));
	}

	ChangeDocumentType() {
		this.gridcolumns = [];
		if (this.typeDocument.nativeElement.value == "1") {
			this.gridcolumns.push(CreateNewGridColumnCheckbox(80, 'checkbox', '', (element: any) => `${element.checkbox}`, false, false, true, CreateNewCheckBoxColumn(false, false, LabelPositionEnum.after, false, '')));
			this.gridcolumns.push(CreateNewGridColumn(125, 'cnpjsupplier', 'CNPJ do Fornecedor', (element: any) => `${element.cnpjsupplier}`));
			this.gridcolumns.push(CreateNewGridColumn(350, 'suppliername', 'Nome do Fornecedor', (element: any) => `${element.suppliername}`));
			this.gridcolumns.push(CreateNewGridColumn(95, 'emissionDate', 'Emissão', (element: any) => `${this.datePipe.transform(new Date(element.emissionDate), "dd/MM/yyyy")}`));
			this.gridcolumns.push(CreateNewGridColumn(100, 'numero_documento', 'Nota', (element: any) => `${element.numero_documento}`));
			this.gridcolumns.push(CreateNewGridColumn(60, 'qtd_itens', 'Itens', (element: any) => `${element.qtd_itens}`));
			this.gridcolumns.push(CreateNewGridColumn(90, 'totalamount', 'Valor Total', (element: any) => `${element.totalamount}`));
			if (this.hasRole(Role.COL_AUDITOR)) {
				this.gridcolumns.push(CreateNewGridColumn(95, 'tax_auditor', 'Auditor tributário', (element: any) => CreateGridAction(element.tax_auditor.iconclass, element.tax_auditor.iconfunction, element.tax_auditor.iconGrid, element.tax_auditor.iconClassComponent, element.tax_auditor.tooltip), true));
			}
			this.gridcolumns.push(CreateNewGridColumn(95, 'manifestation', 'Manifestação', (element: any) => CreateGridAction(element.manifestation.iconclass, element.manifestation.iconfunction, element.manifestation.iconGrid, element.manifestation.iconClassComponent, element.manifestation.tooltip), true));
			this.gridcolumns.push(CreateNewGridColumn(200, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));
		}
		else {
			this.gridcolumns.push(CreateNewGridColumnCheckbox(80, 'checkbox', '', CreateNewCheckBoxColumn(false, false, LabelPositionEnum.after, false, ''), false, false, true, CreateNewCheckBoxColumn(false, false, LabelPositionEnum.after, false, 'Todos')));
			this.gridcolumns.push(CreateNewGridColumn(125, 'cnpjsupplier', 'CNPJ do Fornecedor', (element: any) => `${element.cnpjsupplier}`));
			this.gridcolumns.push(CreateNewGridColumn(350, 'suppliername', 'Nome do Fornecedor', (element: any) => `${element.suppliername}`));
			this.gridcolumns.push(CreateNewGridColumn(95, 'emissionDate', 'Emissão', (element: any) => `${this.datePipe.transform(new Date(element.emissionDate), "dd/MM/yyyy")}`));
			this.gridcolumns.push(CreateNewGridColumn(100, 'numero_documento', 'Nota', (element: any) => `${element.numero_documento}`));
			this.gridcolumns.push(CreateNewGridColumn(60, 'qtd_itens', 'Itens', (element: any) => `${element.qtd_itens}`));
			this.gridcolumns.push(CreateNewGridColumn(90, 'totalamount', 'Valor Total', (element: any) => `${element.totalamount}`));
			this.gridcolumns.push(CreateNewGridColumn(125, 'cnpjdestinatario', 'CNPJ do Destinatário', (element: any) => `${element.cnpjdestinatario}`));
			this.gridcolumns.push(CreateNewGridColumn(95, 'manifestation', 'Manifestação', (element: any) => CreateGridAction(element.manifestation.iconclass, element.manifestation.iconfunction, element.manifestation.iconGrid, element.manifestation.iconClassComponent, element.manifestation.tooltip), true));
			this.gridcolumns.push(CreateNewGridColumn(200, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));
		}

		this.filterDate()
	}


	GetPurchases() {
		let param = this.filter.GetFilterData();
		let request: PurchaseRQ = {
			datein: param.dateIn,
			dateout: param.dateOut,
			selectedType: this.SelectedType,
			CNPJ: [],
			typeDocument: this.typeDocument.nativeElement.value,
			StatusNota: this.statusNota ? this.statusNota : null,
			Manifestacao: this.manifestacao ? +this.manifestacao : null,
			NumeroInicial: this.numeroInicial ? this.numeroInicial : null,
			NumeroFinal: this.numeroFinal ? this.numeroFinal : null
		}

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });

		this.StartDataClicked();

		let hasPermissionToManifest = this.loginService.hasRole(Role.MANIFESTAR);
		let nfeIsSelected = this.typeDocument.nativeElement.value == "1";

		this.purchaseService.GetPurchases(request).subscribe(
			data => {
				this.purchases = [];
				this.purchasesFull = data;
				this.purchasesFull.forEach(data => {
					let manifastationIsPendentOrError = (data.manifestation == StatusManifestacao.Pendente || data.manifestation == StatusManifestacao.Erro);

					var showManifestationActions = manifastationIsPendentOrError && nfeIsSelected && hasPermissionToManifest;

					var item: PurchaseData = {
						actions: [],
						cnpjsupplier: data.cnpjsupplier,
						numero_documento: data.numero_documento,
						chave: data.chave,
						emissionDate: data.emissionDate.toString(),
						id: data.id.toString(),
						manifestationMessage: data.manifestationMessage,
						manifestacaoDesfeita: data.manifestationDesfeita,
						manifestationOriginal: data.manifestation,
						cnpjcomprador: data.cnpjcomprador,
						manifestation: CreateGridAction(classmanifest[data.manifestation], "", this.GetIconModel(classmanifest[data.manifestation]), classmanifest[data.manifestation] + ' grid-mat-icon', this.getIconTooltip(classmanifest[data.manifestation])),//classmanifest[data.manifestation],
						occurrenceDate: this.datePipe.transform(data.occurrenceDate, "dd/MM/yyyy"),
						qtd_itens: data.qtd_itens,
						suppliername: data.suppliername,
						tax_auditor: (data.tax_auditor != "2" ? CreateGridAction(classtaxauditor[data.tax_auditor], "", this.GetIconModel(classtaxauditor[data.tax_auditor]), classtaxauditor[data.tax_auditor] + ' grid-mat-icon', this.getIconTooltip(classtaxauditor[data.tax_auditor])) : CreateGridAction(classtaxauditor[data.tax_auditor], "", CreateNewIcon(classtaxauditor[data.tax_auditor], ''), classtaxauditor[data.tax_auditor] + ' mat-icon', this.getIconTooltip(classtaxauditor[data.tax_auditor]))),
						totalamount: formatCurrency(Number(data.totalamount), 'pt-BR', '', 'BRL').trim(),
						typedocument: data.typedocument,
						cnpjdestinatario: data.cnpjdestinatario ? data.cnpjdestinatario : '',
						destinatario: data.destinatario ? data.destinatario : '',
						checkbox: CreateNewCheckBox(false, false, LabelPositionEnum.after, false, 'text'),
						denegado: data.denegado,
						cancelado: data.cancelado,
						rejeicao: data.rejeicao,
						contingencia: data.contingencia
					};

					let menu = CreateGridMenu();

					menu.actionsMenu.push(CreateGridActionMenu('thumb-up', "AcceptClicked", CreateNewIcon('thumb-up', '../assets/svg/thumb_up.svg'), 'mat-icon icon-design', 'Aceite'));
					menu.actionsMenu.push(CreateGridActionMenu('thumb-down', 'RejeitClicked', CreateNewIcon('thumb-down', '../assets/svg/thumb_down.svg'), 'mat-icon icon-design', 'Recusa'));
					menu.actionsMenu.push(CreateGridActionMenu('Unaware-Operation', 'UnawareOperationClicked', CreateNewIcon('Unaware-Operation', '../assets/svg/Unaware_Operation.svg'), 'mat-icon icon-design icon-manifest', 'Desconhecimento'));

					if (showManifestationActions) {
						item.actions.push(CreateGridAction('hand-point-up-regular', 'HasMenu', CreateNewIcon('hand-point-up-regular', '../assets/svg/hand-point-up-regular.svg'), 'mat-icon icon-design', 'Manifestar', menu, true));
					}
					else if ((data.manifestation == 3 || data.manifestation == 5) && this.typeDocument.nativeElement.value == "2") {
						item.actions.push(CreateGridAction('thumb-down', "RejeitClicked", CreateNewIcon('thumb-down', '../assets/svg/thumb_down.svg'), 'mat-icon icon-design', 'Manifestar Recusa'));
					}

					let downloadMenu = CreateGridMenu();

					let isNfe = data.chave.substring(20, 22) == NFeMod.NFe;

					if (this.hasRole('DOWNLOAD_NOTA')) {
						if (request.typeDocument == 1 && isNfe) {
							if (data.cancelado || (!data.contingencia && !data.rejeicao && !data.denegado)) {
								downloadMenu.actionsMenu.push(CreateGridActionMenu('file-pdf-regular', 'DownloadDanfeClicked', CreateNewIcon('file-pdf-regular', '../assets/svg/file-pdf-regular.svg'), 'mat-icon icon-design', 'Download DANFE'));
							}
						}

						downloadMenu.actionsMenu.push(CreateGridActionMenu('file-code-solid', 'DownloadClicked', CreateNewIcon('file-code-solid', '../assets/svg/file-code-solid.svg'), 'mat-icon icon-design', 'Download XML'));

						if (downloadMenu.actionsMenu.length > 0) {
							item.actions.push(CreateGridAction('arrow-alt-circle-down-regular', 'HasMenu', CreateNewIcon('arrow-alt-circle-down-regular', '../assets/svg/arrow-alt-circle-down-regular.svg'), 'mat-icon icon-design action-icon', 'Download', downloadMenu, true));
						}
					}

					item.actions.push(CreateGridAction('eye', "OpenClicked", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes'));
					this.purchases.push(item);
				})
				this.gridPurchase.refreshDateSource(this.purchases, this.gridcolumns);
			},
			error => {
				this.FinishDataClicked();
				if (error.erros) {
					this.toastr.info(error.erros[0].descricao, "");
				} else if (error.message) {
					if (error.name === "HttpErrorResponse") {
						this.toastr.info(DomeDictionary.ErroConexaoServidor, "")
					} else {
						this.toastr.info(error.message, "")
					}
				}
			});
	}

	getIconTooltip(value: string): string {
		if (this.icons) {
			let icon = this.icons.find(i => i.name == value);
			if (icon)
				return icon.tooltip;
		}

		return '';
	}

	GetIconModel(_name: string): any {
		if (this.icons) {
			let icon = this.icons.find(i => i.name == _name);
			if (icon)
				return icon;
		}

		return '';
	}

	OpenClicked2() {
		this.modalService.open('purchase-detail-modal2')
	}

	GetPurchaseDetails(chave: any) {
		this.StartDataClicked();
		this.purchaseSelected = this.purchases.find(p => p.chave == chave.data.chave);
		this.purchaseService.GetPurchaseDetails(chave).subscribe(data => {
			this.purchaseDetails = { ...data, cnpjcomprador: this.purchaseSelected.cnpjcomprador };
			if (this.typeDocument.nativeElement.value == 1)
				this.nteDetail.refreshDateSource(this.purchaseDetails.nota);

			this.purchaseDetails.itens.forEach(n => {
				n.cst = n.cst ? n.cst : "";
				n.tax_auditor = (n.tax_auditor != "2" ? CreateGridAction(classtaxauditor[n.tax_auditor], "", this.GetIconModel(classtaxauditor[n.tax_auditor]), classtaxauditor[n.tax_auditor] + ' grid-mat-icon', this.getIconTooltip(classtaxauditor[n.tax_auditor])) : CreateGridAction(classtaxauditor[n.tax_auditor], "", CreateNewIcon(classtaxauditor[n.tax_auditor], ''), classtaxauditor[n.tax_auditor] + ' mat-icon', this.getIconTooltip(classtaxauditor[n.tax_auditor])));
				n.valor_unitario = formatCurrency(Number(n.valor_unitario), 'pt-BR', '', 'BRL').trim();
				n.valor_total = formatCurrency(Number(n.valor_total), 'pt-BR', '', 'BRL').trim();
				n.base_icms = formatCurrency(Number(n.base_icms), 'pt-BR', '', 'BRL').trim();
				n.valor_icms = formatCurrency(Number(n.valor_icms), 'pt-BR', '', 'BRL').trim();
				n.quantidade = formatCurrency(Number(n.quantidade), 'pt-BR', '', 'BRL').trim();
				n.actions = [];
				n.actions.push(CreateGridAction('eye', "OpenClicked", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes do item'));
			});

			this.purchaseDetails.eventos.forEach(n => {
				n.actions = [];
				n.actions.push(CreateGridAction('eye', "", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes'));
				n.actions.push(CreateGridAction('download', "", CreateNewIcon('download', '../assets/svg/download.svg'), 'mat-icon icon-design', 'Download'));
			})

			this.purchaseDetails.manifestacoes.forEach(n => {
				n.actions = [];
				n.actions.push(CreateGridAction('eye', "", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes'));
				n.actions.push(CreateGridAction('download', "", CreateNewIcon('download', '../assets/svg/download.svg'), 'mat-icon icon-design', 'Download'));
			})

			if (this.purchaseDetails.vaccines) {
				this.purchaseDetails.vaccines.forEach(n => {
					n.tax_auditor = classtaxauditor[Number(n.status)];
				});
			}
			this.OpenModal('purchase-detail-modal');

			if (this.typeDocument.nativeElement.value == 1) {
				this.itensDetail.refreshDateSource(data.itens);
			}

			this.eventsDetail.refreshDateSource(data.eventos);
			this.manifestsDetail.refreshDateSource(data.manifestacoes);

			if (data.cte != null && this.typeDocument.nativeElement.value == 1) {
				this.cteNTEDetail.refreshDateSource(data.cte.notas_transportadas);
			}
			else if (data.cte != null && this.typeDocument.nativeElement.value == 2) {
				this.cteDetail.refreshDateSource(data.cte);
			}
		},
			error => {
				this.FinishDataClicked();
				if (error.erros) {
					this.toastr.info(error.erros[0].descricao, "");
				} else if (error.message) {
					if (error.name === "HttpErrorResponse") {
						this.toastr.info(DomeDictionary.ErroConexaoServidor, "")
					} else {
						this.toastr.info(error.message, "")
					}
				}
			});
	}
	GetComponentTabs(type: number, _dataSource: any) {
		this.dynamicTabs = [];
		if (type == 0) {
			for (let tab of this.tabsnfe) {
				let tabAux = this.createTab(tab, this.GetTypeComponent(tab), this.GetDataSourceComponent(tab, _dataSource), this.GetGridColumnsComponent(tab))
				this.dynamicTabs.push(tabAux);
			}

		}
		else {
			for (let tab of this.tabscte) {
				let tabAux = this.createTab(tab, this.GetTypeComponent(tab), this.GetDataSourceComponent(tab, _dataSource), this.GetGridColumnsComponent(tab))
				this.dynamicTabs.push(tabAux);
			}
		}
	}

	GetTypeComponent(componentname: string): any {
		switch (componentname) {
			case 'Nota':
				{
					return PurchaseNteDetailComponent;
				}
			case 'Ítens':
				{
					return PurchaseItensDetailComponent;
				}
			case 'Eventos':
				{
					return PurchaseEventsDetailComponent;
				}
			case 'Manifestações':
				{
					return PurchaseManifestsDetailComponent;
				}
			case 'Auditor tributário':
				{
					return PurchaseAuditsDetailComponent;
				}
			case 'Cte':
				{
					return PurchaseCteDetailComponent;
				}
		}
	}

	GetDataSourceComponent(componentname: string, _dataSource: any): any {
		switch (componentname) {
			case 'Nota':
				{
					return _dataSource.nota;
				}
			case 'Ítens':
				{
					return _dataSource.itens;
				}
			case 'Eventos':
				{
					return _dataSource.eventos;
				}
			case 'Manifestações':
				{
					return _dataSource.manifestacoes;
				}
			case 'Auditor tributário':
				{
					return _dataSource.audicoes;
				}
			case 'Cte':
				{
					return _dataSource.cte;
				}
		}
	}

	GetGridColumnsComponent(componentname: string): GridColumn[] {
		let gridcolumns: GridColumn[] = [];
		switch (componentname) {
			case 'Nota':
				{
					return [];
				}
			case 'Ítens':
				{
					gridcolumns.push(CreateNewGridColumn(60, 'numero', 'No.', (element: any) => `${element.numero}`));
					gridcolumns.push(CreateNewGridColumn(100, 'codigo', 'Código', (element: any) => `${element.codigo}`));
					gridcolumns.push(CreateNewGridColumn(450, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
					gridcolumns.push(CreateNewGridColumn(80, 'quantidade', 'Qtd.', (element: any) => `${element.quantidade}`));
					gridcolumns.push(CreateNewGridColumn(105, 'valor_unitario', 'Valor unit.', (element: any) => `${element.valor_unitario}`));
					gridcolumns.push(CreateNewGridColumn(150, 'valor_total', 'Total', (element: any) => `${element.valor_total}`));
					gridcolumns.push(CreateNewGridColumn(100, 'item_details', 'Detalhes', (element: any) => `${element.actions}`, false, true));
					return gridcolumns;
				}
			case 'Eventos':
				{
					gridcolumns.push(CreateNewGridColumn(125, 'tipo', 'Tipo do evento', (element: any) => `${element.tipo}`));
					gridcolumns.push(CreateNewGridColumn(120, 'data', 'Data/Hora', (element: any) => `${this.maskDate(element.data)}`));
					gridcolumns.push(CreateNewGridColumn(380, 'id', 'ID evento', (element: any) => `${element.id}`));
					gridcolumns.push(CreateNewGridColumn(0, 'status', 'Status', (element: any) => `${this.checkIcon(element.status)}`, true));
					gridcolumns.push(CreateNewGridColumn(0, 'status_sefaz', 'Status no SEFAZ', (element: any) => `${element.status_sefaz}`));
					gridcolumns.push(CreateNewGridColumn(160, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
					gridcolumns.push(CreateNewGridColumn(0, 'eventActions', 'Ações', (element: any) => `${element.actions}`, false, true));
					return gridcolumns;
				}
			case 'Manifestações':
				{
					gridcolumns.push(CreateNewGridColumn(125, 'tipo', 'Tipo do evento', (element: any) => `${element.tipo}`));
					gridcolumns.push(CreateNewGridColumn(120, 'data', 'Data/Hora', (element: any) => `${this.maskDate(element.data)}`));
					gridcolumns.push(CreateNewGridColumn(380, 'id', 'ID evento', (element: any) => `${element.id}`));
					gridcolumns.push(CreateNewGridColumn(0, 'status', 'Status', (element: any) => `${this.checkIcon(element.status)}`, true));
					gridcolumns.push(CreateNewGridColumn(0, 'status_sefaz', 'Status no SEFAZ', (element: any) => `${element.status_sefaz}`));
					gridcolumns.push(CreateNewGridColumn(160, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
					gridcolumns.push(CreateNewGridColumn(0, 'eventActions', 'Ações', (element: any) => `${element.actions}`, false, true));
					return gridcolumns;
				}
			case 'Auditor tributário':
				{
					gridcolumns.push(CreateNewGridColumn(0, 'numero', 'Nº item', (element: any) => `${element.numero}`));
					gridcolumns.push(CreateNewGridColumn(0, 'codigo', 'Código', (element: any) => `${element.codigo}`));
					gridcolumns.push(CreateNewGridColumn(0, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
					gridcolumns.push(CreateNewGridColumn(0, 'origem_audicao', 'Origem', (element: any) => `${element.origem_audicao}`));
					gridcolumns.push(CreateNewGridColumn(0, 'descricao_audicao', 'Erro encontrado', (element: any) => `${element.descricao_audicao}`));
					return gridcolumns;
				}
			case 'Cte':
				{
					gridcolumns.push(CreateNewGridColumn(450, 'chave_acesso', 'NFE', (element: any) => `${element.chave_acesso}`));
					gridcolumns.push(CreateNewGridColumn(50, 'numero', 'Número', (element: any) => `${element.numero}`));
					gridcolumns.push(CreateNewGridColumn(50, 'serie', 'Série', (element: any) => `${element.serie}`));
					return gridcolumns;
				}
		}
	}

	SetManifestation(_typeManifest: number, chave: any) {

		let selectedPurchase = this.purchasesFull.find(p => p.chave == chave.data.chave);

		let request: SetManifestationRQ = {
			protocolo: chave.data.id,
			Type: _typeManifest,
			chaveDeAcesso: chave.data.chave,
			cnpjFilial: (this.typeDocument.nativeElement.value == "1" ? selectedPurchase.cnpjcomprador : selectedPurchase.cnpjdestinatario),
			tipoDocumento: this.typeDocument.nativeElement.value
		}
		this.StartDataClicked();
		this.purchaseService.SetManifestation(request).subscribe(
			data => {
				this.TratarSucessoRequest(data, DomeDictionary.ManifestacaoSeraProcessada, DomeDictionary.ErroEnviarManifestacao(data.mensagem), 5410, 5400);
			},
			error => {
				this.TratarErroRequest(error);
			});
	}

	SetManifestations(_typeManifest: number, selectedPurchases: PurchaseData[]) {
		var request: SetManifestationRQ[] = [];

		for (var i = 0; i < selectedPurchases.length; i++) {
			var selected = selectedPurchases[i];

			var reg: SetManifestationRQ = {
				protocolo: selected.id,
				Type: _typeManifest,
				chaveDeAcesso: selected.chave,
				cnpjFilial: (this.typeDocument.nativeElement.value == "1" ? selected.cnpjsupplier : selected.cnpjdestinatario),
				tipoDocumento: this.typeDocument.nativeElement.value
			}
			request.push(reg);
		}

		this.StartDataClicked();
		this.purchaseService.SetManifestationList(request).subscribe(
			data => {
				this.TratarSucessoRequest(data, DomeDictionary.ManifestacoesSeraoProcessadas, DomeDictionary.ErroEnviarManifestacoes(data.mensagem), 200, 500);
			},
			error => {
				this.TratarErroRequest(error);
			});
	}

	TratarSucessoRequest(data: DefaultResponse, msgSucesso: string, msgErro: string, codSucesso: number, codErro: number) {
		this.FinishDataClicked();
		if (data.sucesso)
			this.toastr.info(msgSucesso, "");
		else
			this.toastr.info(msgErro, "");

		this.filterDate();
	}

	TratarErroRequest(error: any) {
		this.FinishDataClicked();
		if (error.erros) {
			this.toastr.info(error.erros[0].descricao, "");
		} else if (error.message) {
			if (error.name === "HttpErrorResponse") {
				this.toastr.info(DomeDictionary.ErroConexaoServidor, "");
			} else {
				this.toastr.info(error.message, "");
			}
		}
	}

	diasNoMes(mes: number, ano: number) {
		var data = new Date(ano, mes, 0);
		return data.getDate();
	}

	maskDate(d: Date): string {
		return new Date(d).toLocaleString('pt-BR');
	}

	checkIcon(b: boolean): string {
		return b == false ? 'clear-red' : 'done-green';
	}

	filterDate(): void {
		this.GetPurchases();
		this.hiddenCheckboxOptions = true;
	}

	createTab(tablabel: string,
		tabcomponent: any,
		tabdatasource: any,
		tabgridcolumns: GridColumn[]): tabs {
		if (tabcomponent.ngBaseDef != undefined
			&& tabcomponent.ngBaseDef != null
			&& tabcomponent.ngBaseDef.inputs != undefined
			&& tabcomponent.ngBaseDef.inputs != null) {
			tabcomponent.ngBaseDef.inputs.dataSource = tabdatasource;
		}

		return {
			label: tablabel,
			component: tabcomponent,
			datasource: tabdatasource,
			gridColumns: tabgridcolumns
		}
	}

	toogleSearch(): void {
		this.searchbarState = this.searchbarState === 'hidden' ? 'visible' : 'hidden'
	}

	ConfirmAction(message: string, action) {
		this.coolDialogs.confirm(message)
			.subscribe(response => {
				if (response) {
					action();
				}
			});
	}

	ShowManifestationActionBarAlert() {
		this.coolDialogs.alert(DomeDictionary.ExistemNotasSelecionadas, {
			okButtonText: 'OK',
		});
	}

	AcceptClicked(eventRow: any): void {

		if (this.GetSelectedCheckBoxCount() > 1) {
			this.ShowManifestationActionBarAlert();
			return;
		}

		if (!eventRow) {
			this.toastr.info(DomeDictionary.CompraSemChaveAcesso, "");
			return;
		}

		this.ConfirmAction(DomeDictionary.ConfirmaManifestarAceite, () => this.SetManifestation(0, eventRow));

	}

	RejeitClicked(eventRow: any): void {
		if (this.GetSelectedCheckBoxCount() > 1) {
			this.ShowManifestationActionBarAlert();
			return;
		}

		if (!eventRow) {
			this.toastr.info(DomeDictionary.CompraSemChaveAcesso, "")
			return;
		}

		this.ConfirmAction(DomeDictionary.ConfirmaManifestarRecusa, () => this.SetManifestation(1, eventRow));
	}

	OpenClicked(eventRow: string): void {
		if (!eventRow)
			this.toastr.info(DomeDictionary.CompraSemChaveAcesso, "")
		else
			this.GetPurchaseDetails(eventRow);

	}

	CloseModal(id: string) {
		this.modalService.close(id);
	}

	UnawareOperationClicked(eventRow: any): void {
		if (this.GetSelectedCheckBoxCount() > 1) {
			this.ShowManifestationActionBarAlert();
			return;
		}

		if (!eventRow) {
			this.toastr.info(DomeDictionary.CompraSemChaveAcesso, "")
			return;
		}

		this.ConfirmAction(DomeDictionary.ConfirmaDesconhecimento, () => this.SetManifestation(2, eventRow));
	}

	RefreshGridClicked(): void {
		this.filterDate();
		this.gridPurchase.refreshDateSource(this.purchases);
	}

	StartDataClicked(): void {
		this.ngxLoader.start();
	}
	FinishDataClicked(): void {
		this.ngxLoader.stop();
	}

	private OpenModal(id: string) {
		this.modalService.open(id);
	}

	AcceptManifestations() {
		var dataChecked = this.GetSelectedRegisters();

		this.ConfirmAction(DomeDictionary.ConfirmaManifestarAceiteLote(this.GetSelectedCheckBoxCount()), () => this.SetManifestations(0, dataChecked));
	}

	RefuseManifestations() {
		var dataChecked = this.GetSelectedRegisters();

		this.ConfirmAction(DomeDictionary.ConfirmaManifestarRecusaLote(this.GetSelectedCheckBoxCount()), () => this.SetManifestations(1, dataChecked));
	}

	UnawareManifestations() {
		var dataChecked = this.GetSelectedRegisters();

		this.ConfirmAction(DomeDictionary.ConfirmaDesconhecimentoLote(this.GetSelectedCheckBoxCount()), () => this.SetManifestations(2, dataChecked));
	}

	GetSelectedRegisters(): PurchaseData[] {
		return this.gridPurchase.GetCheckedObject();
	}

	GetCheckBoxSelected(): any {
		this.gridPurchase.GetCheckedObject()
		return this.gridPurchase.GetCheckLine().listCheckBox;
	}

	CheckboxClicked() {
		this.SetManifestationBarVisibility();
	}

	openFornecedor(cnpj) {
		this.modalService.close('purchase-detail-modal')
		if (cnpj) {
			const supplier = this.supplierList.find(sp => sp.cnpjFornecedor == cnpj);
			if (supplier) {
				this.SupplierAction = 'editar';
				this.SupplierPayload = { ...supplier };
				this.modalService.open('open-new-fornecedor');
			} else {
				this.OpenNewFornecedor();
			}
		}
		this.selected.setValue(4);
	}

	CheckboxFullClick() {
		this.SetManifestationBarVisibility();
	}

	HandleRemanifestation() {
		const dataChecked = this.GetSelectedRegisters();
		const payload = {
			headers: {},
			body: {}
		}

		this.ngxLoader.start();

		if (dataChecked.length == 1) {
			payload.body = { cnpj: dataChecked[0].cnpjcomprador, xmlKey: dataChecked[0].chave };
			this.purchaseService.remanifestation(payload).subscribe((data) => {
				this.toastr.success('Nota enviada para reprocessamento com sucesso!');
				this.hiddenReprocess = true;
				this.GetPurchases();
			}, (err) => {
				this.ngxLoader.stop();
				this.toastr.error('Ocorreu um erro ao enviar nota para reprocessamento, por favor tente novamente mais tarde');
				console.log(err);
			});
		} else {
			payload.body = dataChecked.map(data => ({ cnpj: data.cnpjcomprador, xmlKey: data.chave }));
			this.purchaseService.massRemanifestation(payload).subscribe((data) => {
				this.toastr.success('Notas enviadas para reprocessamento com sucesso!');
				this.hiddenReprocess = true;
				this.GetPurchases();
			}, (err) => {
				this.ngxLoader.stop();
				this.toastr.error('Ocorreu um erro ao enviar notas para reprocessamento, por favor tente novamente mais tarde');
				console.log(err);
			});
		}
	}

	SetManifestationBarVisibility() {
		let nfeIsSelected = this.typeDocument.nativeElement.value == "1";
		let hasPermissionToManifest = this.loginService.hasRole(Role.MANIFESTAR);
		this.hiddenReprocess = !(this.gridPurchase.GetCheckedObject().length >= 1);
		this.hiddenCheckboxOptions = !(this.GetSelectedCheckBoxCount() > 1 && this.gridPurchase.GetCheckedObject().every(data => {
			return (data.manifestationOriginal == StatusManifestacao.Pendente || data.manifestationOriginal == StatusManifestacao.Erro) && nfeIsSelected && hasPermissionToManifest;
		}));
	}

	GetSelectedCheckBoxCount(): number {
		return this.GetCheckBoxSelected().filter(value => value).length;
	}

	alignInkBar() {
		setTimeout(() => {
			this.matTabs._alignInkBar();
		}, 0);
	}

	DownloadDanfe(eventRow: any) {
		this.StartDataClicked();

		this.purchaseService.DownloadDanfe(eventRow.chave).subscribe(data => {
			var bytes = new Uint8Array(data);
			var blob = new Blob([bytes], { type: 'application/pdf' });

			this.DownloadFileBlob(blob, `${eventRow.chave}-danfe.pdf`);

			this.FinishDataClicked();
		},
			error => {
				this.FinishDataClicked();
				this.toastr.info(DomeDictionary.ErroInterno, "Falha ao tentar realizar o download")
			})
	}

	DownloadFileBlob(blob: Blob, fileName: string) {
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
		link.remove();
	}

	DownloadXml(eventRow: any) {
		this.StartDataClicked();

		this.purchaseService.DownloadXml(eventRow.chave, this.typeDocument.nativeElement.value).subscribe(data => {
			var blob = new Blob([data.body], { type: "xml/plain" });
			this.DownloadFileBlob(blob, `${eventRow.chave}.xml`);

			this.FinishDataClicked();
		},
			error => {
				this.FinishDataClicked();
				this.toastr.info(DomeDictionary.ErroInterno, DomeDictionary.ErroDownload)
			})
	}

	populateRelationshipsTable() {
		this.columnsRelationships = [
			CreateNewGridColumn(250, 'emitente', 'CNPJ Fornecedor', (element: any) => `${element.cnpjFornecedor}`),
			CreateNewGridColumn(380, 'nNota', 'Razão Social Fornecedor', (element: any) => `${element.razaoSocialFornecedor}`),
			CreateNewGridColumn(200, 'dataEmissao', 'Finalidade', (element: any) => `${this.finalidades[element.finalidade]}`),
			CreateNewGridColumn(210, 'valor', 'Converter Automaticamente', (element: any) => `${element.converterAutomaticamente ? 'Sim' : 'Não'}`),
			CreateNewGridColumn(170, 'action', 'Ação', (element: any) => `${element.actions}`, false, true),
		];
	}

	getRelationships() {
		this.StartDataClicked();
		this.purchaseService.getRelationships().subscribe(data => {
			this.relationships = data.registro.map(reg => {
				return {
					...reg,
					actions: [
						CreateGridAction('pencil', 'editar', CreateNewIcon('pencil', '../assets/svg/pencil.svg'), 'mat-icon icon-design', 'Editar Relacionamento'),
						CreateGridAction('clear_red', 'excluir', CreateNewIcon('clear_red', '../assets/svg/clear_red.svg'), 'mat-icon icon-design', 'Excluir Relacionamento'),
					]
				}
			});
			this.gridRelationships.refreshDateSource(this.relationships);
		},
			error => {
				this.toastr.info(DomeDictionary.ErroInterno, "Falha ao tentar obter as relações")
			})
	}

	saveRelationship() {
		this.StartDataClicked();
		this.relationshipPayload.finalidade = +this.relationshipPayload.finalidade;
		this.relationshipPayload.converterAutomaticamente = this.verifyAutomaticConvertion(this.relationshipPayload.converterAutomaticamente);
		if (this.relationshipPayload.id) {
			this.purchaseService.updateRelationship(this.relationshipPayload, this.relationshipPayload.id).subscribe(
				data => {
					this.completeSucessRelationship()
				},
				error => {
					this.throwErrorRelationship(error);
				})
		} else {
			this.purchaseService.saveRelationship(this.relationshipPayload).subscribe(
				data => {
					this.completeSucessRelationship()
				},
				error => {
					this.throwErrorRelationship(error);
				})
		}
	}

	verifyAutomaticConvertion(value) {
		return JSON.parse(value);
	}

	completeSucessRelationship() {
		this.toastr.success("Relacionamento salvo com sucesso", 'Sucesso!');
		this.CloseModal('open-new-relationship');
		this.FinishDataClicked();
		this.getRelationships();
	}

	throwErrorRelationship(error) {
		this.FinishDataClicked();
		this.toastr.info(this.extractError(error.errors), "Falha ao tentar salvar a relação")
	}

	extractError(error) {
		return Object.keys(error).map((key) => {
			return error[key].join(' ');
		}).join(' ');
	}


	populateDocumentsTable() {

		this.columnsDocuments = [
			CreateNewGridColumn(300, 'cnpj', 'CNPJ do emitente', (element: any) => `${element.cnpjEmitente}`),
			CreateNewGridColumn(250, 'emitente', 'Emitente', (element: any) => `${element.emitente}`),
			CreateNewGridColumn(380, 'nNota', 'Número da Nota', (element: any) => `${element.nNota}`),
			CreateNewGridColumn(200, 'dataEmissao', 'Data de Emissão', (element: any) => `${this.datePipe.transform(new Date(element.dataEmissao), "dd/MM/yyyy")}`),
			CreateNewGridColumn(210, 'valor', 'Valor Total', (element: any) => `${element.valorTotal}`),
			CreateNewGridColumn(170, 'action', 'Ação', (element: any) => `${element.actions}`, false, true),
		];



		this.columnsConvertedDocuments = [
			CreateNewGridColumn(300, 'cnpj', 'CNPJ do emitente', (element: any) => `${element.cnpjEmitente}`),
			CreateNewGridColumn(250, 'emitente', 'Emitente', (element: any) => `${element.emitente}`),
			CreateNewGridColumn(380, 'nNota', 'Número da Nota', (element: any) => `${element.nNota}`),
			CreateNewGridColumn(200, 'dataEmissao', 'Data de Emissão', (element: any) => `${element.dataEmissao ? this.datePipe.transform(new Date(element.dataEmissao), "dd/MM/yyyy") : ''}`),
			CreateNewGridColumn(200, 'dataEfetivacao', 'Data - Hora Efetivação', (element: any) => `${element.dataEfetivacao ? this.datePipe.transform(new Date(element.dataEfetivacao), "dd/MM/yyyy") : ''}`),
			CreateNewGridColumn(210, 'valor', 'Valor Total', (element: any) => `${element.valorTotal}`),
			CreateNewGridColumn(170, 'action', 'Ação', (element: any) => `${element.actions}`, false, true),
		]
	}

	ListDocuments(statusProcessamento: number) {
		this.StartDataClicked();
		let dateIn = null;
		let dateOut = null;
		if (statusProcessamento == 5 && this.filterConverted) {
			const filter = this.filterConverted.GetFilterData();
			if (filter.dateIn != null) {
				dateIn = filter.dateIn.toISOString().split('T')[0];
				dateOut = filter.dateOut.toISOString().split('T')[0];
			} else {
				dateIn = new Date().toISOString().split('T')[0];
				dateOut = new Date().toISOString().split('T')[0];
			}
		}

		this.purchaseService.ListDocuments(this.cnpj, statusProcessamento, dateIn, dateOut).subscribe(data => {
			this.fiscalDocuments = data.registro.documentosFiscais.map(document => {
				const formatter = new Intl.NumberFormat('pt-BR', {
					style: 'currency',
					currency: 'BRL',
				});
				return {
					cnpjEmitente: document.fornecedor.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
					emitente: document.fornecedor.nome,
					dataEmissao: document.nota.dataEmissao ? document.nota.dataEmissao : '',
					dataEfetivacao: document.dataAceite ? document.dataAceite : '',
					valorTotal: formatter.format(document.nota.valorTotal),
					chave: document.chave,
					nNota: document.chave.split('-')[2],
					produtos: document.nota.itensNotas.map(item => {
						return {
							...item,
							converted: {
								cfop: '',
								cstIcms: '',
								cstIpi: '',
								cstPis: '',
								cstCofins: '',
							}
						}
					}),
					actions:
						statusProcessamento === 1 ?
							this.hasRole('EDIT_FORNECEDOR_FINALIDADE') ?
								[
									CreateGridAction('eye', "OpenClicked", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir Efetivação')
								] : []
							:
							[
								CreateGridAction('eye', "OpenClicked", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Ver nota efetivada'),
								CreateGridAction('download', "download", CreateNewIcon('download', '../assets/svg/download.svg'), 'mat-icon icon-design', 'Download XML')
							]
				}
			});
			if (statusProcessamento == 1) {
				this.gridDocuments.refreshDateSource(this.fiscalDocuments);
			} else {
				this.gridConvertedDocuments.refreshDateSource(this.fiscalDocuments);

			}
			this.FinishDataClicked();
		}, (error) => {
			this.FinishDataClicked();
			this.toastr.error(`Ocorreu um erro ao buscar as Notas, por favor tente novamente`, 'Ops!');
		});
	}

	OpenDocument(event) {
		this.ngxLoader.start();
		this.selectedNFe = { ...event.data };
		this.purchaseService.GetSugestion({
			cnpjFornecedor: this.cnpj,
			ItensNota: this.selectedNFe.produtos.map(p => {
				return {
					xProx: p.xProd,
					Cfop: p.cfop,
					nItem: p.nItem
				}
			})
		}).subscribe(
			data => {
				try {
					if (data.registro.itensNota.length > 0) {
						this.selectedNFe.produtos = this.selectedNFe.produtos.map(p => {
							return {
								...p,
								converted: data.registro.itensNota.find(i => i.nItem === p.nItem)
							}
						})
					}
				} catch (error) {
					this.toastr.error(`Nenhuma sugestão encontrada para esta nota`, 'Ops!');
				}
				this.ngxLoader.stop();
				this.modalService.open('open-products-list')
			},
			error => {
				this.toastr.error(`Nenhuma sugestão encontrada para esta nota`, 'Ops!');
				this.ngxLoader.stop();
				this.modalService.open('open-products-list')
			}
		);
	}

	OpenConvertedDocument(event) {
		if (event.action === 'OpenClicked') {
			this.selectedNFe = { ...event.data };
			this.modalService.open('open-converted-products-list');
		} else {
			this.DownloadXmlConverted(event.data.chave);
		}
	}

	DownloadXmlConverted(chave) {
		this.StartDataClicked();
		this.purchaseService.DownloadConvertedXml(chave).subscribe(data => {
			var blob = new Blob([data.body], { type: "text/xml" });
			this.DownloadFileBlob(blob, `${chave}.xml`);
			this.FinishDataClicked();
		},
			error => {
				this.FinishDataClicked();
				this.toastr.info(DomeDictionary.ErroInterno, DomeDictionary.ErroDownload)
			})
	}

	hasRole(role) {
		return this.loginService.hasRole(role);
	}

	SaveDocument() {
		const products = this.selectedNFe.produtos.map(product => {
			return {
				...product.converted,
				cstCofins: product.converted.cstPis,
				_id: product.id,
				xProd: product.xProd,
				nItem: product.nItem,
			}
		});

		let hasEmptyFields = false;

		for (let product of products) {
			for (let key in product) {
				if (product[key] === null || product[key] === undefined || product[key] === '') {
					hasEmptyFields = true;
					return this.toastr.error(`Prencha todos os campos para continuar`, 'Ops!');
				}
			}
			product.cfop = product.cfop.toString();
			if (product.cfop.length != 4 || !product.cfop.match(/^[0-9]+$/)) {
				this.FinishDataClicked();
				return this.toastr.error(`O CFOP do produto "${product.xProd}" está inválido`, 'Ops!');
			}
			delete product.name;
		}

		if (hasEmptyFields) {
			return;
		}

		this.StartDataClicked();

		this.purchaseService.SaveDocument({ chave: this.selectedNFe.chave, itensNotas: products }).subscribe(data => {
			this.toastr.success(`Nota Fiscal efetivada com sucesso`, 'Sucesso!');
			this.selectedNFe = {};
			this.modalService.close('open-products-list');
			this.ListDocuments(1);
			this.ListDocuments(5);
		}, (error) => {
			this.FinishDataClicked();
			return this.toastr.error(`Ocorreu um erro ao efetivar nota, verifique seus dados e tente novamente.`, 'Ops!');
		});
	}
}
