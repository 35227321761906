import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms'
import { ModalService } from 'src/app/shared/modal/modal.service';
import { UserService } from '../user.service';
import { combineLatest } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login/login.service';
import { addHours, format } from 'date-fns';
import { EventsService, MessageType } from 'src/app/_helpers/events.service';

@Component({
  selector: 'app-userdatadetail',
  templateUrl: './userdatadetail.component.html',
  styleUrls: ['./userdatadetail.component.scss']
})

export class UserDataDetailComponent implements OnInit {
  dataSource: any;
  emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  cnpjPattern = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
  cellphonePattern = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
  email = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);
  telefone = new FormControl('', [Validators.required, Validators.required, Validators.pattern(this.cellphonePattern)]);
  cnpj = new FormControl('', [Validators.required, Validators.required]);
  //cnpj  = new FormControl('', [Validators.required, Validators.required,Validators.minLength(5)]);
  nomeFantasia = new FormControl('', [Validators.required, Validators.required, Validators.minLength(5)]);
  razaoSocial = new FormControl('', [Validators.required, Validators.required, Validators.minLength(5)]);
  detailForm: FormGroup;
  regimeOptions = [];
  atividadeOptions = [];
  tributacaoOptions = [];
  enquadramentoOptions = [];
  verticalsData = [];
  verticalId = '';

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private ngxLoader: NgxUiLoaderService,
    private userService: UserService,
    private toastr: ToastrService,
    private loginService: LoginService,
    private eventsService: EventsService,
  ) { }

  ngOnInit() {
    this.detailForm = this.formBuilder.group({
      nomeFantasia: this.formBuilder.control('', [Validators.required, Validators.minLength(5)]),
      razaoSocial: this.formBuilder.control('', [Validators.required, Validators.minLength(5)]),
      email: this.formBuilder.control('', [Validators.required, Validators.pattern(this.emailPattern)]),
      cnpj: this.formBuilder.control('', [Validators.required]),
      telefone: this.formBuilder.control(null),
      endereco: this.formBuilder.control(null),
      numero: this.formBuilder.control(null),
      complemento: this.formBuilder.control(null),
      cep: this.formBuilder.control(null),
      bairro: this.formBuilder.control(null),
      municipio: this.formBuilder.control(null),
      estado: this.formBuilder.control(null),
      inscricaoEstadual: this.formBuilder.control(null),
      inscricaoMunicipal: this.formBuilder.control(null),
      cnae: this.formBuilder.control(null),
      responsavel: this.formBuilder.control(null),
      cpfResponsavel: this.formBuilder.control(null),
      emailResponsavel: this.formBuilder.control(null),
      taxRegimeId: this.formBuilder.control(null),
      calculationBasisId: this.formBuilder.control(null),
      substitutoTributarioICMS: this.formBuilder.control(null),
      tipoAtividade: this.formBuilder.control(null),
      base64Certificado: this.formBuilder.control(null),
      dataExpiracaoCertificado: this.formBuilder.control(null),
      erpId: this.formBuilder.control(null),
      integraContador: this.formBuilder.control(false),
      nomeContador: this.formBuilder.control(null),
      cpfCnpjContador: this.formBuilder.control(null),
      crcContador: this.formBuilder.control(null),
      utilizaEscrituracaoFiscal: this.formBuilder.control(null),
      simplesFrameworkId: this.formBuilder.control(null),
      mainActivitiesId: this.formBuilder.control(null),
      chaveAcesso: this.formBuilder.control(null),
      dataSimplesNacional: this.formBuilder.control(''),
      senhaCertificado: this.formBuilder.control(''),
      tipoContador: this.formBuilder.control(0),
      clientId: this.formBuilder.control(''),
    });
    this.ngxLoader.start();
    combineLatest([
      this.userService.GetOptionsCalculation(),
      this.userService.GetOptionsMain(),
      this.userService.GetOptionsSimples(),
      this.userService.GetOptionsTax(),
    ]).subscribe((data: any) => {
      this.regimeOptions = data[0];
      this.atividadeOptions = data[1];
      this.enquadramentoOptions = data[2];
      this.tributacaoOptions = data[3];
    });
  }

  refreshDateSource(_dataSource: any) {
    const token = localStorage.getItem('domeToken');
    this.dataSource = _dataSource.users && _dataSource.users.length > 0 ? _dataSource.users[0] : null;
    if (!this.dataSource.token) {
      this.dataSource.token = token;
    }
    this.fetchEnterpriseData(this.loginService.user.economicGroup.emitters[0].cnpjCpf);
  }

  fetchEnterpriseData(cnpj) {
    this.userService.GetVerticals().subscribe((d: any) => {
      this.verticalsData = d.registro;
      this.userService.GetEnterpriseData(cnpj).subscribe((d: any) => {
        const vertical = this.verticalsData.find(vertical => vertical.erps.find(erp => erp.id == d.erpId));
        if (vertical) {
          this.verticalId = vertical.id;
        }
        this.detailForm.patchValue({
          ...d,
          dataSimplesNacional: d.dataSimplesNacional ? format(addHours(new Date(d.dataSimplesNacional), 0), 'YYYY-MM-DDThh:mm') : '',
          dataExpiracaoCertificado: d.dataExpiracaoCertificado ? format(addHours(new Date(d.dataExpiracaoCertificado), 0), 'YYYY-MM-DDThh:mm') : '',
        });
        this.ngxLoader.stop();
      }, (err) => {
        this.ngxLoader.stop();
      });
    });
  }

  getErrorMessage(typeControl: string, p: any) {
    let response: string;
    switch (typeControl.toLocaleUpperCase()) {
      case 'CNPJ':
        {
          if (!p.cnpj.invalid) {
            response = this.cnpj.hasError('required') ? 'Campo obrigatório' :
              this.cnpj.hasError('cnpj') ? 'CNPJ inválido' : '';
          }
          break;
        }
      case 'EMAIL':
        {
          response = this.email.hasError('required') ? 'Campo obrigatório' :
            this.email.hasError('email') ? 'E-mail inválido' : '';
          break;
        }
      case 'telefone':
        {
          response = this.telefone.hasError('required') ? 'Campo obrigatório' :
            this.telefone.hasError('telefone') ? 'Telefone no formato inválido' : '';
          break;
        }
      case 'nomeFantasia':
        {
          response = this.nomeFantasia.hasError('required') ? 'Campo obrigatório' :
            this.nomeFantasia.hasError('nomeFantasia') ? 'Nome fantasia tem que ter mínimo de 5 carácter' : '';
          break;
        }
      case 'RAZAOSOCIAL':
        {
          response = this.nomeFantasia.hasError('required') ? 'Campo obrigatório' :
            this.nomeFantasia.hasError('razaosocial') ? 'Razão social tem que ter mínimo de 5 carácter' : '';
          break;
        }
    }
    return response;
  }

  public OpenModal(id) {
    this.modalService.open(id);
  }

  static equalsTo(group: AbstractControl): { [key: string]: boolean } {
    const email = group.get('email')
    const emailConfirmation = group.get('emailConfirmation')
    if (!email || !emailConfirmation) {
      return undefined
    }

    if (email.value !== emailConfirmation.value) {
      return { emailsNotmatch: true }
    }

    return undefined
  }

  onChangeRegime() {
    const regime = this.detailForm.get('taxRegimeId').value;
    if (!['d5dd8ebd-d28d-430c-8bc6-7de08eaf44e9', '9b34afd0-0018-4dc3-8111-1bc8aba30bf6', '2a866e25-13dc-4fbf-97fe-904c5b64e949'].includes(regime)) {
      this.detailForm.get('simplesFrameworkId').disable();
      this.detailForm.get('calculationBasisId').disable();
      this.detailForm.get('dataSimplesNacional').disable();
      this.detailForm.get('simplesFrameworkId').setValue(null);
      this.detailForm.get('calculationBasisId').setValue(null);
      this.detailForm.get('dataSimplesNacional').setValue(null);
      this.detailForm.get('calculationBasisId').disable();
      this.detailForm.get('dataSimplesNacional').disable();
      this.detailForm.get('simplesFrameworkId').clearValidators();
      this.detailForm.get('simplesFrameworkId').updateValueAndValidity();
      this.detailForm.get('calculationBasisId').clearValidators();
      this.detailForm.get('calculationBasisId').updateValueAndValidity();
      this.detailForm.get('dataSimplesNacional').clearValidators();
      this.detailForm.get('dataSimplesNacional').updateValueAndValidity();
      return;
    }

    this.detailForm.get('calculationBasisId').enable();
    this.detailForm.get('dataSimplesNacional').enable();
    this.detailForm.get('simplesFrameworkId').enable();
    this.detailForm.get('simplesFrameworkId').setValidators(Validators.required);
    this.detailForm.get('simplesFrameworkId').updateValueAndValidity();
    this.detailForm.get('calculationBasisId').setValidators(Validators.required);
    this.detailForm.get('calculationBasisId').updateValueAndValidity();
    this.detailForm.get('dataSimplesNacional').setValidators(Validators.required);
    this.detailForm.get('dataSimplesNacional').updateValueAndValidity();
  }

  onChangeContador() {
    const tipoContador = this.detailForm.get('tipoContador').value;
    if (tipoContador == 0) {
      this.detailForm.get('nomeContador').setValidators(Validators.required);
      this.detailForm.get('nomeContador').updateValueAndValidity();
      this.detailForm.get('crcContador').setValidators(Validators.required);
      this.detailForm.get('crcContador').updateValueAndValidity();
      this.detailForm.get('cpfCnpjContador').setValidators(Validators.required);
      this.detailForm.get('cpfCnpjContador').updateValueAndValidity();
      this.detailForm.get('clientId').clearValidators();
      this.detailForm.get('clientId').updateValueAndValidity();
      return;
    }
    this.detailForm.get('nomeContador').clearValidators();
    this.detailForm.get('nomeContador').updateValueAndValidity();
    this.detailForm.get('crcContador').clearValidators();
    this.detailForm.get('crcContador').updateValueAndValidity();
    this.detailForm.get('cpfCnpjContador').clearValidators();
    this.detailForm.get('cpfCnpjContador').updateValueAndValidity();
    this.detailForm.get('clientId').setValidators(Validators.required);
    this.detailForm.get('clientId').updateValueAndValidity();
  }
  removeValidators() {
    const integraContador = this.detailForm.get('integraContador').value;
    if (integraContador) {
      this.detailForm.get('nomeContador').clearValidators();
      this.detailForm.get('nomeContador').updateValueAndValidity();
      this.detailForm.get('crcContador').clearValidators();
      this.detailForm.get('crcContador').updateValueAndValidity();
      this.detailForm.get('cpfCnpjContador').clearValidators();
      this.detailForm.get('cpfCnpjContador').updateValueAndValidity();
      this.detailForm.get('clientId').clearValidators();
      this.detailForm.get('clientId').updateValueAndValidity();
    }
  }

  getMask(formData) {
    if (!formData.cpfCnpjContador) {
      return '';
    }

    return formData.cpfCnpjContador.length <= 11 ? '000.000.000-009' : '00.000.000/0000-00';
  }

  editData() {
    const payload = Object.keys(this.detailForm.value).reduce((data, key) => {
      data[key] = this.detailForm.get(key).value !== "" ? this.detailForm.get(key).value : null;
      return data;
    }, {});

    this.ngxLoader.start();

    this.userService.SaveEnterpriseData(payload).subscribe((d: any) => {
      if (d.sucesso) {
        this.eventsService.broadcast(MessageType.ATUALIZA_HEADER);
        this.toastr.success(d.mensagem);
      } else {
        this.toastr.error(d.mensagem || 'Ocorreu um erro ao editar empresa, verifique os dados e tente novamente');
      }
      this.ngxLoader.stop();
    });
  }

  getErpByVerticalId() {
    const vertical = this.verticalsData.find(x => x.id == this.verticalId)
    return vertical ? vertical.erps : [];
  }

  onSelectCertificate(e: any) {
    const env = this;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      env.detailForm.patchValue({
        base64Certificado: (reader.result as string).split('base64,')[1]
      });
    };
  }
}
