import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Config } from 'src/app/config';
import { tap } from 'rxjs/operators';
import { Emitter } from 'src/app/nav-menu/GroupOrEmitter/DTO/emitter.model';
import { DefaultResponse } from './../../_models/default-response';

@Injectable({
  providedIn: "root"
})
export class AlterarFilialService {
  constructor(private http: HttpClient) { }

  GetEmitters(): Observable<Emitter[]> {
    return this.http.get<Emitter[]>(Config.DOMEApi.GetEmitters).pipe(
      tap(data => {
        return data;
      })
    );
  }

  GetCnpjRoles(cnpj: string): Observable<string[]> {
    let url = `${Config.DOMEApi.GetCnpjRoles}?cnpj=${cnpj}`
    return this.http.get<string[]>(url).pipe(
      tap(data => {
        return data;
      })
    );
  }

  SetDefaultSubsidiary(cnpj: string): Observable<DefaultResponse> {
    return this.http.put<DefaultResponse>(Config.DOMEApi.SetDefaultSubsidiary, cnpj).pipe(
      tap(data => {
        return data;
      })
    );
  }
}
