import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { EChartOption } from "echarts";
import { ValueSummary } from "./../DTO/dashboard.model";
import { abbreviateNumber } from "src/app/_helpers/number.helper";
import { getTooltipMultiSeries } from "src/app/_helpers/chart.helper";

@Component({
  selector: "app-chart-sale",
  templateUrl: "./chart-sale.component.html",
  styleUrls: ["./chart-sale.component.scss"]
})
export class ChartSaleComponent implements OnInit, OnChanges {
  @Input() sale: ValueSummary[];
  @Input() saleRejection: ValueSummary[];

  ngOnChanges(changes: SimpleChanges) {
    this.updateChartOption();
  }

  saleSeries: number[];
  saleRejectionSeries: number[];
  mergeOption: any;
  chartOption: EChartOption = this.getChartOption();

  constructor() {}

  ngOnInit() {}

  getChartOption(): any {
    var me = this;
    return {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow"
        },
        formatter: function(params: any, ticket, callback) {
          let value = params.seriesName == "Rejeições" ? params.value : me.saleSeries[params.dataIndex];
          return `${params.name}<br /> ${params.seriesName}: ${value}`;
        }
      },
      legend: {
        x: "right",
        data: ["Emissões", "Rejeições"],
        selectedMode: false,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "category",
        axisLine: {
          lineStyle: {
            opacity: 0.1
          }
        },
        data: []
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            opacity: 0.1
          }
        },
        splitLine: {
          lineStyle: {
            color: "#ebebeb"
          }
        },
        axisLabel: {
          formatter: (value, index) => {
            return abbreviateNumber(value);
          }
        }
      },
      series: [
        {
          name: "Rejeições",
          type: "bar",
          stack: "emissao",
          data: [],
          itemStyle: {
            color: "#4DD0E1"
          }
        },
        {
          name: "Emissões",
          type: "bar",
          stack: "emissao",
          data: [],
          itemStyle: {
            color: "#4BA5B1"
          }
        }
      ]
    };
  }

  updateChartOption() {
    let xAxix = this.getXAxix();
    this.saleSeries = this.getSaleSeries();
    this.saleRejectionSeries = this.getSaleRejectionSeries();

    this.mergeOption = {
      xAxis: { data: xAxix },
      series: [{ data: this.saleRejectionSeries }, { data: this.saleSeries }]
    };
  }

  getXAxix(): string[] {
    if (!this.sale) return;

    this.sale.sort(function(a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    //Comentei a linha abaixo, pois em determinados browsers, ordenava de maneira invertida
    // return this.sale.sort(p => p.order).map(p => p.name);
    return this.sale.map(p => p.name);
  }

  getSaleSeries(): number[] {
    if (!this.sale) return;
 
    return this.sale.map(p => p.value);
  }

  getSaleRejectionSeries(): number[] {
    if (!this.saleRejection) return;

    return this.saleRejection.map(p => p.value);
  }
}
