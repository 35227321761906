export interface GetCalendarsEventsRS {
    calendarsEvents: CalendarEventModel[];
}

export interface CalendarEventModel {
    id: string,
    parentId: number,
    cnpj: string,
    tipoEvento: TipoEventoCalendario,
    statusEvento: StatusEventoCalendario,
    idDocumento: string,
    tipoDocumento: string,
    documentoBase64: string,
    extratoBase64: string,
    fatoGerador: string,
    memoriaDeCalculo: string,
    memoriaDeCalculoDAS?: memoriaDeCalculoDAS,
    memoriaDeCalculoICMS?: memoriaDeCalculoICMS,
    dataOcorrencia: Date
    pago: boolean,
    textToogle: string,
    formatoDocumento?: string;
    class_color?: string;
    observacao?: string;
}

export enum StatusEventoCalendario {
    EFETIVADO = 0,
    DISPONIVEL = 1
}

export enum TipoEventoCalendario {
    VENCIMENTO,
    TRANSMISSAO
}

export interface memoriaDeCalculoICMS {

    tipo: string,
    subTipo: string
    operacao: string,
    notas: Nota[],
    valorTotalImposto: number,
    textoLivre: string,
}


export interface Nota {
    tipo: string,
    numero: string,
    valorImposto: number,
    dataOperacao: Date
}

export interface memoriaDeCalculoDAS {
    anexo: string,
    faturamento: Faturamento,
    faixaFaturamento: FaixaFaturamento
    retencaoDeducao: boolean,
    impostos: Imposto[],
    valorBrutoImpostos: number,
    valorTotalImpostos: number,
    aliquotaAplicada: number,
    textoLivre: string,
}

export interface Imposto {
    imposto: string,
    valor: number
}

export interface Faturamento {
    valorPeriodo: number,
    valorAnual: number
}

export interface FaixaFaturamento {
    faixa: string,
    aliquota: number,
    desconto: number
}