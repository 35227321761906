import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AvTaxComplianceService } from './av-tax-compliance.service';
@Component({
  selector: 'app-av-tax-compliance',
  templateUrl: './av-tax-compliance.component.html',
  styleUrls: ['./av-tax-compliance.component.scss']
})
export class AvTaxComplianceComponent implements OnInit {

  public srcAvalara: any = '';

  constructor(
    private sanitizer: DomSanitizer,
    private taxService: AvTaxComplianceService
  ) { }

  ngOnInit() {
  }

  loadAvalara(menuId: string) {
    this.taxService.getSaml(menuId).subscribe((res: any) => {
      this.srcAvalara = this.sanitizer.bypassSecurityTrustHtml(`<html>
      <body>
        <img src="../../../assets/loading.svg" style="width: 10%; margin-left: 45%; margin-top: 10%">
        <h3 style="margin-left: 47%;color: #5b2e90; font-family: 'dosis', sans-serif;margin-top: -0.5%">Carregando...</h3>
        <form
          id="submit"
          action="${res.assertionUrl}"
          method="POST"
        >
          <input
            type="hidden"
            name="SAMLResponse"
            value="${res.base64SamlResponse}"
          /><input
            type="hidden"
            name="RelayState"
            value="${res.relayState}"
          />
        </form>
        <script>
          document.getElementById("submit").submit();
        </script>
      </body>
    </html>
    `);
    });
  }
}
