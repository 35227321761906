import { Component, OnInit } from '@angular/core';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import {
	CreateNewGridColumn,
	CreateGridAction,
	CreateNewIcon,
	CreateNewCheckBox,
	CreateNewCheckBoxColumn,
	CreateNewGridColumnCheckbox,
	CreateGridActionMenu,
	CreateGridMenu
} from '../../../shared/grid/grid.helper';

@Component({
	selector: 'app-charge',
	templateUrl: './charge.component.html',
	styleUrls: ['./charge.component.scss']
})
export class ChargeComponent implements OnInit {

	public headers: GridColumn[] = [];

	constructor() { }

	ngOnInit() {
		this.loadTableHeaders();
	}

	public loadTableHeaders() {
		this.headers.push(CreateNewGridColumn(300, 'tax_auditor', 'Nro. da parcela', (element: any) => CreateGridAction(element.tax_auditor.iconclass, element.tax_auditor.iconfunction, element.tax_auditor.iconGrid, element.tax_auditor.iconClassComponent, element.tax_auditor.tooltip), true))
		this.headers.push(CreateNewGridColumn(300, 'tax_auditor2', 'Data Vencimento', (element: any) => CreateGridAction(element.tax_auditor.iconclass, element.tax_auditor.iconfunction, element.tax_auditor.iconGrid, element.tax_auditor.iconClassComponent, element.tax_auditor.tooltip), true))
		this.headers.push(CreateNewGridColumn(300, 'tax_auditor3', 'Valor Parcela', (element: any) => CreateGridAction(element.tax_auditor.iconclass, element.tax_auditor.iconfunction, element.tax_auditor.iconGrid, element.tax_auditor.iconClassComponent, element.tax_auditor.tooltip), true))
	}

}
