import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { EChartOption } from "echarts";
import { ManifestationData } from "../DTO/dashboard.model";

@Component({
  selector: "app-chart-manifestation",
  templateUrl: "./chart-manifestation.component.html",
  styleUrls: ["./chart-manifestation.component.scss"]
})
export class ChartManifestationComponent implements OnInit, OnChanges {
  @Input() manifestationData: ManifestationData;

  ngOnChanges(changes: SimpleChanges) {
    this.updateChartOption();
  }

  mergeOption: any;
  chartOption: EChartOption = this.getChartOption();

  constructor() {}

  ngOnInit() {}

  getChartOption(): EChartOption {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c}"
      },
      series: [
        {
          name: "Status",
          type: "pie",
          radius: ["40%", "60%"],
          avoidLabelOverlap: false,
          startAngle: 0,
          minShowLabelAngle: 90,
          label: {
            normal: {
              show: true,
              position: "default",
              fontSize: "11",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "11",
                fontWeight: "bold",
                color: "black"
              }
            }
          },
          data: []
        }
      ]
    };
  }

  updateChartOption() {
    let dataSeries = this.getDataSeries();

    this.mergeOption = { series: [{ data: dataSeries }] };
  }

  getDataSeries() {
    if (!this.manifestationData) return;

    return [
      {
        value: this.manifestationData.pendente,
        name: "Pendente",
        itemStyle: {
          color: "#ffd740"
        }
      },
      {
        value: this.manifestationData.manifestado,
        name: "Manifestado",
        itemStyle: {
          color: "#59D6A2"
        }
      }
    ];
  }
}
