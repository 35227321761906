import { Component, OnInit,Input } from '@angular/core';
import { GroupEconomic } from './DTO/GroupEconomic.model';
import { LoginResponse } from '../../login/DTO/login-response';

@Component({
    selector: 'app-grouporemitter',
    templateUrl: './grouporemitter.component.html',
  })
  export class GroupOrEmitterComponent implements OnInit {
  
    @Input() groupeconomic: GroupEconomic;
    groupeconomicsSelect: GroupEconomic;
    NameGroupOrEmitter:string;

    constructor() { }
  
    ngOnInit() {
    }
    
    setGroupOrEmitter(_type:string,id:string){
      
      var ssCurrentUser = <LoginResponse>JSON.parse(sessionStorage.currentUser);
      var groupsSession = <GroupEconomic[]>JSON.parse(sessionStorage.groupeconomics);

      if(_type.toString().toLowerCase()=='group')
      {
        this.groupeconomicsSelect = groupsSession.find(c=>c.idGpecon==Number(id));
        this.NameGroupOrEmitter = this.groupeconomicsSelect.descGrupoEconomico;
        ssCurrentUser.economicGroup = this.groupeconomicsSelect;
      }
      else
      {
        this.groupeconomicsSelect =(groupsSession.filter(c=>c.emitters.find(x=>x.idMidPfj==id)).length>0?groupsSession.filter(c=>c.emitters.find(x=>x.idMidPfj==id))[0]:undefined);

        if(this.groupeconomicsSelect!= undefined)
          this.NameGroupOrEmitter = this.groupeconomicsSelect.emitters.find(c=>c.idMidPfj==id).razaoSocialNomeCompleto;

        ssCurrentUser.economicGroup = this.groupeconomicsSelect;
        var selectedEmitter = this.groupeconomicsSelect.emitters.find(c=>c.idMidPfj==id);
        ssCurrentUser.economicGroup.emitters = [];
        ssCurrentUser.economicGroup.emitters.push(selectedEmitter);
      }
      ssCurrentUser.economicGroup.selectedType = _type.toString();
      sessionStorage.setItem("currentUser", JSON.stringify(ssCurrentUser));

      window.location.reload();
    }
  }