import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-current-date',
  templateUrl: './current-date.component.html',
  styleUrls: ['./current-date.component.scss']
})
export class CurrentDateComponent implements OnInit {

  public now: Date = new Date();

  constructor() {
   
  }

  ngOnInit() {
  }

}
