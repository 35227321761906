import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { PurchaseItenDetail } from '../purchaseitensdetail/DTO/purchaseitensdetail.model';
import { PurchaseAuditsDetailComponent } from '../purchaseauditsdetail/purchaseauditsdetail.component';
import { ModalService } from '../../shared/modal/modal.service';
import { GridComponent } from '../../shared/grid/grid.component';
import { GridColumn } from '../../shared/grid/DTO/gridColumn.model';
import { PurchaseItenDetailModal } from './DTO/purchaseitensdetailmodal.model';

@Component({
	selector: 'app-purchaseitemdetailmodal',
	templateUrl: './purchaseitemdetailmodal.component.html',
	styleUrls: ['./purchaseitemdetailmodal.component.scss']
})
export class PurchaseitemdetailmodalComponent implements OnInit {
	@Input() dataSource: PurchaseItenDetailModal;
	dataSourceLocal: PurchaseItenDetailModal;
	@ViewChild('itemDetails') itemDetails: ElementRef;
	@Output() eventRefreshFinish = new EventEmitter();
	

	constructor(private modalService: ModalService) { }

	ngOnInit() {
		this.refreshDateSource(this.dataSource);
	}

	refreshDateSource(_dataSource: PurchaseItenDetailModal) {
		this.dataSourceLocal = _dataSource;
	}

	RefreshFinishClicked(): void {
		this.eventRefreshFinish.emit();
	}
}
