import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/login/login.service';
import { InsightsService } from '../insights.service';
import { CreateNewGridColumn, CreateNewIcon, CreateGridAction } from 'src/app/shared/grid/grid.helper';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { UserService } from '../../user/user.service';
import { ConsultaRQ } from '../DTO/consultasRQ';
import { ConsultaRS } from '../DTO/consultasRS';
import { Actions } from '../../purchase/DTO/actions.model';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { MatTableDataSource, MatIconRegistry } from '@angular/material';
import { forEach } from '@angular/router/src/utils/collection';
import { ModalService } from '../../shared/modal/modal.service';
import { InsightsConsultasCalculoComponent } from '../insights-consultas-calculo/insights-consultas-calculo.component';
import { GetDataTaxRulesRS, GetDataTaxRules } from '../DTO/getDataTaxRulesRS';
import { GetDataTaxRulesRQ } from '../DTO/getDataTaxRulesRQ';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-insights-consultas',
  templateUrl: './insights-consultas.component.html',
  styleUrls: ['./insights-consultas.component.scss']
})
export class InsightsConsultasComponent implements OnInit {
  icons: IconGrid[] = [];
  @ViewChild('gridfetchTaxParams') gridfetchTaxParams: GridComponent;
  @ViewChild('taxParameters') taxParameters: InsightsConsultasCalculoComponent;
  @Output() eventSearchClick = new EventEmitter();
  @Output() eventStartLoading = new EventEmitter();
  dataSource: any;
  consultaFiscalForm: FormGroup;
  ConsultaData: GetDataTaxRulesRS;
  ConsultaRequest: ConsultaRQ[];
  Consulta: GetDataTaxRules[];
  ConsultaResponse: string;
  Cnpj: string;
  dados: string;
  classRefresh: string;
  titleRefresh: string;
  enabledRefresh: boolean;
  itemTaxRules: GetDataTaxRules;
  descriptionPattern = /^[a-zA-Z\d\-\s]+$/i;


  fetchTaxParamsGridColumns: GridColumn[] = [];
  typeDocument?: string = "";

  constructor(
    private domSanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private loginService: LoginService,
    private insightsService: InsightsService,
    private matIconRegistry: MatIconRegistry,
    private modalService: ModalService) { }

  ngOnInit() {
    this.fillForm();
    this.createGrid();
    this.Cnpj = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf }).toString();
    this.GetDataTaxRules(this.Cnpj);

  }

  fillForm() {

    this.consultaFiscalForm = this.formBuilder.group({
      cnpj: this.Cnpj,
      codigo: this.formBuilder.control("", [Validators.required, Validators.maxLength(30), Validators.pattern(this.descriptionPattern)]),
      descricao: this.formBuilder.control("", [Validators.required, Validators.maxLength(30), Validators.pattern(this.descriptionPattern)]),
      ufDestino: this.formBuilder.control("", [Validators.required, Validators.minLength(2)])
    });
  }

  createGrid() {
    this.CreateRefreshIcon();

    this.classRefresh = "div-refresh";
    this.enabledRefresh = true;
    this.titleRefresh = "Atualização";


    this.fetchTaxParamsGridColumns.push(CreateNewGridColumn(200, 'dataconsulta', 'Data da Consulta', (element: GetDataTaxRules) => `${element.dataHoraConsulta}`, false, false));
    this.fetchTaxParamsGridColumns.push(CreateNewGridColumn(200, 'codigo', 'Código', (element: GetDataTaxRules) => `${element.codigoProduto}`, false, false));
    this.fetchTaxParamsGridColumns.push(CreateNewGridColumn(400, 'descricao', 'Descrição', (element: GetDataTaxRules) => `${element.descricaoProduto}`, false, false));
    this.fetchTaxParamsGridColumns.push(CreateNewGridColumn(200, 'uf', 'UF', (element: GetDataTaxRules) => `${element.UF}`, false, false));
    this.fetchTaxParamsGridColumns.push(CreateNewGridColumn(200, 'action', 'Ação', (element: GetDataTaxRules) => `${element.actions}`, false, true));
  }

  onSubmit(consultaFiscalForm) {
    this.StartDataClicked();

    if (!this.consultaFiscalForm.valid) {
      if (this.consultaFiscalForm.controls.descricao.value.length < 1 || this.consultaFiscalForm.controls.codigo.value.length < 1) {
        this.toastr.info('Campos "Código do Produto", "Descrição do Produto" e "UF" são obrigatórios.');
      }
      if (this.consultaFiscalForm.controls.descricao.hasError('pattern') || this.consultaFiscalForm.controls.codigo.hasError('pattern')){
        this.toastr.info('Caracteres especiais não são permitidos nos campos  "Código do Produto" e "Descrição do Produto".');
      }

      this.FinishDataClicked();
      return;
    }

    var request: ConsultaRQ = {
      cnpj: this.Cnpj,
      codigo: this.consultaFiscalForm.controls.codigo.value,
      descricao: this.consultaFiscalForm.controls.descricao.value,
      ufDestino: this.consultaFiscalForm.controls.ufDestino.value
    };

    this.insightsService.GetTaxRulesAsync(request).subscribe(data => {

      if (data.sucesso) {
        this.toastr.info(data.mensagem);
      }

      this.GetDataTaxRules(this.Cnpj);

      this.gridfetchTaxParams.refreshDateSource(this.Consulta);
      this.FinishDataClicked();

    }, err => {
      console.log(err)
    });

    this.consultaFiscalForm.reset();
  }

  filterDate(): void {
    this.eventSearchClick.emit();

  }

  GetDataTaxRules(cnpj) {
    let dataInicial = new Date();
    dataInicial.setDate(dataInicial.getDate() - 7);

    var request: GetDataTaxRulesRQ = {
      cnpj: this.Cnpj,
      dataInicial: dataInicial,
      dataFinal: new Date()
    };

    this.insightsService.GetDataTaxRulesAsync(request).subscribe(data => {
      this.ConsultaData = data;
      this.Consulta = [];

      if (this.ConsultaData === null || this.ConsultaData === undefined) {
        this.toastr.info('Solicitação em andamento.');
      } else {
        this.ConsultaData.vaccines.forEach(dataItem => {
          dataItem.actions = [];
          let dataConsulta = new Date(dataItem.dataHoraConsulta).toLocaleDateString();
          dataItem.dataHoraConsulta = dataConsulta;

          if (dataItem.consultaPendente) {
            dataItem.actions.push(CreateGridAction('download', "DownloadClicked", CreateNewIcon('download', ''), 'icon-lx-arrow-circle-down mat-icon icon-design action-icon', 'Solicitação em andamento'));
          } else if (dataItem.grupo == null) {
            dataItem.actions.push(CreateGridAction('Unaware-Operation', "UnawareOperationClicked", CreateNewIcon('Unaware-Operation', '../../assets/svg/Unaware_Operation.svg'), 'mat-icon icon-design', 'Parâmetros Fiscais indisponíveis.'));
          } else {
            dataItem.actions.push(CreateGridAction('mathematics', "OpenClicked", CreateNewIcon('mathematics', ''), 'icon-lx-calculator mat-icon icon-design action-icon', 'Parâmetros Fiscais'));
          }
          this.Consulta.push(dataItem);
        })
      }

      this.gridfetchTaxParams.refreshDateSource(this.Consulta);
      this.FinishDataClicked();

    }, err => {
      console.log(err)
    });
  }

  CreateRefreshIcon() {
    this.matIconRegistry.addSvgIcon(
      'refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/refresh.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/download.svg"));
  }

  StartDataClicked(): void {
    this.ngxLoader.start();
  }

  FinishDataClicked(): void {
    this.ngxLoader.stop();
  }

  openModal(p: any) {
    this.itemTaxRules = p.data;
    this.taxParameters.openModal();
  }

  RefreshGridClicked(): void {
    this.StartDataClicked();
    this.GetDataTaxRules(this.Cnpj);
  }
}
