import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { EChartOption } from "echarts";
import { TaxAuditData } from "./../DTO/dashboard.model";

@Component({
  selector: "app-chart-tax-audit",
  templateUrl: "./chart-tax-audit.component.html",
  styleUrls: ["./chart-tax-audit.component.scss"]
})
export class ChartTaxAuditComponent implements OnInit, OnChanges {
  @Input() taxAuditData: TaxAuditData;

  ngOnChanges(changes: SimpleChanges) {
    this.updateChartOption();
  }

  chartOption: EChartOption = this.getChartOption();
  mergeOption: any;

  constructor() {}

  ngOnInit() {}

  getChartOption(): EChartOption {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c}"
      },
      series: [
        {
          name: "Status",
          type: "pie",
          radius: ["40%", "60%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: true,
              position: "default",
              fontSize: "11",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "11",
                fontWeight: "bold",
                color: "black"
              }
            }
          },
          data: []
        }
      ]
    };
  }

  updateChartOption() {
    let dataSeries = this.getDataSeries();

    this.mergeOption = { series: [{ data: dataSeries }] };
  }

  getDataSeries() {
    if (!this.taxAuditData) return;

    return [
      {
        value: this.taxAuditData.pendente,
        name: "Pendente",
        itemStyle: {
          color: "#ffd740"
        }
      },
      {
        value: this.taxAuditData.divergencia,
        name: "Divergência",
        itemStyle: {
          color: "#F0462D"
        }
      },
      {
        value: this.taxAuditData.sucesso,
        name: "Sucesso",
        itemStyle: {
          color: "#59D6A2"
        }
      }
    ];
  }
}
