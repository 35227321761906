import { addHours } from 'date-fns';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateNewGridColumn } from 'src/app/shared/grid/grid.helper';
import { PurchaseService } from '../purchase.service';
import { map } from 'rxjs/operators';
import { FilterComponent } from 'src/app/shared/filter/filter.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RetornoDfe, RetornoDfePayload } from '../DTO/retornosDfe.model';
import { ToastrService } from 'ngx-toastr';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { LoginService } from 'src/app/login/login.service';

@Component({
	selector: 'app-historico-dfe',
	templateUrl: './historico-dfe.component.html',
	styleUrls: ['./historico-dfe.component.scss']
})
export class HistoricoDfeComponent implements OnInit {
	@ViewChild('filter') filter: FilterComponent;
	@ViewChild('gridSuppliers') gridSuppliers: GridComponent;

	public dfeColumns = [
		CreateNewGridColumn(300, 'datahora', 'Data/hora', (element: any) => `${new Date(element.dataHoraProcessamento).toLocaleString('pt-BR').replace(",", "")}`),
		CreateNewGridColumn(250, 'status', 'Status', (element: any) => `${element.codigoStatus}`),
		CreateNewGridColumn(650, 'descricaostatus', 'Descrição Status', (element: any) => `${element.descricaoStatus}`),
	]

	public dfeValues: RetornoDfe[] = [];
	public typeFilter = 1;
	public statusFilter = '';
	public descriptionFilter = '';

	constructor(
		public purchaseService: PurchaseService,
		private ngxLoader: NgxUiLoaderService,
		private toastr: ToastrService,
		private loginService: LoginService
	) { }

	ngOnInit() {
		this.listDfe();
	}

	async listDfe() {
		let param = this.filter.GetFilterData();
		let dataInicial = param.dateIn ? new Date(param.dateIn.setHours(0, 0, 0, 0)) : '';
		let dataFinal = param.dateOut ? new Date(param.dateOut.setHours(23, 59, 59, 999)) : '';
		let request: RetornoDfePayload = {
			DataInicial: dataInicial,
			DataFinal: dataFinal,
			Status: +this.statusFilter || null,
			Descricao: this.descriptionFilter || null,
			Cnpj: this.loginService.getCnpj()
		}
		const compararPorDataHoraProcessamentoDesc = (a: any, b: any) => {
			return new Date(b.dataHoraProcessamento).getTime() - new Date(a.dataHoraProcessamento).getTime();
		};
		this.startLoading();
		await this.purchaseService.getDfeValues(request).pipe(map(response => {
			this.dfeValues = response.registro.map(dfe => ({
				...dfe,
				dataHoraProcessamento: addHours(new Date(dfe.dataHoraProcessamento), 3)
			})).sort(compararPorDataHoraProcessamentoDesc)
			this.gridSuppliers.refreshDateSource(this.dfeValues);
		})).toPromise().catch(err => null);
		this.removeLoading();
	}

	startLoading(): void {
		this.ngxLoader.start();
	}
	removeLoading(): void {
		this.ngxLoader.stop();
	}
}
