import { Component, OnInit, Input } from '@angular/core';
import { DashBoardCalendarRQ } from 'src/app/modelstransport/dashboardcalendarRQ';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';
import { Role } from '../../../_models/enums';

@Component({
  selector: 'app-timeline-marker',
  templateUrl: './timeline-marker.component.html',
  styleUrls: ['./timeline-marker.component.scss']
})
export class TimelineMarkerComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  dateDescription: string;

  @Input()
  status: string;

  @Input()
  payEnabled: boolean;

  @Input()
  date: Date;

  showOpenButton: boolean;
  showPopup: boolean;

  constructor(private router: Router,
    private loginService: LoginService) { }

  getClass() {
    switch (this.status) {
      case "danger":
        return "danger"
      case "success":
        return "success"
      case "pendent":
        return "pendent"
      default:
        return "pendent"
    }
  }

  getDescription() {
    switch (this.status) {
      case "danger":
        return "EM ATRASO"
      case "success":
        return "CONCLUÍDO"
      case "pendent":
        return "A VENCER"
      default:
        return "SEM DADOS"
    }
  }

  ngOnInit() {
    this.showOpenButton = false;
    this.showPopup = true;

    let roles = this.loginService.GetUserLogin().roles;
    let isTimeLineRole = roles.filter(x => x == Role.CALENDARIO).length > 0;

    if (this.status && isTimeLineRole) {
      this.showOpenButton = true;
    }
  }

  clickOpenPopUpDay() {
    this.showPopup = !this.showPopup;
  }

  clickOpenDay() {
    let request: DashBoardCalendarRQ = {
      viewDate: this.date
    };

    sessionStorage.setItem(
      "DashBoardCalendarParameter",
      JSON.stringify(request)
    );
    this.router.navigate(['/fiscal']);
  }
}
