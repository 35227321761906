import { OnInit,  Component, Input, ViewChild, ChangeDetectionStrategy, ViewEncapsulation, TemplateRef, Output, EventEmitter } from '@angular/core';
import { DatePipe, registerLocaleData, WeekDay } from '@angular/common';
import { CalendarModule, CalendarEvent, CalendarDateFormatter, CalendarMonthViewComponent } from 'angular-calendar';
import { Subject } from 'rxjs';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

@Component({
    
    selector: 'app-month-calendar',
    templateUrl: './month-calendar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        './month-calendar.component.scss',
        './angular-calendar.scss'
    ]
})

export class MonthCalendarComponent implements OnInit {
    @Input() viewDate: Date;
    @Input() events: CalendarEvent[];
    @Input() refresh: Subject<any>;
    @Output() eventDayClicked = new EventEmitter<Date>();
    @Output() eventRefreshFinish= new EventEmitter();
    @Output() eventSearch= new EventEmitter<Date>();



    viewDateLocal: Date;
    eventsLocal: CalendarEvent[];
    refreshLocal: Subject<any>;
    locale: string = 'pt';
    activeDayIsOpen: boolean = true;
    view: string = 'month'
    selectedDay: WeekDay;
    
    @ViewChild('calendarDetails') calendarDetails: CalendarMonthViewComponent; 

    ngOnInit() {
        this.viewDateLocal = this.viewDate;
        this.eventsLocal = this.events;
        this.refreshLocal = this.refresh;
    }
    
    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        this.eventDayClicked.emit(date);
        this.calendarDetails.view.days.forEach(day => {
            //this.viewDateLocal = date;
            if (date == day.date) {
                day.backgroundColor = '#D1E8FF';
            } else {
                delete day.backgroundColor;
            }
        });
    }
    refreshEvents(_events: CalendarEvent[]):void
    {
        this.events = _events;
        this.eventsLocal = this.events;
        this.FinishDataClicked();
    }
    FinishDataClicked():void{
        this.eventRefreshFinish.emit();
    }

    public next():void
    {
        this.eventSearch.emit(this.viewDateLocal);
    }

    public previous():void
    {
        this.eventSearch.emit(this.viewDateLocal);
    }
}7