
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardModel } from './DTO/dashboard.model';
import { GetCalendarsEventsRS, CalendarEventModel } from '../conformity/DTO/GetCalendarsEventsRS.model';
import { GetCalendarsEventsRQ } from '../conformity/DTO/GetCalendarsEventsRQ.model';

@Injectable({
  providedIn: "root"
})
export class DashboardService {
  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
  }

  GetCalendarsEvents(_request: GetCalendarsEventsRQ): Observable<GetCalendarsEventsRS> {
    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post<GetCalendarsEventsRS>(Config.DOMEApi.GetCalendarsEvents, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetDashboardData(cnpj: string): Observable<DashboardModel> {
    var request = {
      cnpj: cnpj
    }

    return this.http
      .post<DashboardModel>(Config.DOMEApi.GetDashboardData, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  SetCalendarEvent(_request: CalendarEventModel): Observable<boolean> {
    var request = {
      header: {},
      body: _request
    }

    return this.http
      .post(Config.DOMEApi.SetCalendarEvent, request)
      .pipe(
        map(data => {
          return true;
        })
      );
  }
}