import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class AvTaxComplianceService {

  constructor(
    private http: HttpClient
  ) { }

  getSaml(menuId: string) {
    return this.http.post(Config.urlDOMECloud() + '/autenticacao/obtersamlresponse', JSON.stringify(menuId), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

}
