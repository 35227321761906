import { Component, OnInit, Input } from "@angular/core";
import { PurchaseNteDetail } from './DTO/purchasentedetail.model';
@Component({
    selector: 'app-purchasentedetail',
    templateUrl: './purchasentedetail.component.html',
    styleUrls: ['./purchasentedetail.component.scss'],
  })

export class PurchaseNteDetailComponent implements OnInit {

    @Input() dataSource:PurchaseNteDetail;
    
    dataSourceLocal:PurchaseNteDetail;

    constructor(){ }

    ngOnInit() {
        this.dataSourceLocal=this.dataSource;
    }
    refreshDateSource(_dataSource:PurchaseNteDetail)
    {
        this.dataSourceLocal=_dataSource;
    }
}
