import { CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';

@Injectable()
export class LoggedInGuard implements CanLoad, CanActivate {
    constructor(private router: Router,
        private loginService: LoginService) { }

    checkAuthentication(path: string): boolean {
        const loggedIn = this.loginService.isLoggedIn();
        if (!loggedIn) {
            this.loginService.handleLogin(`/${path}`)
        } else if (this.loginService.hasForceChangePassword()) {
            if (path != 'alterar-senha') {
                this.router.navigate(['/alterar-senha']);
                return false;
            } else {
                return true;
            }
        }
        else if (this.loginService.hasForceCompleRegistration() && sessionStorage.getItem('licenca') != '2') {
            if (path != 'completar-cadastro') {
                this.router.navigate(['/completar-cadastro']);
                return false;
            } else {
                return true;
            }
        }

        return loggedIn;
    }

    canLoad(route: Route): boolean {
        return this.checkAuthentication(route.path);
    }

    canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
        let checkAuth = this.checkAuthentication(activatedRoute.routeConfig.path);

        if (checkAuth) {
            const currentUser = this.loginService.GetUserLogin();
            if (currentUser) {
                if (activatedRoute.data.roles && activatedRoute.data.roles.indexOf(currentUser.roles) !== -1) {
                    this.router.navigate(['/']);
                    return false;
                }

                return true;
            }
        }

        return checkAuth;
    }
}
