import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { IconGrid } from './../../shared/grid/DTO/icongrid.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SendTaxAuditEmail } from './DTO/sendTaxAuditEmail.model';
import { PurchaseService } from './../purchase.service';
import { TaxAuditDetail, PurchaseDetailsDataService } from '../DTO/purchaseData.model';
import { ToastrService } from 'ngx-toastr';
import { DomeDictionary } from './../../dictionary';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'app-purchaseauditsdetail',
    templateUrl: './purchaseauditsdetail.component.html',
    styleUrls: ['./purchaseauditsdetail.component.scss'],
})

export class PurchaseAuditsDetailComponent implements OnInit {

    @Input() dataSource: any;

    @Input('taxAuditDetails')
    set input(value: TaxAuditDetail) {
        this.taxAuditDetails = value;
        this.updateForm();
    }

    @Input() auditsdetailGridColumns: GridColumn[] = [];
    @Input() chaveNota: string;
    @Input() purchaseDetails: PurchaseDetailsDataService;
    @Input() dataSourceAudits: TaxAuditDetail;
    @ViewChild('gridaudits') gridaudits: GridComponent;
    @Output() eventRefreshFinish = new EventEmitter();

    taxAuditDetails: TaxAuditDetail;
    dataEnvioAviso?: Date = null;
    dataSourceLocal: any;
    auditsDetailGridColumnsLocal: GridColumn[] = [];
    icons: IconGrid[] = [];
    fornecedorForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private purchaseService: PurchaseService,
        private ngxLoader: NgxUiLoaderService) { }

    ngOnInit() {

        this.dataSourceLocal = this.dataSource;
        this.auditsDetailGridColumnsLocal = this.auditsdetailGridColumns;

        this.createForm();

        this.icons.push(this.createNewIcon('done-green', '../assets/svg/done_green.svg', 'Item sem ocorrência'));
        this.icons.push(this.createNewIcon('priority-high', '../assets/svg/priority_high.svg', 'Item possui ocorrências de advertências'));
        this.icons.push(this.createNewIcon('clear-red', '../assets/svg/clear_red.svg', 'Item possui ocorrências de erros'));
    }

    updateForm() {
        if (!this.fornecedorForm)
            return;

        let email = '';
        let enviarAvisoAutomatico = true;
        let dataEnvioAviso = null;

        if (this.taxAuditDetails && this.taxAuditDetails.emailFornecedor) {
            email = this.taxAuditDetails.emailFornecedor;
            enviarAvisoAutomatico = this.taxAuditDetails.enviarAvisoAutomatico;
            dataEnvioAviso = this.taxAuditDetails.dataEnvioAviso;
        }

        this.fornecedorForm.setValue({
            email: email,
            enviarAvisoAutomatico: enviarAvisoAutomatico
        });

        this.dataEnvioAviso = dataEnvioAviso;
    }

    createForm() {
        this.fornecedorForm = this.formBuilder.group({
            email: [''],
            enviarAvisoAutomatico: [true]
        });
    }

    createNewIcon(_name: string, _url: string, _tooltip: string = ''): IconGrid {
        return {
            name: _name,
            url: _url,
            tooltip: _tooltip
        }
    }

    RefreshDataSource(_dataSource: any, codProduto) {
        this.dataSourceLocal = _dataSource.filter(p => p.id == codProduto.toString());
        this.auditsDetailGridColumnsLocal = this.auditsdetailGridColumns;
        this.gridaudits.refreshDateSource(this.dataSourceLocal);
    }

    RefreshFinishClicked(): void {
        this.eventRefreshFinish.emit();
    }

    onSubmit() {
        if (!this.fornecedorForm.valid) {
            this.toastr.info(DomeDictionary.InformeTodosCampos, "");
            return;
        }

        let request: SendTaxAuditEmail = {
            chaveNfe: this.chaveNota,
            email: this.fornecedorForm.controls.email.value,
            enviarAvisoAutomatico: this.fornecedorForm.controls.enviarAvisoAutomatico.value,
        };

        this.purchaseService.SendTaxAuditEmail(request).subscribe(
            data => {
                let mensagem = DomeDictionary.EmailEnviado;
                let erro = false;
                if (data.mensagem) {
                    mensagem = data.mensagem;
                    erro = true;
                }

                this.toastr.info(mensagem, "");
            });
    }
}
