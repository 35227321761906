import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { CreateNewGridColumn } from 'src/app/shared/grid/grid.helper';

@Component({
    selector: 'app-purchasectedetail',
    templateUrl: './purchasectedetail.component.html',
    styleUrls: ['./purchasectedetail.component.scss'],
})
export class PurchaseCteDetailComponent implements OnInit {
    @Input() dataSource: any;
    ctedetailGridColumns: GridColumn[] = [];
    @ViewChild('gridcteitems') gridcteitems: GridComponent;
    @Output() eventRefreshFinish = new EventEmitter();

    dataSourceLocal: any;

    constructor() { }

    ngOnInit() {
        this.ctedetailGridColumns.push(CreateNewGridColumn(450, 'chave_acesso', 'NFE', (element: any) => `${element.chave_acesso}`));
        this.ctedetailGridColumns.push(CreateNewGridColumn(100, 'numero', 'Número', (element: any) => `${element.numero}`));
        this.ctedetailGridColumns.push(CreateNewGridColumn(100, 'serie', 'Série', (element: any) => `${element.serie}`));
    }

    refreshDateSource(_dataSource: any) {
        this.dataSource = _dataSource;
        this.gridcteitems.refreshDateSource(this.dataSource.notas_transportadas);
    }

    RefreshFinishClicked(): void {
        this.eventRefreshFinish.emit();
    }
}
