import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from './../login.service';

@Component({
  selector: "app-primeiro-acesso",
  templateUrl: "./primeiro-acesso.component.html",
  styleUrls: ["./primeiro-acesso.component.scss"]
})

export class PrimeiroAcessoComponent implements OnInit {
  constructor(
    private router: Router,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
  }

  passwordChanged() {
    this.loginService.disableForceChangePassword();
    let ret = this.router.navigate(["/dashboard"]);
  }
}
