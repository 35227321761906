import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { IconGrid } from './../../shared/grid/DTO/icongrid.model';
import { PurchaseService } from './../purchase.service';
import { UndoManifestationRQ } from './../DTO/undoManifestationRQ.model';
import { SnackBarService } from './../../shared/messages/snackbar/snackbar.service';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { PurchaseNteDetail } from '../purchasentedetail/DTO/purchasentedetail.model';
import { TipoManifestacao, StatusManifestacao, Role } from '../../_models/enums';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CreateNewGridColumn } from 'src/app/shared/grid/grid.helper';
import { LoginService } from 'src/app/login/login.service';
import { DomeDictionary } from './../../dictionary';

@Component({
    selector: 'app-purchasemanifestsdetail',
    templateUrl: './purchasemanifestsdetail.component.html',
    styleUrls: ['./purchasemanifestsdetail.component.scss'],
})
export class PurchaseManifestsDetailComponent implements OnInit {
    @Input() dataSource: any;
    @Input() documentData: PurchaseNteDetail;
    @ViewChild('gridmanifests') gridmanifests: GridComponent;
    @Output() eventRefreshFinish = new EventEmitter();

    emProcesso: boolean = false;
    dataSourceLocal: any;
    manifestsdetailGridColumns: GridColumn[] = [];
    icons: IconGrid[] = [];

    constructor(private purchaseService: PurchaseService,
        private snackbarService: SnackBarService,
        private coolDialogs: NgxCoolDialogsService,
        private ngxUiLoaderService: NgxUiLoaderService,
        private loginService: LoginService) { }

    ngOnInit() {
        this.dataSourceLocal = this.dataSource;

        this.manifestsdetailGridColumns.push(CreateNewGridColumn(100, 'tipo', 'Tipo do evento', (element: any) => `${element.tipo}`));
        this.manifestsdetailGridColumns.push(CreateNewGridColumn(125, 'status_sefaz', 'Status no SEFAZ', (element: any) => `${element.status_sefaz}`));
        this.manifestsdetailGridColumns.push(CreateNewGridColumn(0, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
        this.manifestsdetailGridColumns.push(CreateNewGridColumn(150, 'data', 'Data/Hora', (element: any) => `${element.data}`));
        this.manifestsdetailGridColumns.push(CreateNewGridColumn(250, 'id', 'ID evento', (element: any) => `${element.id}`));
    }

    maskDate(d: Date): string {
        return new Date(d).toLocaleString('pt-BR');
    }

    refreshDateSource(_dataSource: any) {
        this.emProcesso = false;
        this.dataSourceLocal = _dataSource.map(it => ({ ...it, data: `${this.maskDate(it.data)}` }));
        this.gridmanifests.refreshDateSource(this.dataSourceLocal);
    }

    RefreshFinishClicked(): void {
        this.eventRefreshFinish.emit();
    }

    showUndoManifestation(): boolean {
        if (!this.loginService.hasRole(Role.ALTERAR_MANIFESTACAO)) {
            return false;
        }

        if (this.documentData
            && this.documentData.manifestacao != StatusManifestacao.Pendente
            && this.documentData.manifestacao != StatusManifestacao.Manifestado
            && this.documentData.manifestacao != StatusManifestacao.Erro)
            return true;

        return false;
    }

    showMessage(): boolean {
        if (this.documentData && this.documentData.mensagemManifestacao)
            return true;

        return false;
    }

    showButton() {
        if (!this.documentData || this.emProcesso || this.documentData.manifestacaoDesfeita)
            return false;

        return true;
    }

    showConfirmacaoButton() {
        if (this.showButton()
            && this.documentData.manifestacao != StatusManifestacao.OperacaoRealizada)
            return true;

        return false;
    }

    showRecusaButton() {
        if (this.showButton()
            && this.documentData.manifestacao != StatusManifestacao.OperacaoNaoRealizada
            && this.documentData.manifestacao != StatusManifestacao.DesconhecimentoDaOperacao)
            return true;

        return false;
    }

    showAlteracaoRealizada() {
        if (this.documentData && this.documentData.manifestacaoDesfeita)
            return true;

        return false;
    }

    showDesconhecimentoButton() {
        if (this.showButton()
            && this.documentData.manifestacao != StatusManifestacao.OperacaoNaoRealizada
            && this.documentData.manifestacao != StatusManifestacao.DesconhecimentoDaOperacao)
            return true;

        return false;
    }

    changeManifestation(tipoManifestacao: TipoManifestacao) {
        this.coolDialogs.confirm(DomeDictionary.ConfirmaAlteracaoManifestacao)
            .subscribe(response => {
                if (response) {
                    this.ngxUiLoaderService.start();
                    let request: UndoManifestationRQ = {
                        chaveDeAcesso: this.documentData.chave_acesso,
                        tipoManifestacao
                    };

                    this.purchaseService.UndoManifestation(request)
                        .subscribe(data => {
                            this.emProcesso = true;
                            this.snackbarService.showMessage(data.mensagem, !data.sucesso);
                            this.ngxUiLoaderService.stop();
                        });
                }
            });
    }
}
