import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {

    private defaultConfig: MatSnackBarConfig;

    private defautDuration = 4000

    constructor(private toastr: ToastrService) {

    }

    showError(message: string, duration: number = this.defautDuration) {
        this.showSnackbarMessage(message, duration, 'login-snakbar-error');
    }

    showSuccess(message: string, duration: number = this.defautDuration) {
        this.showSnackbarMessage(message, duration, 'login-snakbar-sucess');
    }

    showMessage(message: string, error: boolean, duration: number = this.defautDuration) {
        if (error) {
            this.showError(message, duration);
            return;
        }

        this.showSuccess(message, duration);
    }

    showSnackbarMessage(message: string, duration, panelClass: string) {
        this.toastr.info(message, '');
    }
}