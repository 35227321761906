
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { SalesRejectionsResponse, DocumentDetails, SalesContingenciesResponse, Documents, GetInutilizadasInvoiceRequest, SalesInutilizadasResponse } from './DTO/sales.model';

@Injectable({
  providedIn: "root"
})
export class SalesService {

  constructor(private http: HttpClient,
    private datePipe: DatePipe) {
  }

  ngOnInit(): void {
  }

  GetConsolidatedSalesRejections(filter: any): Observable<SalesRejectionsResponse> {
    var request = {
      header: {},
      body: {
        DateIn: filter.dateIn,
        DateOut: filter.dateOut,
        TypeDocument: filter.typeDocument,
        CNPJ: filter.CNPJ,
      }
    }
    return this.http
      .post<SalesRejectionsResponse>(Config.DOMEApi.GetConsolidatedSalesRejections, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetConsolidatedSalesContingencies(filter: any): Observable<SalesContingenciesResponse> {
    var request = {
      header: {},
      body: {
        DateIn: filter.dateIn,
        DateOut: filter.dateOut,
        TypeDocument: filter.typeDocument,
        CNPJ: filter.CNPJ,
      }
    }
    return this.http
      .post<SalesContingenciesResponse>(Config.DOMEApi.GetConsolidatedSalesContingencies, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetSalesInvoices(filter: any): Observable<Documents> {
    var request = {
      header: {},
      body: {
        DateIn: filter.dateIn,
        DateOut: filter.dateOut,
        Codigo: filter.status,
        typeDocument: filter.typeDocument,
        gridFilterType: filter.gridFilterType,///Autorizado=0,Rejeição=1 e Contingencia=2
        CNPJ: filter.CNPJ,
      }
    }
    return this.http
      .post<Documents>(Config.DOMEApi.GetSalesInvoices, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetRejectionDocumentDetails(chave: string): Observable<DocumentDetails> {
    var request = {
      header: {},
      body: {
        Chave: chave
      }
    }
    return this.http
      .post<DocumentDetails>(Config.DOMEApi.GetRejectionDocumentDetails, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetInvoiceItemDetail(chave: string): Observable<DocumentDetails> {

    var request = {
      header: {},
      body: {
        Chave: chave
      }
    }
    return this.http
      .post<DocumentDetails>(Config.DOMEApi.GetSalesInvoiceItensDetail, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetInvoiceEmitterDetail(chave: string): Observable<DocumentDetails> {
    var request = {
      header: {},
      body: {
        Chave: chave
      }
    }
    return this.http
      .post<DocumentDetails>(Config.DOMEApi.GetInvoiceEmitterDetail, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetInvoiceRemitteeDetail(chave: string): Observable<DocumentDetails> {
    var request = {
      header: {},
      body: {
        Chave: chave
      }
    }
    return this.http
      .post<DocumentDetails>(Config.DOMEApi.GetInvoiceRemitteeDetail, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetSalesInvoiceDetail(chave: string): Observable<DocumentDetails> {
    var request = {
      header: {},
      body: {
        Chave: chave
      }
    }
    return this.http
      .post<DocumentDetails>(Config.DOMEApi.GetSalesInvoiceDetail, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetInutilizadasInvoice(request: GetInutilizadasInvoiceRequest){
    return this.http
      .post<SalesInutilizadasResponse>(Config.DOMEApi.GetInutilizadasInvoice, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  DownloadXml(chaveNfe: string) : any{
    var request = { chaveNfe,  documentType: 0 };
    return this.http.post(Config.DOMEApi.SalesDownloadXml, request, {observe: 'response', responseType: 'blob'});
  }
}