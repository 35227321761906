import { GerenciarPerfisService } from './../gerenciar-perfis/gerenciar-perfis.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GridColumn } from '../shared/grid/DTO/gridColumn.model';
import { ManageUsersRQ } from './DTO/manageUsersRQ';
import { ManageUsersRS, ManageUsersData } from './DTO/manageUsersRS';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';
import { CreateNewGridColumn, CreateGridAction, CreateNewIcon } from '../shared/grid/grid.helper';
import { GridComponent } from '../shared/grid/grid.component';
import { ManageUsersService } from './manage-users.service';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { IdPerfil } from '../_models/enums';
import { isNullOrUndefined } from 'util';
import { GetEnumKeyByEnumValue } from "src/app/_helpers/enums.helper";
import { ModalService } from '../shared/modal/modal.service';
import { ManageUsersDetailsComponent } from './manageUsersDetails/manageUsersDetails.component';
import { UserDocument } from './DTO/UserDocument';
import { UserDetailsModel } from './DTO/userDetailsModel';
import { ManageUsersAccessComponent } from './manageUsersAccess/manageUsersAccess.component';
import { SaveUserRQ } from './DTO/SaveUserRQ';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { DomeDictionary } from '../dictionary';
import { ManageUsersChangeHistoryComponent } from './manageUsersChangeHistory/manageUsersChangeHistory.component';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Role } from '../_models/enums';

@Component({
	selector: 'app-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {
	icons: IconGrid[] = [];
	@ViewChild('gridManageableUsers') gridManageableUsers: GridComponent;
	@Input() dataSource: any = [];
	manageUsersForm: FormGroup;
	manageUsersGridColumns: GridColumn[] = [];
	ManageUsersRQ: ManageUsersRQ;
	ManageUsersDataResponse: ManageUsersRS;
	ManageUsersData: ManageUsersData[];
	profile: ManageUsersData[];
	Nome: string;
	Username: string;
	LoggedUser: string;
	IdUsuario: string;
	Cnpj: string;
	CnpjMatriz: string;
	Ativo: boolean;
	descriptionPattern = /^[0-9]+$/g;

	_internalCodeError = '500';

	_pageMode: string = 'query';

	@ViewChild('usersDetails') _manageUsersDetailsComponent: ManageUsersDetailsComponent;
	@ViewChild('userAccess') _manageUserAccess: ManageUsersAccessComponent;
	@ViewChild('userChangeHistory') _manageUsersHistoryComponent: ManageUsersChangeHistoryComponent;

	_userDetails: UserDetailsModel = {} as UserDetailsModel;

	constructor(
		private formBuilder: FormBuilder,
		private ngxLoader: NgxUiLoaderService,
		private toastr: ToastrService,
		private loginService: LoginService,
		private manageUsersService: ManageUsersService,
		private modalService: ModalService,
		private coolDialogs: NgxCoolDialogsService,
		private domSanitizer: DomSanitizer,
		private matIconRegistry: MatIconRegistry,
		private gerenciarPerfisService: GerenciarPerfisService) { }

	ngOnInit() {
		this.loginService.CheckRolebyUser(Role.GERENCIAR_USUARIOS);


		this.CreateIcons();
		this.createGrid();
		this.fillForm();
		this.CnpjMatriz = this.loginService.getMatrizCnpj();
		this.LoggedUser = this.loginService.user.loginUsuario;
		this.IdUsuario = this.loginService.user.idUsuario;

		this.GetManageableUsers();
	}

	StartDataClicked(): void {
		this.ngxLoader.start();
	}

	fillForm() {
		this.manageUsersForm = this.formBuilder.group({
			nome: this.Nome,
			username: this.Username,
			cnpj: this.formBuilder.control("", [Validators.maxLength(14), Validators.pattern(this.descriptionPattern)]),
			cnpjMatriz: this.CnpjMatriz,
			ativo: this.Ativo
		});
	}

	CreateIcons() {
		this.matIconRegistry.addSvgIcon(
			'search',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/search.svg')
		);
	}

	createGrid() {
		this.manageUsersGridColumns.push(CreateNewGridColumn(200, 'username', 'Usuário', (element: ManageUsersData) => `${element.usuario}`, false, false));
		this.manageUsersGridColumns.push(CreateNewGridColumn(200, 'nome', 'Nome', (element: ManageUsersData) => `${element.nomeCompleto}`, false, false));
		this.manageUsersGridColumns.push(CreateNewGridColumn(200, 'email', 'Email', (element: ManageUsersData) => `${element.email}`, false, false));
		this.manageUsersGridColumns.push(CreateNewGridColumn(200, 'idPerfil', 'Perfil de Acesso', (element: ManageUsersData) => `${element.perfilStr}`, false, false));
		this.manageUsersGridColumns.push(CreateNewGridColumn(200, 'status', 'Status', (element: ManageUsersData) => `${element.ativo}`, false, false));
		this.manageUsersGridColumns.push(CreateNewGridColumn(200, 'dataCriacao', 'Data de Criação', (element: ManageUsersData) => `${element.dataCriacao}`, false, false));
		this.manageUsersGridColumns.push(CreateNewGridColumn(200, 'ultimaAtualizacao', 'Data da Últ. Alteração', (element: ManageUsersData) => `${element.ultimaAtualizacao}`, false, false));
		this.manageUsersGridColumns.push(CreateNewGridColumn(200, 'actions', 'Ação', (element: ManageUsersData) => `${element.actions}`, false, true));
	}

	onSubmit(manageUsersForm) {
		if (!this.manageUsersForm.valid) {
			if (this.manageUsersForm.controls.cnpj.value.length > 15 || this.manageUsersForm.controls.cnpj.hasError('pattern')) {
				this.toastr.info('Campo "Cnpj" deve conter somente números e, no máximo, 14 caracteres.');
			}
		}
		else {
			this.GetManageableUsers();
		};
	}

	GetManageableUsers() {
		var request: ManageUsersRQ = {
			nome: this.manageUsersForm.controls.nome.value,
			username: this.manageUsersForm.controls.username.value,
			loggedUser: this.LoggedUser,
			cnpj: this.manageUsersForm.controls.cnpj.value,
			cnpjMatriz: '',
			ativo: this.manageUsersForm.controls.ativo.value,
			idUsuario: this.IdUsuario
		};

		this.StartDataClicked();
		this.gerenciarPerfisService.getProfiles().subscribe((perfis: any) => {
			this.manageUsersService.GetManageableUsers(request).subscribe(data => {

				this.ManageUsersDataResponse = data;
				this.ManageUsersData = [];

				this.manageUsersService.SetEmitters(this.loginService.user.economicGroup.emitters);

				this.ManageUsersDataResponse.users.forEach(dataItem => {
					dataItem.actions = [];

					if (!isNullOrUndefined(dataItem.idPerfil)) {
						dataItem.idPerfil = dataItem.idPerfilCloud;
						const profile = perfis.registro.find(perfil => perfil.id == dataItem.idPerfilCloud);
						if (profile) {
							dataItem.perfilStr = profile.nome;
						} else {
							dataItem.perfilStr = '--'
						}
					}
					else {
						dataItem.idPerfil = "";
					}

					if (!isNullOrUndefined(dataItem.ativo)) {
						dataItem.ativo = this.GetStatusDescription(dataItem.ativo);
					}
					else {
						dataItem.ativo = "";
					}

					dataItem.dataCriacao = this.maskDate(dataItem.dataCriacao);
					dataItem.ultimaAtualizacao = this.maskDate(dataItem.ultimaAtualizacao);

					if (isNullOrUndefined(dataItem.email)) {
						dataItem.email = "";
					}

					if (isNullOrUndefined(dataItem.nomeCompleto)) {
						dataItem.nomeCompleto = "";
					}

					if (isNullOrUndefined(dataItem.usuario)) {
						dataItem.usuario = "";
					}

					dataItem.actions.push(CreateGridAction('eye', "AcceptClicked", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Detalhes'));

					if (this.HasEditRole() || this.LoggedUser == dataItem.usuario) {
						dataItem.actions.push(CreateGridAction('pencil', "RejeitClicked", CreateNewIcon('pencil', '../assets/svg/pencil.svg'), 'mat-icon icon-design', 'Editar'));
					};
					this.ManageUsersData.push(dataItem);
				});
				this.gridManageableUsers.refreshDateSource(this.ManageUsersData);
				setTimeout(() => {
					this.FinishDataClicked();
				}, 1500);
			});
		},
			err => {
				this.FinishDataClicked();
				console.log(err);
			});
	}

	maskDate(date: Date): any {
		if (date == null)
			return "";
		return new Date(date).toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric' });
	}

	GetStatusDescription(status) {
		let description = status ? "Ativo" : "Inativo";
		return description;
	}

	CreateUser() {
		this.OpenNewDetailModal();
	}

	OpenNewDetailModal(): void {
		this.StartDataClicked();
		this._pageMode = 'edit';
		this.manageUsersService.SetPageMode(this._pageMode);
		this.manageUsersService.SetIsNewUser(true);
		this.FillCurrentUser(null);
		this._manageUsersDetailsComponent.RefreshModalContent(null);
		this._manageUserAccess.CreateGrid();
		this.OpenModal('manage-users-detail-modal');
		this._manageUsersDetailsComponent.enableEmail();
		this.FinishDataClicked();
	}

	AcceptClicked(element: any): void {
		this.OpenQueryDetailModal(element);
	}

	OpenQueryDetailModal(element: any): void {
		this.StartDataClicked();

		this._pageMode = 'query';

		this.manageUsersService.SetPageMode(this._pageMode);

		this.manageUsersService.GetUserData(element.data.id).subscribe(data => {
			this.FillUserDetails(data.userDocument);
			this.FillCurrentUser(data.userDocument);

			this._manageUserAccess.CreateGrid();
			this._manageUsersDetailsComponent.RefreshModalContent(this._userDetails);

			this.OpenModal('manage-users-detail-modal');
		});
		this.FinishDataClicked();
	}

	RejeitClicked(element: any): void {
		this.OpenEditDetailModal(element);

	}

	OpenEditDetailModal(element: any): void {
		this.StartDataClicked();

		this._pageMode = 'edit';

		this.manageUsersService.SetPageMode(this._pageMode);
		this.manageUsersService.SetIsNewUser(false);

		this.manageUsersService.GetUserData(element.data.id).subscribe(data => {
			this.FillUserDetails(data.userDocument);
			this.FillCurrentUser(data.userDocument);

			this._manageUsersDetailsComponent.RefreshModalContent(this._userDetails);
			this._manageUserAccess.CreateGrid();

			this.OpenModal('manage-users-detail-modal');
		});

		this.FinishDataClicked();
	}

	FinishDataClicked(): void {
		this.ngxLoader.stop();
	}

	FillUserDetails(userDocument: UserDocument): void {
		this._userDetails.usuario = userDocument.usuario;
		this._userDetails.email = userDocument.email;
		this._userDetails.alterarSenha = userDocument.alterarSenha;
		this._userDetails.nomeCompleto = userDocument.nomeCompleto;
		this._userDetails.dataCriacao = userDocument.dataCriacao;
		this._userDetails.ultimaAtualizacao = userDocument.ultimaAtualizacao;
		this._userDetails.ativo = userDocument.ativo;
		this._userDetails.idPerfil = userDocument.idPerfilCloud as any;
	}

	FillCurrentUser(userDocument: UserDocument): void {
		if (!userDocument) {
			this.manageUsersService.SetCurrentUser(userDocument);
			return;
		}

		userDocument.idPerfil = userDocument.idPerfilCloud;
		this.manageUsersService.SetCurrentUser(userDocument);
	}

	ClearCurrentUser(): void {
		this.manageUsersService.SetCurrentUser(null);
	}

	OpenModal(id: string): void {
		this.modalService.open(id);
	}

	CloseModal(id: string): void {
		this.modalService.close(id);
	}

	SaveUser(modalId: string) {
		this.ConfirmAction(DomeDictionary.ConfirmarOperacao, () => this.ConfirmSaveUser());
	}

	ConfirmAction(message: string, action) {
		this.coolDialogs.confirm(message)
			.subscribe(response => {
				if (response) {
					action();
				}
			});
	}

	ConfirmSaveUser() {
		if (!this._manageUsersDetailsComponent.IsValidForm()) {
			this.FinishDataClicked();
		}

		else {
			this.StartDataClicked();
			let currentUser = this.manageUsersService.GetCurrentUser();
			currentUser.alterarSenha = currentUser.alterarSenha ? currentUser.alterarSenha : false;

			var request: SaveUserRQ = {
				userDocument: {
					...currentUser,
					idPerfil: 1,
					idPerfilCloud: currentUser.idPerfil.toString()
				},
				loggedUser: this.LoggedUser,
			};

			if (this.manageUsersService.GetIsNewUser()) {
				if (request.userDocument.acessos && request.userDocument.acessos.length > 0) {
					request.userDocument.acessos[0].padrao = true;
				}

				this.manageUsersService.SaveNewUserAsync(request).subscribe(
					data => {
						if (!data.sucesso && data.codigoRetorno === this._internalCodeError) {
							this.FinishDataClicked();
							this.ShowAlertModal(DomeDictionary.FalhaRealizarOperacao(data.mensagem));
							return;
						}
						else if (!data.sucesso && data.codigoRetorno !== this._internalCodeError) {
							this.FinishDataClicked();
							this.toastr.error(data.mensagem);
							return;
						}
						this.ClearCurrentUser();
						this.CloseModal('manage-users-detail-modal');
						this.onSubmit(this.manageUsersForm);
						this.toastr.show(DomeDictionary.SucessoOperacao);
					})
			}
			else {
				this.manageUsersService.UpdateUserAsync(request).subscribe(
					data => {
						if (!data.sucesso && data.codigoRetorno === this._internalCodeError) {
							this.FinishDataClicked();
							this.ShowAlertModal(DomeDictionary.FalhaRealizarOperacao(data.mensagem));
							return;
						}
						else if (!data.sucesso && data.codigoRetorno !== this._internalCodeError) {
							this.FinishDataClicked();
							this.toastr.error(data.mensagem);
							return;
						}
						this.ClearCurrentUser();
						this.CloseModal('manage-users-detail-modal');
						this.onSubmit(this.manageUsersForm);
						this.toastr.show(DomeDictionary.SucessoOperacao);
					})
			}
		}
	}

	HasRoleCreateUser(): boolean {
		return this.loginService.hasRole(Role.CRIAR_USUARIOS)
	}

	HasEditRole(): boolean {
		return this.loginService.hasRole(Role.EDITAR_USUARIOS)
	}

	ShowAlertModal(message: string) {
		this.coolDialogs.alert(message, {
			okButtonText: 'Fechar',
		});
	}

	ClearForm() {
		this.manageUsersForm.reset();
		this.GetManageableUsers();
	}
}
