import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { Actions } from 'src/app/sales/DTO/actions.model';
import { ModalService } from 'src/app/shared/modal/modal.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatIconRegistry } from '@angular/material';
import { CheckboxColumn, LabelPositionEnum } from 'src/app/shared/grid/DTO/checkboxColumn.model';
import { LabelColumn } from 'src/app/shared/grid/DTO/labelColumn.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ConformityService } from '../conformity.service';

@Component({
  selector: 'app-conformityfileextraction',
  templateUrl: './conformityfileextraction.component.html',
  styleUrls: ['./conformityfileextraction.component.scss']
})

export class ConformityFileExtractionComponent implements OnInit {
  icons: IconGrid[] = [];
  ConformityTaxesGridColumns: GridColumn[] = [];
  @ViewChild('gridconformityExtraction') gridconformityExtraction: GridComponent;
  ConformityFileExtractionData: any[] = [];
  @ViewChild('dwnldLnk') dwnldLnk: ElementRef;
  @ViewChild('textFaixaTax') textFaixaTax: ElementRef;
  @ViewChild('textFaixaICMSTax') textFaixaICMSTax: ElementRef;
  mathContent: string;
  positionTaxCalendar: number = 0;
  tooltipDownload: string = 'Download do arquivo';
  enabledRefresh: boolean;
  classRefresh: string;
  titleRefresh: string;
  @Output() eventRefreshGrid = new EventEmitter();

  constructor(private ngxLoader: NgxUiLoaderService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private conformityService: ConformityService) {
    this.icons.push(this.createNewIcon('mathematics', '../../assets/svg/mathematics.svg'));
    this.icons.push(this.createNewIcon('download', '../../assets/svg/download.svg'));
    this.enabledRefresh = true;
    this.titleRefresh = "Atualização";
    this.classRefresh = "div-refresh";

    this.matIconRegistry.addSvgIcon(
      'refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/refresh.svg')
    );
    this.matIconRegistry.addSvgIcon("download", this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/svg/download.svg"));

    this.ConformityTaxesGridColumns.push(this.createNewGridColumn(200, 'tipo', 'Tipo', (element: any) => `${element.tipo}`, false, false, false, null, "", 200));
    this.ConformityTaxesGridColumns.push(this.createNewGridColumn(300, 'nomeDocumento', 'Nome', (element: any) => `${element.nomeDocumento}`, false, false, false, null, "", 300));
    this.ConformityTaxesGridColumns.push(this.createNewGridColumn(300, 'action', 'Ação', (element: any) => `${element.actions}`, false, true, false, null, "iconaction-align-start"));

  }
  ngOnInit() {
  }

  createNewIcon(_name: string, _url: string, _tooltip: string = ''): IconGrid {
    return {
      name: _name,
      url: _url,
      tooltip: _tooltip
    }
  }

  createNewCheckBox(
    _checked: any = false,
    _indeterminate: boolean = false,
    _labelPosition: LabelPositionEnum = LabelPositionEnum.after,
    _disabled: boolean = false,
    _labeltext: string = ''
  ): CheckboxColumn {
    return {
      checked: _checked,
      disabled: _disabled,
      indeterminate: _indeterminate,
      labelPosition: _labelPosition.toString(),
      labeltext: _labeltext,
    }
  }

  createNewGridColumn(_width: number,
    _columnDef: string,
    _header: string,
    _cell: any,
    _enabledIcon: boolean = false,
    _enabledAction: boolean = false,
    _enabledCheckbox: boolean = false,
    _checkbox: CheckboxColumn = null,
    _classAlignAction: string = "",
    _widthmax: number = 0,
  ): GridColumn {
    return {
      width: _width,
      columnDef: _columnDef,
      header: _header,
      cell: _cell,
      checkbox: _checkbox,
      enabledCheckbox: _enabledCheckbox,
      enabledIcon: _enabledIcon,
      enabledAction: _enabledAction,
      classAlignAction: _classAlignAction,
      widthmax: _widthmax
    }
  }
  createNewAction(_iconclass: string,
    _iconfunction: any,
    _icongrid: IconGrid,
    _iconClassComponent: string = '',
    _tooltip: string = ''): Actions {
    return {
      iconclass: _iconclass,
      iconfunction: _iconfunction,
      iconGrid: _icongrid,
      iconClassComponent: _iconClassComponent,
      tooltip: _tooltip,
      hasMenu: false
    }
  }
  createLabel(
    _label: string,
    _class: string
  ): LabelColumn {
    return {
      class: _class,
      label: _label
    }
  }

  refreshDataSource(_dataSource: any) {
    if (_dataSource.length > 0) {
      this.ConformityFileExtractionData = _dataSource;
    }
    else {
      this.ConformityFileExtractionData = [];
    }

    this.gridconformityExtraction.refreshDateSource(this.ConformityFileExtractionData);
  }

  downloadFile(e: any) {
    const document = e.data;
    this.conformityService.downloadDocument('sintegra', document.nomeDocumento).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'text/plain' });
      this.dwnldLnk.nativeElement.href = window.URL.createObjectURL(blob);
      this.dwnldLnk.nativeElement.download = `${document.nomeDocumento}.${document.extensao}`;
      this.dwnldLnk.nativeElement.click();
    });
  }

  StartDataClicked(): void {
    this.ngxLoader.start();
  }
  FinishDataClicked(): void {
    this.ngxLoader.stop();
  }

  private openModal(id: string) {
    this.modalService.open(id);
  }

  private closeModal(id: string) {
    this.modalService.close(id);
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  RefreshGridClicked(): void {
    this.eventRefreshGrid.emit();
  }

}
