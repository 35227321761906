import { LoginService } from 'src/app/login/login.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuAvalaraConst } from 'src/app/const/menu/menu-avalara-mock.const';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu-multi-level',
  templateUrl: './menu-multi-level.component.html',
  styleUrls: ['./menu-multi-level.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '100%',
        opacity: 1,
        overflowX: 'auto',
        padding: '15px'
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
        overflowX: 'hidden',
        padding: '0'
      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ]),
    ]),
  ],
})
export class MenuMultiLevelComponent implements OnInit {

  @Input() menuDataRaw: any = MenuAvalaraConst;
  @Output() onMenuClick = new EventEmitter()

  menuData: any = { menuItems: [] };
  menus: any[] = [];
  submenus: any[] = [];
  menuSelected: string = '';
  groupSelected: string = '';
  searchTerm: string = '';

  constructor(
    private loginService: LoginService,
  ) {
    // Construindo Menu
  }

  ngOnInit() {
    let _menus = JSON.parse(JSON.stringify((this.menuDataRaw)));
    if (environment.development || environment.homologation) {
      _menus = { table: _menus.tableDev.map(menu => ({ ...menu, parentMenuId: menu.parentMenuId.toLowerCase(), menuId: menu.menuId.toLowerCase() })) }
    } else {
      _menus = { table: _menus.tablePrd.map(menu => ({ ...menu, parentMenuId: menu.parentMenuId.toLowerCase(), menuId: menu.menuId.toLowerCase() })) }
    }

    const lvl2 = _menus.table.filter(menu => menu.level == 2);

    lvl2.forEach(menu => {
      const exists = this.loginService.user.roles.find(str => str.toLowerCase() == menu.menuId.toLowerCase());
      if (!exists) {
        _menus.table.splice(_menus.table.findIndex(menuOriginal => menuOriginal.menuId == menu.menuId), 1);
        const lvl3 = _menus.table.filter(menu2 => menu2.parentMenuId.toLowerCase() == menu.menuId.toLowerCase());
        lvl3.forEach(menu3 => {
          _menus.table.splice(_menus.table.findIndex(menuOriginal => menuOriginal.menuId == menu3.menuId), 1);
        });
      }
    });

    const lvl1 = _menus.table.filter(menu => menu.level == 1);

    lvl1.forEach(menu => {
      const exists = _menus.table.find((mn) => mn.parentMenuId == menu.menuId && mn.level == 2);
      if (!exists) {
        _menus.table.splice(_menus.table.findIndex(menuOriginal => menuOriginal.menuId == menu.menuId), 1);
      }
    });

    this.mapMenuData(_menus);
    this.setScreenMenu();
    this.menuSelected = '';
  }

  mapMenuData(_menus: any) {

    const firstLevelXlsx: any[] = _menus.table.filter((menuXlsx: any) => menuXlsx.level === 1);

    firstLevelXlsx.forEach((menu: any) => {
      const menuItem: any = this.buildMenu(menu);
      const secondLevelXlsx: any[] = _menus.table.filter((groupXlsx: any) => groupXlsx.parentMenuId === menu.menuId) || [];

      if (!this.menuSelected && this.menuSelected.length == 0)
        this.menuSelected = menu.menuId;

      secondLevelXlsx.forEach((group: any) => {
        const groupItem: any = this.buildGroupMenu(group);
        const thirdLevelXlsx: any[] = _menus.table.filter((subMenu: any) => subMenu.parentMenuId === group.menuId) || [];

        thirdLevelXlsx.forEach((submenu: any) => {
          groupItem.items.push(this.buildSubmenu(submenu));
        });

        menuItem.groups.push(groupItem);
      });

      this.menuData.menuItems.push(menuItem);
    });

    this.menus = this.menuData.menuItems;
  }

  buildSubmenu(itemXlsx: any): any {
    return {
      id: itemXlsx.menuId,
      title: itemXlsx.menuName,
      parentId: itemXlsx.parentMenuId,
    };
  }

  buildGroupMenu(itemXlsx: any): any {
    return {
      id: itemXlsx.menuId,
      title: itemXlsx.menuName,
      parentId: itemXlsx.parentMenuId,
      items: []
    };
  }

  buildMenu(itemXlsx: any): any {
    return {
      id: itemXlsx.menuId,
      title: itemXlsx.menuName,
      groups: []
    };
  }

  setScreenMenu() {
    if (!this.menuSelected) {
      return;
    }

    const _menuItensSelected: any[] = this.menuData.menuItems.filter(m => m.id === this.menuSelected);
    let _menuGroupSelected!: any[];

    if (this.groupSelected && this.groupSelected.length > 0) {
      _menuGroupSelected = _menuItensSelected[0].groups.filter(g => g.id === this.groupSelected);
    } else {
      _menuGroupSelected = _menuItensSelected[0].groups.filter(g => g.id !== null);
    }

    let itensGrouped: any[] = [];

    _menuGroupSelected.forEach((g: any) => {
      const _group: any = {
        id: g.id,
        title: g.title,
        parentId: g.parentId,
        type: 'group'
      }

      itensGrouped.push(_group);

      g.items.forEach((sm: any) => {
        const _subMenu: any = {
          id: sm.id,
          title: sm.title,
          parentId: sm.parentId,
          type: 'menu-item'
        }
        itensGrouped.push(_subMenu);
      });
    });

    let itensFiltered: any[] = [];

    if (this.searchTerm && this.searchTerm.length > 0) {
      itensFiltered = itensGrouped.filter(ig =>
        this.removeVogalsEspecialChars(ig.title).toLowerCase().includes(this.removeVogalsEspecialChars(this.searchTerm).toLowerCase().trim())
      );
    } else {
      itensFiltered = itensGrouped;
    }

    this.submenus = itensFiltered;
  }

  selectMenu(id: string) {
    if (!this.menuData.menuItems.find(menu => menu.id === id))
      return;

    if (this.menuSelected === id) {
      this.menuSelected = '';
      return;
    } else {
      this.menuSelected = id;
    }

    this.groupSelected = '';
    this.searchTerm = '';

    this.setScreenMenu();
  }

  filterByTerm(event: any) {
    this.searchTerm = event.currentTarget.value;
    this.setScreenMenu();
  }

  sendSubmenuId(id: string) {
    this.menuSelected = '';
    this.onMenuClick.emit(id);
  }

  removeVogalsEspecialChars(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
