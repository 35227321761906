import { ToastrService } from 'ngx-toastr';
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "../login/login.service";
import { DomeDictionary } from './../dictionary';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: LoginService,
    private toastr: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError(err => {
          if (err.status === 401) {
            this.authenticationService.logout();
            this.toastr.info(DomeDictionary.SessaoExpirou);
            return throwError(err);
          }

          return throwError(err.error);
        })
      );
  }
}
