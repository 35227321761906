import { Actions } from './actions.model';
import { CheckboxColumn } from 'src/app/shared/grid/DTO/checkboxColumn.model';
import { LabelColumn } from 'src/app/shared/grid/DTO/labelColumn.model';

export interface ConformityModel{
    parentId:number,
    cnpj:string,
    tipoEvento:string,
    statusEvento:string,
    idDocumento:string,
    tipoDocumento:string,
    documentoBase64:string,
    extratoBase64:string,
    FatoGerador:string,
    memoriaDeCalculo:string,
    memoriaDeCalculoICMS?:any,
    memoriaDeCalculoDAS?:any,
    dataOcorrencia:Date
}

export interface ConformityTaxesModel{
    id:string,
    imposto:string
    data_vencimento:string,
    valor:string,
    status_pagamento:LabelColumn//string,
    idDocumento:string,
    tipoDownload:number,
    tipoDocumento:string,
    documentoBase64:string,
    documentoPendente:boolean,
    extratoBase64:string,
    memoriaDeCalculoICMS?:any,
    memoriaDeCalculoDAS?:any,
    dataOcorrencia:Date,
    formatoDocumento?:string
    index:Number,
    actions:Actions[],
    checkbox?:CheckboxColumn,
}

export interface ConformityFileExtractionData{
    flow:string,
    tipoDocumento:string,
    url:string,
    actions:Actions[],
}

export interface ConformityReportsExtractionData{
    nome:string,
    relatorio:string,
    datasolicitacao:string,
    actions:Actions[],
}

export enum TypeDocument{
    Todos_Entrada=0,
    NFe_Entrada=1,
    Todos_Saida=99,
    NFe_Saida=3,
    CFe_Saida=5,
    NFCe_Saida=4
}

export enum TypeFlow{
    Xml_de_Entrada=0,
    Xml_de_Saida=1
}
