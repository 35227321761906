import {
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { LoginService } from "../login/login.service";
import { Role } from "../_models/enums";
import { MatIconRegistry, MatSelect } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { IconGrid } from "../shared/grid/DTO/icongrid.model";
import { AccountIntegrationInconsistencyComponent } from './accountintegrationinconsistency/accountintegrationinconsistency.component';
import { AccountantIntegrationService } from './accountantintegration.service';
import { AccountantIntegrationFilterComponent } from './accountantintegrationfilter/accountantintegrationfilter.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GetAccountantIntegrationRQ } from './DTO/GetAccountantIntegrationRQ.';

@Component({
  selector: "app-accountantintegration",
  templateUrl: "./accountantintegration.component.html",
  styleUrls: ["./accountantintegration.component.scss"]
})
export class AccountantIntegrationComponent implements OnInit {


  @ViewChild('inconsistency') inconsistency: AccountIntegrationInconsistencyComponent;
  @ViewChild('filteraccountantintegration') filteraccountantintegration: AccountantIntegrationFilterComponent;

  dataSource: any;
  currentFilter: GetAccountantIntegrationRQ;

  icons: IconGrid[] = [];

  constructor(
    private loginService: LoginService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private ngxLoader: NgxUiLoaderService,
    private accountantIntegrationService: AccountantIntegrationService
  ) {  }

  ngOnInit() {
    this.loginService.CheckRolebyUser(Role.NOTA_DE_ENTRADA);
  }

  GetAccountantIntegration() {
    this.currentFilter = this.filteraccountantintegration.GetFilterData();

    this.StartDataClicked();
    this.accountantIntegrationService.GetAccountantIntegration(this.currentFilter).subscribe(
      data => {
        this.dataSource = data;
        if (this.dataSource && this.dataSource.inconsistencies != null && this.dataSource.inconsistencies.length > 0) {
          this.inconsistency.refreshDataSource(this.dataSource.inconsistencies);
        }

        this.RefreshFinishClicked();
      });
  }

  StartDataClicked(): void {
    this.ngxLoader.start();
  }

  RefreshFinishClicked(): void {
    this.ngxLoader.stop();
  }
}
