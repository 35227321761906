export const MenuAvalaraConst = {
  "tablePrd": [
    {
      "menuId": "a9faae96-2712-4a63-a9dd-564cebbd874a",
      "menuName": "Tabelas Auxiliares",
      "parentMenuId": "null",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "375f8369-7d43-4c46-9ff9-9828b06d1f55",
      "menuName": "Cadastros Gerais",
      "parentMenuId": "null",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "menuName": "Geral",
      "parentMenuId": "375f8369-7d43-4c46-9ff9-9828b06d1f55",
      "roles": ['FISCAL', 'CONTABIL', 'SIMPLES_NACIONAL', 'GNRE'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "menuName": "Fiscal",
      "parentMenuId": "375f8369-7d43-4c46-9ff9-9828b06d1f55",
      "roles": ['FISCAL', 'SIMPLES_NACIONAL', 'GNRE'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "menuName": "Contábil",
      "parentMenuId": "375f8369-7d43-4c46-9ff9-9828b06d1f55",
      "roles": ['CONTABIL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "d6d7ddcc-d212-4e1f-9152-be7f2e3708ac",
      "menuName": "ECF",
      "parentMenuId": "375f8369-7d43-4c46-9ff9-9828b06d1f55",
      "roles": ['CONTABIL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "db2907bb-4d15-4031-b6c4-c35781b9bc39",
      "menuName": "Controle de Acesso",
      "parentMenuId": "375f8369-7d43-4c46-9ff9-9828b06d1f55",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "daec91b0-37c4-4bb3-b989-b1e62b71768e",
      "menuName": "Parâmetro Envio de Email",
      "parentMenuId": "375f8369-7d43-4c46-9ff9-9828b06d1f55",
      "type": "Group",
      "level": 2
    },

    {
      "menuId": "81381c52-5183-4c91-8495-7f78885f4552",
      "menuName": "Simples Nacional",
      "parentMenuId": "375f8369-7d43-4c46-9ff9-9828b06d1f55",
      "roles": ['SIMPLES_NACIONAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "menuName": "Fiscal",
      "parentMenuId": "null",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "menuName": "Contábil",
      "parentMenuId": "null",
      "role": ['CONTABIL'],
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "51ea8472-cd5e-462c-8427-2db761f35e2a",
      "menuName": "Apurador",
      "parentMenuId": "null",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "menuName": "Processamentos",
      "parentMenuId": "null",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "7ffd04b5-99bc-4bce-a501-ffb809d9e34c",
      "menuName": "REINF",
      "parentMenuId": "null",
      "type": "Group",
      "level": 1
    },
    // {
    //   "menuId": "ea42d5e2-bfce-473d-8ac5-3c796178f5b7",
    //   "menuName": "Arquivo",
    //   "parentMenuId": "null",
    //   "type": "Route",
    //   "level": 1
    // },
    // {
    //   "menuId": "38d2b620-6056-480f-a2d1-fc643d0ce8ad",
    //   "menuName": "Dashboard",
    //   "parentMenuId": "null",
    //   "type": "Route",
    //   "level": 1
    // },
    {
      "menuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "menuName": "Cadastros",
      "parentMenuId": "a9faae96-2712-4a63-a9dd-564cebbd874a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "fd1c5885-e6ea-4697-9e47-93925c32ca79",
      "menuName": "ANP",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "489ae1a9-5fb8-4370-9dd0-22ecf50492b7",
      "menuName": "Ajuste Apuração",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "71fdd14a-5a80-4626-b8c9-1c7158ade1b1",
      "menuName": "Ajuste Apuração PIS/COFINS",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e8020091-42a2-48f4-8acf-0a86ed95b14d",
      "menuName": "Ajuste IPI",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "83b0310e-90d7-4643-9e3e-6a02ded3c2a3",
      "menuName": "Atividade Contribuição Previdenciária",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1640b703-c13e-44d9-8404-2e5560ce477c",
      "menuName": "Atividade Econômica",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "dc19e5af-80f8-4570-a020-4226ed56bf89",
      "menuName": "CEST - Código Especificador da Substituição Tributária",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5a02d2f4-7072-4bee-8323-640b652b8d5e",
      "menuName": "CFOP",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5438f8da-bba4-463d-9d34-f67036be3e9f",
      "menuName": "CNAE",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "70a95b36-76d6-4bf8-9376-f8cd9594473b",
      "menuName": "Cidade",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b113f5e0-64f7-4557-aaa9-05a5a53e48b9",
      "menuName": "Classe Documento PIS/COFINS",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5be0941d-37ee-488a-84ba-4fce8ecf8d79",
      "menuName": "Classe de Consumo",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f0d55b04-d912-4ef5-ba47-eeed595d3e8a",
      "menuName": "Classificação Fiscal",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b7306fb7-23ff-41ca-b7c1-e5541b2b49de",
      "menuName": "Classificação Serviço Prestado",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "683397f2-c295-4b47-80e0-a72676d44bcb",
      "menuName": "Classificação Tributária",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "68ae3323-c16c-4668-9a3a-dd1b1cc12f5e",
      "menuName": "Classificação de Rendimento",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "686f3b8c-c804-4a93-8d65-8abcabf8828e",
      "menuName": "Complemento",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6a6c0516-97ba-4aba-a9c3-b41bc8c77932",
      "menuName": "Composição",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ec2f449a-60a5-4ed7-b745-9265352c7eb5",
      "menuName": "Conhecimento Transporte",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3828e65d-bc88-4b5b-be72-266b640a0759",
      "menuName": "Código Ajuste Base de Cálculo Contribuição",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "14b491d9-c19a-45e7-969a-e041ded1a3d7",
      "menuName": "Código Ajuste Benefício",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "78a0d5a2-4986-4845-b906-ca8bce59535a",
      "menuName": "Código COSIF",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6aae81cc-f919-48a5-b6ce-d9fbd900bd69",
      "menuName": "Código Class. Contribuinte IPI",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0db742bc-2b61-4185-a142-bad4dd235ab5",
      "menuName": "Código Complemento Analitico",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cd6f9589-e869-4600-acf0-cb101e9ce5e8",
      "menuName": "Código Contribuição",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "fbfe5498-bbc3-4ae0-b22b-139f9a3c809e",
      "menuName": "Código Imposto",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "65740a6c-70a1-4807-a32c-1a83d591a702",
      "menuName": "Código Informação Adicional",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "db584b83-e0d5-4ac2-86dc-2eaae04614ba",
      "menuName": "Código Informação Adicional Item",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3630ba63-9464-4ef3-8bb4-8302bb5033e3",
      "menuName": "Código Motivo Rest. Compl. ICMS",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2cb06d35-4c33-4ac1-8326-202798b09b43",
      "menuName": "Código Receita",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "faf39298-fb1b-4094-8c35-254ecf1ca78e",
      "menuName": "Código de Pagamento",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2642f142-aa38-4d52-96dc-6db57c53df97",
      "menuName": "Entidade Responsável",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e1098f6a-41fd-4b22-b9ba-702f190d400a",
      "menuName": "Espécie Decisão",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6db21f37-9f2c-4316-af0e-d5b8684d679a",
      "menuName": "Estado",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d55044f8-2ecf-4f09-a43d-051c27ea9d2f",
      "menuName": "Grupo Tensão",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c4055dc7-72b9-4f49-87b8-de987624afce",
      "menuName": "Gênero",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1da33700-94c3-4c39-9773-53c93dee359c",
      "menuName": "Imposto",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "323cd6f8-38ac-4f91-99e7-1dca1de76a93",
      "menuName": "Indicador Situação Especial",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "269818f7-132b-4f6c-9d5a-43a2146a5ba5",
      "menuName": "Instituição Responsável Cadastro",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "21975a04-bf2d-473d-83aa-ef0c5914d011",
      "menuName": "Item Índice Participação",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "48c2c2a7-6939-4cf8-97e9-1bb7fb034362",
      "menuName": "Lista Serviço",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "95a5c7ac-2351-4042-ba53-6a623fbeba11",
      "menuName": "Modelo NF",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f3ddaac2-1606-42aa-8fb5-6b84e7f5573a",
      "menuName": "Natureza Base Crédito PIS/COFINS x CFOP",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a1fe5fe1-142e-4168-b344-f8ef14ce1cda",
      "menuName": "Natureza Conta",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c0369b38-f578-4cd2-99c1-c5a03da76044",
      "menuName": "Natureza Jurídica",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "be980998-eeb5-4f1e-9215-088387ad43a6",
      "menuName": "Natureza Rendimento",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2d9e9b5f-aaa3-4200-a153-0530f73d1542",
      "menuName": "Natureza Retenção Fonte",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e0f2049a-beea-4ca5-a242-8a0971aad92b",
      "menuName": "Natureza Subconta",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "56d073fa-77f6-4c2d-85af-7b665eefe33f",
      "menuName": "Observação Ajuste ICMS",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0564388c-7ce9-4d66-bef3-926aff5efef1",
      "menuName": "Parametrização FUNRURAL",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d5463854-6ab8-4d82-aa01-48bf4f33cda8",
      "menuName": "País",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d6009c3a-8791-4eac-a5f0-ad580455bd48",
      "menuName": "Plano Conta Referenciado",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "93889912-14c3-4049-8a9d-29f5095301a4",
      "menuName": "Quadro DIME",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "915132a4-0f2f-4e12-a918-2d4f3d3978a2",
      "menuName": "Qualificação Assinante",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "943cb7f0-0581-4c6f-a395-0bb08bb3e178",
      "menuName": "Selo IPI",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1116b6d4-51c0-4eed-87c2-2c3ee2c2293c",
      "menuName": "Serviço Federal x Código REINF",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2ffc87ee-aae7-41a1-a481-193f3448c299",
      "menuName": "Situação Tributária COFINS",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "459a080c-c1c2-4db1-9f21-b3ea97404637",
      "menuName": "Situação Tributária ICMS Tab A",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "87f6998c-6c61-4708-a9d9-cdc0b7127c63",
      "menuName": "Situação Tributária ICMS Tab B",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "84e118f6-f78e-4ad2-b236-e89b0577249a",
      "menuName": "Situação Tributária IPI",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "234b7721-d8dd-4fb8-bcf1-82b59bd3661f",
      "menuName": "Situação Tributária PIS",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a332fd3d-7599-40f8-ba37-e5ba860131ab",
      "menuName": "Taxa SELIC",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "45b0c6d7-ac97-494d-8675-d75f5f4bf548",
      "menuName": "Tipo Crédito PIS/COFINS",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "35238dcd-ad05-4ac6-aa63-cbd48dc93599",
      "menuName": "Tipo Estabelecimento",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "15c08cf5-331d-4eec-a26b-5a2172ed3aa1",
      "menuName": "Tipo Item",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7d3981e4-d336-4daa-913a-c7aa251af986",
      "menuName": "Tipo Nota Fiscal Serviço",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7dccaca1-1ac6-4e63-baf8-8dc9d48267e4",
      "menuName": "Tipo Produto/Serviço",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e94f94e4-dfff-4b4f-bbf7-b6fc0d9a7b81",
      "menuName": "Tipo Serviço",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8ecaac8c-8cd4-482f-85c9-935ee7e6e996",
      "menuName": "Unidade Medida FCI",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f9d0c900-6634-4ad8-81e3-a72f3a4ec023",
      "menuName": "Unidades de Medidas Tributáveis no Comércio Exterior",
      "parentMenuId": "688d91f5-938f-4249-8147-b3d5c81d39af",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5d84f518-3104-414e-b9ec-917672d510f9",
      "menuName": "DCTF",
      "parentMenuId": "a9faae96-2712-4a63-a9dd-564cebbd874a",
      "type": "Route",
      "level": 2
    },
    {
      "menuId": "d10da745-d6d1-4ea0-a0fd-d82dd2f9d623",
      "menuName": "DCTF Tipo Declaração",
      "parentMenuId": "5d84f518-3104-414e-b9ec-917672d510f9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e6f34d45-d8be-470d-9014-bdbc81ca7cb8",
      "menuName": "DCTF Situação",
      "parentMenuId": "5d84f518-3104-414e-b9ec-917672d510f9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ec90b27e-371c-466d-afbf-5c6b3ef35f30",
      "menuName": "DCTF Forma Tributação",
      "parentMenuId": "5d84f518-3104-414e-b9ec-917672d510f9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3e3f2569-faa3-49da-9cc8-7760d2e1c93b",
      "menuName": "DCTF Qualificação PJ",
      "parentMenuId": "5d84f518-3104-414e-b9ec-917672d510f9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "bf7f772d-d424-4084-82fb-d1b00e298bea",
      "menuName": "DCTF Situação PJ Mês Declaração",
      "parentMenuId": "5d84f518-3104-414e-b9ec-917672d510f9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "57cce9af-bae6-44c9-9759-1559309fffe3",
      "menuName": "DCTF Variações Monetárias",
      "parentMenuId": "5d84f518-3104-414e-b9ec-917672d510f9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0e37cfe7-f6c6-4b51-bb68-0be218a3cee4",
      "menuName": "Opções Referente Lei 12973/2014",
      "parentMenuId": "5d84f518-3104-414e-b9ec-917672d510f9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "menuName": "ECF",
      "parentMenuId": "a9faae96-2712-4a63-a9dd-564cebbd874a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "2593415c-fdc3-4697-b3bc-b679f0403aa5",
      "menuName": "Apuraçao/Cálculo Lucro Presumido - IRPJ/CSLL",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9f2c51e9-8f91-4bd0-85e4-3972f7a2e2a0",
      "menuName": "Base Cálculo Lucro Arbitrado IRPJ CSLL",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5763e665-3845-4243-8813-3c3c12091956",
      "menuName": "Base Cálculo Lucro Presumido IRPJ CSLL",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7c34c1e3-f789-425b-8f48-c933cadf621f",
      "menuName": "Composição Custos L210",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5c07ccf8-03d2-438b-9caa-2fa47aaaeaef",
      "menuName": "Cálculo Estimativa IRPJ CSLL",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8fb7688a-215a-4140-8e43-e9597dee4a63",
      "menuName": "Cálculo Lucro Arbitrado IRPJ CSLL",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "14248843-d461-4dca-86ca-6fd5c88e1822",
      "menuName": "Cálculo Lucro Presumido IRPJ CSLL",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "af15955d-4817-4820-97ac-380348417a1d",
      "menuName": "Cálculo Lucro Real IRPJ/CSLL",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7ebbf987-04fc-49e3-bee6-79af681b994d",
      "menuName": "Cálculo da Isenção e Redução do Imposto Sobre o Lucro Real IRPJ",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cb2ebe71-cb91-4911-a423-c553451a50c6",
      "menuName": "Cálculo da Isenção e Redução do Lucro Presumido",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7b249a97-3170-41e1-b0af-56b31d9327ba",
      "menuName": "Cálculo de Imune e Isentas do IRPJ CSLL",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "81012f20-a925-4ec0-a844-204844f89f80",
      "menuName": "Código Padrão da Parte B",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1cadfcab-478c-4d76-a488-66a1b4d0038d",
      "menuName": "Código Receita IR e CSLL Retido na Fonte",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f3d84b29-61e7-4cd7-8881-f879c258f091",
      "menuName": "Demonstrativo Recursos Moeda Estrangeira",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "28b9325e-fa2e-40a4-a9f8-18d788fea112",
      "menuName": "Demonstração das Receitas Incentivadas do Lucro Presumido",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f1de01b5-c583-46cd-9fea-6fe6cdc6d472",
      "menuName": "Demonstração do Lucro da Exploração IRPJ",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8a829a66-32f7-42ab-b20b-5b6922f35386",
      "menuName": "Lançamento Parte A e-LALUR e e-LACS",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "08016c74-edaf-4bb1-8dca-59a666b35a5e",
      "menuName": "Qualificação do Sócio ou Titular",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "502badeb-3009-4743-8378-f72ebc6d499b",
      "menuName": "Tabela ECF",
      "parentMenuId": "5f367521-bb4b-49f4-b00d-ea5fe96de40f",
      "type": "Route",
      "level": 3
    }
    ,
    {
      "menuId": "b5701796-2435-4e79-b487-773c4391cb7d",
      "menuName": "GNRE",
      "parentMenuId": "a9faae96-2712-4a63-a9dd-564cebbd874a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "12d49bea-4631-4c15-810f-8f564cd3b539",
      "menuName": "GNRE Produto",
      "parentMenuId": "b5701796-2435-4e79-b487-773c4391cb7d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "022cb56e-2af5-4d50-9947-05034fcb3efd",
      "menuName": "Detalhamento Receita",
      "parentMenuId": "b5701796-2435-4e79-b487-773c4391cb7d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4d1dc71e-9831-4077-bebc-c62101eb9343",
      "menuName": "Origem Documento",
      "parentMenuId": "b5701796-2435-4e79-b487-773c4391cb7d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3d9370e5-baa8-468b-b4ac-d602c231b8fd",
      "menuName": "Código Receita",
      "parentMenuId": "b5701796-2435-4e79-b487-773c4391cb7d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0f2f5d0b-bba2-4739-993f-7771d979d88a",
      "menuName": "WebService",
      "parentMenuId": "b5701796-2435-4e79-b487-773c4391cb7d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "426e5859-76f3-4cb7-93e4-0362c5477ef4",
      "menuName": "Estado Webservice",
      "parentMenuId": "b5701796-2435-4e79-b487-773c4391cb7d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "76d2bdc7-b680-46b8-850e-e71848296a94",
      "menuName": "Simples Nacional",
      "parentMenuId": "a9faae96-2712-4a63-a9dd-564cebbd874a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "720ed076-feb3-4e8d-982f-4cb6a66e44a3",
      "menuName": "Cadastros",
      "parentMenuId": "7ffd04b5-99bc-4bce-a501-ffb809d9e34c",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "menuName": "Transações",
      "parentMenuId": "7ffd04b5-99bc-4bce-a501-ffb809d9e34c",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "b6e339a7-00be-406e-9fae-eb37b5e2c397",
      "menuName": "Gerencial",
      "parentMenuId": "7ffd04b5-99bc-4bce-a501-ffb809d9e34c",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "0f6ec110-4f94-4186-be87-030965b686ae",
      "menuName": "Painel de Controle",
      "parentMenuId": "b6e339a7-00be-406e-9fae-eb37b5e2c397",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c03a6f4f-0b18-42ce-9632-58af3df4deaa",
      "menuName": "Dashboard",
      "parentMenuId": "b6e339a7-00be-406e-9fae-eb37b5e2c397",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "189ffc35-3713-4f68-9c81-32c246518d11",
      "menuName": "Integração por Planilha",
      "parentMenuId": "b6e339a7-00be-406e-9fae-eb37b5e2c397",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "21e7a08a-9225-45e4-ae64-85636a00d4c8",
      "menuName": "Fechamento dos Eventos Periódicos",
      "parentMenuId": "b6e339a7-00be-406e-9fae-eb37b5e2c397",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f3465dd5-1da2-497d-a94c-df72a159ebb7",
      "menuName": "Consolidação de Serviços Prestados e Tomados",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7ff15145-4dfe-4404-b995-4d400188c67a",
      "menuName": "Retenção Contribuição Previdenciária - Serviços Tomados",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9e828dd9-f6fa-4f34-8e9c-45c880569ceb",
      "menuName": "Retenção Contribuição Previdenciária - Serviços Prestados",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },

    {
      "menuId": "4b6ae08c-42c6-4b22-97d7-1581186aa236",
      "menuName": "Recursos Recebidos Associação Desportiva",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e6eb76f7-8760-4a7b-92b7-557092fb228c",
      "menuName": "Recursos Repassados para Associação Desportiva",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a836f160-45c7-4b04-9203-3e490115565b",
      "menuName": "Contribuição Previdenciária sobre a Receita Bruta - CPRB",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "39055737-00da-4cb3-b2ee-cb71a9d4a709",
      "menuName": "Comercialização da Produção por Produtor Rural PJ/Agroindústria",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3f334c17-efe4-41d2-9b81-31536a6cb6c4",
      "menuName": "Aquisição Produção Rural",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b949cad0-4418-42ee-81fe-82b88ff7a0e1",
      "menuName": "Retenção Fonte PJ",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a59fc94e-5af3-40e6-8101-c559839c4111",
      "menuName": "Receita de Espetáculo Desportivo",
      "parentMenuId": "7578a12b-512f-460c-b917-708ddce00b6a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9201d644-eef0-4d4f-8361-2d00127bd3e3",
      "menuName": "Dados Contribuinte",
      "parentMenuId": "720ed076-feb3-4e8d-982f-4cb6a66e44a3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b5b142a1-f846-4a29-86c2-aad0ef9bd6fd",
      "menuName": "Processos Administrativos/Judiciais",
      "parentMenuId": "720ed076-feb3-4e8d-982f-4cb6a66e44a3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "menuName": "Fiscal",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "9aec7fc7-fba6-453b-b685-8dadd80c4201",
      "menuName": "CIAP",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "728fd6da-a086-424f-bc69-825e1acdaeca",
      "menuName": "Registro de Controle de Produção e Estoque (P3)",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "972e2d3e-124b-4c9a-9b7f-0b9c6cd062d3",
      "menuName": "FCI",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "a941c7cf-8dc5-4d7b-913c-8b98b43fdc8d",
      "menuName": "GPS",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "a78fbf4b-80be-49f0-9832-1158d92ce4d9",
      "menuName": "DCTF",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "menuName": "PERDCOMP",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "32843369-826f-4e19-8ee1-a98bdf82882a",
      "menuName": "GNRE",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "menuName": "Outras Contribuições",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "menuName": "Bloco K / Bloco H",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },

    {
      "menuId": "343954ec-3336-4977-b565-7eec53e0e9bd",
      "menuName": "Outras Contribuições - Regime de Caixa",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "93779c74-e7f5-4a2d-a81f-f5af65704e26",
      "menuName": "Controle Ressarcimento/Complemento ICMS ST",
      "parentMenuId": "af066c2c-31c0-48c0-9485-da515805d02a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "4fb26bb9-8786-4daf-8f70-241ec96dfaed",
      "menuName": "Atribuição Produto x Ressarcimento/Complemento ICMS ST",
      "parentMenuId": "93779c74-e7f5-4a2d-a81f-f5af65704e26",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7fc64828-8cd9-4cfc-ba40-a6fce4f1c127",
      "menuName": "Saldo Inicial Ressarcimento/Complemento ICMS ST",
      "parentMenuId": "93779c74-e7f5-4a2d-a81f-f5af65704e26",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "bbfde178-6f62-4129-b705-1fac6b08edc7",
      "menuName": "Apuração Ressarcimento ICMSST",
      "parentMenuId": "93779c74-e7f5-4a2d-a81f-f5af65704e26",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cbd8a841-f187-4e0d-90f6-d92ec8d22854",
      "menuName": "Fórmulas Apuração Ressarcimento ICMSST",
      "parentMenuId": "93779c74-e7f5-4a2d-a81f-f5af65704e26",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "49083a06-380f-41e2-85f3-a2fc45d673dc",
      "menuName": "Demais Documentos para Operações Geradoras de Contribuição e Créditos",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e24c1046-1705-4d92-b9d7-38c5ff9e8d14",
      "menuName": "Bens Incorporados ao Ativo Imobilizado para Operações Geradoras de Crédito",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b7ad609b-5088-4471-a2f2-67aa02bcbf17",
      "menuName": "Crédito Presumido sobre Estoque de Abertura",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d692aba3-95ae-4ddd-9666-5dc8b4ab6578",
      "menuName": "Contribuição Retida na Fonte",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "38f96628-073d-4e6e-9693-162e0538a9c5",
      "menuName": "Contribuições Retidas - Documentos Fiscais",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d42e57c1-664b-4bed-a830-042edc77cc03",
      "menuName": "Deduções Diversas",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5cf32625-9d65-42ac-b305-af728fa3e470",
      "menuName": "Créditos Decorrentes de Eventos de Incorporação, Fusão e Cisão",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "bd2de4be-6930-4a89-aaad-caafc2835969",
      "menuName": "Operações Instituições Financeiras e Assemelhadas",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3c46d1b8-f5e0-4614-bc26-7d9f384b4596",
      "menuName": "PIS/PASEP - Folha de Salários",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0b6a1213-e227-4141-9c8d-b0f41e78ed08",
      "menuName": "Apuração Contribuição Previdenciária",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1d27fc61-6023-4f50-9e53-afc9fdce543b",
      "menuName": "Contribuição Previdenciária Consolidada",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cce735f7-d329-4879-b9b6-8a02a38449c4",
      "menuName": "Unidade Imobiliária Vendida - Movimentação Fiscal",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "22a9302b-0f1b-4be6-a987-c5cecb693802",
      "menuName": "DIMOB",
      "parentMenuId": "7d80d4b0-2a0f-407f-899c-df98b46f6048",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "207af682-06cb-4d68-b7b2-5c6cd24445ed",
      "menuName": "Painel Gerencial - GNRE",
      "parentMenuId": "32843369-826f-4e19-8ee1-a98bdf82882a",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8bfe0b34-008a-4f0e-b70c-604916d9c724",
      "menuName": "Bens/Componentes do Ativo Permanente",
      "parentMenuId": "9aec7fc7-fba6-453b-b685-8dadd80c4201",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b1952520-28e5-4b4e-a4dd-ae32477b2e88",
      "menuName": "Movimentação - CIAP",
      "parentMenuId": "9aec7fc7-fba6-453b-b685-8dadd80c4201",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "12eef3d3-a4e9-44e2-aa4d-e759531e12b0",
      "menuName": "Outros Créditos CIAP",
      "parentMenuId": "9aec7fc7-fba6-453b-b685-8dadd80c4201",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3fa016a8-b172-497c-8368-28b2179d5e74",
      "menuName": "Cálculo CIAP",
      "parentMenuId": "9aec7fc7-fba6-453b-b685-8dadd80c4201",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f086ab91-324e-4b88-b7f2-0ba84de726bd",
      "menuName": "Demonstrativo de Cálculo do CIAP",
      "parentMenuId": "9aec7fc7-fba6-453b-b685-8dadd80c4201",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "85fa70f1-ec50-4e55-9b24-73f51666972e",
      "menuName": "Lote GPS",
      "parentMenuId": "a941c7cf-8dc5-4d7b-913c-8b98b43fdc8d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "73c78224-9fc2-49a7-a8ad-b9c3291c9fcb",
      "menuName": "Pedido de Nota",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c67597a5-a50a-4d78-a6f9-74c01ae5c07d",
      "menuName": "Documento Fiscal",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "role": ['SIMPLES_NACIONAL', 'GNRE'],
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "fcb47b15-2b0d-4b94-8652-b1131af2330f",
      "menuName": "Exportação",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c6eba247-1bd6-48b2-9f31-807b93ad97a3",
      "menuName": "Redução",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "328c94a4-f6c1-45f8-80a3-04cefbd51359",
      "menuName": "Cupom Fiscal",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1b46eb13-9bb9-47c0-aebe-92d6b700c6a6",
      "menuName": "Operações com Cartão de Crédito/Débito",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5020b5bd-1ac3-4240-8071-d79e9be22a9c",
      "menuName": "Operações com Instrumentos de Pagamentos Eletrônicos",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "fa16ddd8-1cf8-4745-afdc-b77d5ccab525",
      "menuName": "Valor Agregado",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8424896e-157c-44c8-bba6-e58bd4e1ce26",
      "menuName": "Fechamento Período Fiscal",
      "parentMenuId": "a468f1da-4f2a-4eb3-9876-8277c6f2568e",
      "role": ['SIMPLES_NACIONAL', 'GNRE'],
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7863937e-4f73-4cfe-b2a2-5d6c07e7ab0e",
      "menuName": "Tipo Movimentação Inventário",
      "parentMenuId": "728fd6da-a086-424f-bc69-825e1acdaeca",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "08fd18ee-176d-4fe9-b89a-c0980aceaa2b",
      "menuName": "Movimento de Inventário",
      "parentMenuId": "728fd6da-a086-424f-bc69-825e1acdaeca",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "86fc6006-26eb-42ac-b6b2-c629bc5b8066",
      "menuName": "Saldo de Fechamento",
      "parentMenuId": "728fd6da-a086-424f-bc69-825e1acdaeca",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "df4eb7f5-9729-4e38-a051-d0e8a1dbfab7",
      "menuName": "Regime de Caixa - Documento Fiscal",
      "parentMenuId": "343954ec-3336-4977-b565-7eec53e0e9bd",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e2475ae8-ec3f-4528-889a-c05573156192",
      "menuName": "Consolidação Pessoa Jurídica - Lucro Presumido",
      "parentMenuId": "343954ec-3336-4977-b565-7eec53e0e9bd",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "05e40349-73eb-423c-9b5e-c250f3996e9d",
      "menuName": "Composição da Receita",
      "parentMenuId": "343954ec-3336-4977-b565-7eec53e0e9bd",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ca0a4ea3-a69a-4586-ac35-68a5cef754c0",
      "menuName": "Notas Fiscais - Exportação Direta / Transferência",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "557ceab1-da63-4d28-9a7b-593c0a9e8da1",
      "menuName": "Exportação Direta",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "fbb42f79-1cf6-4c66-82ea-3d190f1d3540",
      "menuName": "Nota Fiscal de Venda para Empresa Comercial Exportadora",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "07cef873-02a3-4868-815a-a78b45bdb4f4",
      "menuName": "Livro Registro de Apuração do IPI no Período do Ressarcimento - Entradas e Saídas (Fichas 011 e 012)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0ef74d77-90e5-420b-8b42-1898705c7917",
      "menuName": "Notas Fiscais de Crédito - Entrada/Aquisição, Créditos Extemporâneos e Demais Créditos (Fichas 013 e 015)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cd970482-6823-4654-9ddd-c1e54fd74446",
      "menuName": "Livro Registro de Apuração do IPI Após o Período do Ressarcimento (Ficha 021)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "460b7f7c-1e42-49aa-a366-febe9bcd9669",
      "menuName": "Demonstrativo da Constituição do Crédito IRRF - Cooperativas / Juros sobre o Capital Próprio (Fichas 042 e 044)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3825ab34-7177-4ee3-892d-09237f7995d9",
      "menuName": "Salário-Maternidade (Ficha 109)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1db7ffda-6425-4789-b94a-3ba8fb5bf244",
      "menuName": "Salário-Família e Filhos ou Equiparados (Fichas 107 e 108)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3748efa4-c0d1-417c-b73c-81e1c8c8c368",
      "menuName": "Contribuição Retida na Fonte (Ficha 113)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2d2a84c8-6a18-446e-ad89-cccad6764901",
      "menuName": "Consolidação Documentos Fiscais para PERDCOMP-Reintegra/IPI",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "db0d072a-bf38-4c4a-acf8-75bc1100c938",
      "menuName": "Bens Exportados - Reintegra (Ficha 128)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "283d7e82-23a6-4015-83b1-2795905d8656",
      "menuName": "Notas Fiscais e Declarações - Reintegra (Fichas 129, 130, 131, 132, 133, 134, 139 e 140)",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "89ac96dd-4d47-4984-ae0d-bfd543fd518b",
      "menuName": "Retenção Fonte",
      "parentMenuId": "74cd8983-ec42-48a3-aa14-28041e52ff06",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7253c08a-d941-4b7f-975e-6d3140c5b025",
      "menuName": "Consumo Específico Padronizado",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a0d0ce89-28d6-4e2d-85ce-a6642b2fdbb8",
      "menuName": "Inventário/Estoque Escriturado",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "role": ['SIMPLES_NACIONAL'],
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c4121380-ff58-4f43-9a74-f4310b455acd",
      "menuName": "Desmontagem de Mercadorias",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "961de33d-fed0-4856-8cff-a2910821304b",
      "menuName": "Outras Movimentações",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e5856158-7077-45bf-a28e-961615b852e8",
      "menuName": "Produção",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "37ce87f6-bb2a-44be-8bbb-a0d0ed5052c6",
      "menuName": "Industrialização",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6f3cead7-00c3-4ce3-834e-b81ba74f8be9",
      "menuName": "Reprocessamento Reparo Produto",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "17ef4e47-a182-4f4d-a5e6-45a912ab9b8d",
      "menuName": "Correção de Apontamento",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "type": "Route",
      "level": 3
    },

    {
      "menuId": "e103ae80-934e-4fe1-8d9d-74fa1d7c873e",
      "menuName": "Correção de Apontamentos de Estoque",
      "parentMenuId": "6295db49-98ad-4bb9-9052-192f4d9ef823",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ec31a3b1-f550-482a-b364-754e49b963f6",
      "menuName": "Débito Imposto",
      "parentMenuId": "a78fbf4b-80be-49f0-9832-1158d92ce4d9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ceecc366-6f43-432a-89e2-4909d6b86ed1",
      "menuName": "Pagamento DARF",
      "parentMenuId": "a78fbf4b-80be-49f0-9832-1158d92ce4d9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a5d8b65b-0d6a-4a67-8a3c-745116acdf55",
      "menuName": "Pagamento Indevido",
      "parentMenuId": "a78fbf4b-80be-49f0-9832-1158d92ce4d9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cb91e313-59b5-4360-99b5-d1e69ae0e310",
      "menuName": "Outras Compensações",
      "parentMenuId": "a78fbf4b-80be-49f0-9832-1158d92ce4d9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7c872b2c-943e-4002-a03a-d8010d77709d",
      "menuName": "Suspensão Imposto",
      "parentMenuId": "a78fbf4b-80be-49f0-9832-1158d92ce4d9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d4137026-5707-45ef-be8a-87aa7c5a16a3",
      "menuName": "Parcelamento Imposto",
      "parentMenuId": "a78fbf4b-80be-49f0-9832-1158d92ce4d9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "eba6f384-7c02-47ad-9023-8cefd0ef14ef",
      "menuName": "Dedução DARF",
      "parentMenuId": "a78fbf4b-80be-49f0-9832-1158d92ce4d9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d809d228-ef68-486b-a152-3b07efcc1d1d",
      "menuName": "Cálculo da Ficha do Conteúdo de Importação",
      "parentMenuId": "972e2d3e-124b-4c9a-9b7f-0b9c6cd062d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ec689336-8665-4640-a266-9324901e8159",
      "menuName": "Ficha de Conteúdo de Importação",
      "parentMenuId": "972e2d3e-124b-4c9a-9b7f-0b9c6cd062d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3626fb91-9936-4ef5-b915-f6716e73b21f",
      "menuName": "Processar Arquivo de Retorno",
      "parentMenuId": "972e2d3e-124b-4c9a-9b7f-0b9c6cd062d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8d5a6348-c93d-4e69-a8ce-a38482752bd4",
      "menuName": "Produtos controlados pela FCI",
      "parentMenuId": "972e2d3e-124b-4c9a-9b7f-0b9c6cd062d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "menuName": "Contábil",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "49b9e066-0134-4116-9756-6df5aca19709",
      "menuName": "Conglomerados Econômicos",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },

    {
      "menuId": "9a87d6c8-9292-4f0c-8c07-ca23a815b5ae",
      "menuName": "ECF - Geral",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "menuName": "ECF - Lucro Real",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "be4b5d81-3967-41db-8d8c-6d7b91643abc",
      "menuName": "ECF - Lucro Presumido",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "209d478c-7f54-412a-88cb-65e86e24ad27",
      "menuName": "ECF - Lucro Arbitrado",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "8a320895-2658-4385-ac85-8370e57bdaac",
      "menuName": "ECF - Imune ou Isentas",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "0c972d4a-abde-4d27-958d-5b4df3da97e0",
      "menuName": "ECF - Livro Caixa",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "menuName": "ECF - Informações Econômicas",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "menuName": "ECF - Informações Gerais",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "39c7a07f-4a14-472e-8e51-fe53289b512c",
      "menuName": "ECF - Declaração País-a-País",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "94ca5afe-a774-4c8a-add2-618b574dc739",
      "menuName": "ECF - DEREX",
      "parentMenuId": "ca32d6f7-ceb9-42fa-9d6b-a17cdad31227",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "e0d30a05-8b27-4e10-a092-7f96f7e03077",
      "menuName": "Declaração País-a-País",
      "parentMenuId": "39c7a07f-4a14-472e-8e51-fe53289b512c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "503b2371-d808-487a-9e03-87e859f096c4",
      "menuName": "Lançamento Contábil",
      "parentMenuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9459d8d6-1f41-4f96-86d0-5de6b71c275f",
      "menuName": "Cálculo do Saldo Contábil por Centro de Custo",
      "parentMenuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "539349f4-67cd-438a-a9ad-5cb0186fb59b",
      "menuName": "Saldo Contábil",
      "parentMenuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "689ba5b1-4e5c-4351-aba2-940d486359b4",
      "menuName": "Transferência Saldo Contábil",
      "parentMenuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "99944745-5547-47f6-b35f-34d9d72ae710",
      "menuName": "Razão Auxiliar Sub Conta",
      "parentMenuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "41636939-014c-4e71-b5eb-89794bbe2a11",
      "menuName": "Encerramento de Exercício",
      "parentMenuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0855087a-4cbc-47be-8410-c0491d83053f",
      "menuName": "Termo de Verificação para Fins de Substitução da ECD",
      "parentMenuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c8b25dc7-6337-42af-b22c-6ee8ae461eb6",
      "menuName": "Apuração Regime Especial de Tributação",
      "parentMenuId": "47ebea50-9940-4d0e-a2ca-e180c4380b01",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "776edbad-53d6-40c4-895e-e39191ece48c",
      "menuName": "Balanço Patrimonial Referencial",
      "parentMenuId": "9a87d6c8-9292-4f0c-8c07-ca23a815b5ae",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "34e265ba-a53c-4a02-b01a-47bf793b403d",
      "menuName": "Demonstração do Resultado do Exercício Referencial",
      "parentMenuId": "9a87d6c8-9292-4f0c-8c07-ca23a815b5ae",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "12729173-a99d-4d29-928b-b389baad2286",
      "menuName": "Cálculo do IRPJ",
      "parentMenuId": "8a320895-2658-4385-ac85-8370e57bdaac",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "917ce57a-a8b7-432e-a5d7-87093bf233f8",
      "menuName": "Cálculo do CSLL",
      "parentMenuId": "8a320895-2658-4385-ac85-8370e57bdaac",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8f51b8cb-76a4-4905-80b6-b3f84cadc2e6",
      "menuName": "Período da Escrituração Contábil Consolidada",
      "parentMenuId": "49b9e066-0134-4116-9756-6df5aca19709",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "43c8f204-0fbd-47b1-92e4-5e9a8943a8bb",
      "menuName": "Empresas Consolidadas",
      "parentMenuId": "49b9e066-0134-4116-9756-6df5aca19709",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "86f97bc3-0e08-4fad-a736-9fd67f93b357",
      "menuName": "Empresas Consolidadas Eventos",
      "parentMenuId": "49b9e066-0134-4116-9756-6df5aca19709",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2cded2c4-5c78-4571-80a4-e5bb36a5dde8",
      "menuName": "Plano de Contas Consolidado",
      "parentMenuId": "49b9e066-0134-4116-9756-6df5aca19709",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7c004eac-67f6-4c67-bcd1-adfe78745a43",
      "menuName": "Empresa Consolidada x Conta Contábil Consolidada",
      "parentMenuId": "49b9e066-0134-4116-9756-6df5aca19709",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d38b512b-f762-4729-9c06-062c9f0fa729",
      "menuName": "Saldo Contábil Consolidado",
      "parentMenuId": "49b9e066-0134-4116-9756-6df5aca19709",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "791432bd-655a-4ccf-a245-d01fb268dff6",
      "menuName": "Saldo Contábil Consolidado Empresa",
      "parentMenuId": "49b9e066-0134-4116-9756-6df5aca19709",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "dd6d0584-f5f5-4a20-abc1-26fbff95fc93",
      "menuName": "Demonstração das Receitas Incentivadas",
      "parentMenuId": "be4b5d81-3967-41db-8d8c-6d7b91643abc",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b4999b34-d093-4618-889b-21aaffe3f6a9",
      "menuName": "Cálculo da Isenção e Redução do Lucro Presumido",
      "parentMenuId": "be4b5d81-3967-41db-8d8c-6d7b91643abc",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "188d20e0-3deb-4846-8fa8-1dabf9767b88",
      "menuName": "Apuração Lucro Presumido - IRPJ/CSLL",
      "parentMenuId": "be4b5d81-3967-41db-8d8c-6d7b91643abc",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5b5213a5-2ab7-4385-8473-877155056cf4",
      "menuName": "Cálculo Lucro Presumido - IRPJ/CSLL",
      "parentMenuId": "be4b5d81-3967-41db-8d8c-6d7b91643abc",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f75551e0-ecca-4ff9-8914-19151dc0b3fd",
      "menuName": "Pagamentos/Recebimentos do Exterior ou de Não Residentes",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5d8b15ad-6fc9-43b1-9748-85330af0b202",
      "menuName": "Discriminação Vendas por Atividade Econômica",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "53f97511-cab7-4e05-a759-21ad0d68c467",
      "menuName": "Vendas a Comercial Exportadora com Fim Específico de Exportação",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8dc03101-4ed4-4ee7-b939-b6121cacb1b7",
      "menuName": "Detalhamento das Exportações da Comercial Exportadora",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c8e00918-8a33-4589-9afd-1490e7d2cfd3",
      "menuName": "IR e CSLL Retido na Fonte",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3cb87477-310c-4c39-8ef2-6e26b6e6d652",
      "menuName": "Doações a Campanhas Eleitorais",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "16c5271d-f728-4b32-bcd0-21efc91f8d90",
      "menuName": "Ativos no Exterior",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3c23cf55-4607-4aa0-9696-ab94b498973e",
      "menuName": "Identificação de Sócios ou Titular",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cb1865d9-11dd-462f-8fe0-0fd7eb8ff19d",
      "menuName": "Rendimentos de Dirigentes, Conselheiros, Sócios ou Titular",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "965f389e-7eb7-4e1e-813d-aba47bac7c73",
      "menuName": "Identificação e Rendimentos de Dirigentes e Conselheiros – Imunes ou Isentas",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "50b1acba-dbce-4b7d-adb6-3722b998bdfd",
      "menuName": "Participações Avaliadas Pelo Método de Equivalência Patrimonial",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9f3ea558-9b0e-41d4-855f-cc4f3319e69c",
      "menuName": "Fundos/Clubes de Investimento",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cc352964-c646-451e-a224-f2ce5374b9da",
      "menuName": "Participações em Consórcios de Empresas",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7dfda986-7ab2-4e1c-8c4e-0d28dd219042",
      "menuName": "Dados de Sucessoras",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d4a26746-3897-4639-97b5-0a3c8b5047de",
      "menuName": "Outras Informações (Lucro Real)",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d879c879-03f5-4c0d-9740-b449f5890359",
      "menuName": "Outras Informações (Lucro Presumido ou Lucro Arbitrado)",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "afc2164b-05f4-4e80-8de2-668d55728eee",
      "menuName": "Mês das Informações de Optantes pelo REFIS (Lucro Real, Presumido e Arbitrado)",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "467a0fbc-01a0-4c09-930b-32227294eb4f",
      "menuName": "Informações de Optantes pelo REFIS – Imunes ou Isentas",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "83fe8fe2-858f-4d7c-92fe-3858a7282125",
      "menuName": "Informações de Optantes pelo PAES - Imunes ou Isentas",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "06e77180-5edf-4c97-aa75-1e511584a479",
      "menuName": "Informações de Períodos Anteriores",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "26259c13-8500-4ff6-a2b2-f02f2b35a1cf",
      "menuName": "Outras Informações",
      "parentMenuId": "5e770cb5-a3a0-4d67-b189-6b12dd7f500d",
      "type": "Route",
      "level": 3
    },

    {
      "menuId": "cd3a1645-9932-4eb4-85d9-71753ebe7202",
      "menuName": "Apuração da Base de Cálculo do IRPJ",
      "parentMenuId": "209d478c-7f54-412a-88cb-65e86e24ad27",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c0a8e488-f285-4d0f-ab11-c307b4c69f9a",
      "menuName": "Cálculo do IRPJ",
      "parentMenuId": "209d478c-7f54-412a-88cb-65e86e24ad27",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4569c800-2ebd-4ae1-b850-cda0d04d1db0",
      "menuName": "Apuração da Base de Cálculo do CSLL",
      "parentMenuId": "209d478c-7f54-412a-88cb-65e86e24ad27",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a1f9e69a-5d4d-419c-a019-dde48fc8cd3c",
      "menuName": "Cálculo do CSLL",
      "parentMenuId": "209d478c-7f54-412a-88cb-65e86e24ad27",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6bf82cda-4645-4097-bb76-83a3deb5b838",
      "menuName": "Declaração utilização recursos em moeda estrangeira",
      "parentMenuId": "94ca5afe-a774-4c8a-add2-618b574dc739",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8007e140-6e06-4366-8c4f-4ea35b52fa89",
      "menuName": "Demonstrativo do Livro Caixa",
      "parentMenuId": "0c972d4a-abde-4d27-958d-5b4df3da97e0",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1084fa7d-6fcd-46fe-8a26-dba2683fa495",
      "menuName": "Atividades Incentivadas - PJ Geral",
      "parentMenuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "dbed1816-7a2b-4f2e-88c7-e000735b4609",
      "menuName": "Operações com o Exterior - Pessoa Vinculada ou Não Vinculada",
      "parentMenuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e45c9cee-0a25-4d42-92e3-902877304e15",
      "menuName": "Operações com o Exterior - Importar/Exportar",
      "parentMenuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4fc74af0-f9c0-4b96-8481-86d8a89e0474",
      "menuName": "Identificação da Participação no Exterior",
      "parentMenuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e27b28d0-0e32-4a50-b8c4-78383e57919d",
      "menuName": "Outros Lançamentos e Informações Econômicas",
      "parentMenuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8cdcda0d-6592-4764-a7e7-21883edad531",
      "menuName": "Comércio Eletrônico - Informação de Homepage/Servidor",
      "parentMenuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0f0fc6f5-e4c5-4f0d-9b09-a6733cb7c75a",
      "menuName": "Royalties Recebidos ou Pagos a Beneficiários do Brasil e do Exterior",
      "parentMenuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d9096320-be0b-4c25-9a54-6137fd5d2db5",
      "menuName": "Rendimentos/Pagamentos Relativos a Serviços, Juros e Dividendos Recebidos do Brasil e do Exterior",
      "parentMenuId": "90599f9c-d7a9-45d9-81b3-0bfa96c5e1d3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f0e4fe1e-ce59-4a01-94a0-220d57a021b7",
      "menuName": "Informativo Composição Custos",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "adb6aefb-5772-4716-a671-117f9f11690a",
      "menuName": "Identificação Conta Parte B e-LALUR e e-LACS",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b2b3784e-ff0b-4764-b3fe-f9d1e1272d88",
      "menuName": "Lançamento e-LALUR / e-LACS Parte A",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "faf483ae-ddb9-453a-8b8d-d74c1f115126",
      "menuName": "Lançamentos na Conta da Parte B e-LALUR e e-LACS",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ddece75b-3dfe-48e2-a87e-79f7c6231807",
      "menuName": "Demonstração do Lucro da Exploração IRPJ",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ccaf43cb-dcbd-4aa3-99a8-18a364699132",
      "menuName": "Cálculo da Isenção e Redução do Imposto Sobre o Lucro Real IRPJ",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "621c71b8-69f0-45fc-82eb-e624b53d1367",
      "menuName": "Informações da Base de Cálculo dos Incentivos Fiscais",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d46690ae-f569-4478-90a5-50b67ecfaf3e",
      "menuName": "Apuração IRPJ e CSLL - Mensal por Estimativa",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c4e283c0-ae52-43e3-b901-5d1d54f90537",
      "menuName": "Cálculo IRPJ/CSLL",
      "parentMenuId": "f807be63-3bd3-456d-8f79-06c05b0c27d4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0b198b59-2b9b-48c3-b32a-653aee25b47e",
      "menuName": "Grupo de Usuário",
      "parentMenuId": "db2907bb-4d15-4031-b6c4-c35781b9bc39",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b8d2072b-f763-41da-9229-40b7bc40a6bb",
      "menuName": "Usuário",
      "parentMenuId": "db2907bb-4d15-4031-b6c4-c35781b9bc39",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3499907a-d7e7-4d3d-a205-aa738c5e6373",
      "menuName": "Parâmetros Adições/Exclusões e-LALUR/LACS Parte A",
      "parentMenuId": "d6d7ddcc-d212-4e1f-9152-be7f2e3708ac",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6c9da420-01b9-47d4-ad1a-7d948cd16bad",
      "menuName": "Parâmetros Composição de Custos",
      "parentMenuId": "d6d7ddcc-d212-4e1f-9152-be7f2e3708ac",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f53ffce7-da1c-47d7-88fa-ec7638a0553d",
      "menuName": "Percentual Presunção",
      "parentMenuId": "d6d7ddcc-d212-4e1f-9152-be7f2e3708ac",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d423f913-363b-4a8f-b73b-049c0077d66c",
      "menuName": "Parâmetros CFOP",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "74b9a7f1-6350-4065-afb8-1a6bf6a0a1a1",
      "menuName": "Descrição Complementar Sub Apuração",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c164b958-d427-4bec-a673-1bfbc0b17a42",
      "menuName": "Processo Referenciado de Processo Administrativo",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7e6de074-7ede-43d0-9482-261273cda632",
      "menuName": "Serviço Federal X Municipal",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6c4491cc-f451-456c-a827-51b4ab8e2200",
      "menuName": "Equipamento ECF",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "d5ec9edf-3211-4566-8637-67a9d2960cff",
      "menuName": "Observação Lançamento Fiscal",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "08e79d26-db9f-41ae-a117-9afabb1c6d81",
      "menuName": "Informação Complementar",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5347111f-dee7-497f-9248-9c0a50d8419b",
      "menuName": "Unidade Imobiliária Vendida",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "65088bfc-a6aa-4efb-b4a8-af769b7285ea",
      "menuName": "Natureza Operação",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "fca1dfdd-3793-463a-8e20-c40544870a24",
      "menuName": "Processo Referenciado de Ação Judicial",
      "parentMenuId": "fe23716d-661b-4849-af2a-bd0701feac88",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ed081b00-68b8-473d-b013-94598f3eb9e6",
      "menuName": "Empresa",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "35ca9d9c-1704-4353-bf29-4bfce46d93e3",
      "menuName": "Contribuinte",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c1a8c253-1518-4db0-bb55-9368f5999cad",
      "menuName": "Classe de Imposto",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "601d06d3-8ad6-4b67-9831-b6c4c33fc3a3",
      "menuName": "Grupo de Tributação",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c8db86ba-ad57-4264-8506-ca97a4b2ed80",
      "menuName": "Participante",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "91e0f8af-ebbe-4f0d-83fe-5f38d3b62007",
      "menuName": "Unidade de Medida",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0ce1cdc2-49dd-4ee9-ba38-e59683a9060c",
      "menuName": "Conversão Unidade Medida",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9dae9562-8802-4da7-9633-18eff852e1c1",
      "menuName": "Produto/Serviço",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "167600a2-6d2e-4396-bbd5-655be973fcdb",
      "menuName": "Responsáveis",
      "parentMenuId": "ef2d6be3-f2a9-4736-8542-b4cda0e89640",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cb4dae16-f039-4f36-9c6e-744a8fffdf9e",
      "menuName": "Parâmetro Envio de Email",
      "parentMenuId": "daec91b0-37c4-4bb3-b989-b1e62b71768e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b7e98758-76f4-4049-b180-0645702b2b96",
      "menuName": "Parâmetro Envio de Email Dias",
      "parentMenuId": "daec91b0-37c4-4bb3-b989-b1e62b71768e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3a9acba7-4eb9-43fd-a1e5-bb35b953fc78",
      "menuName": "Folha Pagamento",
      "parentMenuId": "81381c52-5183-4c91-8495-7f78885f4552",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2cfb74cd-6eb3-4353-8cad-ac9b4f36df43",
      "menuName": "Receita Bruta",
      "parentMenuId": "81381c52-5183-4c91-8495-7f78885f4552",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8cd3f153-259e-45b7-8ada-bc509a3b4e44",
      "menuName": "Plano Contas",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "role": ['FISCAL'],
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "02a62819-b401-4219-9e4b-b9c3f698b250",
      "menuName": "Conta Contábil",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "role": ['FISCAL'],
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "86890625-53c8-496b-bf97-2321b7e26885",
      "menuName": "Centro Custo",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "role": ['FISCAL'],
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8050aa5b-ad7e-4059-bdf0-866dddc955b5",
      "menuName": "Conta Contábil X Conta Referencial",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5db8a254-9240-4bf8-843d-0abe27e93274",
      "menuName": "Conta Contábil X Subconta",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8daa36f1-1d49-4ed7-bb25-faadfbcdd7f8",
      "menuName": "Código Aglutinação",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "32b3c2d7-6187-4fd5-ba38-b4f62d38b616",
      "menuName": "Código Aglutinação x Conta Contábil",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b93f33f9-82e1-471e-93a8-25c6d1af27e1",
      "menuName": "Histórico Padrão",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3bcf4a65-de2d-4102-aeea-ee42537dcbbb",
      "menuName": "Livro Contábil",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e86eeb44-1fb4-4804-839a-0aa8d5461433",
      "menuName": "Outras Inscrições Cadastrais da Pessoa Jurídica",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "80e4046b-8b78-4372-bc5b-a80fa513d9ad",
      "menuName": "Participante Acionário",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e42fc352-bb42-4a20-aac9-0b87fbc850ff",
      "menuName": "Replicação de Dados Contábeis",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "451db955-349e-4f26-9013-cf5daf12675c",
      "menuName": "Quadro Contábil DIME x Conta Contábil",
      "parentMenuId": "4be5095b-b583-4256-b3d0-19759979b24c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8f44980a-2447-4330-a2da-aa945c558ff5",
      "menuName": "Tabela Enquadramento Simples Nacional",
      "parentMenuId": "76d2bdc7-b680-46b8-850e-e71848296a94",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "menuName": "Parametrizações",
      "parentMenuId": "51ea8472-cd5e-462c-8427-2db761f35e2a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "menuName": "Fiscal",
      "parentMenuId": "51ea8472-cd5e-462c-8427-2db761f35e2a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "menuName": "Outras Contribuições",
      "parentMenuId": "51ea8472-cd5e-462c-8427-2db761f35e2a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "797500e9-64b0-40ce-98b5-06f4796ef798",
      "menuName": "Retidos",
      "parentMenuId": "51ea8472-cd5e-462c-8427-2db761f35e2a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "a945f2d4-4e46-4dbb-a819-761783f5a73c",
      "menuName": "Serviços",
      "parentMenuId": "51ea8472-cd5e-462c-8427-2db761f35e2a",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "392b2eba-1b9e-4b45-8adb-84622324ab78",
      "menuName": "Gerencial",
      "parentMenuId": "51ea8472-cd5e-462c-8427-2db761f35e2a",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "3bbebdfc-76bf-4bcd-a610-375d7127ac1e",
      "menuName": "Simples Nacional",
      "parentMenuId": "51ea8472-cd5e-462c-8427-2db761f35e2a",
      "role": ['SIMPLES_NACIONAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "7a00a60c-736c-4e31-9bf1-fa9dc31b7d69",
      "menuName": "Lançamento de Ajustes de Apuração de Contribuição e Créditos",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "260d4364-25f6-44a6-b3d7-da196d8ccfa0",
      "menuName": "Saldo do Crédito Fiscal PIS/COFINS",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "acf12619-ee2f-4cba-97c8-479908bde26f",
      "menuName": "Crédito Fiscal PIS/COFINS",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "296de31b-f3d3-46a9-9835-8fc1f6b43648",
      "menuName": "Exclusões Específicas da Sociedade Cooperativa",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4af0ca16-2a68-46a9-b91b-cafe60ca1617",
      "menuName": "Processo de Apuração Receita",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4f9917bb-0198-4b06-a97e-b8630e8ef3d2",
      "menuName": "Processo de Apuração PIS/COFINS",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2ac67b34-ec69-4887-bfd5-3c692a71cdea",
      "menuName": "Consolidação da Apuração PIS/COFINS",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "71cbb310-358d-4b7c-8f16-b76359c6706c",
      "menuName": "Controle dos Valores Retidos na Fonte",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f907381f-49ac-4239-a9c3-fa06a20d2c59",
      "menuName": "Cálculo FUNRURAL/SENAR/RAT",
      "parentMenuId": "c3af23d8-3eaf-419d-9c20-da04b5b63c4b",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1b8a379b-961f-492b-8aaf-0dd2b8907009",
      "menuName": "Dashboard",
      "parentMenuId": "392b2eba-1b9e-4b45-8adb-84622324ab78",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5b60782d-cbec-465b-b149-c357a4948e51",
      "menuName": "Geração de Lançamento Contabil em Fiscal",
      "parentMenuId": "392b2eba-1b9e-4b45-8adb-84622324ab78",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "60bf5116-7922-4ba3-abd5-98189a279898",
      "menuName": "Alteração em Lote de Documento Fiscal",
      "parentMenuId": "392b2eba-1b9e-4b45-8adb-84622324ab78",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "26f3a25d-be54-4709-a307-676942506957",
      "menuName": "Fechamento da Contabilização",
      "parentMenuId": "392b2eba-1b9e-4b45-8adb-84622324ab78",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5e640971-9a4e-4b7c-bcfc-327132fc0580",
      "menuName": "Controle Numeração de Guias",
      "parentMenuId": "392b2eba-1b9e-4b45-8adb-84622324ab78",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a720c42a-5933-468d-b1dc-3290bbc4a3ae",
      "menuName": "Data Vencimento Imposto",
      "parentMenuId": "392b2eba-1b9e-4b45-8adb-84622324ab78",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b1cf5be8-86e4-4701-ab01-2e8f6d222a9f",
      "menuName": "Unidade Imobiliária Vendida - Consolidação Contábil/Fiscal",
      "parentMenuId": "392b2eba-1b9e-4b45-8adb-84622324ab78",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b08704db-b192-45e1-99e4-56b6a9c04c01",
      "menuName": "Apurador ISS",
      "parentMenuId": "a945f2d4-4e46-4dbb-a819-761783f5a73c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c2a5ef74-ef9d-4b1f-b246-442b8b3e74f6",
      "menuName": "Controle Guia - ISS",
      "parentMenuId": "a945f2d4-4e46-4dbb-a819-761783f5a73c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5349fb0c-1041-48ac-8832-20a1cd5a9a68",
      "menuName": "Atribuição de Natureza da Receita",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5b44eebb-8811-4acc-aa3b-fb4cceb643ac",
      "menuName": "Atribuição Imposto Retido x Data Retenção",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "e5e3d2e3-3402-45f8-ab40-bd37c7df452e",
      "menuName": "Tipo Crédito Estadual",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "bf8d82b0-4088-43b3-93c2-8e65c81f9a37",
      "menuName": "Tipo Crédito",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b3c11132-7d59-47af-99eb-57c1aa391588",
      "menuName": "Tipo Crédito Detalhe",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "73cd4263-7cff-49cd-88b8-d394e1ef071f",
      "menuName": "Atribuição de Créditos Estaduais",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8c1121e4-26eb-4d29-a253-936997f5e38d",
      "menuName": "Parametrização de Código da Receita",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f0162a18-d613-48ae-bc48-76fea64a26be",
      "menuName": "Parametrização da Contabilização dos Vencimentos",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "cc54721a-b679-4e1f-8553-fe98dbf81107",
      "menuName": "Incentivo Fiscal",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "05f9f37b-00f4-4109-a98e-3ab90cca6fab",
      "menuName": "Parâmetrização para Transferência de Saldo Apurado de Imposto",
      "parentMenuId": "56e0f55c-d562-4141-828d-52ad3327d34f",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c522a093-616d-40fd-a064-97b082415c22",
      "menuName": "Simples Nacional - Painel Gerencial",
      "parentMenuId": "3bbebdfc-76bf-4bcd-a610-375d7127ac1e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b56bf698-9276-4e41-bbae-b487af1420e4",
      "menuName": "Apuração Simples Nacional",
      "parentMenuId": "3bbebdfc-76bf-4bcd-a610-375d7127ac1e",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c98772cf-dc59-4aa9-b63d-9e27d21cf866",
      "menuName": "Lançamento Saldo Período Anterior",
      "parentMenuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a0847238-b2c6-46c4-9843-bc2485c52e6f",
      "menuName": "Cálculo de Créditos Estaduais",
      "parentMenuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "698a7194-3e18-4366-99a0-4a600ca56a7d",
      "menuName": "Ajuste Apuração de Imposto ICMS/ICMSST",
      "parentMenuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6bd9e5e7-59cb-49b7-a2e3-dc1dfdd416d1",
      "menuName": "Ajuste Apuração de Imposto IPI",
      "parentMenuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "51b6decc-4ed4-45c0-b268-fa11de10123a",
      "menuName": "Apuração de Imposto ICMS/ICMSST/IPI/DIFAL/Sub Apuração",
      "parentMenuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7e00133c-3fc9-4fce-8aee-eb2376e2f9e2",
      "menuName": "Extra Apuração",
      "parentMenuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4ddc9d71-9090-4017-beb6-95286dcc3d75",
      "menuName": "Lançamento Obrigação à Recolher",
      "parentMenuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "24a0b3f8-99e2-4852-aa7f-481cdab14fa3",
      "menuName": "Informações Adicionais",
      "parentMenuId": "428058fd-7b13-4390-b3b5-0fbe766211f4",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3be4c7ac-c9db-43b8-b4e4-203a9a70b7ef",
      "menuName": "Apurador GPS",
      "parentMenuId": "797500e9-64b0-40ce-98b5-06f4796ef798",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ca9b2d98-59fc-4d9b-858c-960699ad24f9",
      "menuName": "Controle Guia - GPS",
      "parentMenuId": "797500e9-64b0-40ce-98b5-06f4796ef798",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1653de4b-ea14-412b-8bd6-e8caa2e3ba97",
      "menuName": "Controle de Imposto Retido",
      "parentMenuId": "797500e9-64b0-40ce-98b5-06f4796ef798",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b48aa149-7ed0-40b9-8ca3-edc51017a261",
      "menuName": "Arquivos Digitais",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "role": ['FISCAL', 'CONTABIL', 'SIMPLES_NACIONAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "e6776c3b-2894-4508-adc1-8089ff5233ca",
      "menuName": "Tarefas",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "role": ['FISCAL', 'CONTABIL', 'SIMPLES_NACIONAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "2ebf56f9-1d0a-41ea-a620-6c5b39244074",
      "menuName": "Extração",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "ef6d2b8a-92fc-45fe-a62b-4c6786f1590c",
      "menuName": "Transformação",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "f4caed00-cd62-4ea0-a948-ac17bc95dbc6",
      "menuName": "Importação",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "4ddbf9c5-0b84-4b3d-9bea-002b1ef21a77",
      "menuName": "Limpeza de Dados",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "role": ['FISCAL', 'CONTABIL', 'SIMPLES_NACIONAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "0a0245f2-2eaa-4b17-b5de-f9846d8a17d2",
      "menuName": "Validação",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "role": ['FISCAL', 'CONTABIL', 'SIMPLES_NACIONAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "a84c766d-ab14-4ab3-bb15-e01e1d40fa73",
      "menuName": "Relatórios",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "role": ['FISCAL', 'CONTABIL', 'SIMPLES_NACIONAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "d98a238c-ee68-4035-b333-151d4d1cc74d",
      "menuName": "Controle de Remessa e Retorno",
      "parentMenuId": "b1e94dca-357b-404b-88b0-223ec9818848",
      "role": ['FISCAL'],
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "72e64cc2-574f-4d70-ace4-ffb709e57111",
      "menuName": "Regra Validação Fiscal / Contábil",
      "parentMenuId": "0a0245f2-2eaa-4b17-b5de-f9846d8a17d2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c160b888-3fbf-40b1-8bbc-4aa461201576",
      "menuName": "Regra Validação Fiscal / Contábil Suspensa",
      "parentMenuId": "0a0245f2-2eaa-4b17-b5de-f9846d8a17d2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6fb32e34-19f0-4476-b94a-b04a50a121c1",
      "menuName": "Executar",
      "parentMenuId": "0a0245f2-2eaa-4b17-b5de-f9846d8a17d2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "75b8d8a3-88df-4c57-a238-940449488709",
      "menuName": "Resultado",
      "parentMenuId": "0a0245f2-2eaa-4b17-b5de-f9846d8a17d2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9a210902-9e1a-49ec-9f77-7313dd674f17",
      "menuName": "Gerar",
      "parentMenuId": "b48aa149-7ed0-40b9-8ca3-edc51017a261",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2dee9160-839c-48c5-aad4-49f5c9a16da0",
      "menuName": "Configurar",
      "parentMenuId": "a84c766d-ab14-4ab3-bb15-e01e1d40fa73",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5603fea7-c299-4fd3-ace8-44e1067cc286",
      "menuName": "Visualização",
      "parentMenuId": "a84c766d-ab14-4ab3-bb15-e01e1d40fa73",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "a8644af9-0ff5-4fa8-bcb8-27d09fc9c4bb",
      "menuName": "Padrão",
      "parentMenuId": "a84c766d-ab14-4ab3-bb15-e01e1d40fa73",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "405ef8a1-2b94-46bc-acb9-ac9dd6a469fc",
      "menuName": "Configuração Importação",
      "parentMenuId": "f4caed00-cd62-4ea0-a948-ac17bc95dbc6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "c87cee6c-36a3-466f-8df9-8571f1d89b1b",
      "menuName": "Importação",
      "parentMenuId": "f4caed00-cd62-4ea0-a948-ac17bc95dbc6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f2caf6b6-e7af-45fe-8c72-8db49d3fc4e1",
      "menuName": "Log de Importação",
      "parentMenuId": "f4caed00-cd62-4ea0-a948-ac17bc95dbc6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4f5e1129-aa9b-434d-88ed-3d5161c949c9",
      "menuName": "Log de Importação Automática",
      "parentMenuId": "f4caed00-cd62-4ea0-a948-ac17bc95dbc6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "517c7937-28df-4012-aff7-f370a5968587",
      "menuName": "Regra Validação",
      "parentMenuId": "f4caed00-cd62-4ea0-a948-ac17bc95dbc6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "23b013a9-74a5-4958-bbd8-8a9ca9bd23c6",
      "menuName": "Gerenciador de Tarefas",
      "parentMenuId": "e6776c3b-2894-4508-adc1-8089ff5233ca",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "f1c90d65-059e-4c7e-9e6b-fc8fd6f9406a",
      "menuName": "Agendamento/Execução",
      "parentMenuId": "2ebf56f9-1d0a-41ea-a620-6c5b39244074",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "08897f3a-9f25-4119-8c80-e580c5a047f3",
      "menuName": "Extração de Dados ERP",
      "parentMenuId": "2ebf56f9-1d0a-41ea-a620-6c5b39244074",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b872e76a-0fdc-412f-957f-c460dc7485d9",
      "menuName": "Regra de Transformação",
      "parentMenuId": "ef6d2b8a-92fc-45fe-a62b-4c6786f1590c",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b99021c3-7ef2-4fa1-9a7f-2259eb4d33d8",
      "menuName": "Regra de Transformação",
      "parentMenuId": "b872e76a-0fdc-412f-957f-c460dc7485d9",
      "type": "Route",
      "level": 4
    },
    {
      "menuId": "8318a5a3-e1f0-4158-828e-40ef27e6c3ab",
      "menuName": "Configuração de CFOP para Remessa e Retorno",
      "parentMenuId": "d98a238c-ee68-4035-b333-151d4d1cc74d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "b0bb776e-80e0-4aa0-95f9-3da2d7467c78",
      "menuName": "Controle de Remessa e Retorno",
      "parentMenuId": "d98a238c-ee68-4035-b333-151d4d1cc74d",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8a40138a-4c57-4fc7-82bb-e90028cb05f0",
      "menuName": "Limpeza de Dados",
      "parentMenuId": "4ddbf9c5-0b84-4b3d-9bea-002b1ef21a77",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0DA4941F-9E9F-4504-844C-7B8DE15CAE45",
      "menuName": "Controle de Produção de Usina",
      "parentMenuId": "NULL",
      "type": "Route",
      "level": 1
    },
    {
      "menuId": "077D4547-D2D0-4E75-B3E4-A57624F0E023",
      "menuName": "Envio de Email",
      "parentMenuId": "375F8369-7D43-4C46-9FF9-9828B06D1F55",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "62D5B17B-AB86-4002-8C20-6FFFF419CB58",
      "menuName": "Combustíveis",
      "parentMenuId": "AF066C2C-31C0-48C0-9485-DA515805D02A",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "3C2B692D-5782-4813-B43D-791FE160CCE3",
      "menuName": "Formulário de E-mail",
      "parentMenuId": "E6776C3B-2894-4508-ADC1-8089FF5233CA",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A4C00909-8BAC-4E35-949B-FDFA0B613D39",
      "menuName": "Controle e Envio de E-mail",
      "parentMenuId": "E6776C3B-2894-4508-ADC1-8089FF5233CA",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "705920EE-C766-4B71-9743-025C18AD4FD0",
      "menuName": "Atribuição da Natureza do Rendimento",
      "parentMenuId": "56E0F55C-D562-4141-828D-52AD3327D34F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "18E748C4-C904-401C-A69E-0DBD5C8CFC72",
      "menuName": "Lançamento de GNRE",
      "parentMenuId": "32843369-826F-4E19-8EE1-A98BDF82882A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "40C032C0-5D0D-4DF4-94D9-03EC9AB7D898",
      "menuName": "Consolidação da Prestação de Serviços x Convênio ICMS 115/03",
      "parentMenuId": "A468F1DA-4F2A-4EB3-9876-8277C6F2568E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C93CD0C4-83D7-4815-AB06-40336F7C126E",
      "menuName": "Controle de Produção de Usina",
      "parentMenuId": "A468F1DA-4F2A-4EB3-9876-8277C6F2568E",
      "type": "Group",
      "level": 3
    },
    {
      "menuId": "1B9E441C-CF55-4948-8E7F-853EB8B1FB55",
      "menuName": "Sociedade Uniprofissional",
      "parentMenuId": "A468F1DA-4F2A-4EB3-9876-8277C6F2568E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "82574B24-E6C8-4542-BD63-14B06FF27F8B",
      "menuName": "Movimentação Diária",
      "parentMenuId": "62D5B17B-AB86-4002-8C20-6FFFF419CB58",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D0AC58C7-F445-4F4D-99BC-1771610A7255",
      "menuName": "Bombas",
      "parentMenuId": "62D5B17B-AB86-4002-8C20-6FFFF419CB58",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3E068BC5-3210-452B-A9AF-7C8E8FF07A70",
      "menuName": "Movimentação Diária",
      "parentMenuId": "62D5B17B-AB86-4002-8C20-6FFFF419CB58",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F4941A43-8CE7-44DA-B750-0E4E88F1779F",
      "menuName": "Sublimites do Simples Nacional",
      "parentMenuId": "76D2BDC7-B680-46B8-850E-E71848296A94",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "24D83E46-2F46-454B-BDCA-9DA303EF1020",
      "menuName": "Natureza Rendimento x Código Receita",
      "parentMenuId": "688D91F5-938F-4249-8147-B3D5C81D39AF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CDA5EA85-D7F3-4270-80DA-166A91F37ADF",
      "menuName": "Retenção Fonte Beneficiários não identificados",
      "parentMenuId": "7578A12B-512F-460C-B917-708DDCE00B6A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "75A7266A-FC4B-4AC0-AD3B-634B38920B4C",
      "menuName": "Consolidação de Impostos Retidos",
      "parentMenuId": "7578A12B-512F-460C-B917-708DDCE00B6A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8081EDFC-49C4-4049-A603-951D9D05F340",
      "menuName": "Retenção Fonte PF",
      "parentMenuId": "7578A12B-512F-460C-B917-708DDCE00B6A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8F7F31D2-4F7A-4EC1-8B29-9668B53D7F98",
      "menuName": "Retenção Fonte Recebimentos",
      "parentMenuId": "7578A12B-512F-460C-B917-708DDCE00B6A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6BBF27AF-BDAD-4A23-90C8-2F00DC6EE9AE",
      "menuName": "Tipo Isencao",
      "parentMenuId": "720ED076-FEB3-4E8D-982F-4CB6A66E44A3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "42AC64CF-358A-4C1E-8219-DA19BDC24E04",
      "menuName": "Entidades Ligadas",
      "parentMenuId": "720ED076-FEB3-4E8D-982F-4CB6A66E44A3",
      "type": "Route",
      "level": 3
    }
  ],
  "tableDev": [
    {
      "menuId": "FA2384C7-65E8-41E8-9A91-71602028E12D",
      "menuName": "Tabelas Auxiliares",
      "parentMenuId": "NULL",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "E13C6A37-B709-4F5A-85CD-4071040E2123",
      "menuName": "Cadastros Gerais",
      "parentMenuId": "NULL",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "menuName": "Geral",
      "parentMenuId": "E13C6A37-B709-4F5A-85CD-4071040E2123",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "menuName": "Fiscal",
      "parentMenuId": "E13C6A37-B709-4F5A-85CD-4071040E2123",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "menuName": "Contábil",
      "parentMenuId": "E13C6A37-B709-4F5A-85CD-4071040E2123",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "1D8D1729-02A1-4768-BA5F-4D94C5F53B05",
      "menuName": "ECF",
      "parentMenuId": "E13C6A37-B709-4F5A-85CD-4071040E2123",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "12FECF1F-0246-4479-B102-70586C3C39B6",
      "menuName": "Controle de Acesso",
      "parentMenuId": "E13C6A37-B709-4F5A-85CD-4071040E2123",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "CD87F284-6CA0-4FBB-9159-F4983F06B988",
      "menuName": "Simples Nacional",
      "parentMenuId": "E13C6A37-B709-4F5A-85CD-4071040E2123",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "menuName": "Fiscal",
      "parentMenuId": "NULL",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "menuName": "Contábil",
      "parentMenuId": "NULL",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "F4808843-464C-4C61-A3E0-9345F1103BDB",
      "menuName": "Apurador",
      "parentMenuId": "NULL",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "menuName": "Processamentos",
      "parentMenuId": "NULL",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "A4E6AA19-472D-4036-97A5-EF5981C7853A",
      "menuName": "REINF",
      "parentMenuId": "NULL",
      "type": "Group",
      "level": 1
    },
    {
      "menuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "menuName": "Cadastros",
      "parentMenuId": "FA2384C7-65E8-41E8-9A91-71602028E12D",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "F3EC032C-95B8-41F0-904A-67A13A6254FF",
      "menuName": "DCTF",
      "parentMenuId": "FA2384C7-65E8-41E8-9A91-71602028E12D",
      "type": "Route",
      "level": 2
    },
    {
      "menuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "menuName": "ECF",
      "parentMenuId": "FA2384C7-65E8-41E8-9A91-71602028E12D",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "82A9F377-F84F-4BFB-82B0-7C35796F1F55",
      "menuName": "GNRE",
      "parentMenuId": "FA2384C7-65E8-41E8-9A91-71602028E12D",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "CF5EEEE2-9F37-495D-A9FC-2FCD94EDD491",
      "menuName": "Simples Nacional",
      "parentMenuId": "FA2384C7-65E8-41E8-9A91-71602028E12D",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "AFFCCBCC-378D-4154-89A7-58838E1CEF30",
      "menuName": "DCTF Tipo Declaração",
      "parentMenuId": "F3EC032C-95B8-41F0-904A-67A13A6254FF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7C619724-162C-4B72-9FAC-B03D01C5CC7E",
      "menuName": "DCTF Situação",
      "parentMenuId": "F3EC032C-95B8-41F0-904A-67A13A6254FF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2EEE7C1C-7A0A-4A76-86DE-B92FF7B56DEA",
      "menuName": "DCTF Forma Tributação",
      "parentMenuId": "F3EC032C-95B8-41F0-904A-67A13A6254FF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "14ED7BE3-B415-4F37-9FCF-282737908F31",
      "menuName": "DCTF Qualificação PJ",
      "parentMenuId": "F3EC032C-95B8-41F0-904A-67A13A6254FF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "60D16281-DD7E-4A4A-AC98-B908476D1CA0",
      "menuName": "DCTF Situação PJ Mês Declaração",
      "parentMenuId": "F3EC032C-95B8-41F0-904A-67A13A6254FF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "42E8F32D-4936-4A1B-A8D3-9F18AE155C46",
      "menuName": "DCTF Variações Monetárias",
      "parentMenuId": "F3EC032C-95B8-41F0-904A-67A13A6254FF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CC95DFDC-5921-450B-BC51-9C6D09FFAE7C",
      "menuName": "Opções Referente Lei 12973/2014",
      "parentMenuId": "F3EC032C-95B8-41F0-904A-67A13A6254FF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9F3B641F-A5B3-4EAA-93E0-453B7C6D6768",
      "menuName": "Tabela Enquadramento Simples Nacional",
      "parentMenuId": "CF5EEEE2-9F37-495D-A9FC-2FCD94EDD491",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F21C81C6-D86C-46EB-8455-7A0F27BA502C",
      "menuName": "GNRE Produto",
      "parentMenuId": "82A9F377-F84F-4BFB-82B0-7C35796F1F55",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "028AF23F-2467-477E-B553-1637AF7BCB03",
      "menuName": "Detalhamento Receita",
      "parentMenuId": "82A9F377-F84F-4BFB-82B0-7C35796F1F55",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "EBC4AE52-38A3-4E28-848F-C323F0F2E1B8",
      "menuName": "Origem Documento",
      "parentMenuId": "82A9F377-F84F-4BFB-82B0-7C35796F1F55",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CAEFE6F2-2627-4FDC-90B4-B5728FC767D3",
      "menuName": "Código Receita",
      "parentMenuId": "82A9F377-F84F-4BFB-82B0-7C35796F1F55",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "822DC256-3CD3-4F89-9434-2564F865CAD2",
      "menuName": "WebService",
      "parentMenuId": "82A9F377-F84F-4BFB-82B0-7C35796F1F55",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A5B148EA-3E53-42A6-93A0-3605ACF3DE57",
      "menuName": "Estado Webservice",
      "parentMenuId": "82A9F377-F84F-4BFB-82B0-7C35796F1F55",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8A2A2801-4511-4710-8EB7-DF2B0F45E5BC",
      "menuName": "Apuraçao/Cálculo Lucro Presumido - IRPJ/CSLL",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "598B522D-6538-447A-8E5A-52817DFBCF8A",
      "menuName": "Base Cálculo Lucro Arbitrado IRPJ CSLL",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "87EAA96B-5E72-49D2-AAEE-D878F1135943",
      "menuName": "Base Cálculo Lucro Presumido IRPJ CSLL",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A6BE4EC2-5824-4886-9D6A-B1EF01A4930A",
      "menuName": "Composição Custos L210",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "09DD3265-6784-42C1-A986-D124E2087BDD",
      "menuName": "Cálculo Estimativa IRPJ CSLL",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F7701B26-5BAD-4EEC-863C-BE5C597F5020",
      "menuName": "Cálculo Lucro Arbitrado IRPJ CSLL",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7FBDCD12-B7BD-4F1A-8A0A-8EFF128C9DE5",
      "menuName": "Cálculo Lucro Presumido IRPJ CSLL",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "47FD322E-0CA9-461D-AA22-01EE00AB48A2",
      "menuName": "Cálculo Lucro Real IRPJ/CSLL",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0C66EF98-5784-44F3-B7B0-6807A8B9D3D0",
      "menuName": "Cálculo da Isenção e Redução do Imposto Sobre o Lucro Real IRPJ",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F7BEF005-3F46-4EF0-9B72-30B829E2CC38",
      "menuName": "Cálculo da Isenção e Redução do Lucro Presumido",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4A00C767-0FB4-452A-953B-38166DBE9653",
      "menuName": "Cálculo de Imune e Isentas do IRPJ CSLL",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "41403419-BD1E-401B-9D08-DC21BC395E09",
      "menuName": "Código Padrão da Parte B",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9B54B253-677E-484E-82A6-A07B0F6178E1",
      "menuName": "Código Receita IR e CSLL Retido na Fonte",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "60B5A8B9-5275-4612-A1AA-47FE6DF3DE01",
      "menuName": "Demonstrativo Recursos Moeda Estrangeira",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "AA9A6898-D75A-401E-B5E0-CCCA41DA0331",
      "menuName": "Demonstração das Receitas Incentivadas do Lucro Presumido",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "40EE2DA1-09BF-415C-8A2B-31BF0970AB13",
      "menuName": "Demonstração do Lucro da Exploração IRPJ",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F36CAE6D-0F34-4BE9-B354-87E468243266",
      "menuName": "Lançamento Parte A e-LALUR e e-LACS",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A11BD813-9580-4724-A1F5-E393D594EC9E",
      "menuName": "Qualificação do Sócio ou Titular",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "BDD2EA15-5DD9-40FB-A6D2-DAB795F2AC56",
      "menuName": "Tabela ECF",
      "parentMenuId": "80DFFE0E-4E19-4720-B1DB-A1F7BE50982D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FAC526E7-64A4-47D2-95A2-695BB18A61DA",
      "menuName": "ANP",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FBCBAB45-67BB-40C3-AE6D-6B6399FD1A6B",
      "menuName": "Ajuste Apuração",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "68AD7B4C-6845-4D40-92F3-885D76CC5E73",
      "menuName": "Ajuste Apuração PIS/COFINS",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4DBF5614-A719-42D8-969F-03249CA2ED9A",
      "menuName": "Ajuste IPI",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "69F2B7B2-777C-4741-9A0F-FF90CDAEA3EA",
      "menuName": "Atividade Contribuição Previdenciária",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "01C54C77-EB64-4274-A914-1B47D72CB2E4",
      "menuName": "Atividade Econômica",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9DCFF159-F7AF-4FB7-98F3-5A6D730D1571",
      "menuName": "CEST - Código Especificador da Substituição Tributária",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7D815C09-8DB2-42E3-AB4E-CB70EFEDF739",
      "menuName": "CFOP",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ED9A3F3C-38E3-4657-9C3E-9D93288942A3",
      "menuName": "CNAE",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7E7535B2-CE45-4456-9084-AE65A0EF60B0",
      "menuName": "Cidade",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E5E6E56E-BD1E-4979-B879-ED926413EF09",
      "menuName": "Classe Documento PIS/COFINS",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "17AA42D3-2522-4AFD-AC2B-B94ECD5233C9",
      "menuName": "Classe de Consumo",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A24A93A6-80C5-45DB-878A-AD40CBF8BE90",
      "menuName": "Classificação Fiscal",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C056971B-DA98-4EAD-90B3-0B9FDE38A8B1",
      "menuName": "Classificação Serviço Prestado",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8F7A6A44-D872-4D82-B862-CCF517BFFF97",
      "menuName": "Classificação Tributária",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DF901F09-22FC-4FED-BD01-D328606B4A82",
      "menuName": "Classificação de Rendimento",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CF68D3D2-D664-46B5-B1CA-54B19267811F",
      "menuName": "Complemento",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0827F86F-FE00-48A5-B57A-5B01F04FD7DC",
      "menuName": "Composição",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FFE32958-340B-45A3-95FD-618FA673FFE9",
      "menuName": "Conhecimento Transporte",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B8132156-C4CC-4892-B73C-26A6C9DBA603",
      "menuName": "Código Ajuste Base de Cálculo Contribuição",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4FA0844A-5FAB-4CBE-96A8-34C22DB8DDDB",
      "menuName": "Código Ajuste Benefício",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0A18B3B7-ADD9-43A7-998A-C942CE9B39A7",
      "menuName": "Código COSIF",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6995F634-3A78-4492-890C-A2C05873B5AF",
      "menuName": "Código Class. Contribuinte IPI",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "320CC93F-C1B2-4640-BFE7-4CDE1F367317",
      "menuName": "Código Complemento Analitico",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5E32AEAB-4DFB-464C-859E-8052388024B2",
      "menuName": "Código Contribuição",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "698267DA-5E5C-4637-ACD3-FF41B8C06BAC",
      "menuName": "Código Imposto",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E2B72A9A-7320-4BC9-A001-EFFE9FAA997B",
      "menuName": "Código Informação Adicional",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "154DDA5A-AA0D-4FA0-A01D-BF958CFBB607",
      "menuName": "Código Informação Adicional Item",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E6878A96-716B-42DE-BD38-E7A3DCF94309",
      "menuName": "Código Motivo Rest. Compl. ICMS",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7398BFC3-B98D-4F15-A0FA-E1D371E65D05",
      "menuName": "Código Receita",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0239F7FA-CEEB-4930-BD3E-6ECA78E6939C",
      "menuName": "Código de Pagamento",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D9B0D4BD-FC26-417A-A476-F865D967C3EC",
      "menuName": "Entidade Responsável",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DFC0F636-122B-4D60-BABF-E24AF5FADF81",
      "menuName": "Espécie Decisão",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "53BD39AE-8AC8-4543-BBF5-E7B897A4239A",
      "menuName": "Estado",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B446A54E-0C16-4478-AEAC-F22D110F7DB6",
      "menuName": "Grupo Tensão",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DFE3411A-FB74-48D8-AF92-A49B08C2749D",
      "menuName": "Gênero",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "AEF662B1-8654-4416-9A0A-A04EF4B3CBE2",
      "menuName": "Imposto",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "16AD98A5-8557-4A48-A1AA-F2634260F2CA",
      "menuName": "Indicador Situação Especial",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "93BD6F2D-B22E-4371-BF12-481FE9739A26",
      "menuName": "Instituição Responsável Cadastro",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "617798E7-E70F-42CD-A2CC-9624DE421F51",
      "menuName": "Item Índice Participação",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "08601F55-2CAB-476A-98E3-0B1FECB31A9E",
      "menuName": "Lista Serviço",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1B1C20D9-4421-45FA-AB91-03EC1C9295E9",
      "menuName": "Modelo NF",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8EF49775-C89A-407E-B129-7A393D5F93FB",
      "menuName": "Natureza Base Crédito PIS/COFINS x CFOP",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0776F1EC-A4A2-47D3-88D1-36E0F7D1C9D1",
      "menuName": "Natureza Conta",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C6831059-072D-4F21-B869-382EF5F0CEB3",
      "menuName": "Natureza Jurídica",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1CFD3282-5B81-4854-A63E-09CB56736935",
      "menuName": "Natureza Rendimento",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DEE9AC8F-B36F-41F9-B419-C7EADD1E5218",
      "menuName": "Natureza Retenção Fonte",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9DEE9ED2-1733-41D3-B694-2EEDEC6BDAB6",
      "menuName": "Natureza Subconta",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C3E1E20E-4B18-4D86-9BAF-5F39304D16F2",
      "menuName": "Observação Ajuste ICMS",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9F182A97-9E95-48DA-AE47-2AA9723446A3",
      "menuName": "Parametrização FUNRURAL",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C6831A0F-5671-4165-95AC-88E13CB223EE",
      "menuName": "País",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DD7F9F95-805E-47C2-BA0D-24E95121E444",
      "menuName": "Plano Conta Referenciado",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E9CD5C4D-997F-4EB0-870A-729AC0D3E700",
      "menuName": "Quadro DIME",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9E11A880-7512-48FD-B55D-0B9AD173B3C7",
      "menuName": "Qualificação Assinante",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8E6744E9-5697-4530-AF96-992B900A4F1E",
      "menuName": "Selo IPI",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "91C213FF-3E0E-411C-BFAB-656A030FB766",
      "menuName": "Serviço Federal x Código REINF",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9BA7D10D-8342-46C4-980A-699A39C69188",
      "menuName": "Situação Tributária COFINS",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D411673B-F9D7-499F-8339-A2CAB7632F54",
      "menuName": "Situação Tributária ICMS Tab A",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "901500D0-A0A8-4061-90B6-3418156592C7",
      "menuName": "Situação Tributária ICMS Tab B",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "37F72E5E-7B15-49B5-994D-6EDF29E192B3",
      "menuName": "Situação Tributária IPI",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "EA14429E-C753-4FC1-A65A-9CDAEC6C04CB",
      "menuName": "Situação Tributária PIS",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3E2F1B5F-FC62-4D1F-B0D0-75D41CD3C3F1",
      "menuName": "Taxa SELIC",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "93615939-D1CB-4196-BCBB-76784C760CB8",
      "menuName": "Tipo Crédito PIS/COFINS",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4E7C92F7-B732-4EFC-A975-805A6AC3B647",
      "menuName": "Tipo Estabelecimento",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A42DD9C3-13E2-4C7B-9129-A90D5BEAE438",
      "menuName": "Tipo Item",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B8BC8969-AB82-4EE7-B00E-5D4092F2AF79",
      "menuName": "Tipo Nota Fiscal Serviço",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "46BE0FEB-E27C-4BD3-B2A1-F606C2D0D1A6",
      "menuName": "Tipo Produto/Serviço",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "AA8FAF4F-34AB-4191-96F5-2E9C51D031E0",
      "menuName": "Tipo Serviço",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0CF8996E-1843-4411-96EB-85268B30CD5C",
      "menuName": "Unidade Medida FCI",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "455E0BCD-9BFC-4357-9164-AE3DBD655781",
      "menuName": "Unidades de Medidas Tributáveis no Comércio Exterior",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "AF5BC30E-16A3-42A2-8FAE-9DC03130C13B",
      "menuName": "Cadastros",
      "parentMenuId": "A4E6AA19-472D-4036-97A5-EF5981C7853A",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "menuName": "Transações",
      "parentMenuId": "A4E6AA19-472D-4036-97A5-EF5981C7853A",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "72788A46-CA63-44E0-ADFE-49DB57C92B95",
      "menuName": "Gerencial",
      "parentMenuId": "A4E6AA19-472D-4036-97A5-EF5981C7853A",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "2C031215-BDB3-43C9-903B-B01E07869BBE",
      "menuName": "Painel de Controle",
      "parentMenuId": "72788A46-CA63-44E0-ADFE-49DB57C92B95",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "16BEDB8D-45EE-492F-B495-065E1898117E",
      "menuName": "Dashboard",
      "parentMenuId": "72788A46-CA63-44E0-ADFE-49DB57C92B95",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "233DA834-A8E1-48AB-A060-2E150B863A3F",
      "menuName": "Integração por Planilha",
      "parentMenuId": "72788A46-CA63-44E0-ADFE-49DB57C92B95",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "02729087-C176-46EE-A0DB-75116F79C16F",
      "menuName": "Fechamento dos Eventos Periódicos",
      "parentMenuId": "72788A46-CA63-44E0-ADFE-49DB57C92B95",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FF6291F3-3107-4410-8EB8-7BCC04732D7B",
      "menuName": "Consolidação de Serviços Prestados e Tomados",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "51D42950-7023-4F10-A82E-ED04AEE23515",
      "menuName": "Retenção Contribuição Previdenciária - Serviços Tomados",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "550F5DC9-6BC1-44CC-9D83-97FA52303D3A",
      "menuName": "Retenção Contribuição Previdenciária - Serviços Prestados",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "49F72830-E250-47AA-BB7A-5400CD5E16CE",
      "menuName": "Recursos Recebidos Associação Desportiva",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "383A6CE8-7E16-437E-9477-7CB28A39E5D0",
      "menuName": "Recursos Repassados para Associação Desportiva",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0D938AC1-A29D-4F6A-BB7E-BF5239084127",
      "menuName": "Contribuição Previdenciária sobre a Receita Bruta - CPRB",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "57AF924E-E905-4BDA-87FA-540B4BB90D8A",
      "menuName": "Comercialização da Produção por Produtor Rural PJ/Agroindústria",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F7ACF685-C954-49AE-81BE-BD0F21E391FF",
      "menuName": "Aquisição Produção Rural",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6F511288-D4BA-46C5-BC2A-D13BD9C40514",
      "menuName": "Retenção Fonte PJ",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "EF018935-EC8E-42DD-A333-F6DD0CFE0B26",
      "menuName": "Receita de Espetáculo Desportivo",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1BD22A1E-F248-4816-BEED-FA0FA3B688BB",
      "menuName": "Dados Contribuinte",
      "parentMenuId": "AF5BC30E-16A3-42A2-8FAE-9DC03130C13B",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DCA65B2D-0F46-4286-9358-BF6901A0D5B7",
      "menuName": "Processos Administrativos/Judiciais",
      "parentMenuId": "AF5BC30E-16A3-42A2-8FAE-9DC03130C13B",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "79A02A72-4A65-436D-B38A-99ED8A04BCBE",
      "menuName": "Parâmetros Adições/Exclusões e-LALUR/LACS Parte A",
      "parentMenuId": "1D8D1729-02A1-4768-BA5F-4D94C5F53B05",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C425F368-B2F2-4BAD-B9C4-20FA117A96EE",
      "menuName": "Parâmetros Composição de Custos",
      "parentMenuId": "1D8D1729-02A1-4768-BA5F-4D94C5F53B05",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "BDD02538-3CC5-4767-A6B7-C26847C0C381",
      "menuName": "Percentual Presunção",
      "parentMenuId": "1D8D1729-02A1-4768-BA5F-4D94C5F53B05",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "99D104FF-674D-42BB-8D49-A0923EE11C12",
      "menuName": "Plano Contas",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9D01E5DB-3D77-4176-97D7-647B3F85E5B8",
      "menuName": "Conta Contábil",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "09700536-363D-43D3-AFF4-93121471DA56",
      "menuName": "Centro Custo",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CD1E3006-C772-4CA2-AAF9-5FA7643A90BF",
      "menuName": "Conta Contábil X Conta Referencial",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "49985265-7A1E-4470-9E16-A3BC426B8643",
      "menuName": "Conta Contábil X Subconta",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B77B85EA-55E1-42B7-B917-53AA34BBA3FC",
      "menuName": "Código Aglutinação",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3D7C4398-8515-47EA-9915-08DE65F54F44",
      "menuName": "Código Aglutinação x Conta Contábil",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "74E27EF6-C074-4B5D-AFF0-80FFB2019F29",
      "menuName": "Histórico Padrão",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1BAC1362-1349-446A-B1DB-4C2F1F7C9AA0",
      "menuName": "Livro Contábil",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "BCF6E211-98FA-44F7-A052-E23F6138E651",
      "menuName": "Outras Inscrições Cadastrais da Pessoa Jurídica",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "19D505E8-0444-4386-B2E9-6CC9C3B4E46D",
      "menuName": "Participante Acionário",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F21C0EBC-B4BA-4942-8C47-23EDAE6738B1",
      "menuName": "Replicação de Dados Contábeis",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "615CB2D0-4D9B-4E87-84FB-518CCCB6948A",
      "menuName": "Quadro Contábil DIME x Conta Contábil",
      "parentMenuId": "872C88FF-F044-4FD8-918B-868F9E3C709F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2BE87928-33FE-42A3-A2AA-08A42AC3A1F2",
      "menuName": "Folha Pagamento",
      "parentMenuId": "CD87F284-6CA0-4FBB-9159-F4983F06B988",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D06098FD-0C7A-44F8-A8CD-385E201BD549",
      "menuName": "Receita Bruta",
      "parentMenuId": "CD87F284-6CA0-4FBB-9159-F4983F06B988",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F8880D27-D98A-4895-A97E-DA3B2E57B9FF",
      "menuName": "Parâmetros CFOP",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CC08A5B3-6D4C-4E1B-BC17-2F3A32146E39",
      "menuName": "Descrição Complementar Sub Apuração",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6BA2F2F4-25B0-4741-AB4A-C5C85B892671",
      "menuName": "Processo Referenciado de Processo Administrativo",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "AE50490E-168A-4A30-B702-248F336AF0D9",
      "menuName": "Serviço Federal X Municipal",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "EF21169E-6BFA-44DD-A21A-AE9579F4378E",
      "menuName": "Equipamento ECF",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D3B195C1-1EDF-4099-B0F3-EFE56D35518E",
      "menuName": "Observação Lançamento Fiscal",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E52A28CB-1556-4E4E-809D-D7FD2064862A",
      "menuName": "Informação Complementar",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A138FCC5-D5EF-44A8-9D49-EFB10B2986BF",
      "menuName": "Unidade Imobiliária Vendida",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9400ECCD-0BCE-44D7-BD93-4F2FC99D084C",
      "menuName": "Natureza Operação",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "54D5BF9B-F545-44D0-9ECF-0E416162BF57",
      "menuName": "Processo Referenciado de Ação Judicial",
      "parentMenuId": "7157343F-C7A5-4EA4-BFB3-22B735BF0D7C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2805300F-F9A0-49D6-B198-13220096CFB5",
      "menuName": "Empresa",
      "parentMenuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "34FF142B-EAF9-4C36-8918-BACC393AA0F6",
      "menuName": "Contribuinte",
      "parentMenuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FC309DE9-C781-4FE5-9112-B2D67377AB0F",
      "menuName": "Classe de Imposto",
      "parentMenuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2355A1F4-4ABF-4ADA-9C3C-625FF15A99FD",
      "menuName": "Grupo de Tributação",
      "parentMenuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7F9D47A8-5496-46F6-ABB1-555B1FC5A6F2",
      "menuName": "Participante",
      "parentMenuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E278F1D6-A348-438D-9095-97279A8BAE3C",
      "menuName": "Unidade de Medida",
      "parentMenuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9EBA9036-8ADA-4B2A-A822-76AF45B5A137",
      "menuName": "Produto/Serviço",
      "parentMenuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DF2AEEE6-D3E6-450F-8B82-7FE6539FD969",
      "menuName": "Responsáveis",
      "parentMenuId": "D1E191D0-4D6C-445E-9D8E-22BD1420795F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4EC3F28D-BC25-4816-933C-EA0F18BD798E",
      "menuName": "Grupo de Usuário",
      "parentMenuId": "12FECF1F-0246-4479-B102-70586C3C39B6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "36540420-C279-4DCF-A4BB-422D2C0CC774",
      "menuName": "Usuário",
      "parentMenuId": "12FECF1F-0246-4479-B102-70586C3C39B6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "menuName": "Parametrizações",
      "parentMenuId": "F4808843-464C-4C61-A3E0-9345F1103BDB",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "menuName": "Fiscal",
      "parentMenuId": "F4808843-464C-4C61-A3E0-9345F1103BDB",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "menuName": "Outras Contribuições",
      "parentMenuId": "F4808843-464C-4C61-A3E0-9345F1103BDB",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "0F043E44-CC04-482E-871F-CFBF3F5A4C3C",
      "menuName": "Retidos",
      "parentMenuId": "F4808843-464C-4C61-A3E0-9345F1103BDB",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "DD3DF022-87AD-4970-822E-453A598E02D0",
      "menuName": "Serviços",
      "parentMenuId": "F4808843-464C-4C61-A3E0-9345F1103BDB",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "99E9F4B7-7D9F-412A-B499-1D56569B5566",
      "menuName": "Gerencial",
      "parentMenuId": "F4808843-464C-4C61-A3E0-9345F1103BDB",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "1E74B687-C9DE-4847-A7DF-ADA280518407",
      "menuName": "Simples Nacional",
      "parentMenuId": "F4808843-464C-4C61-A3E0-9345F1103BDB",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "8A9EBF7D-226D-4613-BC1C-DC65CB107C7D",
      "menuName": "Atribuição de Natureza da Receita",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B74181B4-F97A-4946-98E2-50E1AE6AEF06",
      "menuName": "Atribuição Imposto Retido x Data Retenção",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6F8DB27E-9170-4E3A-BA71-B07CE7F2A7DF",
      "menuName": "Tipo Crédito Estadual",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FBF8FA35-2A89-413B-8286-2239A8F914BA",
      "menuName": "Tipo Crédito",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4418B9CC-B72F-405A-A666-A5B359487ADA",
      "menuName": "Tipo Crédito Detalhe",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E9343FEB-FFF3-4CC9-9204-F407141012C2",
      "menuName": "Atribuição de Créditos Estaduais",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9BD2047C-509F-4AE6-AFA0-B136FEE3BD63",
      "menuName": "Parametrização de Código da Receita",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2A6B8DDF-B809-4534-8188-67843189BFBC",
      "menuName": "Parametrização da Contabilização dos Vencimentos",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9F9515FC-277C-467E-9A6C-56AC4B44BC3E",
      "menuName": "Incentivo Fiscal",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9ADCDACD-F803-42A5-9401-C5B3EA833FCF",
      "menuName": "Parâmetrização para Transferência de Saldo Apurado de Imposto",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C7DC23A4-DC4C-44FA-AC73-D028817C4D34",
      "menuName": "Simples Nacional - Painel Gerencial",
      "parentMenuId": "1E74B687-C9DE-4847-A7DF-ADA280518407",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0303338A-C81E-4101-99AB-F950DDE293E9",
      "menuName": "Apuração Simples Nacional",
      "parentMenuId": "1E74B687-C9DE-4847-A7DF-ADA280518407",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "959845E0-AE2E-4D45-9885-DF7D2083AD2A",
      "menuName": "Lançamento de Ajustes de Apuração de Contribuição e Créditos",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "666F4EB4-0643-4AAE-B20C-0192F4267F17",
      "menuName": "Saldo do Crédito Fiscal PIS/COFINS",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "744D5FA1-725E-4998-B3F7-9C7B536D4AD0",
      "menuName": "Crédito Fiscal PIS/COFINS",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9F7C7C61-C3FB-49A3-B4A6-C875A34573FF",
      "menuName": "Exclusões Específicas da Sociedade Cooperativa",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "67531C47-45D9-4AC3-A0D9-AED24A4A6883",
      "menuName": "Processo de Apuração Receita",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F5C4F060-9DAA-4C12-9FD6-E537226DB88E",
      "menuName": "Processo de Apuração PIS/COFINS",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "98549E64-93A2-4D6D-9B9B-DB11C83D63FB",
      "menuName": "Consolidação da Apuração PIS/COFINS",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B6CEA9CE-6365-4BB1-890A-49D107374477",
      "menuName": "Controle dos Valores Retidos na Fonte",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "257A9C4E-F635-48AD-AE60-468E2AF3D20E",
      "menuName": "Cálculo FUNRURAL/SENAR/RAT",
      "parentMenuId": "56AC742D-083A-4395-B813-F1D964EF45BE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8714AD82-12AE-43BB-BE0B-B9B6CA48DD47",
      "menuName": "Dashboard",
      "parentMenuId": "99E9F4B7-7D9F-412A-B499-1D56569B5566",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3BF0A247-6ED4-4257-9E34-F72ED9CA0CA7",
      "menuName": "Geração de Lançamento Contabil em Fiscal",
      "parentMenuId": "99E9F4B7-7D9F-412A-B499-1D56569B5566",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C0B8E6A6-AA0E-48F9-BA12-79438E3558DC",
      "menuName": "Alteração em Lote de Documento Fiscal",
      "parentMenuId": "99E9F4B7-7D9F-412A-B499-1D56569B5566",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "85EC6931-9299-4768-A1DA-78F2421E4A1A",
      "menuName": "Fechamento da Contabilização",
      "parentMenuId": "99E9F4B7-7D9F-412A-B499-1D56569B5566",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "496FE563-0C8F-49D9-9576-A3447A66A209",
      "menuName": "Controle Numeração de Guias",
      "parentMenuId": "99E9F4B7-7D9F-412A-B499-1D56569B5566",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8755C010-8BCC-4DA7-BCB7-836697389F92",
      "menuName": "Data Vencimento Imposto",
      "parentMenuId": "99E9F4B7-7D9F-412A-B499-1D56569B5566",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CD207549-1287-4805-956F-4307FADCC05E",
      "menuName": "Unidade Imobiliária Vendida - Consolidação Contábil/Fiscal",
      "parentMenuId": "99E9F4B7-7D9F-412A-B499-1D56569B5566",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "20334C10-F3EF-4D19-8E1B-EF33C68312DE",
      "menuName": "Apurador GPS",
      "parentMenuId": "0F043E44-CC04-482E-871F-CFBF3F5A4C3C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "AEA52372-11AC-4C34-BD3C-6126E1E5EC63",
      "menuName": "Controle Guia - GPS",
      "parentMenuId": "0F043E44-CC04-482E-871F-CFBF3F5A4C3C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1B095ED0-8BF3-46E7-BCE1-29CDFC232911",
      "menuName": "Controle de Imposto Retido",
      "parentMenuId": "0F043E44-CC04-482E-871F-CFBF3F5A4C3C",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C25119FF-61B2-449F-AC2C-A951074384B5",
      "menuName": "Apurador ISS",
      "parentMenuId": "DD3DF022-87AD-4970-822E-453A598E02D0",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FFA3A62E-DF03-4095-ABC0-92887A596A36",
      "menuName": "Controle Guia - ISS",
      "parentMenuId": "DD3DF022-87AD-4970-822E-453A598E02D0",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "006E8B64-7170-45C5-BF71-533A9A6CF4C6",
      "menuName": "Lançamento Saldo Período Anterior",
      "parentMenuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0915EAC9-4513-4E4F-BC5B-1E1BC40DA9A3",
      "menuName": "Cálculo de Créditos Estaduais",
      "parentMenuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9AB9AFC1-1CB4-4699-AAAD-5EB2334BCA20",
      "menuName": "Ajuste Apuração de Imposto ICMS/ICMSST",
      "parentMenuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6A2C77AA-825C-42A4-ACAB-866F90399DB4",
      "menuName": "Ajuste Apuração de Imposto IPI",
      "parentMenuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2197162B-AC1A-4703-A0A3-EEF8D6449976",
      "menuName": "Apuração de Imposto ICMS/ICMSST/IPI/DIFAL/Sub Apuração",
      "parentMenuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "272C9D6A-C646-4A59-B0F8-136EE7326CFE",
      "menuName": "Extra Apuração",
      "parentMenuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0C1FA7F7-1A19-4271-8614-B91B0AD268B3",
      "menuName": "Lançamento Obrigação à Recolher",
      "parentMenuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7546D7BE-3089-4E00-9FA0-186805AA2E4E",
      "menuName": "Informações Adicionais",
      "parentMenuId": "A0452ED5-13D6-4327-94CE-DC43F38779F1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "menuName": "Contábil",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "373F7718-81C3-4AD3-BCC0-004EC8BE4AE8",
      "menuName": "Conglomerados Econômicos",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "FAEEDE6B-1A1C-493B-B694-F0EE7703671B",
      "menuName": "ECF - Geral",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "menuName": "ECF - Lucro Real",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "818EDCC6-E7B1-490E-B219-67206EDBD813",
      "menuName": "ECF - Lucro Presumido",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "1FEB6A33-CB10-45A1-82C0-8C645F2672B5",
      "menuName": "ECF - Lucro Arbitrado",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "967F6747-8753-43CB-8F30-92FCB7D83ED2",
      "menuName": "ECF - Imune ou Isentas",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "5DF191A1-1590-4366-8AD1-1BE002E94F9D",
      "menuName": "ECF - Livro Caixa",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "menuName": "ECF - Informações Econômicas",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "menuName": "ECF - Informações Gerais",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "BB68FD7C-6974-430F-B486-BE5DF6B381EF",
      "menuName": "ECF - Declaração País-a-País",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "DADB423B-A97C-425D-8B6E-DCBAA2D972CE",
      "menuName": "ECF - DEREX",
      "parentMenuId": "4368EA5E-96C3-4ADC-9ACA-53336A14120B",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "E862F05D-1D51-4031-8D62-4F5AB389EAF5",
      "menuName": "Demonstrativo do Livro Caixa",
      "parentMenuId": "5DF191A1-1590-4366-8AD1-1BE002E94F9D",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "72D9478D-114C-4440-92C3-7428996DBA65",
      "menuName": "Declaração País-a-País",
      "parentMenuId": "BB68FD7C-6974-430F-B486-BE5DF6B381EF",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "70FDD9D2-5C14-4FD6-AA14-3DB7518F0505",
      "menuName": "Atividades Incentivadas - PJ Geral",
      "parentMenuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F8AAD304-096C-46DF-AC2C-7C8FADBE7AA4",
      "menuName": "Operações com o Exterior - Pessoa Vinculada ou Não Vinculada",
      "parentMenuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9CA6C9C7-3214-4BCE-A3CC-7DEEF15172BE",
      "menuName": "Operações com o Exterior - Importar/Exportar",
      "parentMenuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "71DFEA45-C2A7-4342-9241-D457FA835A1C",
      "menuName": "Identificação da Participação no Exterior",
      "parentMenuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "99DBA7E0-BF27-4456-902A-D6DE4118BD72",
      "menuName": "Outros Lançamentos e Informações Econômicas",
      "parentMenuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B83DA671-265E-4911-B4D6-BBDB99234191",
      "menuName": "Comércio Eletrônico - Informação de Homepage/Servidor",
      "parentMenuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6AA2B33C-3AC7-4532-B503-C38F83951ED5",
      "menuName": "Royalties Recebidos ou Pagos a Beneficiários do Brasil e do Exterior",
      "parentMenuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3566F3DA-0516-455C-B78D-C59F2E3762E0",
      "menuName": "Rendimentos/Pagamentos Relativos a Serviços, Juros e Dividendos Recebidos do Brasil e do Exterior",
      "parentMenuId": "225F1A79-8453-4C1D-8112-DB088B717BA2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F33595AC-2135-41E7-80F2-94412BB72C3A",
      "menuName": "Lançamento Contábil",
      "parentMenuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0B40FA4D-B72C-444F-8B24-870C19ECB540",
      "menuName": "Cálculo do Saldo Contábil por Centro de Custo",
      "parentMenuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FCD01C5A-35A9-4B8E-9C56-116A88E4ADA7",
      "menuName": "Saldo Contábil",
      "parentMenuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2D1CB183-1173-404B-8035-7E2501FE90F1",
      "menuName": "Transferência Saldo Contábil",
      "parentMenuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "36F3B46E-B7CF-4578-BD8D-E86EDEAC9BE5",
      "menuName": "Razão Auxiliar Sub Conta",
      "parentMenuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "205B95C6-029B-4C16-B1C4-2DCDE40C302B",
      "menuName": "Encerramento de Exercício",
      "parentMenuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C15F39C1-B643-4CB3-8BDF-3C4529632C2F",
      "menuName": "Termo de Verificação para Fins de Substitução da ECD",
      "parentMenuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CCBBFC35-414B-4572-B53D-73AC15F823A1",
      "menuName": "Apuração Regime Especial de Tributação",
      "parentMenuId": "CB8A8CF7-D85E-480C-A87F-B77390B4AE17",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "74990117-37B9-49AE-96E3-B1EE490BBC60",
      "menuName": "Pagamentos/Recebimentos do Exterior ou de Não Residentes",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "EB9FF43D-21B0-4BA1-8B81-6AF90A920318",
      "menuName": "Discriminação Vendas por Atividade Econômica",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "606EC874-F253-4DEB-9031-5CD76C9C789F",
      "menuName": "Vendas a Comercial Exportadora com Fim Específico de Exportação",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "00EA983A-E639-433D-A9E8-F24C7396737D",
      "menuName": "Detalhamento das Exportações da Comercial Exportadora",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "347C797B-1019-4269-BDF8-E72C9E101E1C",
      "menuName": "IR e CSLL Retido na Fonte",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0D4D1EB8-D6CB-4369-A1E9-3F7C1F92A4AB",
      "menuName": "Doações a Campanhas Eleitorais",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D364E66E-A977-4964-A60E-8AF6E00908AA",
      "menuName": "Ativos no Exterior",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4314C534-9B38-43CA-88AE-52D1061A12AA",
      "menuName": "Identificação de Sócios ou Titular",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F6CBA92C-A736-40C4-BB78-0E8EC0F04EB2",
      "menuName": "Rendimentos de Dirigentes, Conselheiros, Sócios ou Titular",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "96D29EDE-EB53-4D95-BCF9-E02F245B833F",
      "menuName": "Identificação e Rendimentos de Dirigentes e Conselheiros – Imunes ou Isentas",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "39D4296E-126C-4467-80A5-0BFFFF0150AB",
      "menuName": "Participações Avaliadas Pelo Método de Equivalência Patrimonial",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "EC4A3748-528C-4F10-A6C6-EE87E33ECEC7",
      "menuName": "Fundos/Clubes de Investimento",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D6AA3884-085B-4C21-9CA9-00ACD9D7AB35",
      "menuName": "Participações em Consórcios de Empresas",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DA5B7793-AAFF-486E-845C-90AB025CFDD2",
      "menuName": "Dados de Sucessoras",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D7A0A16E-61B9-4FB7-B94D-D8187257AB4A",
      "menuName": "Outras Informações (Lucro Real)",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F50769B7-7A82-45EE-B88F-C5EB6654A18F",
      "menuName": "Outras Informações (Lucro Presumido ou Lucro Arbitrado)",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "701B7311-F72A-4CDD-BABD-0E03F883DDD8",
      "menuName": "Mês das Informações de Optantes pelo REFIS (Lucro Real, Presumido e Arbitrado)",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5BE00D84-45BC-4BD3-8C55-4DFDB9CE7C3E",
      "menuName": "Informações de Optantes pelo REFIS – Imunes ou Isentas",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7AEAFDCD-2386-4847-B679-E2FEFD3718C2",
      "menuName": "Informações de Optantes pelo PAES - Imunes ou Isentas",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "66094292-4D28-4613-8EF4-02E7F95D240A",
      "menuName": "Informações de Períodos Anteriores",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4737BA4D-CACD-4BD0-99AC-E20888785FB7",
      "menuName": "Outras Informações",
      "parentMenuId": "A7B649F9-47DE-4943-93EE-51D94048EF6E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DB244AEE-8710-41D5-B44E-44DA3E167DE0",
      "menuName": "Informativo Composição Custos",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0A0B3007-B158-4C75-A027-79151BF3E41C",
      "menuName": "Identificação Conta Parte B e-LALUR e e-LACS",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CFE58E56-0667-48BE-9357-5BD94B7FD5C8",
      "menuName": "Lançamento e-LALUR / e-LACS Parte A",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2692C86F-E1CB-4116-A91A-7127DD051B50",
      "menuName": "Lançamentos na Conta da Parte B e-LALUR e e-LACS",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DFA11BDC-BF01-4A1F-87C7-97F2883249A8",
      "menuName": "Demonstração do Lucro da Exploração IRPJ",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "59E5A823-3B4C-4F15-98C0-B557AAAB2953",
      "menuName": "Cálculo da Isenção e Redução do Imposto Sobre o Lucro Real IRPJ",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "404924F0-0384-4D22-8DCE-53D39072F465",
      "menuName": "Informações da Base de Cálculo dos Incentivos Fiscais",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D473E25E-A69A-4336-8ECC-37D67E86780A",
      "menuName": "Apuração IRPJ e CSLL - Mensal por Estimativa",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "009EE549-9642-456F-AEB7-4B6ED1369C3A",
      "menuName": "Cálculo IRPJ/CSLL",
      "parentMenuId": "86573013-1946-4B70-A191-8E927D9341F2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2ADA046A-56A0-4B71-9A4E-2403F38B1955",
      "menuName": "Apuração da Base de Cálculo do IRPJ",
      "parentMenuId": "1FEB6A33-CB10-45A1-82C0-8C645F2672B5",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "45AA41B6-F509-49E5-B152-A5D8D9C317ED",
      "menuName": "Cálculo do IRPJ",
      "parentMenuId": "1FEB6A33-CB10-45A1-82C0-8C645F2672B5",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "43136588-7AD6-4C33-88E3-AD3B99B32EA2",
      "menuName": "Apuração da Base de Cálculo do CSLL",
      "parentMenuId": "1FEB6A33-CB10-45A1-82C0-8C645F2672B5",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0EA560C3-F964-4ECA-80F9-2C1978449314",
      "menuName": "Cálculo do CSLL",
      "parentMenuId": "1FEB6A33-CB10-45A1-82C0-8C645F2672B5",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1C6FF3B3-7CAA-4B64-8C7C-54265C4501FB",
      "menuName": "Balanço Patrimonial Referencial",
      "parentMenuId": "FAEEDE6B-1A1C-493B-B694-F0EE7703671B",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8006F564-DD37-4D63-8F02-E20203EC03AA",
      "menuName": "Demonstração do Resultado do Exercício Referencial",
      "parentMenuId": "FAEEDE6B-1A1C-493B-B694-F0EE7703671B",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B4D39BFE-D9D0-49EA-9C02-CFE8EEA356E3",
      "menuName": "Cálculo do IRPJ",
      "parentMenuId": "967F6747-8753-43CB-8F30-92FCB7D83ED2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "588877EF-4A2E-4696-811B-B2DB0F99B6D1",
      "menuName": "Cálculo do CSLL",
      "parentMenuId": "967F6747-8753-43CB-8F30-92FCB7D83ED2",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B4104E9D-054E-4997-A9DB-AC4BD27E4AD8",
      "menuName": "Período da Escrituração Contábil Consolidada",
      "parentMenuId": "373F7718-81C3-4AD3-BCC0-004EC8BE4AE8",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3BFA6D47-4356-40EB-85A4-B909A53772FC",
      "menuName": "Empresas Consolidadas",
      "parentMenuId": "373F7718-81C3-4AD3-BCC0-004EC8BE4AE8",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "52B8AA43-A2DC-4935-AE7E-9AAA79413902",
      "menuName": "Empresas Consolidadas Eventos",
      "parentMenuId": "373F7718-81C3-4AD3-BCC0-004EC8BE4AE8",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "03FF8305-AF71-427B-BC19-780DFEEAF98D",
      "menuName": "Plano de Contas Consolidado",
      "parentMenuId": "373F7718-81C3-4AD3-BCC0-004EC8BE4AE8",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5EB28D5B-69EF-4A27-9E51-CDF8EA734F25",
      "menuName": "Empresa Consolidada x Conta Contábil Consolidada",
      "parentMenuId": "373F7718-81C3-4AD3-BCC0-004EC8BE4AE8",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E565D6C6-1A90-4AA6-817E-57783925D358",
      "menuName": "Saldo Contábil Consolidado",
      "parentMenuId": "373F7718-81C3-4AD3-BCC0-004EC8BE4AE8",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B75779C7-9B0D-42EC-B79B-BB9426E6E8C9",
      "menuName": "Saldo Contábil Consolidado Empresa",
      "parentMenuId": "373F7718-81C3-4AD3-BCC0-004EC8BE4AE8",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0A0B7B3E-FC1D-4ACC-B0B5-F31FCE4D9D35",
      "menuName": "Declaração utilização recursos em moeda estrangeira",
      "parentMenuId": "DADB423B-A97C-425D-8B6E-DCBAA2D972CE",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "99A23673-929B-49D0-9DEC-017B5B6E2919",
      "menuName": "Demonstração das Receitas Incentivadas",
      "parentMenuId": "818EDCC6-E7B1-490E-B219-67206EDBD813",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "C10B9412-4C01-42BB-8D26-1B9C351517F8",
      "menuName": "Cálculo da Isenção e Redução do Lucro Presumido",
      "parentMenuId": "818EDCC6-E7B1-490E-B219-67206EDBD813",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F30FE57F-FC1E-4033-9AB9-6C8AE1198996",
      "menuName": "Apuração Lucro Presumido - IRPJ/CSLL",
      "parentMenuId": "818EDCC6-E7B1-490E-B219-67206EDBD813",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "801153F9-7F2C-45BB-91C7-E20B5A77C7F6",
      "menuName": "Cálculo Lucro Presumido - IRPJ/CSLL",
      "parentMenuId": "818EDCC6-E7B1-490E-B219-67206EDBD813",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "884A11B1-E9BF-42E9-8134-E0ADE7A8B925",
      "menuName": "Arquivos Digitais",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "A90FC918-4F0E-428B-B2A6-4E3D75EFA7D3",
      "menuName": "Tarefas",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "CFD83DD8-4777-4C47-80A3-E4E4EE3BDCC7",
      "menuName": "Extração",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "B90FF3DF-EEBC-401E-B10A-D2EA10BE56A7",
      "menuName": "Transformação",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "FD913A76-AFEB-4B2C-A1E8-A6FAA2DA7320",
      "menuName": "Importação",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "F57188E9-F3FD-420A-87F5-245DB2AFCFED",
      "menuName": "Limpeza de Dados",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "9C963CD0-5BDC-4986-AEA0-647AC3952AF7",
      "menuName": "Validação",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "15D4FD7F-9464-4C9B-9134-9EC34F2CA5E7",
      "menuName": "Relatórios",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "52E45C73-DEB0-4789-95DB-88DF3CBD1ECC",
      "menuName": "Controle de Remessa e Retorno",
      "parentMenuId": "57188CAC-D7C6-4CAE-AC89-B75A179680EF",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "63DA4DE6-DAD1-4E4F-AE74-E28BD72D17CA",
      "menuName": "Gerenciador de Tarefas",
      "parentMenuId": "A90FC918-4F0E-428B-B2A6-4E3D75EFA7D3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6DD17EBA-D0D9-4590-9D3F-F01CAE9AC224",
      "menuName": "Regra Validação Fiscal / Contábil",
      "parentMenuId": "9C963CD0-5BDC-4986-AEA0-647AC3952AF7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0743D4F2-FB8B-4D6E-AA1D-4B1D1A17B212",
      "menuName": "Regra Validação Fiscal / Contábil Suspensa",
      "parentMenuId": "9C963CD0-5BDC-4986-AEA0-647AC3952AF7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "08EDFB0B-EB56-4EA0-A75A-68512FFB70FC",
      "menuName": "Executar",
      "parentMenuId": "9C963CD0-5BDC-4986-AEA0-647AC3952AF7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4F1D4E88-50A5-4C2F-8840-A9FF7C839F8C",
      "menuName": "Resultado",
      "parentMenuId": "9C963CD0-5BDC-4986-AEA0-647AC3952AF7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "EA5F7D2E-C7D2-4951-B73C-0CF49EF545FF",
      "menuName": "Configuração de CFOP para Remessa e Retorno",
      "parentMenuId": "52E45C73-DEB0-4789-95DB-88DF3CBD1ECC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2B37DC8A-1D6A-4EB0-9F82-EEF03A7A9C72",
      "menuName": "Controle de Remessa e Retorno",
      "parentMenuId": "52E45C73-DEB0-4789-95DB-88DF3CBD1ECC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FC16C9A1-4665-4485-9218-92FA6D636092",
      "menuName": "Configuração Importação",
      "parentMenuId": "FD913A76-AFEB-4B2C-A1E8-A6FAA2DA7320",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "066A7048-67CB-40CB-B198-4B91DCB46CBF",
      "menuName": "Importação",
      "parentMenuId": "FD913A76-AFEB-4B2C-A1E8-A6FAA2DA7320",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E9ED89F5-4020-4C29-937A-E7B79861129D",
      "menuName": "Log de Importação",
      "parentMenuId": "FD913A76-AFEB-4B2C-A1E8-A6FAA2DA7320",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7AE3F7CA-494D-4F21-ABF4-8E5CA691EF08",
      "menuName": "Log de Importação Automática",
      "parentMenuId": "FD913A76-AFEB-4B2C-A1E8-A6FAA2DA7320",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DAA10D7B-7A26-43C3-82AA-D1876E98722F",
      "menuName": "Regra Validação",
      "parentMenuId": "FD913A76-AFEB-4B2C-A1E8-A6FAA2DA7320",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F682CB18-415E-4030-8B12-081BD40A0753",
      "menuName": "Agendamento/Execução",
      "parentMenuId": "CFD83DD8-4777-4C47-80A3-E4E4EE3BDCC7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "270BB6FE-D701-454A-B49B-83E23F3A8D97",
      "menuName": "Extração de Dados ERP",
      "parentMenuId": "CFD83DD8-4777-4C47-80A3-E4E4EE3BDCC7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "FD05A8A4-790E-4DA3-9DA5-A017DF0E4EC1",
      "menuName": "Gerar",
      "parentMenuId": "884A11B1-E9BF-42E9-8134-E0ADE7A8B925",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "463B041B-8554-4801-8F4D-0D596A73F437",
      "menuName": "Regra de Transformação",
      "parentMenuId": "B90FF3DF-EEBC-401E-B10A-D2EA10BE56A7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "599C6AEB-23F9-4ED6-8E89-22C17BA57042",
      "menuName": "Configurar",
      "parentMenuId": "15D4FD7F-9464-4C9B-9134-9EC34F2CA5E7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "89823136-2A38-44B3-9D92-FB0142D8CC0F",
      "menuName": "Visualização",
      "parentMenuId": "15D4FD7F-9464-4C9B-9134-9EC34F2CA5E7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "19A6D0DF-2215-4DB6-B06C-1ED75D540D81",
      "menuName": "Padrão",
      "parentMenuId": "15D4FD7F-9464-4C9B-9134-9EC34F2CA5E7",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5D9F2915-2A92-49A3-A6C0-9A4AC9A89347",
      "menuName": "Limpeza de Dados",
      "parentMenuId": "F57188E9-F3FD-420A-87F5-245DB2AFCFED",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "menuName": "Fiscal",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "85EE35A1-C4C3-4172-B5AB-0A2834969EBD",
      "menuName": "CIAP",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "A88C8A19-3420-4BF2-83F4-BBCF6D6C596F",
      "menuName": "Registro de Controle de Produção e Estoque (P3)",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "23841E74-6039-48A0-A05E-BF653DF868D1",
      "menuName": "FCI",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "4EC72329-4FCE-4C06-AB6C-746FDF8A8B37",
      "menuName": "GPS",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "956ED891-9C1F-4D6D-8F9F-B5ABA9D72B4A",
      "menuName": "DCTF",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "menuName": "PERDCOMP",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "639949F1-84A2-42F3-9E54-72CF9CAAE1CC",
      "menuName": "GNRE",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "menuName": "Outras Contribuições",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "menuName": "Bloco K / Bloco H",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "19DF3671-D7F8-49DF-B10F-7CBFF71D5301",
      "menuName": "Outras Contribuições - Regime de Caixa",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "DC15F370-5D43-4680-9213-341BE4601C86",
      "menuName": "Controle Ressarcimento/Complemento ICMS ST",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "4D233684-5E8C-4E75-AC94-2331E8966912",
      "menuName": "Consumo Específico Padronizado",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "906508F5-9B06-4836-95C2-5AFB7FFC6CA8",
      "menuName": "Inventário/Estoque Escriturado",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "role": [
        "SIMLES_NACIONAL"
      ],
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "480B561C-AED8-40E4-8294-01B2A1AB2CEC",
      "menuName": "Desmontagem de Mercadorias",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "3C64AA99-C2A3-49F3-B97B-837A4CE0EAF3",
      "menuName": "Outras Movimentações",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "37FEED66-1A29-423E-9678-8F2B60634D32",
      "menuName": "Produção",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "23439AF4-2997-42EF-B321-745265FEFA0B",
      "menuName": "Industrialização",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5C6F8233-FBAF-4FDA-AF12-84B405767A80",
      "menuName": "Reprocessamento Reparo Produto",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CA468FAA-F64B-43D8-AAAF-553868EF20E3",
      "menuName": "Correção de Apontamento",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "277AEB84-1610-4F15-8364-1070BC76FC2B",
      "menuName": "Correção de Apontamentos de Estoque",
      "parentMenuId": "681854F2-9455-4F85-9AA9-A6F01615D374",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "013C1221-F9DF-4126-BDF5-B5F3C82BF408",
      "menuName": "Regime de Caixa - Documento Fiscal",
      "parentMenuId": "19DF3671-D7F8-49DF-B10F-7CBFF71D5301",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "81ED66F4-C1E7-43D7-8E23-A9452EF07FF3",
      "menuName": "Consolidação Pessoa Jurídica - Lucro Presumido",
      "parentMenuId": "19DF3671-D7F8-49DF-B10F-7CBFF71D5301",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7273F39C-D81D-4969-BD5A-D5AA3E5B701A",
      "menuName": "Composição da Receita",
      "parentMenuId": "19DF3671-D7F8-49DF-B10F-7CBFF71D5301",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "66B2B6DF-8C58-4691-BE22-7863B72998A7",
      "menuName": "Cálculo da Ficha do Conteúdo de Importação",
      "parentMenuId": "23841E74-6039-48A0-A05E-BF653DF868D1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "657691B5-86E9-412F-AD87-B8AE69145E63",
      "menuName": "Ficha de Conteúdo de Importação",
      "parentMenuId": "23841E74-6039-48A0-A05E-BF653DF868D1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "EBEE4007-D0B3-45EE-9A28-326B54514A70",
      "menuName": "Processar Arquivo de Retorno",
      "parentMenuId": "23841E74-6039-48A0-A05E-BF653DF868D1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "895507CE-B64A-4E50-9A31-FCE7CCA2C91C",
      "menuName": "Produtos controlados pela FCI",
      "parentMenuId": "23841E74-6039-48A0-A05E-BF653DF868D1",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "BEF5C78E-2752-47A1-932E-149B337B108D",
      "menuName": "Lote GPS",
      "parentMenuId": "4EC72329-4FCE-4C06-AB6C-746FDF8A8B37",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1703ED14-E7CA-465A-8AC1-8F90D260877E",
      "menuName": "Demais Documentos para Operações Geradoras de Contribuição e Créditos",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A137153D-BCA5-4007-A710-463693C037C1",
      "menuName": "Bens Incorporados ao Ativo Imobilizado para Operações Geradoras de Crédito",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A08853CE-31B5-4F88-A4F7-1C168D3C5C55",
      "menuName": "Crédito Presumido sobre Estoque de Abertura",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7BB7F611-8B7E-4570-A0C0-639BB5AB05C4",
      "menuName": "Contribuição Retida na Fonte",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E1264E55-DEC7-417D-8D9A-5486C1EE57E4",
      "menuName": "Contribuições Retidas - Documentos Fiscais",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "63ADF579-1A38-4A0B-A16D-356F373BEBB6",
      "menuName": "Deduções Diversas",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1ACC77A3-7B76-4DFE-A5C8-D2988E9A796F",
      "menuName": "Créditos Decorrentes de Eventos de Incorporação, Fusão e Cisão",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F40186C0-7612-4A25-B8B9-C9351D15E5A0",
      "menuName": "Operações Instituições Financeiras e Assemelhadas",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "68ED86FC-8C3E-4779-94CD-6677B94999F6",
      "menuName": "PIS/PASEP - Folha de Salários",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7F78BA34-C907-4D66-BFF2-D464C54A0A27",
      "menuName": "Apuração Contribuição Previdenciária",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "197F68A4-BE59-484C-894D-2C211C16068B",
      "menuName": "Contribuição Previdenciária Consolidada",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E06CC580-9B18-4FB7-9A23-59B708D1BA6A",
      "menuName": "Unidade Imobiliária Vendida - Movimentação Fiscal",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "BFB3B48D-FCD1-4569-BC19-121E1A2EB4F2",
      "menuName": "DIMOB",
      "parentMenuId": "E59AC884-CDAC-48F8-BFD1-76028E9E8F3E",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "05148235-A8A3-4A29-9B4B-7B9D7079A805",
      "menuName": "Documento Fiscal",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "22938C9B-4E74-4C7E-8B3F-B9496EDB929D",
      "menuName": "Exportação",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "05A32650-500F-4335-B5FB-94F6FDFBD6FD",
      "menuName": "Redução",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1C7E4771-F94B-4431-8FB2-704D986E1D2A",
      "menuName": "Cupom Fiscal",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "35AA9A66-8C05-4449-9C9E-78A2C8D8AFC7",
      "menuName": "Operações com Cartão de Crédito/Débito",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "13B0B6C1-5833-4D84-92AE-8D9833EE8C95",
      "menuName": "Operações com Instrumentos de Pagamentos Eletrônicos",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D3ABF7B4-81CE-4295-8FF1-1B58B7AF686F",
      "menuName": "Valor Agregado",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4EB00AED-2B1E-40BF-BECB-82E6D6E46C4E",
      "menuName": "Fechamento Período Fiscal",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DA87C7F4-DA12-4603-A646-50E71D8EA0A0",
      "menuName": "Tipo Movimentação Inventário",
      "parentMenuId": "A88C8A19-3420-4BF2-83F4-BBCF6D6C596F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "DACEAE28-9F4E-42A5-83C2-868AC8B64810",
      "menuName": "Movimento de Inventário",
      "parentMenuId": "A88C8A19-3420-4BF2-83F4-BBCF6D6C596F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "69F8988F-67FD-496B-BFB4-BA73836EA4A3",
      "menuName": "Saldo de Fechamento",
      "parentMenuId": "A88C8A19-3420-4BF2-83F4-BBCF6D6C596F",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A5132410-6284-4542-9785-6062D76C25D2",
      "menuName": "Bens/Componentes do Ativo Permanente",
      "parentMenuId": "85EE35A1-C4C3-4172-B5AB-0A2834969EBD",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9CE125A3-DC86-4250-BF9C-7048E3C735F7",
      "menuName": "Movimentação - CIAP",
      "parentMenuId": "85EE35A1-C4C3-4172-B5AB-0A2834969EBD",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "D9E54849-BF7B-427A-B626-E38738EC2D72",
      "menuName": "Outros Créditos CIAP",
      "parentMenuId": "85EE35A1-C4C3-4172-B5AB-0A2834969EBD",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9F5E6ED7-D760-44D2-8EF3-9A6C88D08238",
      "menuName": "Cálculo CIAP",
      "parentMenuId": "85EE35A1-C4C3-4172-B5AB-0A2834969EBD",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1D5EBF19-20DA-448B-9EC6-276125C4A383",
      "menuName": "Demonstrativo de Cálculo do CIAP",
      "parentMenuId": "85EE35A1-C4C3-4172-B5AB-0A2834969EBD",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A5962DD2-CB1F-4E6F-9B22-ACA454020D1C",
      "menuName": "Notas Fiscais - Exportação Direta / Transferência",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E2F14B68-4EEC-4E51-B6F5-CF5401286974",
      "menuName": "Exportação Direta",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9556B39E-A808-4FA9-81BD-749097CDD08A",
      "menuName": "Nota Fiscal de Venda para Empresa Comercial Exportadora",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CC992065-BD91-40A4-8D77-5717B48B3B59",
      "menuName": "Livro Registro de Apuração do IPI no Período do Ressarcimento - Entradas e Saídas (Fichas 011 e 012)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "876B6B41-C49D-473A-96F2-DC4EA137126E",
      "menuName": "Notas Fiscais de Crédito - Entrada/Aquisição, Créditos Extemporâneos e Demais Créditos (Fichas 013 e 015)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9C080DCC-B4BC-441D-82DF-B9277C703C56",
      "menuName": "Livro Registro de Apuração do IPI Após o Período do Ressarcimento (Ficha 021)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "18C4134E-CA4B-4D8B-8542-8BFC4773A6B0",
      "menuName": "Demonstrativo da Constituição do Crédito IRRF - Cooperativas / Juros sobre o Capital Próprio (Fichas 042 e 044)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "1BB801A0-E20A-4AA1-883F-24083502A373",
      "menuName": "Salário-Maternidade (Ficha 109)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E3B8B562-1F96-426E-AA11-A8BF38DB2968",
      "menuName": "Salário-Família e Filhos ou Equiparados (Fichas 107 e 108)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "19A55D0C-1E2E-495A-A655-6FE24DC05FA8",
      "menuName": "Contribuição Retida na Fonte (Ficha 113)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7E7742C6-CF71-4A88-B20D-CEEB8A0E2F55",
      "menuName": "Consolidação Documentos Fiscais para PERDCOMP-Reintegra/IPI",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "68E78D5C-E8F0-419B-8381-2876953D5CCB",
      "menuName": "Bens Exportados - Reintegra (Ficha 128)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ACDEC00D-1144-498C-AF10-339DDB882523",
      "menuName": "Notas Fiscais e Declarações - Reintegra (Fichas 129, 130, 131, 132, 133, 134, 139 e 140)",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7DF773E6-1D8E-40C9-9F45-7608052F7F28",
      "menuName": "Retenção Fonte",
      "parentMenuId": "2E8CA0A8-B2AC-4B56-A1CF-795C1017BCD6",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2CCF5B61-69D7-4F00-B67C-46024BCC51D1",
      "menuName": "Atribuição Produto x Ressarcimento/Complemento ICMS ST",
      "parentMenuId": "DC15F370-5D43-4680-9213-341BE4601C86",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "43551470-AD53-451F-AEEC-98441AFC5790",
      "menuName": "Saldo Inicial Ressarcimento/Complemento ICMS ST",
      "parentMenuId": "DC15F370-5D43-4680-9213-341BE4601C86",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E492C53C-4A5B-491C-ADD0-E7D6332F8A20",
      "menuName": "Apuração Ressarcimento ICMSST",
      "parentMenuId": "DC15F370-5D43-4680-9213-341BE4601C86",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6D9E3F5B-C79D-4CD6-92A5-6C8D70B70599",
      "menuName": "Fórmulas Apuração Ressarcimento ICMSST",
      "parentMenuId": "DC15F370-5D43-4680-9213-341BE4601C86",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A8D16719-1687-4777-AF10-CDCC1A1C4A6D",
      "menuName": "Painel Gerencial - GNRE",
      "parentMenuId": "639949F1-84A2-42F3-9E54-72CF9CAAE1CC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "ABB20E4A-51CD-44D6-9378-72EB5BC681A4",
      "menuName": "Débito Imposto",
      "parentMenuId": "956ED891-9C1F-4D6D-8F9F-B5ABA9D72B4A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F8F32654-6999-4E32-B298-8EFBEF80285D",
      "menuName": "Parcelamento Imposto",
      "parentMenuId": "956ED891-9C1F-4D6D-8F9F-B5ABA9D72B4A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "4C847B20-CB18-476F-BFFB-728E06B7A30A",
      "menuName": "Pagamento DARF",
      "parentMenuId": "956ED891-9C1F-4D6D-8F9F-B5ABA9D72B4A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A294287E-92AD-4CE5-8A99-D606359AE20A",
      "menuName": "Pagamento Indevido",
      "parentMenuId": "956ED891-9C1F-4D6D-8F9F-B5ABA9D72B4A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E5B7825C-42EE-4332-9DA4-E65E91A967EC",
      "menuName": "Outras Compensações",
      "parentMenuId": "956ED891-9C1F-4D6D-8F9F-B5ABA9D72B4A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "8C05CC7B-2840-4F2B-A0FD-96220DC5DBAE",
      "menuName": "Suspensão Imposto",
      "parentMenuId": "956ED891-9C1F-4D6D-8F9F-B5ABA9D72B4A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "48C04C2C-C6A7-4235-94D9-04FF10AED8BD",
      "menuName": "Dedução DARF",
      "parentMenuId": "956ED891-9C1F-4D6D-8F9F-B5ABA9D72B4A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "37B39CF5-C09C-4997-983D-A9206AD63017",
      "menuName": "Dashboard",
      "parentMenuId": "NULL",
      "type": "Route",
      "level": 1
    },
    {
      "menuId": "D2D87AE2-EFD2-450B-A020-21CAAF532A86",
      "menuName": "Controle de Produção de Usina",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "15224017-4BC1-46CA-A57F-39D0D813BC99",
      "menuName": "Combustíveis",
      "parentMenuId": "E4AADA33-B9F5-4677-931F-9D04E426B832",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "CE2C9646-5421-4F17-B0A1-577E8003A052",
      "menuName": "Envio de Email",
      "parentMenuId": "E13C6A37-B709-4F5A-85CD-4071040E2123",
      "type": "Group",
      "level": 2
    },
    {
      "menuId": "5721AC27-0877-438F-93D2-C0350FD2C49A",
      "menuName": "Controle e Envio de E-mail",
      "parentMenuId": "A90FC918-4F0E-428B-B2A6-4E3D75EFA7D3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "E4B13F70-5752-4393-8972-E93975CD2DB3",
      "menuName": "Formulário de E-mail",
      "parentMenuId": "A90FC918-4F0E-428B-B2A6-4E3D75EFA7D3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "49290658-20F0-45FE-8000-442BB8C2D15A",
      "menuName": "Parâmetro Envio de Email Dias",
      "parentMenuId": "CE2C9646-5421-4F17-B0A1-577E8003A052",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2D6FC914-1708-4D45-8BCC-CE925C4925E4",
      "menuName": "Parâmetro Envio de Email",
      "parentMenuId": "CE2C9646-5421-4F17-B0A1-577E8003A052",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "5AD17E09-33DA-4298-9853-D22852544D67",
      "menuName": "Natureza Rendimento x Código Receita",
      "parentMenuId": "00A3DAED-D3C3-4934-B531-F1C5478D2394",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "31DAC45A-FDEB-43F6-8B68-3A7ED5A3B8D1",
      "menuName": "Sublimites do Simples Nacional",
      "parentMenuId": "CF5EEEE2-9F37-495D-A9FC-2FCD94EDD491",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "2E2B3D5B-278E-4948-ACCD-296E2B07DDCC",
      "menuName": "Sociedade Uniprofissional",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A6DFBF62-F52E-4B05-A429-5E58CBA338E8",
      "menuName": "Consolidação da Prestação de Serviços x Convênio ICMS 115/03",
      "parentMenuId": "1CD9B004-71EE-4616-AB32-8E54F3AB9CDC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CFE500CB-F4D2-477D-A2DC-409145F35F38",
      "menuName": "Lançamento de GNRE",
      "parentMenuId": "639949F1-84A2-42F3-9E54-72CF9CAAE1CC",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "03EA4FB3-4575-4E2B-BDD1-5B38CF83FFDF",
      "menuName": "Bombas",
      "parentMenuId": "15224017-4BC1-46CA-A57F-39D0D813BC99",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "7F104A3E-8B64-4AC2-9A03-E3762C1D77AC",
      "menuName": "Movimentação Diária",
      "parentMenuId": "15224017-4BC1-46CA-A57F-39D0D813BC99",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CB938FED-0649-49A3-890E-FB9E00E90936",
      "menuName": "Controle de Produção de Usina",
      "parentMenuId": "D2D87AE2-EFD2-450B-A020-21CAAF532A86",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "79CBD2A3-0145-4FCE-A85A-60527A3A1594",
      "menuName": "Atribuição da Natureza do Rendimento",
      "parentMenuId": "71923C4E-A981-41C3-B18A-877A6FCC05E9",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "A9411010-D31F-442C-957C-18A8D1F406CC",
      "menuName": "Entidades Ligadas",
      "parentMenuId": "AF5BC30E-16A3-42A2-8FAE-9DC03130C13B",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "CDCBCE2C-BC44-42C4-A4A6-91F1057B566B",
      "menuName": "Tipo Isencao",
      "parentMenuId": "AF5BC30E-16A3-42A2-8FAE-9DC03130C13B",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "0363F9D1-97DA-468E-BD0E-09B3BF6BF0ED",
      "menuName": "Retenção Fonte PF",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "65116A1C-3EAB-4311-A3BB-206652A54B47",
      "menuName": "Retenção Fonte Recebimentos",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "BCA1C2D8-2730-4A5E-AD25-2A2AC8493BC2",
      "menuName": "Consolidação de Impostos Retidos",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9F59A3DC-6334-4CBE-AF34-2B78F8988EE1",
      "menuName": "Retenção Fonte Beneficiários não identificados",
      "parentMenuId": "7A57A6F7-FE25-434F-99BA-17576AF4B747",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "F3465DD5-1DA2-497D-A94C-DF72A159EBB7",
      "menuName": "Consolidação de Serviços Prestados e Tomados",
      "parentMenuId": "7578A12B-512F-460C-B917-708DDCE00B6A",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "9201D644-EEF0-4D4F-8361-2D00127BD3E3",
      "menuName": "Dados Contribuinte",
      "parentMenuId": "720ED076-FEB3-4E8D-982F-4CB6A66E44A3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "6BBF27AF-BDAD-4A23-90C8-2F00DC6EE9AE",
      "menuName": "Tipo Isencao",
      "parentMenuId": "720ED076-FEB3-4E8D-982F-4CB6A66E44A3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "B5B142A1-F846-4A29-86C2-AAD0EF9BD6FD",
      "menuName": "Processos Administrativos/Judiciais",
      "parentMenuId": "720ED076-FEB3-4E8D-982F-4CB6A66E44A3",
      "type": "Route",
      "level": 3
    },
    {
      "menuId": "42AC64CF-358A-4C1E-8219-DA19BDC24E04",
      "menuName": "Entidades Ligadas",
      "parentMenuId": "720ED076-FEB3-4E8D-982F-4CB6A66E44A3",
      "type": "Route",
      "level": 3
    }
  ]
}
