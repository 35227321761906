import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { CreateNewGridColumn } from 'src/app/shared/grid/grid.helper';

@Component({
  selector: 'app-saledocuments',
  templateUrl: './saledocuments.component.html',
  styleUrls: ['./saledocuments.component.scss']
})
export class SaleDocumentsComponent implements OnInit {

  @Output() eventOpenClick = new EventEmitter<any>();
  @Output() eventRefreshFinish = new EventEmitter();
  @Output() eventDownloadClick = new EventEmitter();

  @Input() dataSource: any = [];

  @ViewChild('gridsaledocuments') gridsaledocuments: GridComponent;

  @Input() DocumentsGridColumns: GridColumn[] = [];

  constructor() { }

  ngOnInit() {
    if (this.dataSource != null && this.dataSource.length > 0)
      this.dataSource = this.dataSource.map(it => ({ ...it, data_emissao: `${it.data_emissao}` }));

    this.GetGridCollun();
  }

  refreshDateSource(_dataSource: any) {
    this.GetGridCollun();
    this.dataSource = _dataSource.map(it => ({ ...it, data_emissao: `${it.data_emissao}` }));
    if (this.gridsaledocuments && this.dataSource)
      this.gridsaledocuments.refreshDateSource(this.dataSource);
  }

  maskDate(d: Date): string {
    return new Date(d).toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric' });
  }
  
  private GetGridCollun(): void {
    if (this.DocumentsGridColumns.length == 0) {
      this.DocumentsGridColumns.push(CreateNewGridColumn(100, 'numero', 'Número', (element: any) => `${element.numero}`));
      this.DocumentsGridColumns.push(CreateNewGridColumn(80, 'serie', 'Série', (element: any) => `${element.serie}`));
      this.DocumentsGridColumns.push(CreateNewGridColumn(120, 'data_emissao', 'Data emissão', (element: any) => `${this.maskDate(element.data_emissao)}`));
      this.DocumentsGridColumns.push(CreateNewGridColumn(365, 'chave', 'Chave', (element: any) => `${element.chave}`));
      this.DocumentsGridColumns.push(CreateNewGridColumn(80, 'qtd_itens', 'Nº de ítens', (element: any) => `${element.qtd_itens}`));
      this.DocumentsGridColumns.push(CreateNewGridColumn(80, 'valor', 'Valor', (element: any) => `${element.valor}`));
      this.DocumentsGridColumns.push(CreateNewGridColumn(80, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));
    }
  }

  OpenDocumentDetail(p: any): void {
    this.eventOpenClick.emit(p);
  }

  FinishDataClicked(): void {
    this.eventRefreshFinish.emit();
  }

  DownloadClick(eventRow: any): void {
    this.eventDownloadClick.emit(eventRow)
  }
}