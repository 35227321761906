import { OnInit, Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { FilterModel } from './DTO/filter.model';
import { IconGrid } from '../grid/DTO/icongrid.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [
    trigger('toggleSearch', [
      state('visible', style({ visibility: 'visible' })),
      transition('hidden=>visible', [
        style({ visibility: 'hidden', transform: 'translate(-30px,-10px)' }),
        animate('500ms 0s ease-in-out')
      ]),
      state('hidden', style({ visibility: 'hidden' })),
      transition('visible=>hidden', [
        style({ visibility: 'visible', transform: 'translate(0px,0px)' }),
        animate('0ms 0s ease-in-out')
      ])
    ])
  ]
})

//@Injectable()
export class FilterComponent implements OnInit {
  searchbarState = 'hidden';
  @Input() dateIn = new FormControl(new Date());
  @Input() dateOut = new FormControl(new Date());

  @Input() typeFilter: number;

  @Output() eventSearchClick = new EventEmitter();
  @Output() eventStartLoading = new EventEmitter();

  @ViewChild('today') today: ElementRef;
  @ViewChild('yesterday') yesterday: ElementRef;
  @ViewChild('last7days') last7days: ElementRef;
  @ViewChild('last30days') last30days: ElementRef;
  @ViewChild('period') period: ElementRef;

  @Input() enabledIcon: boolean = true;
  @Input() icons: IconGrid[] = [];

  @Input() iconclassIni: string = "ini";
  @Input() iconClassComponentIni: string = "icon-lx-calendar mat-icon filter-icon-align";
  @Input() iconTooltipIni: string = "Data Inicial";

  @Input() iconclassFin: string = "fin";
  @Input() iconClassComponentFin: string = "icon-lx-calendar mat-icon filter-icon-align";
  @Input() iconTooltipFin: string = "Data Final";

  @Input() iconClassComponentSelect: string = "icon-lx-calendar mat-icon mt-n_1 color_Dourado";

  filterDateIn = (d: Date): boolean => {
    return d <= this.dateOut.value;
  }

  filterDateFin = (d: Date): boolean => {
    return d >= this.dateIn.value;
  }

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) { }

  ngOnInit() {

    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/search.svg')
    );
    this.filterDate(this.typeFilter);
  }
  GetFilterData(): FilterModel {
    return {
      dateIn: this.dateIn.value,
      dateOut: this.dateOut.value,
      typeFilter: this.typeFilter,
    };
  }


  filterDate(DayType: number): void {//0=ontem,1=Hoje,2=Ultimos 7 dias, 3= Ultimos 30 dias e 4 = Por Periodo
    this.typeFilter = DayType;
    this.StartLoading();
    this.today.nativeElement.className = "btn dome-btn";
    this.yesterday.nativeElement.className = "btn dome-btn";
    this.last7days.nativeElement.className = "btn dome-btn";
    this.last30days.nativeElement.className = "btn dome-btn";
    this.period.nativeElement.className = "btn dome-btn";
    switch (DayType) {
      case 0:
        {
          this.dateIn = new FormControl(new Date(new Date().setDate(new Date().getDate() - 1)));
          this.dateOut = new FormControl(new Date(new Date().setDate(new Date().getDate() - 1)));
          this.yesterday.nativeElement.className = "btn dome-btn selected";
          break;
        }
      case 1:
        {
          this.dateIn = new FormControl(new Date());
          this.dateOut = new FormControl(new Date());
          this.today.nativeElement.className = "btn dome-btn selected";
          break;
        }
      case 2:
        {
          this.dateIn = new FormControl(new Date(new Date().setDate(new Date().getDate() - 7)));
          this.dateOut = new FormControl(new Date());
          this.last7days.nativeElement.className = "btn dome-btn selected";

          break;
        }
      case 3:
        {
          this.dateIn = new FormControl(new Date(new Date().setDate(new Date().getDate() - 30)));
          this.dateOut = new FormControl(new Date());
          this.last30days.nativeElement.className = "btn dome-btn selected";
          break;
        }
      case 4:
        {
          this.dateIn = new FormControl(this.dateIn.value);
          this.dateOut = new FormControl(this.dateOut.value);
          this.period.nativeElement.className = "btn dome-btn selected";
          break;
        }
    }
    this.eventSearchClick.emit();
  }
  StartLoading(): void {
    this.eventStartLoading.emit();
  }
  toogleSearch(): void {
    this.today.nativeElement.className = "btn dome-btn";
    this.yesterday.nativeElement.className = "btn dome-btn";
    this.last7days.nativeElement.className = "btn dome-btn";
    this.last30days.nativeElement.className = "btn dome-btn";

    if (this.searchbarState === 'hidden') {
      this.searchbarState = 'visible';
      this.period.nativeElement.className = "btn dome-btn selected";
    }
    else {
      this.searchbarState = 'hidden';
      this.period.nativeElement.className = "btn dome-btn";
    }

  }

}