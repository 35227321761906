import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms'
import { UserService } from './user.service';
import { UserRQ } from './DTO/userRQ.model';
import { UserDataDetailComponent } from './userdatadetails/userdatadetail.component';
import { UserSignatureDetailComponent } from './usersignaturedetail/usersignaturedetail.component';
import { MatTabGroup } from '@angular/material';
import { UserEmailConfigComponent } from './useremailconfig/useremailconfig.component';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})

export class UserComponent implements OnInit {
  user: any;
  @ViewChild('userdatadetail') userdatadetail: UserDataDetailComponent;
  @ViewChild('usersignaturedetail') usersignaturedetail: UserSignatureDetailComponent;
  @ViewChild('useremailconfig') useremailconfig: UserEmailConfigComponent;

  constructor(private userService: UserService,
    private loginService: LoginService) { }

  ngOnInit() {
    this.GetUser();
  }

  GetUser() {
    let request: UserRQ = {
      IdUsuario: ""
    }

    request.IdUsuario = this.loginService.user.idUsuario;

    this.userService.GetUsers(request).subscribe(
      data => {
        this.user = data;
        this.userdatadetail.refreshDateSource(this.user);
        this.usersignaturedetail.refreshDateSource(this.user);
        this.useremailconfig.refreshDateSource(this.user.users[0]);
      });
  }

}