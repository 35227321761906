
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { FilterComponent } from 'src/app/shared/filter/filter.component';
import { ModalService } from 'src/app/shared/modal/modal.service';
import { InutilizadaDocument } from '../DTO/sales.model';

@Component({
  selector: 'app-saleinutdetail',
  templateUrl: './saleinutdetail.component.html',
  styleUrls: ['./saleinutdetail.component.scss']
})
export class SaleInutdetailComponent implements OnInit {

  @Input() idModal: string;
  @Input() dataSource: InutilizadaDocument;
  @Output() eventRefreshFinish = new EventEmitter();
  emitters: any;
  Cnpj: string;
  NomeFantasia: string;

  constructor(private modalService: ModalService, private loginService: LoginService,) {
  }

  ngOnInit() {
    this.emitters = this.loginService.user.economicGroup.emitters.map(function (n) { return (n) })[0];
    this.Cnpj = this.emitters.cnpjCpf;
    this.NomeFantasia = this.emitters.nomeFantasia;
  }

  maskDate(d: Date): string {
    return new Date(d).toLocaleString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric' });
  }

  refreshDateSource(_dataSource: InutilizadaDocument) {
    this.dataSource = _dataSource;
    this.FinishDataClicked();
  }

  FinishDataClicked(): void {
    this.eventRefreshFinish.emit();
  }
  public openModal() {
    this.modalService.open(this.idModal);
  }

  public closeModal() {
    this.modalService.close(this.idModal);
  }
}
