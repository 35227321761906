import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { EChartOption } from "echarts";
import { ValueSummary } from "./../DTO/dashboard.model";
import { Router } from "@angular/router";
import {
  formatMoneyPrefix,
  abbreviateNumber
} from "src/app/_helpers/number.helper";
import { DashBoardPurchaseRQ } from "src/app/modelstransport/dashboardpuchaseRQ";
import { jsonpCallbackContext } from "@angular/common/http/src/module";

@Component({
  selector: "app-chart-purchase",
  templateUrl: "./chart-purchase.component.html",
  styleUrls: ["./chart-purchase.component.scss"]
})
export class ChartPurchaseComponent implements OnInit, OnChanges {
  @Input() purchase: ValueSummary[];

  ngOnChanges(changes: SimpleChanges) {
    this.updateChartOption();
  }

  mergeOption: any;
  chartOption: EChartOption = this.getChartOption();

  DashBoardPurchaseParameter: DashBoardPurchaseRQ;

  constructor(private router: Router) {}

  ngOnInit() {}

  getChartOption(): EChartOption {
    return {
      xAxis: {
        type: "category",
        axisLine: {
          lineStyle: {
            opacity: 0
          }
        },
        data: []
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            opacity: 0
          }
        },
        splitLine: {
          lineStyle: {
            color: "#ebebeb"
          }
        },
        axisLabel: {
          formatter: (value, index) => {
            return abbreviateNumber(value);
          }
        }
      },
      tooltip: {
        trigger: "item",
        formatter: function(params: any, ticket, callback) {
          let axisLabel = params.name;
          let formattedValue = formatMoneyPrefix(+params.value, "R$ ");
          return `${axisLabel}<br /> ${formattedValue}`;
        }
      },
      series: [
        {
          data: [],
          type: "line",
          symbol: "circle",
          smooth: true,
          itemStyle: {
            color: "#4FD29B"
          }
        }
      ]
    };
  }

  updateChartOption() {
    let xAxix = this.getXAxix();
    let dataSeries = this.getDataSeries();

    this.mergeOption = {
      xAxis: { data: xAxix },
      series: [{ data: dataSeries }]
    };
  }

  getXAxix(): string[] {
    if (!this.purchase) return;

    this.purchase.sort(function(a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    return this.purchase.map(n => n.name);
  }

  getDataSeries(): number[] {
    if (!this.purchase) return;

    this.purchase.sort(function(a, b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    return this.purchase.map(p => p.value);
  }

  goPurchase() {
    let dashBoardPurchaseParameter: DashBoardPurchaseRQ = {
      filterType: 3,
      dateIn: new Date(new Date().setDate(new Date().getDate() - 30)),
      dateFin: new Date(),
      typeDocument: "1"
    };

    sessionStorage.setItem(
      "DashBoardPurchaseParameter",
      JSON.stringify(dashBoardPurchaseParameter)
    );

    this.router.navigate(["/compras"]);
  }
}
