import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';

@Component({
    selector: 'app-purchaseeventsdetail',
    templateUrl: './purchaseeventsdetail.component.html',
    styleUrls: ['./purchaseeventsdetail.component.scss'],
})

export class PurchaseEventsDetailComponent implements OnInit {

    @Input() dataSource: any;
    @Input() eventdetailGridColumns: GridColumn[] = [];
    @ViewChild('gridevents') gridevents: GridComponent;
    @Output() eventRefreshFinish = new EventEmitter();

    dataSourceLocal: any;
    eventdetailGridColumnsLocal: GridColumn[] = [];
    icons: IconGrid[] = [];

    constructor() { }

    ngOnInit() {
        this.dataSourceLocal = this.dataSource;
        this.eventdetailGridColumnsLocal = this.eventdetailGridColumns;

        this.icons.push(this.createNewIcon('done-green', '../assets/svg/done_green.svg', 'Manifestação realizada com sucesso'));
        this.icons.push(this.createNewIcon('clear-red', '../assets/svg/clear_red.svg', 'Ocorreu um erro'));
    }

    createNewIcon(_name: string, _url: string, _tooltip: string = ''): IconGrid {
        return {
            name: _name,
            url: _url,
            tooltip: _tooltip
        }
    }

    maskDate(d: Date): string {
        return new Date(d).toLocaleString('pt-BR');
    }

    refreshDateSource(_dataSource: any) {
        this.dataSourceLocal = _dataSource.map(it => ({ ...it, data: `${this.maskDate(it.data)}` }));
        this.eventdetailGridColumnsLocal = this.eventdetailGridColumns;
        this.gridevents.refreshDateSource(this.dataSourceLocal);
    }
    RefreshFinishClicked(): void {
        this.eventRefreshFinish.emit();
    }
}
