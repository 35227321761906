import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-auditor',
  templateUrl: './tax-auditor.component.html',
  styleUrls: ['./tax-auditor.component.scss']
})
export class TaxAuditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
