import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { UserService } from "./../user.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { ForgotMyPassWordRequest } from "../DTO/forgot-my-password.model";
import { ValidateBrService } from "angular-validate-br";
import { Router } from "@angular/router";
import { DomeDictionary } from 'src/app/dictionary';

@Component({
  selector: "app-forgot-my-password-form",
  templateUrl: "./forgot-my-password-form.component.html",
  styleUrls: ["./forgot-my-password-form.component.scss"]
})
export class ForgotMyPasswordFormComponent implements OnInit {
  
  usuario = new FormControl("", [Validators.required]);
  mypasswordForm: FormGroup;
  @Input() usePrimeiroAcessoClass: boolean;
  @Output() public passwordChanged = new EventEmitter();

  mailsend: boolean = false;
  mailaddress: string;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private validateBrService: ValidateBrService
  ) { }

  ngOnInit() {
    this.mypasswordForm = this.formBuilder.group({
      usuario: new FormControl("", [Validators.required])
    });
  }

  getErrorMessage(typeControl: string, control: any) {
    let response: string = "";
    switch (typeControl.toLocaleUpperCase()) {
      case "usuario": {
        response = this.usuario.hasError("required")
          ? "Campo obrigatório"
          : this.usuario.hasError("usuario")
            ? "Usuario inválido"
            : "";
        break;
      }
    }
    return response;
  }
  onSubmit() {
    if (!this.mypasswordForm.controls.usuario.valid) {
      this.toastr.info(DomeDictionary.InformeTodosCampos, "");
      return;
    }

    let request: ForgotMyPassWordRequest = {
      usuario: this.mypasswordForm.controls.usuario.value
    };

    this.ngxLoader.start();
    this.userService.forgotMyPassword(request).subscribe(data => {
      if (data.sucesso) {
        this.mailsend = true;
        this.mailaddress = data.email;
      } else {
        this.toastr.info(data.mensagem, "");
      }

      this.ngxLoader.stop();
    });
  }

  backLogin() {
    this.router.navigate(["/login"]);
  }
}
