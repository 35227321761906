import { Component, OnInit } from "@angular/core";
import { LoginService } from '../login/login.service';
import { Role } from '../_models/enums';


@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
})
export class InsightsComponent implements OnInit {
  constructor(
    private loginService: LoginService) {
  }

  ngOnInit() {
    this.loginService.CheckRolebyUser(Role.INSIGHTS);
  }

  hasRole(role) {
    return this.loginService.hasRole(role);
  }
}
