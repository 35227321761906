import { Component, Input, OnInit } from '@angular/core';
import { EmitterDetail } from './DTO/emitter.model';

@Component({
  selector: 'app-purchaseemitterdetail',
  templateUrl: './purchaseemitterdetail.component.html',
  styleUrls: ['./purchaseemitterdetail.component.scss']
})
export class PurchaseemitterdetailComponent implements OnInit {
  @Input() _dataSource: EmitterDetail;

  constructor() { }

  ngOnInit() {
  }

  RefreshDataSource(dataSource: EmitterDetail){
    this._dataSource = dataSource;
  }
}
