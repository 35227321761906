import { Component, OnInit } from '@angular/core';
import { AlterarFilialService } from './alterar-filial.service';
import { Emitter } from 'src/app/nav-menu/GroupOrEmitter/DTO/emitter.model';
import { LoginService } from 'src/app/login/login.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EventsService, MessageType } from 'src/app/_helpers/events.service';
import { SnackBarService } from './../../shared/messages/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { differenceInDays, format, isBefore } from 'date-fns';

@Component({
  selector: 'app-alterar-filial',
  templateUrl: './alterar-filial.component.html',
  styleUrls: ['./alterar-filial.component.scss']
})
export class AlterarFilialComponent implements OnInit {

  filterText: string = "";
  filterCertificate: string = "";
  emitters: Emitter[];
  filteredEmitters: Emitter[];

  constructor(private alteraFilialService: AlterarFilialService,
    private loginService: LoginService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private eventsService: EventsService,
    private snackBarService: SnackBarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.loadEmitters();
  }

  loadEmitters() {
    this.ngxUiLoaderService.start();
    this.alteraFilialService.GetEmitters().subscribe(
      data => {

        this.emitters = data.map(emitter => {

          if (!emitter.dataExpiracaoCertificado) {
            return emitter;
          }

          const date = emitter.dataExpiracaoCertificado.split(' ')[0];

          if (!date) {
            return emitter;
          }

          const isExpired = isBefore(new Date(date), new Date());
          const daysDiff = differenceInDays(new Date(date), new Date())

          emitter.formattedDate = format(date, 'DD/MM/YYYY')
          if (isExpired) {
            emitter.certificate = `O seu certificado digital está expirado, para renovar com a Linx , <a href="https://mkt.linx.com.br/certificado-digital" target="_blank">clique aqui.</a>`;
            emitter.certificateStatus = 'expired';
            emitter.certificateIcon = {
              icon: '../../assets/svg/Unaware_Operation.svg',
              color: 'red'
            }
            return emitter;
          }

          if (daysDiff > 30) {
            emitter.certificate = `Certificado Válido: ${format(date, 'DD/MM/YYYY')}`;
            emitter.certificateStatus = 'valid';
            emitter.certificateIcon = {
              icon: '../../assets/svg/done_green.svg',
              color: 'green'
            }
            return emitter;
          }

          emitter.certificate = `O seu certificado digital vai expirar em ${daysDiff} dias, para renovar com a Linx , <a href="https://mkt.linx.com.br/certificado-digital" target="_blank">clique aqui.</a>`
          emitter.certificateStatus = 'toExpire';
          emitter.certificateIcon = {
            icon: '../../assets/svg/priority_high.svg',
            color: 'orange'
          }

          return emitter;
        });

        this.filteredEmitters = this.emitters;
        this.ngxUiLoaderService.stop()
      });
  }

  filterEmitters() {
    if (!this.filterText && !this.filterCertificate) {
      this.filteredEmitters = this.emitters;
      return;
    }

    if (this.filterCertificate && this.filterText) {
      this.filteredEmitters = this.emitters.filter(emitter =>
        emitter.certificateStatus === this.filterCertificate &&
        (
          emitter.cnpjCpf.toLowerCase().includes(this.filterText.toLowerCase()) ||
          emitter.nomeFantasia.toLowerCase().includes(this.filterText.toLowerCase()) ||
          emitter.razaoSocialNomeCompleto.toLowerCase().includes(this.filterText.toLowerCase())
        )
      );
      return;
    }

    if (this.filterCertificate) {
      this.filteredEmitters = this.emitters.filter(emitter =>
        emitter.certificateStatus === this.filterCertificate
      );
      return;
    }

    this.filteredEmitters = this.emitters.filter(emitter =>
      emitter.cnpjCpf.toLowerCase().includes(this.filterText.toLowerCase()) ||
      emitter.nomeFantasia.toLowerCase().includes(this.filterText.toLowerCase()) ||
      emitter.razaoSocialNomeCompleto.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }

  isSelecionado(cnpj: string): boolean {
    let cnpjSelecionado = this.loginService.user.economicGroup.emitters[0].cnpjCpf;
    return cnpjSelecionado === cnpj;
  }

  onSelecionarFilial(cnpj: string) {
    if (this.isSelecionado(cnpj))
      return;

    this.ngxUiLoaderService.start();
    let emitter = this.emitters.find(p => p.cnpjCpf === cnpj);
    let user = this.loginService.user;
    user.economicGroup.emitters[0] = emitter;

    this.alteraFilialService.GetCnpjRoles(cnpj).subscribe(
      async data => {
        user.roles = data;
        this.loginService.updateUser(user);
        this.eventsService.broadcast(MessageType.ATUALIZAR_MENU)
        this.ngxUiLoaderService.stop();

        if (await this.loginService.checkLicense()) {
          if (emitter.cadastroCompleto) {
            this.router.navigate(['/dashboard']);
          } else if (sessionStorage.getItem('licenca') != '2') {
            this.router.navigate(['/completar-cadastro']);
          }
        }
      });
  }

  onDefinirPadrao(cnpj: string) {
    this.ngxUiLoaderService.start();

    this.alteraFilialService.SetDefaultSubsidiary(cnpj).subscribe(
      data => {
        this.snackBarService.showMessage(data.mensagem, !data.sucesso);
        this.loadEmitters();
      });
  }
}
