import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
	HttpClient
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { LoginService } from '../login/login.service';
import { LoginRequest } from '../login/DTO/loginRQ';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { ModalService } from "../shared/modal/modal.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private loginService: LoginService, private http: HttpClient, private modalService: ModalService) { }

	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		if (!request.url.includes("api/v1")) {
			request = this.injectAcessTokenDome(request);
		} else {
			request = this.injectAcessToken(request);
		}

		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error && error.status === 401) {
					if (!this.refreshTokenInProgress) {
						this.refreshTokenInProgress = true;
						this.refreshTokenSubject.next(null);
						var refreshTokenRequest = this.refreshTokenRequest();
						return this.loginService.login(refreshTokenRequest).switchMap(
							(data: any) => {
								this.refreshTokenInProgress = false;
								this.refreshTokenSubject.next(data.refreshToken);
								if (request.url.includes("dome.linx")) {
									return next.handle(this.injectAcessTokenDome(request));
								} else {
									return next.handle(this.injectAcessToken(request));
								}
							}
						);
					} else {
						return this.refreshTokenSubject.pipe(
							filter(result => result !== null),
							take(1),
							switchMap(() => {
								if (request.url.includes("dome.linx")) {
									return next.handle(this.injectAcessTokenDome(request));
								} else {
									return next.handle(this.injectAcessToken(request));
								}
							})
						);
					}
				} else {
					return throwError(error);
				}
			}));
	}

	private injectAcessToken(request: HttpRequest<any>) {
		let currentUser = this.loginService.getUser() || null;
		let cnpj = '';
		if (currentUser) {
			cnpj = currentUser.economicGroup.emitters[0].cnpjCpf;
		}

		request = request.clone({
			setHeaders: {
				domeApiToken: currentUser ? currentUser.accessToken : '',
				SecretTokenApiKey: 'a3ad-bd4e36a5beb6',
				CnpjFilial: cnpj
			}
		});
		return request;
	}

	private injectAcessTokenDome(request: HttpRequest<any>) {
		let currentUser = this.loginService.getUser() || null;
		let cnpj = '';
		if (currentUser) {
			cnpj = currentUser.economicGroup.emitters[0].cnpjCpf;
		}

		request = request.clone({
			setHeaders: {
				domeApiToken: currentUser ? currentUser.accessTokenDomeCloud : '',
				SecretTokenApiKey: 'a3ad-bd4e36a5beb6',
				CnpjFilial: cnpj
			}
		});
		return request;
	}


	public refreshTokenRequest() {
		let currentUser = this.loginService.getUser();

		var loginRequest: LoginRequest = {
			UserName: currentUser.loginUsuario,
			Password: "",
			RefreshToken: currentUser.refreshToken,
			GrantType: "refresh_token"
		};

		return loginRequest;
	}
}
