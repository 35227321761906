import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NavMenuConfigList,
  NavMenuConfigModel
} from "src/app/nav-menu/DTO/nav-menuConfig";
import { Router } from "@angular/router";
import { LoginService } from "src/app/login/login.service";
import { Role } from "../../_models/enums";
import { EventsService, MessageType } from 'src/app/_helpers/events.service';
import { NavMenuComponent } from 'src/app/nav-menu/nav-menu.component';

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html"
})
export class MenuComponent implements OnInit {
  @ViewChild("navMenu") navMenu: NavMenuComponent;

  constructor(private router: Router,
    private loginService: LoginService,
    private eventsService: EventsService) { }

  configMenu: NavMenuConfigList;

  getCor(path: string): string {
    if (this.router.url.startsWith(path)) {
      return "#F0462D"; //cor ícone ativo
    } else {
      return "#5B2E90";
    }
  }

  ngOnInit() {
    this.updateMenuConfig();

    this.eventsService.subscribe(MessageType.ATUALIZAR_MENU, () => {
      console.log('subscribe')
      this.updateMenuConfig();
    })
  }

  updateMenuConfig() {
    this.configMenu = this.getMenuConfig();
  }

  getMenuConfig(): NavMenuConfigList {
    const currentUser = this.loginService.GetUserLogin();
    let router: any[] = [];

    if (sessionStorage.getItem('licenca') == '2') {
      return this.createNewMenuList('/licenca', []);
    }

    for (let i = 0; i <= currentUser.roles.length - 1; i++) {
      router.push(this.GetRolesbyRouter(currentUser.roles[i]));
    }



    router.push({
      routerLink: "/faq",
      title: "Ajuda",
      svg: {
        id: "sidebar-icon-c-7",
        idSideBar: "sidebar-icon-7",
        class: "nav-header-svg",
        hrefsvg: "../../assets/img/sidebar7.svg#svg"
      },
      position: 7
    });

    let menu = this.createNewMenuList(
      "/dashboard",
      router
        .filter(x => x != undefined)
        .sort((a, b) => (a.position > b.position ? 1 : -1))
    );

    return menu;
  }

  GetRolesbyRouter(role: string): any {
    switch (role.toUpperCase()) {
      case Role.DASHBOARD.toUpperCase(): {
        return {
          routerLink: "/dashboard",
          title: "Dashboard",
          svg: {
            id: "sidebar-icon-c-1",
            idSideBar: "sidebar-icon-1",
            class: "nav-header-svg",
            hrefsvg: "../../assets/img/sidebar6.svg#svg"
          },
          position: 0
        };
      }
      case Role.CALENDARIO.toUpperCase(): {
        return {
          routerLink: "/fiscal",
          title: "Simples Nacional Reduzido",
          svg: {
            id: "sidebar-icon-c-5",
            idSideBar: "sidebar-icon-5",
            class: "nav-header-svg",
            hrefsvg: "../../assets/img/sidebar1.svg#svg"
          },
          position: 4
        };
      }
      case Role.NOTA_DE_ENTRADA.toUpperCase(): {
        return {
          routerLink: "/compras",
          title: "Compras",
          svg: {
            id: "sidebar-icon-c-2",
            idSideBar: "sidebar-icon-2",
            class: "nav-header-svg",
            hrefsvg: "../../assets/img/sidebar2.svg#svg"
          },
          position: 1
        };
      }
      case Role.NOTA_DE_SAIDA.toUpperCase(): {
        return {
          routerLink: "/vendas",
          title: "Vendas",
          svg: {
            id: "sidebar-icon-c-3",
            idSideBar: "sidebar-icon-3",
            class: "nav-header-svg",
            hrefsvg: "../../assets/img/sidebar4.svg#svg"
          },
          position: 2
        };
      }
      case Role.DECLARACOES_FISCAIS.toUpperCase(): {
        break;
      }
      case Role.GUIA_IMPOSTO.toUpperCase(): {
        break;
      }
      case Role.INTEGRACAO_CONTADOR.toUpperCase():
        return {
          routerLink: "/integracao",
          title: "Integração Contador",
          svg: {
            id: "sidebar-icon-c-8",
            idSideBar: "sidebar-icon-8",
            class: "nav-header-svg",
            hrefsvg: "../../assets/img/sidebar8.svg#svg"
          },
          position: 5
        };
      case Role.OBRIGACOES_FISCAIS.toUpperCase(): {
        return {
          routerLink: "/taxes",
          title: "Obrigações Fiscais",
          svg: {
            id: "sidebar-icon-c-6",
            idSideBar: "sidebar-icon-6",
            class: "nav-header-svg",
            hrefsvg: "../../assets/img/sidebar3.svg#svg"
          },
          position: 6
        }
      }
    }
  }

  createNewMenuList(
    _routerLinkRoot: string,
    _lstMenu: NavMenuConfigModel[]
  ): NavMenuConfigList {
    return {
      routerLinkRoot: _routerLinkRoot,
      lstMenu: _lstMenu
    };
  }

  closeSidebar(): void {
    this.navMenu.closeSidebar();
  }
}
