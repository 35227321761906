import { Actions, Menu, ActionMenu } from 'src/app/sales/DTO/actions.model';
import { IconGrid } from './DTO/icongrid.model';
import { GridColumn } from './DTO/gridColumn.model';
import { CheckboxColumn, LabelPositionEnum } from './DTO/checkboxColumn.model';
import { LabelColumn } from './DTO/labelColumn.model';
import { PopOver } from 'src/app/sales/DTO/popover.model';
import { EventEmitter } from 'events';

export function ValueOrEmpty(value: string): string {
	if (value) {
		return value;
	}

	return "";
}

export function CreateGridMenu() : Menu {
	return {
		actionsMenu: []
	}
}
export function CreateGridAction(_iconclass: string,
	_iconfunction: any,
	_icongrid: IconGrid,
	_iconClassComponent: string = '',
	_tooltip: string = '',
	_menu?: Menu,
	_hasMenu: boolean = false
): Actions {
	return {
		iconclass: _iconclass,
		iconfunction: _iconfunction,
		iconGrid: _icongrid,
		iconClassComponent: _iconClassComponent,
		tooltip: _tooltip,
		menu: _menu,
		hasMenu: _hasMenu

	}
}

export function CreateGridActionMenu(_iconclass: string,
	_iconfunction: string,
	_iconGrid: IconGrid,
	_iconClassComponent: string,
	_description: string): ActionMenu {
	return {
		iconclass: _iconclass,
		iconfunction: _iconfunction,
		iconGrid: _iconGrid,
		iconClassComponent: _iconClassComponent,
		description: _description
	}
}


export function CreateGridPopOverAction(_iconclass: string,
	_iconfunction: any,
	_icongrid: IconGrid,
	_iconClassComponent: string = '',
	_tooltip: string = '',
	_isPopOver: boolean = true,
	_popOverContent: string = '',
	_popOverTitle: string = ''
): PopOver {
	return {
		iconclass: _iconclass,
		iconfunction: _iconfunction,
		iconGrid: _icongrid,
		iconClassComponent: _iconClassComponent,
		tooltip: _tooltip,
		popOverContent: _popOverContent,
		popOverTitle: _popOverTitle,
		isPopOver: _isPopOver,
		hasMenu: false
	}
}

export function CreateNewIcon(_name: string, _url: string, _tooltip: string = ''): IconGrid {
	return {
		name: _name,
		url: _url,
		tooltip: _tooltip
	}
}

export function CreateNewGridColumn(_width: number,
	_columnDef: string,
	_header: string,
	_cell: any,
	_enabledIcon: boolean = false,
	_enabledAction: boolean = false,
): GridColumn {
	return {
		width: _width,
		columnDef: _columnDef,
		header: _header,
		cell: _cell,
		enabledIcon: _enabledIcon,
		enabledAction: _enabledAction,
		widthmax: _width
	}
}

export function CreateNewGridColumnCheckbox(_width: number,
	_columnDef: string,
	_header: string,
	_cell: any,
	_enabledIcon: boolean = false,
	_enabledAction: boolean = false,
	_enabledCheckbox: boolean = false,
	_checkbox: CheckboxColumn = null,
): GridColumn {
	return {
		width: _width,
		columnDef: _columnDef,
		header: _header,
		cell: _cell,
		enabledIcon: _enabledIcon,
		enabledAction: _enabledAction,
		enabledCheckbox: _enabledCheckbox,
		checkbox: _checkbox,
	}
}

export function CreateNewCheckBoxColumn(
	_checked: any = false,
	_indeterminate: boolean = false,
	_labelPosition: LabelPositionEnum = LabelPositionEnum.after,
	_disabled: boolean = false,
	_labeltext: string = ''
): CheckboxColumn {
	return {
		checked: _checked,
		disabled: _disabled,
		indeterminate: _indeterminate,
		labelPosition: _labelPosition.toString(),
		labeltext: _labeltext,
	}
}

export function CreateNewCheckBox(
	_checked: boolean = false,
	_indeterminate: boolean = false,
	_labelPosition: LabelPositionEnum = LabelPositionEnum.after,
	_disabled: boolean = false,
	_labeltext: string = '',
	_class: string = ''
): CheckboxColumn {
	return {
		checked: _checked,
		disabled: _disabled,
		indeterminate: _indeterminate,
		labelPosition: _labelPosition.toString(),
		labeltext: _labeltext,
		class: _class
	}
}

export function CreateLabel(
	_label: string,
	_class: string
): LabelColumn {
	return {
		class: _class,
		label: _label
	}
}

