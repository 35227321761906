import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { InsightsRS, Insights } from '../DTO/insightsRS';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InsightsService } from '../insights.service';
import { FilterComponent } from 'src/app/shared/filter/filter.component';
import { LoginService } from 'src/app/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { CreateNewGridColumn } from 'src/app/shared/grid/grid.helper';
import { InsightsRQ, StatusInsightType } from '../DTO/insightsRQ';
import { DomeDictionary } from 'src/app/dictionary';

@Component({
  selector: 'app-insights-aprovados',
  templateUrl: './insights-aprovados.component.html',
  styleUrls: ['./insights-aprovados.component.scss']
})
export class InsightsAprovadosComponent implements OnInit {
  @ViewChild('gridaudits') gridaudits: GridComponent;
  @ViewChild('filter') filter: FilterComponent;

  typeDocument?: string = "";
  auditsdetailGridColumns: GridColumn[] = [];
  InsightsData: Insights[];
  InsightsResponse: InsightsRS;
  typeFilter: number;
  icons: IconGrid[] = [];

  constructor(private ngxLoader: NgxUiLoaderService,
    private insightsService: InsightsService,
    private toastr: ToastrService,
    private loginService: LoginService) { }

  ngOnInit() {
    this.typeFilter = 3;

    this.auditsdetailGridColumns.push(CreateNewGridColumn(400, 'chave', 'Chave', (element: Insights) => `${element.chave}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(80, 'numeroDocumento', 'Nº Nota', (element: Insights) => `${element.numeroDocumento}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(75, 'serie', 'Série', (element: Insights) => `${element.serie}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(60, 'id', 'Nº item', (element: Insights) => `${element.id}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(110, 'codProdItem', 'Código Produto', (element: Insights) => `${element.codProdItem}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(200, 'descricaoItem', 'Descrição Produto', (element: Insights) => `${element.descricaoItem}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(200, 'descricaoError', 'Ocorrência', (element: Insights) => `${element.descricaoError}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(70, 'codErrors', 'Código Atual', (element: Insights) => `${element.original}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(200, 'sugestaoError', 'Sugestão Auditoria', (element: Insights) => `${element.sugestaoError}`));
  }

  filterDate(): void {//0=ontem,1=Hoje,2=Ultimos 7 dias, 3= Ultimos 30 dias e 4 = Por Periodo
    let param = this.filter.GetFilterData();
    this.typeFilter = param.typeFilter;

    this.GetInsightsTrib(param.dateIn, param.dateOut);
  }

  GetInsightsTrib(_dateIni: Date, _dateFin: Date) {
    this.StartDataClicked();

    let request: InsightsRQ = {
      dateIn: _dateIni,
      dateOut: _dateFin,
      CNPJ: [],
      typeDocument: null,
      StatusInsight: StatusInsightType.Aprovado
    }

    if (this.typeDocument) {
      request.typeDocument = Number(this.typeDocument);
    }

    request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });

    this.insightsService.GetInsightsTrib(request).subscribe(data => {
      this.InsightsResponse = data;

      this.gridaudits.refreshDateSource(this.InsightsResponse.vaccines);
      this.FinishDataClicked();
    },
      error => {
        this.FinishDataClicked();
        if (error.erros) {
          this.toastr.info(error.erros[0].descricao, "");
        } else if (error.message) {
          if (error.name === "HttpErrorResponse") {
            this.toastr.info(DomeDictionary.ErroConexaoServidor, "");
          } else {
            this.toastr.info(error.message, "");
          }
        }
      });
  }

  StartDataClicked(): void {
    this.ngxLoader.start();
  }

  FinishDataClicked(): void {
    this.ngxLoader.stop();
  }
}
