import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { EventsService, MessageType } from '../_helpers/events.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-licenca',
  templateUrl: './licenca.component.html',
  styleUrls: ['./licenca.component.scss']
})
export class LicencaComponent implements OnInit {

  public message = {
    titulo: '',
    texto: ''
  }

  public state = 0;
  public enterprise = '';
  public errorMessage = '';

  constructor(
    private eventsService: EventsService,
    private router: Router,
    private loginService: LoginService,
    private ngxUiLoaderService: NgxUiLoaderService
  ) { }

  ngOnInit() {
    this.message = JSON.parse(sessionStorage.getItem('licencaMensagem'));
    this.enterprise = this.loginService.user.economicGroup.emitters[0].nomeFantasia;
    this.eventsService.broadcast(MessageType.ATUALIZA_HEADER);
  }

  async handleUnblock() {
    this.ngxUiLoaderService.start();
    try {
      const res: any = await this.loginService.unblockAccess().toPromise();
      if (!res.sucesso) {
        this.errorMessage = res.licencaMensagemResponseDto.texto;
        this.state = 2;
        this.ngxUiLoaderService.stop();
        return;
      }
      sessionStorage.removeItem('licencaMensagem');
      sessionStorage.removeItem('licenca');
      this.state = 1;
      this.ngxUiLoaderService.stop();
    } catch (error) {
      this.errorMessage = error.licencaMensagemResponseDto.texto || 'Ocorreu um erro ao desbloquear acesso.';
      this.state = 2;
      this.ngxUiLoaderService.stop();
    }
  }

  public handleNavigate() {
    this.eventsService.broadcast(MessageType.ATUALIZAR_MENU);
    this.eventsService.broadcast(MessageType.ATUALIZA_HEADER);
    if(!this.loginService.user.economicGroup.emitters[0].cadastroCompleto) {
      this.router.navigate(['/completar-cadastro']);
      return;
    }

    this.router.navigate(['/dashboard']);
  }

}
