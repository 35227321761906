import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from "@angular/core";
import { GridColumn } from '../shared/grid/DTO/gridColumn.model';
import { MatTabNav } from '@angular/material';
import { IconGrid } from '../shared/grid/DTO/icongrid.model';
import { SeriesData } from './DTO/seriesdata.model';
import { SalesService } from './sales.service';
import { SalesRejectionsResponse, ConsolidatedRejection, DocumentDetails, SalesContingenciesResponse, Documents, ConsolidatedContingency, SalesInutilizadasResponse, GetInutilizadasInvoiceRequest, InutilizadaDocument } from './DTO/sales.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GridComponent } from '../shared/grid/grid.component';
import { Chart } from 'angular-highcharts';
import { SaleDocumentsComponent } from './saledocuments/saledocuments.component';
import { SaleGradesComponent } from './salegrades/salegrades.component';
import { SaleItensComponent } from './saleitens/saleitens.component';
import { SaleEmitterComponent } from './saleemitter/saleemitter.component';
import { SaleSubjectComponent } from './salesubject/salesubject.component';
import { ChatsConfig } from '../shared/charts/DTO/chatsconfig.model';
import { ChartsComponent } from '../shared/charts/charts.component';
import { DashBoardSaleRQ } from '../modelstransport/dashboardsaleRQ';
import { FilterComponent } from '../shared/filter/filter.component';
import { Role } from '../_models/enums';
import { LoginService } from '../login/login.service';
import { CreateNewGridColumn, CreateGridAction, CreateNewIcon, CreateGridPopOverAction } from '../shared/grid/grid.helper';
import { ModalService } from '../shared/modal/modal.service';
import { RejectionTreatmentLinkShare } from '../_models/enums';
import { isNullOrEmpty } from '../_helpers/string.helper';
import { DatePipe, formatCurrency } from "@angular/common";
import { SaleInutdetailComponent } from "./saleinutdetail/saleinutdetail.component";
import { DomeDictionary } from "../dictionary";
import { ToastrService } from "ngx-toastr";

@Component({
	selector: 'app-sales',
	templateUrl: './sales.component.html',
	styleUrls: ['./sales.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class SalesComponent implements OnInit {
	titleName: string;
	dataExport: SeriesData[];
	typeChart: string;
	xAxisLabel: number[];
	chart: Chart;
	salesRejectionGridMainClass: string;

	consolidatedEmissionsData: Documents;
	consolidatedRejectionsData: SalesRejectionsResponse;
	consolidatedContingenciesData: SalesContingenciesResponse;
	consolidatedInutilizadasData: SalesInutilizadasResponse;

	documents: Documents;
	documentDetails: DocumentDetails;
	documentEmitterDetails: DocumentDetails;
	documentRemitteeDetails: DocumentDetails;
	dataSource: InutilizadaDocument;
	chartData: ConsolidatedRejection[];
	chartsconfig: ChatsConfig;

	icons: IconGrid[] = [];
	typeFilter: number;
	gridFilterType: number;

	salesRejectionGridColumns: GridColumn[] = [];
	salesContingenciesGridColumns: GridColumn[] = [];
	rejectionDocumentsGridColumns: GridColumn[] = [];
	salesEmissionsGridColumns: GridColumn[] = [];
	salesInutilizadasGridColumns: GridColumn[] = [];

	@ViewChild('matTabs') matTabs: MatTabNav;
	@ViewChild('gridsalesrejections') gridsalesrejections: GridComponent;
	@ViewChild('gridsalescontingencies') gridsalescontingencies: GridComponent;
	@ViewChild('gridSalesInutilizadas') gridSalesInutilizadas: GridComponent;
	@ViewChild('gridsalesemissions') gridsalesemissions: SaleDocumentsComponent;
	@ViewChild('gridsalesrejectiondocumentdetails') gridsalesrejectiondocumentdetails: GridComponent;
	@ViewChild('gridsaledocuments') gridsaledocuments: SaleDocumentsComponent;
	@ViewChild('gradesdetail') gradesdetail: SaleGradesComponent;
	@ViewChild('itensdetail') itensdetail: SaleItensComponent;
	@ViewChild('emitterdetail') emitterdetail: SaleEmitterComponent;
	@ViewChild('remitteedetail') remitteedetail: SaleSubjectComponent;
	@ViewChild('chartcontingencies') _chartcontingencies: ChartsComponent;
	@ViewChild('typedocument') typeDocument: ElementRef;
	@ViewChild('filter') filter: FilterComponent;
	@ViewChild('saleinutdetail') saleinutdetail: SaleInutdetailComponent;

	DashBoardSaleParameter: DashBoardSaleRQ;

	public Role = Role;

	constructor(
		private salesService: SalesService,
		private ngxLoader: NgxUiLoaderService,
		private loginService: LoginService,
		private modalService: ModalService,
		private datePipe: DatePipe,
		private toastr: ToastrService) {
	}

	ngOnInit() {
		this.loginService.CheckRolebyUser(Role.NOTA_DE_SAIDA);

		if (sessionStorage.DashBoardSaleParameter) {
			this.DashBoardSaleParameter = <DashBoardSaleRQ>JSON.parse(sessionStorage.DashBoardSaleParameter);
			this.typeFilter = this.DashBoardSaleParameter.filterType;
			this.filter.dateIn.setValue(this.DashBoardSaleParameter.dateIn);
			this.filter.dateOut.setValue(this.DashBoardSaleParameter.dateFin);
			this.filter.typeFilter = this.typeFilter;
			this.typeDocument.nativeElement.value = this.DashBoardSaleParameter.typeDocument;
			sessionStorage.removeItem("DashBoardSaleParameter");
		}
		else {
			this.typeFilter = 1;
		}

		this.icons.push(CreateNewIcon('eye', '../assets/svg/eye.svg'));

		this.salesRejectionGridMainClass = "grid-main-rejections";
		this.salesRejectionGridColumns.push(CreateNewGridColumn(100, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));
		this.salesRejectionGridColumns.push(CreateNewGridColumn(80, 'status', 'Código', (element: any) => `${element.status}`));
		this.salesRejectionGridColumns.push(CreateNewGridColumn(480, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
		this.salesRejectionGridColumns.push(CreateNewGridColumn(80, 'quantidade', 'Qtde', (element: any) => `${element.quantidade}`));

		this.salesContingenciesGridColumns.push(CreateNewGridColumn(70, 'quantidade', 'Qtde', (element: any) => `${element.quantidade}`));
		this.salesContingenciesGridColumns.push(CreateNewGridColumn(100, 'status', 'Código', (element: any) => `${element.status}`));
		this.salesContingenciesGridColumns.push(CreateNewGridColumn(300, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
		this.salesContingenciesGridColumns.push(CreateNewGridColumn(0, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));

		this.salesEmissionsGridColumns.push(CreateNewGridColumn(90, 'emissao', 'Emissão', (element: any) => `${element.emissao}`));
		this.salesEmissionsGridColumns.push(CreateNewGridColumn(110, 'status', 'Status', (element: any) => `${element.status}`));
		this.salesEmissionsGridColumns.push(CreateNewGridColumn(80, 'numero', 'Nota', (element: any) => `${element.numero}`));
		this.salesEmissionsGridColumns.push(CreateNewGridColumn(60, 'qtd_itens', 'Itens', (element: any) => `${element.qtd_itens}`));
		this.salesEmissionsGridColumns.push(CreateNewGridColumn(60, 'serie', 'Série', (element: any) => `${element.serie}`));
		this.salesEmissionsGridColumns.push(CreateNewGridColumn(110, 'data_emissao', 'Data de emissão', (element: any) => `${this.datePipe.transform(element.data_emissao, "dd/MM'/yyyy")}`));
		this.salesEmissionsGridColumns.push(CreateNewGridColumn(130, 'valor', 'Valor', (element: any) => `${element.valor}`));
		this.salesEmissionsGridColumns.push(CreateNewGridColumn(450, 'chave', 'Chave', (element: any) => `${element.chave}`));
		this.salesEmissionsGridColumns.push(CreateNewGridColumn(80, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));

		this.salesInutilizadasGridColumns.push(CreateNewGridColumn(150, 'dataHora', 'Data da Inutilização', (element: any) => `${this.datePipe.transform(element.dataHora, "dd/MM'/yyyy")}`));
		this.salesInutilizadasGridColumns.push(CreateNewGridColumn(100, 'nrInicial', 'Número Inicial', (element: any) => `${element.nrInicial}`));
		this.salesInutilizadasGridColumns.push(CreateNewGridColumn(100, 'nrFinal', 'Número Final', (element: any) => `${element.nrFinal}`));
		this.salesInutilizadasGridColumns.push(CreateNewGridColumn(100, 'serie', 'Série', (element: any) => `${element.serie}`));
		this.salesInutilizadasGridColumns.push(CreateNewGridColumn(150, 'status', 'Status', (element: any) => `${element.status}`));
		this.salesInutilizadasGridColumns.push(CreateNewGridColumn(400, 'chave', 'Chave', (element: any) => `${element.chave}`));
		this.salesInutilizadasGridColumns.push(CreateNewGridColumn(80, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));


		if (this.hasRole('VENDAS_EMISSOES')) {
			return this.gridFilterType = SalesType.EMISSÕES;
		}

		if (this.hasRole('VENDAS_REJEICOES')) {
			return this.gridFilterType = SalesType.REJEIÇÕES;
		}

		if (this.hasRole('VENDAS_CONTINGENCIA')) {
			return this.gridFilterType = SalesType.CONTINGÊNCIA;
		}

		if (this.hasRole('VENDAS_INUTILIZACOES')) {
			return this.gridFilterType = SalesType.INUTILIZAÇÕES;
		}
	}

	maskDate(d: Date): string {
		return new Date(d).toLocaleString('pt-BR');
	}

	hasRole(role) {
		return this.loginService.hasRole(role);
	}

	GetConsolidatedSalesRejections() {
		let param = this.filter.GetFilterData();
		let request = {
			dateIn: param.dateIn,
			dateOut: param.dateOut,
			CNPJ: [],
			typeDocument: null
		}

		if (this.typeDocument.nativeElement.value) {
			request.typeDocument = Number(this.typeDocument.nativeElement.value);
		}

		this.StartDataClicked();

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });

		this.salesService.GetConsolidatedSalesRejections(request).subscribe(data => {
			this.consolidatedRejectionsData = data;

			this.consolidatedRejectionsData.rejeicoes.forEach(c => {
				c.actions = [];
				c.actions.push(CreateGridPopOverAction('Unaware_Operation', "null", CreateNewIcon('Unaware_Operation', '../assets/svg/Unaware_Operation.svg'), 'mat-icon icon-design', 'Orientação', true, this.GetTreatmentRejection(c.status, c.tratamento), 'Orientação'));
				c.actions.push(CreateGridAction('eye', "OpenRejectionDocuments", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes'));
			});

			this.chartData = this.GetFirstFive(data.rejeicoes);
		});
	}

	GetTreatmentRejection(rejectionCode: number, rejectionTreatment: string) {
		var treatment = isNullOrEmpty(rejectionTreatment) ? "Para maiores informações acesse nosso manual de rejeições no Linx Share." : rejectionTreatment;
		switch (rejectionCode) {
			case 203: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_203}`;
			case 217: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_217}`;
			case 237: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_237}`;
			case 241: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_241}`;
			case 391: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_391}`;
			case 590: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_590}`;
			case 750: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_750}`;
			case 778: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_778}`;
			case 781: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_781}`;
			case 883: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_883}`;
			default: return `${treatment} ${RejectionTreatmentLinkShare.REJECTION_DEFAULT}`;
		}
	}

	GetConsolidatedSalesContingencies() {
		let param = this.filter.GetFilterData();
		let request = {
			dateIn: param.dateIn,
			dateOut: param.dateOut,
			CNPJ: [],
			typeDocument: null
		}

		if (this.typeDocument.nativeElement.value) {
			request.typeDocument = Number(this.typeDocument.nativeElement.value);
		}

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });

		this.StartDataClicked();

		this.salesService.GetConsolidatedSalesContingencies(request).subscribe(data => {
			this.consolidatedContingenciesData = data;

			this.consolidatedContingenciesData.contingencies.forEach(c => {
				c.actions = [];
				c.actions.push(CreateGridAction('eye', "OpenContingencyDocuments", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes'));
			});

			this.chartData = this.GetFirstFiveContingencies(data.contingencies);
			this.chartsconfig = {
				chartConfig: {
					type: 'column'
				},
				legendConfig: {
					enabled: false
				},
				creditsConfig: {
					enabled: false
				},
				plotOptionsConfig: {
					column: {
						dataLabels: {
							enabled: true
						},
					}
				},
				subtitleConfig: {},
				titleConfig: {
					text: 'TOP 5 MOTIVOS',
					style: {
						height: 400,
						width: 848
					}
				},
				tooltipConfig: {},
				xAxisConfig: {
					categories: this.chartData.map(function (n) { return n.status.toString() }),
					title: {
						text: 'Status da contingência'
					}
				},
				yAxisConfig: {
					title: { text: 'Quantidade de contingências' },
					gridLineColor: 'white'
				},
				seriesConfig: [{
					type: 'column',
					name: '',
					borderRadius: 5,
					data: this.chartData.map(function (n) {
						return {
							name: n.status.toString(),
							y: n.quantidade
						};
					}),
				}],
				seriesData: this.chartData

			};

			if (this.chartData && this.chartData.length > 0)
				this._chartcontingencies.refreshDateSource(this.chartsconfig);

		});
	}

	GetSalesInvoices(_status: number) {
		this.StartDataClicked();
		let param = this.filter.GetFilterData();
		let statusNf = this.gridFilterType;
		switch (statusNf) {
			case 0:
				statusNf = 0;
				break;
			case 1:
				statusNf = 1;
				break;
			case 2:
				statusNf = 2;
				break;
		}

		let request = {
			status: _status,
			dateIn: param.dateIn,
			dateOut: param.dateOut,
			typeDocument: null,
			gridFilterType: statusNf,
			CNPJ: [],
		};

		if (this.typeDocument.nativeElement.value) {
			request.typeDocument = Number(this.typeDocument.nativeElement.value);
		}

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });
		this.salesService.GetSalesInvoices(request).subscribe(data => {
			if (this.gridFilterType == 0) {
				this.consolidatedEmissionsData = data;
				this.consolidatedEmissionsData.documentos.forEach(c => {
					c.actions = [];
					c.actions.push(CreateGridAction('eye', "OpenRejectionDocuments", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes'));
					if (this.hasRole('VENDAS_DOWNLOAD')) {
						c.actions.push(CreateGridAction('arrow-alt-circle-down-regular', 'DownloadClicked', CreateNewIcon('arrow-alt-circle-down-regular', '../assets/svg/arrow-alt-circle-down-regular.svg'), 'mat-icon icon-design', 'Download XML'));
					}
					c.valor = formatCurrency(Number(c.valor), 'pt-BR', '', 'BRL');
				});

				if (this.gridsalesemissions && this.consolidatedEmissionsData.documentos) {
					this.gridsalesemissions.refreshDateSource(this.consolidatedEmissionsData.documentos);
				}
			}
			else {
				this.OpenModal('rejection-detail-modal');

				this.documents = data;
				this.documents.documentos.forEach(c => {
					c.actions = [];
					c.actions.push(CreateGridAction('eye', "OpenDocumentDetail", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design'));
					c.valor = Number(c.valor).toFixed(2);
				});

				if (this.gridsaledocuments && this.documents.documentos) {
					this.gridsaledocuments.refreshDateSource(this.documents.documentos);
					this.alignInkBar();
				}
			}
			this.FinishDataClicked();
		});
	}

	GetSalesInvoiceDetail(_chave: string) {
		this.StartDataClicked();
		this.salesService.GetSalesInvoiceDetail(_chave).subscribe(data => {
			this.OpenModal('document-detail-modal');

			this.documentDetails = data;

			if (this.gradesdetail && this.documentDetails)
				this.gradesdetail.refreshDateSource(this.documentDetails);

		});

		this.salesService.GetInvoiceEmitterDetail(_chave).subscribe(data => {

			this.documentEmitterDetails = data;

			if (this.emitterdetail && this.documentEmitterDetails)
				this.emitterdetail.refreshDateSource(this.documentEmitterDetails);

		});

		this.salesService.GetInvoiceRemitteeDetail(_chave).subscribe(data => {
			this.documentRemitteeDetails = data;

			if (this.remitteedetail && this.documentRemitteeDetails)
				this.remitteedetail.refreshDateSource(this.documentRemitteeDetails);

		});
		this.itensdetail.GetInvoiceItemDetail(_chave);
	}

	GetRejectionDocumentDetails(_chave: string) {
		this.StartDataClicked();
		this.salesService.GetRejectionDocumentDetails(_chave).subscribe(data => {
			this.OpenModal('document-detail-modal');

			this.documentDetails = data;

		});
	}

	GetFirstFive(a: ConsolidatedRejection[]) {
		var clone = Object.assign([], a);
		return clone.sort(function (a, b) {
			if (a.quantidade < b.quantidade)
				return 1;
			if (a.quantidade > b.quantidade)
				return -1;
			return 0;
		}).slice(0, 5);
	}

	GetFirstFiveContingencies(a: ConsolidatedContingency[]) {
		var clone = Object.assign([], a);
		return clone.sort(function (a, b) {
			if (a.quantidade < b.quantidade)
				return 1;
			if (a.quantidade > b.quantidade)
				return -1;
			return 0;
		}).slice(0, 5);
	}

	GetSalesInutilizadas() {
		this.StartDataClicked();

		let filters = this.filter.GetFilterData();

		let request = this.BuildGetInutilizadasInvoiceRequest(filters.dateIn, filters.dateOut, this.loginService.getCnpj());
		if (this.typeDocument.nativeElement.value) {
			request.TypeDocument = Number(this.typeDocument.nativeElement.value);
		}

		this.salesService.GetInutilizadasInvoice(request).subscribe(data => {
			this.consolidatedInutilizadasData = data;
			this.consolidatedInutilizadasData.documents.forEach(c => {
				c.actions = [];
				c.actions.push(CreateGridAction('eye', "OpenInutilizadaDetail", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes'));
			});


			this.FinishDataClicked();
		});
	}

	BuildGetInutilizadasInvoiceRequest(_dateIn: any, _dateOut: any, _cnpj: string): GetInutilizadasInvoiceRequest {
		return {
			DateIn: _dateIn,
			DateOut: _dateOut,
			TypeDocument: undefined,
			CNPJ: [_cnpj],
		};
	}

	filterType(_type: any): void {//0=rejeições, 1=Emissões, 2=contingência, 3=Inutilizadas
		this.consolidatedRejectionsData = undefined;
		this.consolidatedContingenciesData = undefined;
		this.consolidatedEmissionsData = undefined;
		this.consolidatedInutilizadasData = undefined;

		this.gridFilterType = SalesType[_type.tab.textLabel] as any;

		if (this.gridFilterType == 0)
			this.GetSalesInvoices(0);
		else if (this.gridFilterType == 1)
			this.GetConsolidatedSalesRejections();
		else if (this.gridFilterType == 2)
			this.GetConsolidatedSalesContingencies();
		else
			this.GetSalesInutilizadas();
	}

	filterDate(): void {//0=ontem,1=Hoje,2=Ultimos 7 dias, 3= Ultimos 30 dias e 4 = Por Periodo
		this.consolidatedRejectionsData = undefined;
		this.consolidatedContingenciesData = undefined;
		this.consolidatedEmissionsData = undefined;
		this.consolidatedInutilizadasData = undefined;

		if (this.gridFilterType == 0)
			this.GetSalesInvoices(0);
		else if (this.gridFilterType == 1)
			this.GetConsolidatedSalesRejections();
		else if (this.gridFilterType == 2)
			this.GetConsolidatedSalesContingencies();
		else
			this.GetSalesInutilizadas();
	}

	OpenRejectionDocuments(p: any): void {
		this.GetSalesInvoices(p.data.status);
	}

	OpenContingencyDocuments(p: any): void {
		this.GetSalesInvoices(p.data.status);
	}

	OpenDocumentDetail(p: any): void {
		this.GetSalesInvoiceDetail(p.data.chave);
	}

	OpenInutilizadaDetail(p: any): void {
		this.dataSource = p.data;
		this.saleinutdetail.openModal();
	}

	FinishDataClicked(): void {
		this.ngxLoader.stop();
	}

	StartDataClicked(): void {
		this.ngxLoader.start();
	}

	private OpenModal(id: string) {
		this.modalService.open(id);
	}

	DownloadFileBlob(blob: Blob, fileName: string) {
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
		link.remove();
	}

	DownloadXml(eventRow: any) {
		this.StartDataClicked();

		this.salesService.DownloadXml(eventRow.data.chave).subscribe(data => {
			var blob = new Blob([data.body], { type: "xml/plain" });
			this.DownloadFileBlob(blob, `${eventRow.data.chave}.xml`);

			this.FinishDataClicked();
		},
			error => {
				this.FinishDataClicked();
				this.toastr.info(DomeDictionary.ErroInterno, DomeDictionary.ErroDownload)
			})
	}

	private CloseModal(id: string) {
		this.modalService.close(id);
	}

	alignInkBar() {
		setTimeout(() => {
			this.matTabs._alignInkBar();
		}, 0);
	}
}

enum SalesType {
	'EMISSÕES' = 0,
	'REJEIÇÕES' = 1,
	'CONTINGÊNCIA' = 2,
	'INUTILIZAÇÕES' = 3
}