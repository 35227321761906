import { Component, OnInit, Input } from '@angular/core';
import { BreadcrumbItemModel } from './breadcrumb-item.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  constructor() { }

  @Input()
  name: string

  @Input()
  route: string

  @Input()
  lista: any[]

  ngOnInit() {
  }

  getBreadCrumbItens(): BreadcrumbItemModel[] {
    let itens = [];

    itens.push({ name: this.getInicioName(), route: '/dashboard' });

    if (this.lista && this.lista.length > 0) {
      itens.push(...this.lista);
    }
    else if (this.name) {
      itens.push({ name: this.name, route: this.route });
    }

    return itens;
  }

  getInicioName() {
    if (this.name || this.lista)
      return "Início";

    return "Dashboard";
  }
}
