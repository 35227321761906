import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from '../login.service';

@Component({
  selector: "app-forgot-my-password",
  templateUrl: "./forgot-my-password.component.html"
})

export class ForgotMyPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
  }

  passwordChanged() {
    this.loginService.disableForceChangePassword();
    let ret = this.router.navigate(["/dashboard"]);
  }
}
