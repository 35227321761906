import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ChangePasswordRequest } from './../DTO/change-password.model';
import { UserService } from './../user.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomeDictionary } from './../../dictionary';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {

  loginForm: FormGroup;
  @Input() usePrimeiroAcessoClass: boolean;
  @Output() public passwordChanged = new EventEmitter();

  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      currentPassword: ["", Validators.compose([Validators.required])],
      newPassword: ["", Validators.compose([Validators.required])],
      confirmNewPassword: ["", Validators.compose([Validators.required])]
    });
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      this.toastr.info(DomeDictionary.InformeTodosCampos, "");
      return;
    }

    let request: ChangePasswordRequest = {
      newPassword: this.loginForm.controls.newPassword.value,
      currentPassword: this.loginForm.controls.currentPassword.value,
      confirmNewPassword: this.loginForm.controls.confirmNewPassword.value,
    }

    this.ngxLoader.start();
    this.userService.ChangePassword(request).subscribe(
      data => {
        if (data.sucesso) {
          this.toastr.info(DomeDictionary.SenhaAlterada, "");
          this.loginForm.reset();
          this.passwordChanged.emit();
        } else {
          this.toastr.info(data.mensagem, "");
        }

        this.ngxLoader.stop();
      });
  }

}
