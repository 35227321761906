import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { LoginService } from './login.service';
import { Injectable, Injector } from '@angular/core';

@Injectable()
export class Authinterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.loginService.isLoggedIn()) {
            const authRequest = request.clone(
                {
                    setHeaders: request.url.includes('importarfornecedores') ? {} :
                        {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Access-Control-Allow-Origin': '*'
                        }
                });
            return next.handle(authRequest);
        }
        else {
            return next.handle(request);
        }
    }
}