import { environment } from '../environments/environment';

export class Config {
	static urlDOME() {
		if (environment.localhost) {
			return "http://localhost:55007/api/v1/";
		}
		if (environment.development) {
			return "https://dev.dome.linx.com.br/bffapi/api/v1/";
		}
		else if (environment.production) {
			return "https://dome.linx.com.br/bffapi/api/v1/";
		}
		else if (environment.homologation) {
			return "https://hml.dome.linx.com.br/bffapi/api/v1/";
		}
	}

	static urlDOMECloud() {
		if (environment.localhost) {
			return "https://dev.dome.linx.com.br/bffapi";
		}
		if (environment.development) {
			return "https://dev.dome.linx.com.br/bffapi";
		}
		else if (environment.production) {
			return "https://dome.linx.com.br/bffapi";
		}
		else if (environment.homologation) {
			return "https://hml.dome.linx.com.br/bffapi";
		}
	}

	static LoginApi = {
		Login: Config.urlDOME() + "Authenticator/login"
	};

	static FilterGroupOrIssuerApi = {
		GroupOrIssuer: Config.urlDOME() + "Emitter/EconomicGroupsAndEmitters",
	};

	static DOMEApi = {
		GetPurchaseDetails: Config.urlDOME() + "ComprasDetalhes/ConsultaComprasDetalhe",
		SendTaxAuditEmail: Config.urlDOME() + "ComprasDetalhes/EnviaImpostoAuditoriaEmail",

		GetPurchases: Config.urlDOME() + "Compras/ConsultaCompras",
		GetPurchasesReport: Config.urlDOME() + "Compras/consultacomprasmanifestacao",
		GetPurchasesPending: Config.urlDOME() + "Compras/ConsultaComprasPendentes",
		SetManifestation: Config.urlDOME() + "Compras/AplicarManifestacao",
		SetManifestationList: Config.urlDOME() + "Compras/AplicarListaManifestacaoLista",
		UndoManifestation: Config.urlDOME() + "Compras/DesfazerManifestacao",
		DownloadDanfe: Config.urlDOME() + "Compras/DownloadDanfe",
		PurchasesDownloadXml: Config.urlDOME() + "Compras/DownloadXml",

		GetConsolidatedSalesRejections: Config.urlDOME() + "Vendas/ConsultaVendasRejeitadas",
		GetConsolidatedSalesContingencies: Config.urlDOME() + "Vendas/ConsultaVendasContingencias",
		GetRejectionDocuments: Config.urlDOME() + "Vendas/GetRejectionDocuments",
		GetSalesInvoices: Config.urlDOME() + "Vendas/ConsultaFaturas",
		SalesDownloadXml: Config.urlDOME() + "Vendas/DownloadXml",
		GetInutilizadasInvoice: Config.urlDOME() + "Vendas/ConsultaFaturasInutilizadas",
		GetSalesInvoiceDetail: Config.urlDOME() + "Vendas/ConsultaFaturasVendasDetalhes",
		GetSalesInvoiceItensDetail: Config.urlDOME() + "Vendas/ConsultaFaturasItens",
		GetRejectionDocumentDetails: Config.urlDOME() + "Vendas/GetRejectionDocumentDetails",
		GetInvoiceRemitteeDetail: Config.urlDOME() + "Vendas/ConsultaFaturasDestinatario",
		GetInvoiceEmitterDetail: Config.urlDOME() + "Vendas/ConsultaFaturasEmpresasDetalhes",

		GetProductionModule: Config.urlDOME() + "Usuarios/GetProductModule",
		GetUsers: Config.urlDOME() + "Usuarios/ConsultaUsuarios",
		ChangePassword: Config.urlDOME() + "Usuarios/AlterarSenha",
		ForgotMyPassword: Config.urlDOME() + "Usuarios/EsqueciMinhaSenha",
		GetEmitters: Config.urlDOME() + "Usuarios/ConsultaEmpresas",
		GetCnpjRoles: Config.urlDOME() + "Usuarios/ConsultaRolesCnpj",
		SetDefaultSubsidiary: Config.urlDOME() + "Usuarios/AtualizaSubsidiariaPadrao",

		GetManageableUsers: Config.urlDOME() + "GerenciarUsuarios/ConsultaUsuariosGerenciaveis",
		GetUserData: Config.urlDOME() + "GerenciarUsuarios/ConsultaDadosUsuario",
		SaveNewUserAsync: Config.urlDOME() + "GerenciarUsuarios/SalvarNovoUsuario",
		UpdateUserAsync: Config.urlDOME() + "GerenciarUsuarios/AlterarUsuario",
		GetUserHistory: Config.urlDOME() + "GerenciarUsuarios/ConsultaUsuarioHistorico",

		GetCalendarsEvents: Config.urlDOME() + "EventosCalendario/ConsultaEventosCalendario",
		SetCalendarEvent: Config.urlDOME() + "EventosCalendario/CadastrarCalendarioEvento",
		GetTaxCalendarsEvents: Config.urlDOME() + "EventosCalendario/ConsultaImpostosCalendario",
		GenerateDocument: Config.urlDOME() + "EventosCalendario/GerarDocumento",

		CompleteEmitterRegistration: Config.urlDOME() + "Empresas/CompletarEmpresa",
		GetCompleteEmitterRegistration: Config.urlDOME() + "Empresas/ConsultaCompletarEmpresa",
		ChangeEmailConfig: Config.urlDOME() + "Empresas/AlterarConfiguracaoEmail",

		GetInsightsTrib: Config.urlDOME() + "InsightsTributarios/ConsultaInsightsTributarios",
		SetInsight: Config.urlDOME() + "InsightsTributarios/AtualizarInsight",
		SetInsightList: Config.urlDOME() + "InsightsTributarios/AtualizarInsightLista",
		GetTaxRules: Config.urlDOME() + "InsightsTributarios/ConsultaRegrasFiscais",
		GetDataTaxRules: Config.urlDOME() + "InsightsTributarios/ConsultaDadosRegrasFiscais",

		GetDashboardData: Config.urlDOME() + "Dashboard/ConsultaDashboard",

		GetExtractDocuments: Config.urlDOME() + "ExtracaoDados/ConsultaExtracaoDocumentos",
		ExtractDocumentsAsync: Config.urlDOME() + "ExtracaoDados/ExtrairDocumentos",

		GetAccountantIntegration: Config.urlDOME() + "IntegracaoConta/ConsultaIntegracoesConta",

		SendFileTransEvents: Config.urlDOME() + "Email/EnviarArquivos",

		GetFaq: Config.urlDOME() + "Faq/ConsultaFaq",

		SetLogUserAction: Config.urlDOME() + "Logger/IncluirAcaoUsuario ",

		Vacina: Config.urlDOME() + "vacina",

		ConsultaEmpresaPendente: Config.urlDOME() + "/Empresas/ConsultaEmpresaPendenteAtualizacao",

		SalvaEmpresaPendente: Config.urlDOME() + "/Empresas/salvardadosadicionaisempresa",
	};

	static DOMECloud = {
		Authenticate: Config.urlDOMECloud() + "/autenticacao/logar",
		GetUser: Config.urlDOMECloud() + "/autenticacao/usuarioautenticado",
		RequestReport: Config.urlDOMECloud() + "/gerarrelatorio/livrofiscal",
		ListReports: Config.urlDOMECloud() + "/gerarrelatorio/consultalivrosfiscais",
		DownloadReport: Config.urlDOMECloud() + "/gerarrelatorio/downloadlivrofiscal",
		ManageCfop: Config.urlDOMECloud() + "/cfop",
		FiscalDocuments: Config.urlDOMECloud() + "/documentoefetivacao",
		Supplier: Config.urlDOMECloud() + "/fornecedores",
		Relationships: Config.urlDOMECloud() + "/escrituracaofiscalfinalidadesfornecedores",
		GetSugestion: Config.urlDOMECloud() + "/obtersugestaoconversaocfop",
		Perfil: Config.urlDOMECloud() + "/perfis",
		ConsultaModulos: Config.urlDOMECloud() + "/modulos/consultamodulosacoesporusuario",
		ConsultaTodosModulos: Config.urlDOMECloud() + "/modulos/consultamodulosacoesporusuarioperfilmaster",
		ConsultaPerfis: Config.urlDOMECloud() + "/perfis",
		ConsultaRetornoDfe: Config.urlDOMECloud() + "/ConsultarStatusDfe",
		Verticais: Config.urlDOMECloud() + "/verticais",
		ValidaLicenca: Config.urlDOMECloud() + "/licenca/validar",
		DesbloqueiaAcesso: Config.urlDOMECloud() + "/licenca/desbloquear",
	}

	static UserIdleConfiguration = {
		idle: 7200,
		timeout: 1,
		ping: 120
	}

	static NewUrl() {
		if (environment.production) {
			return "https://portal-dome.linx.com.br";
		}
		else if (environment.homologation) {
			return "https://hml-portal-dome.linx.com.br";
		} else if (environment.development) {
			return "https://dev-portal-dome.linx.com.br";
		}
	}
}
