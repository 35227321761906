import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SalesComponent } from './sales/sales.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { LoggedInGuard } from './login/loggedin.guard';
import { ConformityComponent } from './conformity/conformity.component';
import { InsightsComponent } from './insights/insights.component';
import { FaqComponent } from './faq/faq.component';
import { FaqDuvidasComponent } from './faq/faq-duvidas/faq-duvidas.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { PrimeiroAcessoComponent } from './login/primeiro-acesso/primeiro-acesso.component';
import { Role } from './_models/enums';
import { UserComponent } from './user/user.component';
import { ForgotMyPasswordComponent } from './login/forgot-my-password/forgot-my-password.component';
import { CompleteRegistrationComponent } from './user/complete-registration/complete-registration.component';
import { AccountantIntegrationComponent } from './accountantintegration/accountantintegration.component';
import { AlterarFilialComponent } from './home/alterar-filial/alterar-filial.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { AvTaxComplianceComponent } from "./av-tax-compliance/av-tax-compliance.component";
import { GerenciarPerfisComponent } from "./gerenciar-perfis/gerenciar-perfis.component";
import { LicencaComponent } from "./licenca/licenca.component";

const appRoutes: Routes = [
  { path: "login/:to", component: LoginComponent },
  { path: "login", component: LoginComponent },
  {
    path: "alterar-senha",
    component: PrimeiroAcessoComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard]
  },
  {
    path: "completar-cadastro",
    component: CompleteRegistrationComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard]
  },
  {
    path: "taxes",
    component: AvTaxComplianceComponent,
    canLoad: [LoggedInGuard],
  },
  { path: "esqueci-minha-senha", component: ForgotMyPasswordComponent },
  {
    path: "",
    component: HomeComponent,
    canLoad: [LoggedInGuard],
    canActivate: [LoggedInGuard],
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.DASHBOARD] }
      },
      {
        path: "fiscal",
        component: ConformityComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.CALENDARIO] }
      },
      {
        path: "meusdados",
        component: UserComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard]
      },
      {
        path: "faq",
        component: FaqComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard]
      },
      {
        path: "faq/duvidas",
        component: FaqDuvidasComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard]
      },
      {
        path: "senha",
        component: ChangePasswordComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard]
      },
      {
        path: "alterar-filial",
        component: AlterarFilialComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard]
      },
      {
        path: "gerenciar-usuarios",
        component: ManageUsersComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard]
      },
      {
        path: "compras/:id",
        component: PurchaseComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.NOTA_DE_ENTRADA] }
      },
      {
        path: "compras",
        component: PurchaseComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.NOTA_DE_ENTRADA] }
      },
      {
        path: "vendas/:id",
        component: SalesComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.NOTA_DE_SAIDA] }
      },
      {
        path: "vendas",
        component: SalesComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.NOTA_DE_SAIDA] }
      },
      {
        path: "integracao",
        component: AccountantIntegrationComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.INTEGRACAO_CONTADOR] }
      },
      {
        path: "gerenciar-perfil",
        component: GerenciarPerfisComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.INTEGRACAO_CONTADOR] }
      },
      {
        path: "licenca",
        component: LicencaComponent,
        canLoad: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        data: { roles: [Role.INTEGRACAO_CONTADOR] }
      },
    ]
  },

  // otherwise redirect to home
  { path: "**", redirectTo: "" }
];

export const routing = RouterModule.forRoot(appRoutes);
