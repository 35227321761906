import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserService } from "./../user.service";

@Component({
  selector: "app-useremailconfig",
  templateUrl: "./useremailconfig.component.html",
  styleUrls: ["./useremailconfig.component.scss"]
})
export class UserEmailConfigComponent implements OnInit {
  dataSource: any;
  emailConfigForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.emailConfigForm = this.formBuilder.group({
      email: this.formBuilder.control("", Validators.required),
      usuario: this.formBuilder.control("", Validators.required),
      senha: this.formBuilder.control("", Validators.required),
      servidor: this.formBuilder.control("", Validators.required),
      porta: this.formBuilder.control("", Validators.required),
      usarSSL: this.formBuilder.control("", Validators.required),
      protocolo: this.formBuilder.control("", Validators.required),
      autenticacao: this.formBuilder.control("", Validators.required)
    });
  }

  onSubmit(formData) {
    if (this.emailConfigForm.invalid) {
      return;
    }

    this.ngxLoader.start();

    this.userService.ChangeEmailConfig(formData)
      .subscribe(data => {
        this.toastr.info(data.mensagem, "");
        this.ngxLoader.stop();
      })
  }

  refreshDateSource(_dataSource: any) {
    this.dataSource = _dataSource;

    if (this.dataSource.configEmail) {
      let config = this.dataSource.configEmail;

      if (config.contaEmail) {
        this.emailConfigForm.controls.email.setValue(config.contaEmail.email);
        this.emailConfigForm.controls.usuario.setValue(
          config.contaEmail.usuario
        );
        this.emailConfigForm.controls.senha.setValue(config.contaEmail.senha);
      }

      if (config.servidorEmail) {
        this.emailConfigForm.controls.servidor.setValue(
          config.servidorEmail.endereco
        );
        this.emailConfigForm.controls.porta.setValue(
          config.servidorEmail.porta
        );
        this.emailConfigForm.controls.usarSSL.setValue(
          config.servidorEmail.usarSSLouTLS
        );
        this.emailConfigForm.controls.protocolo.setValue(
          config.servidorEmail.protocolo
        );
        this.emailConfigForm.controls.autenticacao.setValue(
          config.servidorEmail.autenticacao
        );
      }
    }
  }
}
