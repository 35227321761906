import { LoginService } from 'src/app/login/login.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from './shared/modal/modal.service';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

	public modalData: any = {};
	public subscription = null;

	constructor(
		public loginService: LoginService,
		private modalService: ModalService
	) { }

	ngOnInit(): void {
		if(this.loginService.isLoggedIn()) {
			this.loginService.checkLicense();
		}
		this.subscription = this.modalService.modalsData.subscribe((data) => {
			if (data) {
				this.modalData = data;
			}
		});
	}

	public closeModal(id: string) {
		this.modalService.close(id);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
