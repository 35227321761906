import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { Actions } from 'src/app/sales/DTO/actions.model';
import { ConformityTaxesModel } from '../DTO/conformity.model';
import { ModalService } from 'src/app/shared/modal/modal.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LabelPositionEnum, CheckboxColumn } from 'src/app/shared/grid/DTO/checkboxColumn.model';
import { LabelColumn } from 'src/app/shared/grid/DTO/labelColumn.model';
import { CreateNewCheckBoxColumn, CreateNewGridColumn, CreateNewGridColumnCheckbox } from 'src/app/shared/grid/grid.helper';
import { DomeDictionary } from './../../dictionary';
import { UserActionRQ } from '../../common/logger/DTO/UserActionRQ';
import { ConformityService } from '../conformity.service';
import { GetDataForFileName } from 'src/app/_helpers/dateTime.helper';

@Component({
  selector: 'app-conformitydeclarationtax',
  templateUrl: './conformitydeclarationtax.component.html',
  styleUrls: ['./conformitydeclarationtax.component.scss']
})

export class ConformityDeclarationTaxComponent implements OnInit {
  icons: IconGrid[] = [];
  ConformityDeclarationTaxesGridColumns: GridColumn[] = [];
  @ViewChild('gridconformitydeclarationtaxes') gridconformitydeclarationtaxes: GridComponent;
  ConformityDeclarationTaxes: ConformityTaxesModel[] = [];
  @ViewChild('dwnldLnk') dwnldLnk: ElementRef;
  @ViewChild('textFaixaTax') textFaixaTax: ElementRef;
  @ViewChild('textFaixaICMSTax') textFaixaICMSTax: ElementRef;

  mathContent: string;
  positionDeclarationTaxCalendar: number = 0;

  constructor(private ngxLoader: NgxUiLoaderService,
    private modalService: ModalService,
    private conformityService: ConformityService,
    private toastr: ToastrService) {
    this.icons.push(this.createNewIcon('mathematics', '../../assets/svg/mathematics.svg'));
    this.icons.push(this.createNewIcon('download', '../../assets/svg/download.svg'));

    this.ConformityDeclarationTaxesGridColumns.push(CreateNewGridColumnCheckbox(80, 'checkbox', '', (element: any) => `${element.checkbox}`, false, false, true, CreateNewCheckBoxColumn(false, false, LabelPositionEnum.after, false, '')));
    this.ConformityDeclarationTaxesGridColumns.push(CreateNewGridColumnCheckbox(80, 'imposto', 'Declarações', (element: any) => `${element.imposto}`));
    this.ConformityDeclarationTaxesGridColumns.push(CreateNewGridColumnCheckbox(80, 'data_vencimento', 'Data de vencimento', (element: any) => `${element.data_vencimento}`));
    this.ConformityDeclarationTaxesGridColumns.push(CreateNewGridColumnCheckbox(80, 'status_pagamento', 'Status de transmissão', (element: any) => this.createLabel(element.status_pagamento.label, element.status_pagamento.class)));
    this.ConformityDeclarationTaxesGridColumns.push(CreateNewGridColumnCheckbox(180, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));
  }
  ngOnInit() {
  }

  createNewIcon(_name: string, _url: string, _tooltip: string = ''): IconGrid {
    return {
      name: _name,
      url: _url,
      tooltip: _tooltip
    }
  }

  createNewAction(_iconclass: string,
    _iconfunction: any,
    _icongrid: IconGrid,
    _iconClassComponent: string = '',
	  _tooltip: string = ''): Actions {
    return {
      iconclass: _iconclass,
      iconfunction: _iconfunction,
      iconGrid: _icongrid,
      iconClassComponent: _iconClassComponent,
	  tooltip: _tooltip,
	  hasMenu: false
    }
  }
  createLabel(
    _label: string,
    _class: string
  ): LabelColumn {
    return {
      class: _class,
      label: _label
    }
  }

  refreshDataSource(_dataSource: any) {
    if (_dataSource.length > 0) {
      this.ConformityDeclarationTaxes = _dataSource;
    }
    else {
      this.ConformityDeclarationTaxes = [];
    }

    this.gridconformitydeclarationtaxes.refreshDateSource(this.ConformityDeclarationTaxes);
  }


  ClickHelp(p: any): void {
    (async () => {
      if (p.data.tipoDocumento) {
        this.positionDeclarationTaxCalendar = p.data.index;
        switch (p.data.tipoDocumento.toUpperCase()) {
          case 'ICMS_AVULSO':
            {
              if (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null) {
                this.StartDataClicked();
                this.textFaixaICMSTax.nativeElement.innerHTML = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS.textoLivre : "");
                this.mathContent = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcICMSModal");
              }
              break;
            }
          case 'DAS':
            {
              if (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoDAS != null) {
                this.StartDataClicked();
                this.textFaixaTax.nativeElement.innerHTML = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoDAS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoDAS.faixaFaturamento.faixa : "");
                this.mathContent = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoDAS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoDAS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcDasModal");
              }
              break;
            }
          case 'ICMS':
            {
              if (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null) {
                this.StartDataClicked();
                this.textFaixaICMSTax.nativeElement.innerHTML = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS.textoLivre : "");
                this.mathContent = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcICMSModal");
              }
              break;
            }
          case 'ICMS_ST':
            {
              if (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null) {
                this.StartDataClicked();
                this.textFaixaICMSTax.nativeElement.innerHTML = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS.textoLivre : "");
                this.mathContent = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcICMSModal");
              }
              break;
            }
          case 'ICMS_ST_AVULSO':
            {
              if (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null) {
                this.StartDataClicked();
                this.textFaixaICMSTax.nativeElement.innerHTML = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS.textoLivre : "");
                this.mathContent = (this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS != null ? this.ConformityDeclarationTaxes[this.positionDeclarationTaxCalendar].memoriaDeCalculoICMS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcICMSModal");
              }
              break;
            }
        }
        this.FinishDataClicked();
      }

    })();
  }

  downloadFile(p: any, helpClick: boolean) {
    const dataFileName = GetDataForFileName(p.data.dataOcorrencia);

    let idDocumento = "";
    if(p.data.idDocumento != null)
    {
      idDocumento = p.data.idDocumento + '_';
    }

    if (!helpClick)
    {
      if ((p.data.formatoDocumento && p.data.formatoDocumento == 'PDF') || (p.data.documentoBase64 && atob(p.data.documentoBase64).split('-')[0] == '%PDF')) {
        if (p.data.documentoBase64 != null) {
          this.dwnldLnk.nativeElement.href = "data:application/pdf;base64," + p.data.documentoBase64;
          this.dwnldLnk.nativeElement.download = p.data.tipoDocumento + '_' + idDocumento + dataFileName + '.pdf';
         }
      }
      else {
        if (p.data.formatoDocumento && p.data.formatoDocumento == 'TEXT' && p.data.documentoBase64 && p.data.documentoBase64 != null) {
          this.dwnldLnk.nativeElement.href = "data:application/text;base64," + p.data.documentoBase64;
          this.dwnldLnk.nativeElement.download = p.data.tipoDocumento + '_' + idDocumento + dataFileName + '.txt';
        }
      }
    }
    else {
      if (p.data.tipoDocumento == "SINTEGRA") {
        this.dwnldLnk.nativeElement.href = "../assets/file/pdf/HELP_SINTEGRA.pdf";
        this.dwnldLnk.nativeElement.download = 'downloadHelpSintegra_' + idDocumento + dataFileName + '.pdf';
      }
      else
        if (p.data.tipoDocumento == "DESTDA") {
          this.dwnldLnk.nativeElement.href = "../assets/file/pdf/HELP_DESTDA.pdf";
          this.dwnldLnk.nativeElement.download = 'downloadHelpDesTDA_' + idDocumento + dataFileName + '.pdf';
        }
    }

    let logUserActionRQ: UserActionRQ = { action: "" };

    if (p.data.documentoBase64 && p.data.documentoBase64 != "" && p.data.documentoBase64 != null) {
      this.toastr.info(this.GetTypeMessage(p.data.tipoDocumento, 1), "");
      this.dwnldLnk.nativeElement.click();
      logUserActionRQ.action = `Download de documento ${p.data.tipoDocumento}.`;
      this.conformityService.SetLogUserAction(logUserActionRQ);

    }
    else {
      if (p.data.tipoDownload == 1 && (p.data.tipoDocumento == "SINTEGRA" || p.data.tipoDocumento == "DESTDA")) {
        this.toastr.info(this.GetTypeMessage(p.data.tipoDocumento, 1), "");
        this.dwnldLnk.nativeElement.click();
      }
      else {
        this.dwnldLnk.nativeElement.href = "";
        this.dwnldLnk.nativeElement.download = "";
        this.toastr.info(DomeDictionary.ArquivoIndisponivel, "");
      }
    }
  }

  GetTypeMessage(tipoDocumento: string, type: number): string //type=0 Pagamento , 1= Download
  {
    if (type == 0) {
      switch (tipoDocumento.toUpperCase()) {
        case 'ICMS_AVULSO':
          {
            return DomeDictionary.ConfirmacaoPagamento;
          }
        case 'DAS':
          {
            return DomeDictionary.ConfirmacaoPagamento;
          }
        case 'ICMS':
          {
            return DomeDictionary.ConfirmacaoPagamento;
          }
        case 'ICMS_ST':
          {
            return DomeDictionary.ConfirmacaoPagamento;
          }
        case 'SINTEGRA':
          {
            return DomeDictionary.ConfirmacaoTransmissao;
          }
        case 'DESTDA':
          {
            return DomeDictionary.ConfirmacaoTransmissao;
          }
      }
    }
    else
      return DomeDictionary.DownloadRealizado;
  }

  StartDataClicked(): void {
    this.ngxLoader.start();
  }
  FinishDataClicked(): void {
    this.ngxLoader.stop();
  }

  private GetType(type: string): string {
    let response: string = "";
    if (type == undefined) {
      return "";
    }

    switch (type) {
      case "ST":
        response = "Substituição Tributária";
        break;
      case "DA":
        response = "Diferencial de Alíquotas";
        break;
      default:
        response = "";
        break;
    }
    return response;
  }

  private GetTypeOperacao(operacao: string): string {
    let response: string = "";
    if (operacao == undefined) {
      return "";
    }

    switch (operacao) {
      case "COMPRA":
        response = "Compra";
        break;
      case "VENDA":
        response = "Venda";
        break;
      default:
        response = "";
        break;
    }
    return response;
  }

  private GetSubType(sub: string): string {
    let response: string = "";
    if (sub == undefined) {
      return "";
    }
    switch (sub) {
      case "ESTOQUE":
        response = "Estoque";
        break;
      case "ATIVO":
        response = "Ativo";
        break;
      case "ANTECIPACAO":
        response = "Antecipação";
        break;
      case "USO_CONSUMO":
        response = "Uso e Consumo";
        break;
      default:
        response = "";
        break;
    }
    return response;
  }

  private openModal(id: string) {
    this.modalService.open(id);
  }

  private closeModal(id: string) {
    this.modalService.close(id);
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  checkBoxFull() {
    /*let response = this.gridconformitydeclarationtaxes.GetCheckLine();
    response.type=2//0=none 1=Imposto 2 = Não imposto
    this.eventCheckBoxFullClick.emit(response);*/
    if (this.ConformityDeclarationTaxes.length > 0) {
      let response = this.gridconformitydeclarationtaxes.GetCheckLine();
      if (response.blnFullCheckBox) {
        this.ConformityDeclarationTaxesGridColumns[0].checkbox.checked = response.blnFullCheckBox;
        for (var i = 0; i <= this.ConformityDeclarationTaxes.length - 1; i++) {
          this.ConformityDeclarationTaxes[i].checkbox.checked = true;
        }
      }
      else {
        for (var i = 0; i <= response.listCheckBox.length - 1; i++) {
          this.ConformityDeclarationTaxes[i].checkbox.checked = response.listCheckBox[i];
        }
      }
    }
    let dataSourceLocal = this.ConformityDeclarationTaxes;
    let GridColumnsLocal = this.ConformityDeclarationTaxesGridColumns;
    this.ConformityDeclarationTaxes = [];
    this.ConformityDeclarationTaxesGridColumns = [];
    this.gridconformitydeclarationtaxes.refreshDateSource(this.ConformityDeclarationTaxes, this.ConformityDeclarationTaxesGridColumns);
    this.ConformityDeclarationTaxes = dataSourceLocal;
    this.ConformityDeclarationTaxesGridColumns = GridColumnsLocal;
    this.gridconformitydeclarationtaxes.refreshDateSource(this.ConformityDeclarationTaxes, this.ConformityDeclarationTaxesGridColumns);
  }

  GetCheckBoxSelected(): any {
    return this.gridconformitydeclarationtaxes.GetCheckLine();
  }
}
