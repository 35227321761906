export interface InsightsRQ {
    dateIn: Date,
    dateOut: Date,
    typeDocument?: number,//3 = NF-e , 4 = NFC-e e 5 = CF-e
    CNPJ: string[],
    StatusInsight: StatusInsightType
}

export enum StatusInsightType {
    Pendente,
    Aprovado,
    Recusado
}
