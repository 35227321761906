import { Component, OnInit, ViewChild } from '@angular/core';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { CreateGridAction, CreateNewGridColumn, CreateNewIcon } from 'src/app/shared/grid/grid.helper';

@Component({
	selector: 'app-products-and-services',
	templateUrl: './products-and-services.component.html',
	styleUrls: ['./products-and-services.component.scss']
})
export class ProductsAndServicesComponent implements OnInit {


	@ViewChild('productGrid') productGrid: GridComponent;

	public headers: GridColumn[] = [];
	public dataset: any[] = [
		{
			cnpjsupplier: '1',
			cnpjsupplier2: '1',
			cnpjsupplier3: '1',
			cnpjsupplier4: '1',
			cnpjsupplier5: '1',
			cnpjsupplier6: '1',
			actions: [
				CreateGridAction('eye', "OpenClicked", CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes')
			]
		}
	];
	private icons: any = [];

	constructor() { }

	ngOnInit() {
		this.loadTableHeaders();
	}

	public loadTableHeaders() {
		console.log(this.dataset);
		this.headers.push(CreateNewGridColumn(300, 'cnpjsupplier', 'Nro. item', (element: any) => `${element.cnpjsupplier}`, true))
		this.headers.push(CreateNewGridColumn(500, 'tax_auditor2', 'Descricao', (element: any) => `${element.cnpjsupplier}`, true))
		this.headers.push(CreateNewGridColumn(200, 'tax_auditor3', 'CFOP', (element: any) => `${element.cnpjsupplier3}`, true))
		this.headers.push(CreateNewGridColumn(200, 'tax_auditor4', 'Qtd.', (element: any) => `${element.cnpjsupplier4}`, true))
		this.headers.push(CreateNewGridColumn(300, 'tax_auditor5', 'Valor Unitário', (element: any) => `${element.cnpjsupplier5}`, true))
		this.headers.push(CreateNewGridColumn(300, 'tax_auditor6', 'Valor Total', (element: any) => `${element.cnpjsupplier6}`, true))
		this.headers.push(CreateNewGridColumn(80, 'actions', '', (element: any) => `${element.actions}`, true))
		console.log(this.dataset, this.headers)
		this.productGrid.refreshDateSource(this.dataset, this.headers);

	}

	private getIconTooltip(value: string): string {
		if (this.icons) {
			let icon = this.icons.find(i => i.name == value);
			if (icon)
				return icon.tooltip;
		}

		return '';
	}

	private GetIconModel(_name: string): any {
		if (this.icons) {
			let icon = this.icons.find(i => i.name == _name);
			if (icon)
				return icon;
		}

		return '';
	}

}
