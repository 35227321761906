import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { DocumentDetails } from '../DTO/sales.model';

@Component({
  selector: 'app-saleemitter',
  templateUrl: './saleemitter.component.html',
  styleUrls: ['./saleemitter.component.scss']
})
export class SaleEmitterComponent implements OnInit {

  @Input() dataSource: DocumentDetails;
  @Output() eventRefreshFinish = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  refreshDateSource(_dataSource: DocumentDetails) {
    this.dataSource = _dataSource;
    this.FinishDataClicked();
  }
  FinishDataClicked(): void {
    this.eventRefreshFinish.emit();
  }
}