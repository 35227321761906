import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConformityTaxesModel, ConformityFileExtractionData, TypeDocument, ConformityReportsExtractionData } from './DTO/conformity.model';
import { DatePipe } from '@angular/common';
import { MatIconRegistry } from '@angular/material';
import { IconGrid } from '../shared/grid/DTO/icongrid.model';
import { DomSanitizer } from "@angular/platform-browser";
import { CalendarEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { MonthCalendarComponent } from '../shared/calendar/month/month-calendar.component';
import { startOfDay, subDays, addDays } from 'date-fns';
import { ConformityService } from './conformity.service';
import { GetCalendarsEventsRQ } from './DTO/GetCalendarsEventsRQ.model';
import { DashBoardCalendarRQ } from '../modelstransport/dashboardcalendarRQ';
import { GetCalendarsEventsRS, CalendarEventModel } from './DTO/GetCalendarsEventsRS.model';
import { InputToggleModel } from '../shared/input-toggle/DTO/input-toggle.model';
import { ModalService } from '../shared/modal/modal.service';
import { ConformityTaxGuideComponent } from './conformitytaxguide/conformitytaxguide.component';
import { GetTaxCalendarsEventsRQ } from './DTO/GetTaxCalendarsEventsRQ.model';
import { ConformityFilterComponent } from './conformityfilter/conformityfilter.component';
import { ConformityDeclarationTaxComponent } from './conformitydeclarationtax/conformitydeclarationtax.component';
import { LabelPositionEnum } from '../shared/grid/DTO/checkboxColumn.model';
import { Validators, FormControl } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { Role } from '../_models/enums';
import { SendFileTransEventsRQ } from './DTO/SendFileTransEventsRQ';
import { GetFileExtractionRQ } from './DTO/GetFileExtractionRQ';
import { ConformityFileExtractionComponent } from './conformityfileextraction/conformityfileextraction.component';
import { SetRequestFileExtractionRQ } from './DTO/SetRequestFileExtractionRQ';
import { CreateNewIcon, CreateGridAction, CreateNewCheckBox, CreateLabel } from '../shared/grid/grid.helper';
import { CalculoDASComponent } from './calculo-das/calculo-das.component';
import { MonthList, MonthsModel, GetDataForFileName } from '../_helpers/dateTime.helper';
import { DomeDictionary } from '../dictionary';
import { UserActionRQ } from '../common/logger/DTO/UserActionRQ';
import { ConformityExtractReportsComponent } from './conformityextractreports/conformityextractreports.component';
import { RequestReportRQ } from './DTO/RequestReport';

@Component({
	selector: 'app-conformity',
	templateUrl: './conformity.component.html',
	styleUrls: ['./conformity.component.scss']
})

export class ConformityComponent implements OnInit {
	enabledStatusPaymentTaxGuide: boolean = true;
	enabledBtnSendEmailTaxGuide: boolean = false;
	enabledBtnExportTaxGuide: boolean = false;
	enabledStatusPaymentDeclarationTax: boolean = false;
	enabledBtnSendEmailDeclarationTax: boolean = true;
	enabledBtnExportDeclarationTax: boolean = true;
	downloadFileTransm: boolean = false
	typeTab: number = 0
	declarationView: string = 'month';
	hideTaxGrid: boolean = true;
	LoggedUser: number;
	statusProcessamentoProcessado: number = 2;
	ConformityTaxes: ConformityTaxesModel[] = [];
	ConformityNoTaxes: ConformityTaxesModel[] = [];

	ConformityFileExtractionData: ConformityFileExtractionData[];

	viewDate: Date = subDays(startOfDay(new Date()), 1);

	events: CalendarEvent[];
	eventsFull: CalendarEvent[];
	calendarsFull: GetCalendarsEventsRS;

	dashboardRedirect: boolean = false;

	selectedCalendarDay: Date = new Date();
	hasEventsToday: boolean;
	nextCalendarEvent: any;

	refresh: Subject<any> = new Subject();

	icons: IconGrid[] = [];
	@ViewChild('taxguide') taxguide: ConformityTaxGuideComponent;
	@ViewChild('declarationtax') declarationtax: ConformityDeclarationTaxComponent;

	@ViewChild('calendarEvents') calendarEvents: MonthCalendarComponent;

	@ViewChild('dwnldLnk') dwnldLnk: ElementRef;
	@ViewChild('textFaixa') textFaixa: ElementRef;
	@ViewChild('textFaixaICMS') textFaixaICMS: ElementRef;
	@ViewChild('filtertaxguide') filtertaxguide: ConformityFilterComponent;
	@ViewChild('dascalculation') dascalculation: CalculoDASComponent;
	@ViewChild('filterdeclaration') filterdeclaration: ConformityFilterComponent;
	@ViewChild('filterfilextraction') filterfilextraction: ConformityFilterComponent;
	@ViewChild('filterextractreports') filterextractreports: ConformityFilterComponent;

	@ViewChild('filextraction') filextraction: ConformityFileExtractionComponent;
	@ViewChild('extractreports') extractreports: ConformityExtractReportsComponent;

	public requestReportPayload: RequestReportRQ = this.resetRequestPayload();

	eventIdModal: string;

	TypeFlow: any[] = [

		{
			label: "Xml de Entrada",
			value: "0",
			select: false,
		},
		{
			label: "Xml de Saída",
			value: "1",
			select: true
		}
	];

	CompentencyMonths: MonthsModel[] = MonthList;

	emailFormControl: any;
	reportNameControl: any;
	mathContent: string;

	DashBoardCalendarParameter: DashBoardCalendarRQ;

	classTitleToogle: string = "titleInputToogle";
	tooltipDownload: string = 'Download do arquivo';
	tooltipHelp: string = 'Entenda o cálculo';
	isLoggedInDomeCloud: string = '';

	constructor(private datePipe: DatePipe,
		private matIconRegistry: MatIconRegistry,
		private ngxLoader: NgxUiLoaderService,
		private domSanitizer: DomSanitizer,
		private conformityService: ConformityService,
		private toastr: ToastrService,
		private modalService: ModalService,
		private loginService: LoginService) {
		this.matIconRegistry.addSvgIcon("help", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/help.svg"));
		this.matIconRegistry.addSvgIcon("PayEnabled", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/circleenabled_green.svg"));
		this.matIconRegistry.addSvgIcon("DocumentExtract", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/document-details.svg"));
		this.matIconRegistry.addSvgIcon("download", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/download.svg"));
	}

	ngOnInit() {
		this.loginService.CheckRolebyUser(Role.CALENDARIO);
		this.LoggedUser = this.loginService.user.idPerfil;
		this.isLoggedInDomeCloud = this.loginService.user.accessTokenDomeCloud;
		if (sessionStorage.DashBoardCalendarParameter) {
			this.DashBoardCalendarParameter = <DashBoardCalendarRQ>JSON.parse(sessionStorage.DashBoardCalendarParameter);
			this.viewDate = new Date(this.DashBoardCalendarParameter.viewDate);
			sessionStorage.removeItem("DashBoardCalendarParameter");
			this.filterType(0)
			this.dashboardRedirect = true;
		}
		else {
			this.filterType(0);
		}

		this.emailFormControl = new FormControl('', [
			Validators.required,
			Validators.email,
		]);

		this.reportNameControl = new FormControl('');
	}

	GetCalendarsEvents(_date: Date = null): CalendarEvent[] {
		this.StartDataClicked();
		if (_date != null) {
			this.viewDate = _date;
		}
		let dateIn = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
		let dateOut = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
		var request: GetCalendarsEventsRQ = {
			datein: dateIn,
			dateout: dateOut,
			CNPJ: []
		};

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });
		this.conformityService.GetCalendarsEvents(request)
			.subscribe(data => {
				this.calendarsFull = null;
				this.events = [];
				this.eventsFull = [];
				this.calendarsFull = data;
				data.calendarsEvents.forEach(data => {
					data.textToogle = this.GetTextTooglebyTypeTax(data.tipoDocumento);
					var response = this.GetTypeEvent(data)
					if (response != null) {
						data.class_color = response.class_color;
						var event: CalendarEvent = {
							id: data.id,
							title: data.tipoDocumento,//this.GetTitleEvent(data.tipoDocumento),
							start: subDays(startOfDay(data.dataOcorrencia), 0),
							meta: {
								message: data.fatoGerador,
								status: response.status
							},
							color: {
								primary: response.primaryColor,
								secondary: response.secondaryColor,
							},
						};
						this.events.push(event);
						this.eventsFull.push(event);
					}
				})

				this.hasEventsToday = this.checkTodaysEvents();
				this.nextCalendarEvent = this.checkNextEvent();

				if (this.dashboardRedirect) {
					this.dayClicked(this.viewDate);
					this.dashboardRedirect = true;
				}
			},
				err => {
					this.RefreshFinishClicked();
				},
				() => {
					this.calendarEvents.refreshEvents(this.events);
					this.RefreshFinishClicked();
					return this.events;
				}
			);

		return this.events;
	}

	GetFileExtrations(): void {
		let param: any;
		param = this.filterfilextraction.GetFilterData()

		var request: GetFileExtractionRQ = {
			year: param.dateIn.getFullYear(),
			cnpj: '',
		};

		request.cnpj = this.loginService.getCnpj();
		this.conformityService.GetFileExtractions(request).subscribe(
			data => {
				this.ConformityFileExtractionData = [];
				if (data.extractions) {
					data.extractions.forEach(dado => {
						let extraction: ConformityFileExtractionData = {
							flow: this.GetTipoFlowByTipoDocumento(dado.tipoNota).name + " - " + this.GetMonth(dado.month) + "/" + dado.year,
							tipoDocumento: this.GetTipoDocumento(dado.tipoNota),
							url: dado.url,
							actions: []
						}
						extraction.actions.push(CreateGridAction('download', "DownloadClicked", CreateNewIcon('download', ''), 'icon-lx-arrow-circle-down mat-icon icon-design action-icon' + (dado.url === null || dado.url === '' ? " bg-yellow" : " bg-green"), 'Download'));
						this.ConformityFileExtractionData.push(extraction);
					});
				}
			},
			err => {
				this.RefreshFinishClicked();
			},
			() => {
				this.filextraction.refreshDataSource(this.ConformityFileExtractionData);
				this.RefreshFinishClicked();
			});
	}

	requestReport() {
		this.requestReportPayload.codigoContribuinte = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf })[0]
		this.requestReportPayload.codigoEmpresa = this.requestReportPayload.codigoContribuinte.split('0001')[0];
		this.requestReportPayload.resumo = (this.requestReportPayload.resumo === 'true');
		this.requestReportPayload.tipoRelatorio = parseInt(this.requestReportPayload.tipoRelatorio as string);
		this.requestReportPayload.formato = parseInt(this.requestReportPayload.formato as string);
		this.ngxLoader.start();
		this.conformityService.RequestReport(this.requestReportPayload).subscribe(data => {
			this.filterType(4);
			this.modalService.close('OpenReportsModal');
			this.requestReportPayload = this.resetRequestPayload();
			this.toastr.success('Relatório solicitado com sucesso!', 'Sucesso!');
			this.ngxLoader.stop();
		}, error => {
			this.toastr.error('Não foi possível solicitar o relatório, verifique seus dados e tente novamente', 'Erro!');
			this.ngxLoader.stop();
		});
	}

	resetRequestPayload() {
		return {
			id: "",
			tipoRelatorio: 0,
			dataInicial: "",
			dataFinal: "",
			codigoCFOP: null,
			numeroDocumento: null,
			resumo: "",
			folha: null,
			numeroLivro: 1,
			paginaInicial: null,
			paginaFinal: null,
			periodoEscrituracao: "",
			codigoEmpresa: "",
			codigoContribuinte: "",
			formato: 1
		}
	}

	GetTaxCalendarsEvents(_typetax: any): void {
		let param: any;
		if (_typetax - 1 == 0) {
			param = this.filtertaxguide.GetFilterData();
		}
		else {
			param = this.filterdeclaration.GetFilterData();
		}

		var request: GetTaxCalendarsEventsRQ = {
			datein: param.dateIn,
			dateout: param.dateOut,
			CNPJ: [],
			statusPayment: param.statusPayment,
			taxType: _typetax - 1,
		};

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });

		this.ConformityTaxes = [];
		this.ConformityNoTaxes = [];
		this.conformityService.GetTaxCalendarsEvents(request).subscribe(
			data => {
				var _index: Number = 0;
				data.calendarsEvents.forEach(data => {
					data.textToogle = this.GetTextTooglebyTypeTax(data.tipoDocumento);
					var response = this.GetTypeEvent(data)
					if (response != null) {
						data.class_color = response.class_color;
						let isDas = data.tipoDocumento.toLocaleUpperCase() === "DAS";

						if (data.tipoDocumento.toLocaleUpperCase() !== "ICMS" || !isDas) {
							var tax: ConformityTaxesModel = {
								id: data.id,
								data_vencimento: this.datePipe.transform(data.dataOcorrencia, "dd/MM/yyyy"),
								imposto: data.tipoDocumento,
								idDocumento: data.idDocumento,
								dataOcorrencia: data.dataOcorrencia,
								tipoDownload: 0,
								index: _index,
								tipoDocumento: data.tipoDocumento,
								documentoBase64: data.documentoBase64,
								documentoPendente: data.documentoPendente,
								extratoBase64: data.extratoBase64,
								memoriaDeCalculoICMS: data.memoriaDeCalculoICMS,
								memoriaDeCalculoDAS: data.memoriaDeCalculoDAS,
								status_pagamento: (!data.pago ? CreateLabel(this.GetPaymentText(data), " label-color-red") : CreateLabel(this.GetPaymentText(data), "")),
								formatoDocumento: data.formatoDocumento,
								valor: (data.tipoDocumento.toLocaleUpperCase() !== "ICMS" && data.memoriaDeCalculoICMS != null ? data.memoriaDeCalculoICMS.valorTotalImposto.toFixed(2) : (data.tipoDocumento.toLocaleUpperCase() !== "DAS" && data.memoriaDeCalculoDAS != null ? data.memoriaDeCalculoDAS.valorTotalImpostos.toFixed(2) : "0,00")),
								actions: [],
								checkbox: CreateNewCheckBox(false, false, LabelPositionEnum.after, false, 'text')
							}

							if (isDas) {
								tax.actions.push(CreateGridAction('documentExtract', "AcceptClicked", CreateNewIcon('documentExtract', ''), 'icon-lx-files mat-icon icon-design action-icon', 'Download Extrato DAS'));
							}

							if (data.documentoBase64 != null && data.documentoBase64 != "") {
								tax.actions.push(CreateGridAction('download', "DownloadClicked", CreateNewIcon('download', ''), 'icon-lx-arrow-circle-down mat-icon icon-design action-icon', 'Download'));
							}
							else if (data.documentoPendente) {
								tax.actions.push(CreateGridAction('download', "DownloadClicked", CreateNewIcon('download', ''), 'icon-lx-arrow-circle-down mat-icon icon-design action-icon', 'Solicitação em andamento.'));
							}
							else if (isDas) {
								tax.actions.push(CreateGridAction('generate-das', "RejeitClicked", CreateNewIcon('generate-das', ''), 'icon-lx-file-pdf mat-icon icon-design action-icon', 'Gerar DAS'));
							}

							if ((data.memoriaDeCalculoICMS != null || data.memoriaDeCalculoDAS != null) && !this.hideHelpButton()) {
								tax.actions.push(CreateGridAction('mathematics', "OpenClicked", CreateNewIcon('mathematics', ''), 'icon-lx-calculator mat-icon icon-design action-icon', 'Entenda o cálculo'));
							}
							else {
								if ((data.tipoDocumento == "SINTEGRA" || data.tipoDocumento == "DESTDA") && !this.hideHelpButton()) {
									tax.actions.push(CreateGridAction('mathematics', "AcceptClicked", CreateNewIcon('mathematics', ''), 'icon-lx-calculator mat-icon icon-design action-icon', 'O que é ' + data.tipoDocumento));
								}
							}

							if (_typetax - 1 == 0) {
								this.ConformityTaxes.push(tax);
							}
							else {
								this.ConformityNoTaxes.push(tax);
							}
							_index = Number(_index) + 1;
						}
					}
				})
			},
			err => {
				this.RefreshFinishClicked();
			},
			() => {
				if (this.taxguide) {
					this.taxguide.refreshDataSource(this.ConformityTaxes);
				}
				this.declarationtax.refreshDataSource(this.ConformityNoTaxes);
				this.RefreshFinishClicked();
			}
		);
	}

	GetMonth(month: string): string {
		let response = this.CompentencyMonths.filter(x => x.value == month);
		return response[0].label;
	}

	GetTipoDocumento(type: number): string {
		switch (type) {
			case TypeDocument.Todos_Entrada: {
				return "Todos";
			}
			case TypeDocument.Todos_Saida: {
				return "Todos";
			}
			case TypeDocument.NFe_Entrada: {
				return "NF-e";
			}
			case TypeDocument.NFe_Saida: {
				return "NF-e";
			}
			case TypeDocument.CFe_Saida: {
				return "CF-e";
			}
			case TypeDocument.NFCe_Saida: {
				return "NFC-e";
			}
		}
	}

	GetTipoFlowByTipoDocumento(type: number): any {
		if (type == TypeDocument.NFe_Entrada || type == TypeDocument.Todos_Entrada) {
			return {
				name: "Xml de Entrada",
				code: 0
			};
		}
		else {
			return {
				name: "Xml de Saída",
				code: 1
			};
		}
	}

	GetPaymentText(item: any): string {
		if (item.pago)
			return "Realizado";
		else
			return "Não realizado";
	}

	GetTextTooglebyTypeTax(typeDoc: string): string {
		if (typeDoc.toUpperCase() === "SINTEGRA" || typeDoc.toUpperCase() === "DESTDA") {
			return "Já transmiti essa obrigação";
		}
		else
			return "Já paguei essa guia";
	}

	GetTypeEvent(item: any) {
		if (item.pago || item.statusEvento === "0")
			return { class_color: 'success', primaryColor: '#91AA5F', secondaryColor: '#cce5ff', status: 'finished' };

		if (new Date(item.dataOcorrencia) <= new Date())
			return { class_color: 'danger', primaryColor: '#F0462D', secondaryColor: '#f8d7da', status: 'pending' };

		return { class_color: 'pendent', primaryColor: '#7c7c7c', secondaryColor: '#7c7c7c', status: 'finished' };
	}

	GetTitleEvent(tipoDocumento: string): string {
		switch (tipoDocumento) {
			case 'ICMS_AVULSO':
				{
					return 'ICMS gerado de forma Avulsa';
				}
			case 'DAS':
				{
					return 'Documento de Arrecadação do Simples nacional';
				}
			case 'ICMS':
				{
					return 'Imposto sobre Circulação de Mercadorias e Serviços';
				}
			case 'ICMS_ST':
				{
					return 'ICMS com Substituição Tributária';
				}
			case 'SINTEGRA':
				{
					return 'Sistema Integrado de Informações sobre Operações Interestaduais com Mercadorias e Serviços';
				}
			case 'DESTDA':
				{
					return 'Declaração de Substituição Tributária';
				}
		}
	}

	displayTaxGrid() {
		this.hideTaxGrid = !this.hideTaxGrid;
	}

	setDeclarationVIew(op: string) {
		this.declarationView = op;
	}

	checkTodaysEvents() {
		return (this.eventsFull != undefined ? this.eventsFull.filter(function (n) {
			return n.start.toLocaleString().slice(0, 10) == this.selectedCalendarDay.toLocaleString().slice(0, 10);
		}, this).length > 0 : false);
	}

	checkNextEvent() {
		var nextEvents = (this.eventsFull != undefined ? this.eventsFull
			.sort(
				(a, b) => { return <any>new Date(a.start) - <any>new Date(b.start) }
			)
			.filter(function (n) {
				return n.start >= addDays(this.selectedCalendarDay, 1);
			}, this) : []);

		return nextEvents.length > 0 ? nextEvents[0].start : null;
	}

	dayClicked(date: Date): void {
		this.StartDataClicked();
		this.selectedCalendarDay = date;
		this.events = (this.eventsFull != undefined ? this.eventsFull.filter(function (n) {
			return n.start.getDate() == this.selectedCalendarDay.getDate();
		}, this) : []);
		this.nextCalendarEvent = this.checkNextEvent();
		this.hasEventsToday = this.checkTodaysEvents();
		this.RefreshFinishClicked();
	}

	OpenDeclarationCalcClicked() {
		document.getElementById("openDeclarationCalcModalBtn").click();
	}

	StartDataClicked(): void {
		this.ngxLoader.start();
	}

	RefreshFinishClicked(): void {
		this.ngxLoader.stop();
	}

	getEventItem(id): CalendarEventModel {
		if (!this.calendarsFull || !this.calendarsFull.calendarsEvents) {
			return null;
		}

		let item = this.calendarsFull.calendarsEvents.filter(p => p.id == id)[0];
		return item;
	}

	downloadFile(id: string, typeDownload: number) {
		const item = this.getEventItem(id);
		const dataFileName = GetDataForFileName(item.dataOcorrencia);

		if (typeDownload == 0)//0=Download Guia(ICMS/DAS) e 1 Help Download de documento (Sintegra e DesTDA) e 2 Extrato DAS
		{
			if (item.formatoDocumento == 'PDF' || atob(item.documentoBase64).split('-')[0] == '%PDF') {
				this.dwnldLnk.nativeElement.href = "data:application/pdf;base64," + item.documentoBase64;
				this.dwnldLnk.nativeElement.download = item.tipoDocumento + '_' + item.idDocumento + '_' + dataFileName + '.pdf';
			}
			else {
				if (item.formatoDocumento == 'TEXT') {
					this.dwnldLnk.nativeElement.href = "data:application/text;base64," + item.documentoBase64;
					this.dwnldLnk.nativeElement.download = item.idDocumento + '.txt';
				}
			}
		}
		else if (typeDownload == 2) {
			this.dwnldLnk.nativeElement.href = "data:application/pdf;base64," + item.extratoBase64;
			this.dwnldLnk.nativeElement.download = 'ExtratoDAS_' + item.idDocumento + '_' + dataFileName + '.pdf';
		}
		else {
			if (item.tipoDocumento == "SINTEGRA") {
				this.dwnldLnk.nativeElement.href = "../assets/file/pdf/HELP_SINTEGRA.pdf";
				this.dwnldLnk.nativeElement.download = 'HelpSintegra_' + item.idDocumento + '_' + dataFileName + '.pdf';
			}
			else {
				if (item.tipoDocumento == "DESTDA") {
					this.dwnldLnk.nativeElement.href = "../assets/file/pdf/HELP_DESTDA.pdf";
					this.dwnldLnk.nativeElement.download = 'HelpDesTDA_' + item.idDocumento + '_' + dataFileName + '.pdf';
				}
				else if (item.tipoDocumento == "ICMS" || item.tipoDocumento == "ICMS_AVULSO" || item.tipoDocumento == "ICMS_ST" || item.tipoDocumento == "ICMS_ST_AVULSO") {
					this.dwnldLnk.nativeElement.href = "../assets/file/pdf/HELP_ICMS.pdf";
					this.dwnldLnk.nativeElement.download = 'HelpICMS_' + item.idDocumento + '_' + dataFileName + '.pdf';
				}
			}
		}

		var logUserActionRQ: UserActionRQ = { action: "" };

		if (typeDownload == 2 && item.extratoBase64 &&
			item.extratoBase64 != "" &&
			item.extratoBase64 != null) {
			this.toastr.info(this.GetTypeMessage(item.tipoDocumento, 1), "");
			logUserActionRQ.action = "Download de Extrato DAS."
			this.conformityService.SetLogUserAction(logUserActionRQ);
			this.dwnldLnk.nativeElement.click();
		}
		else if (item.documentoBase64 &&
			item.documentoBase64 != "" &&
			item.documentoBase64 != null) {
			this.toastr.info(this.GetTypeMessage(item.tipoDocumento, 1), "");
			logUserActionRQ.action = `Download de documento ${item.tipoDocumento}.`;
			this.conformityService.SetLogUserAction(logUserActionRQ);
			this.dwnldLnk.nativeElement.click();
		}
		else {
			if (typeDownload == 1 && (item.tipoDocumento == "SINTEGRA" || item.tipoDocumento == "DESTDA")) {
				this.toastr.info(this.GetTypeMessage(item.tipoDocumento, 1), "");
				this.dwnldLnk.nativeElement.click();
			}
			else {
				this.dwnldLnk.nativeElement.href = "";
				this.dwnldLnk.nativeElement.download = "";
				this.toastr.info(DomeDictionary.ArquivoIndisponivel, "");
			}
		}
	}

	downloadFileTrans(index: number) {
		if (this.ConformityNoTaxes[index].formatoDocumento == 'PDF' || atob(this.ConformityNoTaxes[index].documentoBase64).split('-')[0] == '%PDF') {
			this.dwnldLnk.nativeElement.href = "data:application/pdf;base64," + this.ConformityNoTaxes[index].documentoBase64;
			this.dwnldLnk.nativeElement.download = this.ConformityNoTaxes[index].tipoDocumento + '_' + this.ConformityNoTaxes[index].idDocumento + '_' + new Date(this.ConformityNoTaxes[index].dataOcorrencia).toLocaleDateString('pt-BR').replace('/', '_').replace('/', '_').replace('/', '_') + '.pdf';
		}
		else {
			if (this.ConformityNoTaxes[index].formatoDocumento == 'TEXT') {
				this.dwnldLnk.nativeElement.href = "data:application/text;base64," + this.ConformityNoTaxes[index].documentoBase64;
				this.dwnldLnk.nativeElement.download = this.ConformityNoTaxes[index].idDocumento + '.txt';
			}
		}

		if (this.ConformityNoTaxes[index].formatoDocumento != null && this.ConformityNoTaxes[index].documentoBase64 &&
			this.ConformityNoTaxes[index].documentoBase64 != "" &&
			this.ConformityNoTaxes[index].documentoBase64 != null) {
			this.downloadFileTransm = true;
			this.dwnldLnk.nativeElement.click();
		}
		else {
			this.dwnldLnk.nativeElement.href = "";
			this.dwnldLnk.nativeElement.download = "";
		}
	}

	exportFile(): void {
		this.downloadFileTransm = false;
		let response = this.declarationtax.GetCheckBoxSelected();
		if (response.blnFullCheckBox) {
			for (var index = 0; index <= this.ConformityNoTaxes.length - 1; index++) {
				this.downloadFileTrans(index);
			}
		}
		else {
			for (var index = 0; index <= response.listCheckBox.length - 1; index++) {
				if (response.listCheckBox[index]) {
					this.downloadFileTrans(index);
				}
			}
		}

		if (this.ConformityNoTaxes.length > 0 && this.downloadFileTransm)
			this.toastr.info(this.GetTypeMessage(this.ConformityNoTaxes[0].tipoDocumento, 1), "");
		else
			this.toastr.info(DomeDictionary.ArquivoIndisponivel, "");
	}

	SetPayment(p: InputToggleModel) {
		(async () => {
			let request = this.calendarsFull.calendarsEvents[p.index];
			request.pago = p.checked;
			request.class_color = ((request.pago && new Date(request.dataOcorrencia) <= new Date()) || (request.pago && new Date(request.dataOcorrencia) >= new Date()) ? " success" : (!request.pago && new Date(request.dataOcorrencia) >= new Date() ? "pendent" : "danger"));
			this.conformityService.SetCalendarEvent(request)
				.subscribe(data => {
					this.toastr.info(this.GetTypeMessage(this.calendarsFull.calendarsEvents[p.index].tipoDocumento, 0), "");
				});
			let logUserActionRQ: UserActionRQ = { action: "" };
			let mensagem = request.pago ? "Afirmação" : "Cancelamento de afirmação";
			if (request.tipoDocumento == "DAS") {
				logUserActionRQ.action = `${mensagem} de pagamento da Guia DAS`;
			}
			else {
				logUserActionRQ.action = `${mensagem} de transmissão da obrigação ${request.tipoDocumento}`;
			}
			this.conformityService.SetLogUserAction(logUserActionRQ);
			await this.delay(1500);
			this.GetCalendarsEvents();
		})();
	}

	GetTypeMessage(tipoDocumento: string, type: number): string //type=0 Pagamento , 1= Download
	{
		if (type == 0) {
			switch (tipoDocumento.toUpperCase()) {
				case 'ICMS_AVULSO':
					{
						return DomeDictionary.ConfirmacaoPagamento;
					}
				case 'DAS':
					{
						return DomeDictionary.ConfirmacaoPagamento;
					}
				case 'ICMS':
					{
						return DomeDictionary.ConfirmacaoPagamento;
					}
				case 'ICMS_ST':
					{
						return DomeDictionary.ConfirmacaoPagamento;
					}
				case 'SINTEGRA':
					{
						return DomeDictionary.ConfirmacaoTransmissao;
					}
				case 'DESTDA':
					{
						return DomeDictionary.ConfirmacaoTransmissao;
					}
			}
		}
		else
			return DomeDictionary.DownloadRealizado;
	}

	downloadPDFICMS() {
		this.StartDataClicked();
		this.downloadFile(this.eventIdModal, 1);
		this.toastr.info(this.GetTypeMessage(this.calendarsFull.calendarsEvents[this.eventIdModal].tipoDocumento, 1), "");
		this.RefreshFinishClicked();
	}
	private GetType(type: string): string {
		let response: string = "";
		if (type == undefined) {
			return "";
		}

		switch (type) {
			case "ST":
				response = "Substituição Tributária";
				break;
			case "DA":
				response = "Diferencial de Alíquotas";
				break;
			default:
				response = "";
				break;
		}
		return response;
	}

	private GetTypeOperacao(operacao: string): string {
		let response: string = "";
		if (operacao == undefined) {
			return "";
		}

		switch (operacao) {
			case "COMPRA":
				response = "Compra";
				break;
			case "VENDA":
				response = "Venda";
				break;
			default:
				response = "";
				break;
		}
		return response;
	}

	private GetSubType(sub: string): string {
		let response: string = "";
		if (sub == undefined) {
			return "";
		}
		switch (sub) {
			case "ESTOQUE":
				response = "Estoque";
				break;
			case "ATIVO":
				response = "Ativo";
				break;
			case "ANTECIPACAO":
				response = "Antecipação";
				break;
			case "USO_CONSUMO":
				response = "Uso e Consumo";
				break;
			default:
				response = "";
				break;
		}
		return response;
	}

	private delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	private openModal(id: string) {
		this.modalService.open(id);
	}

	public closeModal(id: string) {
		this.modalService.close(id);
	}

	filterType(_type: any): void {//0=Aba calendario,1= Aba Guia de Impostos
		this.StartDataClicked();

		this.typeTab = (_type.index != undefined ? _type.index : _type);

		if (this.typeTab == 0) {
			this.events = this.GetCalendarsEvents();
		}
		else if (this.typeTab == 1) {
			this.GetDocuments('simples');
		} else if (this.typeTab == 2) {
			this.GetDocuments('sintegra');
		}
		else if (this.typeTab == 3 && this.isLoggedInDomeCloud) {
			this.GetReports();
		}
	}

	GetDocuments(type) {
		this.StartDataClicked();
		const dateFilter = this.ExtractDateString(type === 'simples' ? this.filtertaxguide.GetFilterData().dateIn : this.filterfilextraction.GetFilterData().dateIn);
		this.conformityService.getDocuments(type, [dateFilter]).subscribe((data: any) => {
			const registros = data.registro.map((document: any) => {
				document = {
					tipo: type === 'simples' ? 'Apuração Simples Nacional' : 'Sintegra',
					nomeDocumento: document.nomeArquivo,
					extensao: document.extensao,
					actions: [
						CreateGridAction('download', "DownloadClicked", CreateNewIcon('download', ''), 'icon-lx-arrow-circle-down mat-icon icon-design action-icon bg-green', 'Download')
					]
				}
				return document;
			});

			if (type === 'simples') {
				this.taxguide.refreshDataSource(registros);
			} else {
				this.filextraction.refreshDataSource(registros);
			}

			this.RefreshFinishClicked();
		});
		// this.conformityService.getDocuments(type, []).subscribe();
	}

	ExtractDateString(date) {
		return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
	}

	hasRole(role) {
		return this.loginService.hasRole(role)
	}

	GetReports() {
		this.StartDataClicked();
		// TODO: Fetch API Data for reports extractions
		this.conformityService.ListReports({
			CodigoContribuinte: this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf })[0],
			LimitePorPagina: 50,
			Pagina: 1
		}).toPromise().then((res: any) => {
			console.log(res);
			const report: ConformityReportsExtractionData = res.registros
				.sort((a, b) => a.livroFiscal.dataFinal < b.livroFiscal.dataFinal ? 1 : -1)
				.map(relatorio => {
					return {
						id: relatorio.livroFiscal.id,
						nome: `${relatorio.livroFiscal.nomeArquivo ? relatorio.livroFiscal.nomeArquivo : relatorio.livroFiscal.id}`,
						relatorio: `Livro de ${relatorio.livroFiscal.tipoRelatorio === 0 ?
							'Entrada P1' : 'Saída P2'
							}`,
						datasolicitacao: relatorio.livroFiscal.periodoEscrituracao,
						statusProcessamento: relatorio.livroFiscal.statusProcessamento,
						extension: relatorio.livroFiscal.formato == 1 ?
							'.pdf' : '.xls',
						formato: relatorio.livroFiscal.formato == 1 ?
							'application/pdf' : 'application/vnd.ms-excel',
						actions: [
							relatorio.livroFiscal.statusProcessamento && relatorio.livroFiscal.statusProcessamento == this.statusProcessamentoProcessado ?
								CreateGridAction('download', "DownloadClicked", CreateNewIcon('download', ''),
									'icon-lx-arrow-circle-down mat-icon icon-design action-icon' + " bg-green", 'Download') :
								CreateGridAction('download-block', "DownloadBlock", CreateNewIcon('download', ''),
									'icon-lx-block mat-icon icon-design action-icon' + " bg-green", 'Download bloqueado')
						]
					}
				})
			this.extractreports.refreshDataSource(report);
			this.RefreshFinishClicked();
		}).catch(err => {
			this.ngxLoader.stop();
			console.log(err);
		});
	}

	filterDate(): void {
		this.GetTaxCalendarsEvents(this.typeTab);
	}
	sendEmailTransm() {
		let response = this.declarationtax.GetCheckBoxSelected();
		if (response.blnFullCheckBox || response.listCheckBox.filter(x => x == true).length > 0)
			this.openModal("OpenDeclarationModal");
		else
			this.toastr.info(DomeDictionary.SelecioneUmaLinha, "");
	}

	sendEmailTransDestination(emailDesti: any) {
		if (emailDesti && emailDesti.value == "") {
			this.toastr.info(DomeDictionary.InformeEmailDestinatario, "");
			return;
		}

		let response = this.declarationtax.GetCheckBoxSelected();
		let request: SendFileTransEventsRQ = {
			Email: emailDesti.value,
			CalendarIds: []
		};

		if (response.blnFullCheckBox) {
			for (let i = 0; i <= this.ConformityNoTaxes.length - 1; i++) {
				request.CalendarIds.push(this.ConformityNoTaxes[i].id);
			}
		}
		else {
			for (let i = 0; i <= response.listCheckBox.length - 1; i++) {
				if (response.listCheckBox[i] === true) {
					request.CalendarIds.push(this.ConformityNoTaxes[i].id);
				}
			}
		}

		this.conformityService.SendFileTransEvents(request)
			.subscribe(data => {
				this.toastr.info(DomeDictionary.DeclaracaoFiscalEnviada, "");
				this.closeModal("OpenDeclarationModal");
			});
	}

	requestFileExtraction() {
		let param: any;

		this.filterfilextraction.filterDate();
		param = this.filterfilextraction.GetFilterData()

		var request: SetRequestFileExtractionRQ = {
			month: param.dateIn.getMonth() + 1,
			year: param.dateIn.getFullYear(),
			tipoNota: param.typeDocument,
			cnpj: '',
		};

		request.cnpj = this.loginService.getCnpj();

		this.conformityService.SetRequestFileExtractions(request).subscribe(
			data => {
				if (data.sucesso) {
					this.toastr.info(DomeDictionary.ArquivoSolicitado(this.GetMonth(request.month.toString()) + "/" + request.year), "");
					this.filterType(3);
				}
				else {
					this.toastr.info(data.mensagem.replace('{0}', this.GetMonth(request.month.toString()) + "/" + request.year), "");
				}
			},
			err => {
				this.toastr.info(DomeDictionary.ErroSolicitarArquivo, "");
				this.RefreshFinishClicked();
			},
			() => {
				this.RefreshFinishClicked();
			});
	}

	requestReportExtraction() {
		let param: any;

		this.filterfilextraction.filterDate();
		param = this.filterfilextraction.GetFilterData()
		this.openModal('OpenReportsModal')
	}

	RefreshGridFileExtractionClicked(): void {
		this.filterType(3);
	}

	RefreshGridListReportsClicked(): void {
		this.GetReports();
	}

	RefreshGridTaxGuideClicked(): void {
		this.filterType(1);
	}

	hideDownloadButton(): boolean {
		return !this.loginService.hasRole(Role.DOWNLOAD_FISCAL);
	}

	hideHelpButton(): boolean {
		return !this.loginService.hasRole(Role.MEMORIA_CALCULO);
	}

	HasRoleGuiaImposto(): boolean {
		return this.loginService.hasRole(Role.GUIA_IMPOSTO);
	}

	HasRoleDeclaracoesFiscais(): boolean {
		return this.loginService.hasRole(Role.DECLARACOES_FISCAIS);
	}

	HasRoleExtracao(): boolean {
		return this.loginService.hasRole(Role.CALENDARIO_EXTRACAO);
	}

	openDatePicker(dp) {
		dp.open();
	}

	selectRequestPeriod(requestDate: Date, dp?: any) {
		const year = requestDate.getFullYear();
		const month = requestDate.getMonth() + 1;
		this.requestReportPayload.dataInicial = requestDate.toISOString();
		this.requestReportPayload.dataFinal = new Date(requestDate.getFullYear(), requestDate.getMonth() + 1, 0).toISOString();
		this.requestReportPayload.periodoEscrituracao = (`${month}/${year}`).padStart(7, '0');
		dp.close();
	}
}
