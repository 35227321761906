import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Config } from "../config";
import { tap } from "rxjs/operators";
import {
  ChangePasswordRequest,
  ChangePasswordResponse
} from "./DTO/change-password.model";
import {
  ForgotMyPassWordRequest,
  ForgotMyPassWordResponse
} from "./DTO/forgot-my-password.model";
import { DefaultResponse } from "../_models/default-response";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private http: HttpClient) { }

  ChangePassword(
    _request: ChangePasswordRequest
  ): Observable<ChangePasswordResponse> {
    var request = {
      header: {},
      body: _request
    };

    return this.http
      .post<ChangePasswordResponse>(Config.DOMEApi.ChangePassword, request)
      .pipe(
        tap(data => {
          return data;
        })
      );
  }

  forgotMyPassword(
    _request: ForgotMyPassWordRequest
  ): Observable<ForgotMyPassWordResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      })
    };
    var request = {
      header: {},
      body: _request
    };

    return this.http
      .post<ForgotMyPassWordResponse>(
        Config.DOMEApi.ForgotMyPassword,
        request,
        httpOptions
      )
      .pipe(
        tap(data => {
          return data;
        })
      );
  }

  GetUsers(_request: any): Observable<any> {
    var request = {
      header: {},
      body: _request
    };

    return this.http.post<any>(Config.DOMEApi.GetUsers, request).pipe(
      tap(data => {
        return data;
      })
    );
  }

  GetVerticals(): Observable<any> {
    return this.http.get<any>(Config.DOMECloud.Verticais);
  }

  GetProductionModule(_request: any): Observable<any> {
    var request = {
      header: {},
      body: _request
    };

    return this.http
      .post<any>(Config.DOMEApi.GetProductionModule, request)
      .pipe(
        tap(data => {
          return data;
        })
      );
  }

  GetCompleteEmitterRegistration(cnpj: string): Observable<any> {
    let url = `${Config.DOMEApi.GetCompleteEmitterRegistration}?cnpj=${cnpj}`
    return this.http
      .get<any>(url)
      .pipe(
        tap(data => {
          return data;
        })
      );
  }

  PostCompleteRegistration(_request: any): Observable<DefaultResponse> {
    var request = {
      header: {},
      body: _request
    };

    return this.http.post<DefaultResponse>(
      Config.DOMEApi.CompleteEmitterRegistration,
      request
    );
  }

  ChangeEmailConfig(_request: any): Observable<DefaultResponse> {
    return this.http
      .post<DefaultResponse>(Config.DOMEApi.ChangeEmailConfig, _request);
  }

  AuthenticateDomeCloud(_request: any): Observable<DefaultResponse> {
    return this.http
      .post<DefaultResponse>(Config.DOMECloud.Authenticate, _request);
  }

  GetOptionsCalculation() {
    return this.http.get(Config.urlDOME() + 'Empresas/ConsultaCalculoBasis');
  }

  GetOptionsTax() {
    return this.http.get(Config.urlDOME() + 'Empresas/ConsultaRegimeImpostos');
  }

  GetOptionsSimples() {
    return this.http.get(Config.urlDOME() + 'Empresas/ConsultaSimplesFramework');
  }

  GetOptionsMain() {
    return this.http.get(Config.urlDOME() + 'Empresas/ConsultaAtividadesPrincipais');
  }

  GetEnterpriseData(cnpj: string) {
    return this.http.get(Config.urlDOME() + 'empresas/consultaempresa?cnpj=' + cnpj);
  }

  SaveEnterpriseData(payload) {
    return this.http.post(Config.urlDOME() + 'empresas/editarempresa', { header: {}, body: payload });
  }

}
