import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { memoriaDeCalculoDAS } from '../DTO/GetTaxCalendarsEventsRS.model';
import { ModalService } from 'src/app/shared/modal/modal.service';

@Component({
  selector: 'app-calculo-das',
  templateUrl: './calculo-das.component.html',
  styleUrls: ['./calculo-das.component.scss']
})

export class CalculoDASComponent implements OnInit {

  @Input() memoriaCalculoDAS: memoriaDeCalculoDAS;
  @Input() idModal: string;
  @ViewChild('textFaixa') textFaixa: ElementRef;

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
  }

  public openModal() {
    this.textFaixa.nativeElement.innerHTML = this.memoriaCalculoDAS.faixaFaturamento.faixa;
    this.modalService.open(this.idModal);
  }

  public closeModal() {
    this.modalService.close(this.idModal);
  }

}
