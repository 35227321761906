import { Output, Component, EventEmitter, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { GetAccountantIntegrationRQ } from '../DTO/GetAccountantIntegrationRQ.';
import { LoginService } from 'src/app/login/login.service';
import { MonthList } from 'src/app/_helpers/dateTime.helper';

@Component({
  selector: "app-accountantintegrationfilter",
  templateUrl: "./accountantintegrationfilter.component.html",
  styleUrls: ["./accountantintegrationfilter.component.scss"]
})
export class AccountantIntegrationFilterComponent implements OnInit {
  selectMonths = new FormControl();
  @ViewChild("selectMonth") selectMonth: MatSelect;
  @Input() enabledMultiple: boolean = false;
  @Input() enabledBtnSearch: boolean = true;
  @Input() dateIn = new FormControl(new Date());
  @Input() dateOut = new FormControl(new Date());
  selectedItems: any;
  strYearOld: string = (new Date().getFullYear() - 1).toString();
  strYear: string = new Date().getFullYear().toString();
  strMonths: string = (new Date().getMonth() + 1).toString();
  SelectedCompentencyYear: string = this.strYear;
  SelectedCompentencyMonth: string = this.strMonths;

  @Output() eventSearchClick = new EventEmitter();
  @Output() eventStartLoading = new EventEmitter();

  CompentencyYears: string[] = [this.strYearOld, this.strYear];

  constructor(private loginService: LoginService) {

  }

  CompentencyMonths = MonthList;

  startLoading(): void {
    this.eventStartLoading.emit();
  }

  filterDateIn = (d: Date): boolean => {
    return d <= this.dateIn.value;
  };

  filterDateFin = (d: Date): boolean => {
    return d >= this.dateOut.value;
  };

  ngOnInit() {
    if (this.enabledMultiple) {
      this.selectedItems = [];
      for (
        let i = new Date().getMonth() - 1;
        i <= new Date().getMonth() + 1;
        i++
      ) {
        this.selectedItems.push(i.toString());
      }
    } else {
      this.selectedItems = "";
      this.selectedItems = (new Date().getMonth() + 1).toString();
    }
    this.eventSearchClick.emit();
  }


  filterDate(): void {
    if (this.enabledBtnSearch) {
      this.startLoading();
    }
    if (this.enabledMultiple) {
      if (this.selectedItems.length == 1) {
        this.dateIn = new FormControl(
          new Date(
            Number(this.SelectedCompentencyYear),
            Number(this.selectedItems[0]) - 1,
            1
          )
        );
        this.dateOut = new FormControl(
          new Date(
            Number(this.SelectedCompentencyYear),
            Number(this.selectedItems[0]),
            0
          )
        );
      } else {
        this.dateIn = new FormControl(
          new Date(
            Number(this.SelectedCompentencyYear),
            Number(this.selectedItems[0]) - 1,
            1
          )
        );
        this.dateOut = new FormControl(
          new Date(
            Number(this.SelectedCompentencyYear),
            Number(this.selectedItems[this.selectedItems.length - 1]),
            0
          )
        );
      }
    } else {
      this.dateIn = new FormControl(
        new Date(
          Number(this.SelectedCompentencyYear),
          Number(this.selectedItems) - 1,
          1
        )
      );
      this.dateOut = new FormControl(
        new Date(
          Number(this.SelectedCompentencyYear),
          Number(this.selectedItems),
          0
        )
      );
    }

    if (this.enabledBtnSearch) {
      this.eventSearchClick.emit();
    }
  }

  GetMonthText(month: string): string {
    let itemmonth = this.CompentencyMonths.filter(x => x.value == month);
    return itemmonth && itemmonth.length > 0 ? itemmonth[0].label : "";
  }

  GetFilterData(): GetAccountantIntegrationRQ {
    var request: GetAccountantIntegrationRQ = {
      cnpj: "",
      month: Number(this.selectedItems),
      year: Number(this.SelectedCompentencyYear)
    }

    request.cnpj = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf })[0];

    return request;
  }

}