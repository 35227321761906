import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuMultiLevelComponent } from './menu-multi-level.component';


import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  exports: [
    MenuMultiLevelComponent
  ],
  declarations: [
    MenuMultiLevelComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MenuMultiLevelModule { }
