import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-purchase-document-type-filter',
  templateUrl: './purchase-document-type-filter.component.html'
})
export class PurchaseDocumentTypeFilterComponent implements OnInit {
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  @Input()
  selectedOption?: string = "";

  @Output()
  selectedOptionChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  handleChange() {
    this.selectedOptionChange.emit(this.selectedOption);
    this.onChange.emit(this.selectedOption);
  }
}
