import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetCalendarsEventsRS, CalendarEventModel } from './DTO/GetCalendarsEventsRS.model';
import { GetCalendarsEventsRQ } from './DTO/GetCalendarsEventsRQ.model';
import { GetTaxCalendarsEventsRQ } from './DTO/GetTaxCalendarsEventsRQ.model';
import { GetTaxCalendarsEventsRS } from './DTO/GetTaxCalendarsEventsRS.model';
import { SendFileTransEventsRQ } from './DTO/SendFileTransEventsRQ';
import { SendFileTransEventsRS } from './DTO/SendFileTransEventsRS';
import { GetFileExtractionRQ } from './DTO/GetFileExtractionRQ';
import { GetFileExtractionRS } from './DTO/GetFileExtractionRS';
import { SetRequestFileExtractionRQ } from './DTO/SetRequestFileExtractionRQ';
import { SetRequestFileExtractionRS } from './DTO/SetRequestFileExtractionRS';
import { GenerateDocumentModel } from './DTO/GenerateDocument.model';
import { UserActionRQ } from '../common/logger/DTO/UserActionRQ';
import { RequestReportRQ } from './DTO/RequestReport';
import { DownloadReportRQ } from './DTO/DownloadReport.model';
import { ListReportsRQ } from './DTO/ListReports.model';

@Injectable({
  providedIn: "root"
})
export class ConformityService {
  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {

  }

  GetCalendarsEvents(_request: GetCalendarsEventsRQ): Observable<GetCalendarsEventsRS> {
    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post<GetCalendarsEventsRS>(Config.DOMEApi.GetCalendarsEvents, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }


  GetTaxCalendarsEvents(_request: GetTaxCalendarsEventsRQ): Observable<GetTaxCalendarsEventsRS> {

    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post<GetTaxCalendarsEventsRS>(Config.DOMEApi.GetTaxCalendarsEvents, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  SendFileTransEvents(_request: SendFileTransEventsRQ): Observable<SendFileTransEventsRS> {
    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post<SendFileTransEventsRS>(Config.DOMEApi.SendFileTransEvents, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  SetCalendarEvent(_request: CalendarEventModel): Observable<boolean> {
    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post(Config.DOMEApi.SetCalendarEvent, request)
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  SetRequestFileExtractions(_request: SetRequestFileExtractionRQ): Observable<SetRequestFileExtractionRS> {
    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post<SetRequestFileExtractionRS>(Config.DOMEApi.ExtractDocumentsAsync, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }


  GetFileExtractions(_request: GetFileExtractionRQ): Observable<GetFileExtractionRS> {
    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post<GetFileExtractionRS>(Config.DOMEApi.GetExtractDocuments, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GenerateDocument(_request: GenerateDocumentModel): Observable<boolean> {
    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post(Config.DOMEApi.GenerateDocument, request)
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  SetLogUserAction(_request: UserActionRQ) {
    this.http.post(Config.DOMEApi.SetLogUserAction, _request).subscribe();
  }

  RequestReport(_request: RequestReportRQ) {
    console.log('Requesting Report');
    return this.http.post(Config.DOMECloud.RequestReport, _request);
  }

  DownloadReport(_request: DownloadReportRQ) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*');
    return this.http.get(`${Config.DOMECloud.DownloadReport}?id=${_request.id}`, { headers: headers, responseType: 'blob', observe: 'response' });
  }

  ListReports(_request: ListReportsRQ) {
    console.log('Requesting Report');
    return this.http.get(`${Config.DOMECloud.ListReports}?codigocontribuinte=${_request.CodigoContribuinte}&limiteporpagina=${_request.LimitePorPagina}&pagina=${_request.Pagina}`);
  }

  getDocuments(documentType: string, period: string[]) {
    if (documentType === 'simples') {
      return this.http.post(`${Config.urlDOMECloud()}/documentoscontabeis/consultarapuracoes`, period);
    }

    return this.http.post(`${Config.urlDOMECloud()}/documentoscontabeis/consultarsintegra`, period);
  }

  downloadDocument(documentType: string, documentName: string) {
    if (documentType === 'simples') {
      return this.http.get(`${Config.urlDOMECloud()}/documentoscontabeis/downloadapuracao?nomeArquivo=${documentName}`,
        { responseType: 'blob', observe: 'response' });
    }

    return this.http.get(`${Config.urlDOMECloud()}/documentoscontabeis/downloadsintegra?nomeArquivo=${documentName}`,
      { responseType: 'blob', observe: 'response' });
  }


}
