export interface MonthsModel {
    label: string,
    value: string
}

export const MonthList: MonthsModel[] =
    [
        {
            label: "Janeiro",
            value: "1"
        },
        {
            label: "Fevereiro",
            value: "2"
        },
        {
            label: "Março",
            value: "3"
        },
        {
            label: "Abril",
            value: "4"
        },
        {
            label: "Maio",
            value: "5"
        },
        {
            label: "Junho",
            value: "6"
        },
        {
            label: "Julho",
            value: "7"
        },
        {
            label: "Agosto",
            value: "8"
        },
        {
            label: "Setembro",
            value: "9"
        },
        {
            label: "Outubro",
            value: "10"
        },
        {
            label: "Novembro",
            value: "11"
        },
        {
            label: "Dezembro",
            value: "12"
        }
    ];

export function GetDataForFileName(date: Date): string {
    return new Date(date).toLocaleDateString('pt-BR').split('/').join('_');
}