import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { EChartOption } from "echarts";
import { ConsolidatedRejection } from "./../DTO/sales.model";
import { abbreviateNumber } from "src/app/_helpers/number.helper";

@Component({
  selector: 'app-chart-rejections',
  templateUrl: './chart-rejections.component.html',
  styleUrls: ['./chart-rejections.component.scss']
})
export class ChartRejectionsComponent implements OnInit, OnChanges {
  @Input() rejections: ConsolidatedRejection[];

  ngOnChanges(changes: SimpleChanges) {
    this.updateChartOption();
  }
  mergeOption: any;
  chartOption: EChartOption = this.getChartOption();

  constructor() { }

  ngOnInit() { }

  getChartOption(): any {
    return {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow"
        },
        formatter: function (params: any, ticket, callback) {
          return `${params.seriesName}: ${params.name}<br />Quantidade: ${params.value}`;
        }
      },
      legend: {
        x: "right",
        data: ["Código da rejeição"],
        selectedMode: false,
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "category",
        axisLine: {
          lineStyle: {
            opacity: 0.1
          }
        },
        data: []
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            opacity: 0.1
          }
        },
        splitLine: {
          lineStyle: {
            color: "#ebebeb"
          }
        },
        axisLabel: {
          formatter: (value, index) => {
            let milhar = value >= 1000;
            if (milhar) {
              value = value / 1000;
              return value.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) + "k";
            }
            return value;
          }
        }
      },
      series: [
        {
          name: "Código da rejeição",
          type: "bar",
          stack: "rejecao",
          data: [],
          itemStyle: {
            color: "#4DD0E1"
          }
        }
      ]
    };
  }

  updateChartOption() {
    let xAxix = this.getXAxix();
    let rejectionSeries = this.getQtdeRejectionsSeries();

    while (xAxix.length < 5) {
      xAxix.push("");
      rejectionSeries.push(0);
    }

    this.mergeOption = {
      xAxis: { data: xAxix },
      series: [{ data: rejectionSeries }]
    };
  }

  getXAxix(): string[] {
    return this.rejections.map(p => p.status.toString());
  }

  getQtdeRejectionsSeries(): number[] {
    if (!this.rejections) return;

    return this.rejections.map(p => p.quantidade);
  }
}
