import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { CreateNewGridColumn, CreateGridAction, CreateNewIcon } from 'src/app/shared/grid/grid.helper';
import { InconsisencyDetails } from '../DTO/IncosistencyDetails';
import { InconsistencyManifestationDetailsComponent } from './inconsistency-manifestation-details/inconsistency-manifestation-details.component';
import { GetAccountantIntegrationRQ } from '../DTO/GetAccountantIntegrationRQ.';

@Component({
  selector: "app-accountintegrationinconsistency",
  templateUrl: "./accountintegrationinconsistency.component.html",
  styleUrls: ["./accountintegrationinconsistency.component.scss"]
})
export class AccountIntegrationInconsistencyComponent implements OnInit {

  @Input() filter: GetAccountantIntegrationRQ;

  gridColumns: GridColumn[] = [];
  @ViewChild('gridaccountintegrationinconsistancy') gridaccountintegrationinconsistancy: GridComponent;
  classGridMain: string = "";
  dataSource: any;
  icons: IconGrid[] = [];

  inconsistencyDetails: InconsisencyDetails[] =
    [{ name: "compras_pendentes", detailAction: () => this.manifestaionDetails.ShowDetail() }];

  @ViewChild('gridInconsistency') gridInconsistency: GridComponent;
  @ViewChild('manifestaionDetails') manifestaionDetails: InconsistencyManifestationDetailsComponent;

  constructor() {
    this.gridColumns.push(CreateNewGridColumn(100, 'action', 'detalhes', (element: any) => `${element.actions}`, false, true));
    this.gridColumns.push(CreateNewGridColumn(150, 'inconsistencyAmount', '#', (element: any) => `${element.inconsistencyAmount}`));
    this.gridColumns.push(CreateNewGridColumn(0, 'reasons', 'motivos', (element: any) => `${element.reasons}`));
  }

  ngOnInit() {
  }

  refreshDataSource(_dataSource: any) {
    if (_dataSource.length > 0) {
      this.dataSource = _dataSource;
    }
    else {
      this.dataSource = [];
    }
    this.dataSource.forEach(n => {
      n.actions = [];
      if (this.inconsistencyDetails.find(it => it.name == n.inconsistencyName)) {
        n.actions.push(CreateGridAction('eye', 'OpenInconsistencyDetails', CreateNewIcon('eye', '../assets/svg/eye.svg'), 'mat-icon icon-design', 'Abrir detalhes'));
      }
    })
    this.gridInconsistency.refreshDateSource(this.dataSource);
  }

  OpenInconsistencyDetails(param) {
    var inconsistencyDetail = this.inconsistencyDetails.find(it => it.name === param.data.inconsistencyName);
    if (inconsistencyDetail && inconsistencyDetail.detailAction) {
      inconsistencyDetail.detailAction();
    }
  }
}
