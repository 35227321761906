import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetAccountantIntegrationRS } from './DTO/GetAccountantIntegrationRS';
import { GetAccountantIntegrationRQ } from './DTO/GetAccountantIntegrationRQ.';
import { PurchaseDataService } from '../purchase/DTO/purchaseData.model';

@Injectable({
  providedIn: "root"
})
export class AccountantIntegrationService {
  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {

  }

  GetAccountantIntegration(_request: GetAccountantIntegrationRQ): Observable<GetAccountantIntegrationRS> {
    var request = {
      header: {},
      body: _request
    }
    return this.http
      .post<GetAccountantIntegrationRS>(Config.DOMEApi.GetAccountantIntegration, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetPurchasePending(request: GetAccountantIntegrationRQ): Observable<PurchaseDataService[]> {
    return this.http
      .post<PurchaseDataService[]>(Config.DOMEApi.GetPurchasesPending, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}


