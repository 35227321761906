import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { InsightsRS, Insights } from '../DTO/insightsRS';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InsightsService } from '../insights.service';
import { FilterComponent } from 'src/app/shared/filter/filter.component';
import { LoginService } from 'src/app/login/login.service';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { ToastrService } from 'ngx-toastr';
import { CreateNewGridColumn, CreateGridAction, CreateNewIcon, CreateNewCheckBoxColumn, CreateNewGridColumnCheckbox, CreateNewCheckBox } from 'src/app/shared/grid/grid.helper';
import { InsightsRQ, StatusInsightType } from '../DTO/insightsRQ';
import { Actions } from 'src/app/sales/DTO/actions.model';
import { SetInsightRQ } from '../DTO/setInsightRQ.model';
import { Role } from '../../_models/enums';
import { MatTableDataSource, MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { LabelPositionEnum } from 'src/app/shared/grid/DTO/checkboxColumn.model';
import { DefaultResponse } from 'src/app/_models/default-response';
import { DomeDictionary } from './../../dictionary';

@Component({
  selector: 'app-insights-pendentes',
  templateUrl: './insights-pendentes.component.html',
  styleUrls: ['./insights-pendentes.component.scss']
})
export class InsightsPendentesComponent implements OnInit {
  @ViewChild('gridaudits') gridaudits: GridComponent;
  @ViewChild('filter') filter: FilterComponent;

  typeDocument?: string = "";
  classRefresh: string;
  titleRefresh: string;
  enabledRefresh: boolean;
  title: string;
  auditsdetailGridColumns: GridColumn[] = [];
  InsightsData: Insights[];
  InsightsResponse: InsightsRS;
  typeFilter: number;
  icons: IconGrid[] = [];
  hiddenCheckboxOptions: boolean = true;

  constructor(private ngxLoader: NgxUiLoaderService,
    private insightsService: InsightsService,
    private toastr: ToastrService,
    private loginService: LoginService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private coolDialogs: NgxCoolDialogsService) { }

  ngOnInit() {
    this.typeFilter = 2;

    this.CreateIcons();

    this.classRefresh = "div-refresh";
    this.enabledRefresh = true;
    this.titleRefresh = "Atualização";

    this.auditsdetailGridColumns.push(CreateNewGridColumnCheckbox(65, 'checkbox', '', (element: Insights) => `${element.checkbox}`, false, false, true, CreateNewCheckBoxColumn(false, false, LabelPositionEnum.after, false, '')));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(70, 'tax_auditor', 'Auditor Tributário', (element: Insights) => CreateGridAction(`icon-lx-exclamation-2`, null, null, 'icon-lx-exclamation-2', 'Item com divergências'), true));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(130, 'chave', 'Chave', (element: Insights) => `${element.chave}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(65, 'numeroDocumento', 'Nº Nota', (element: Insights) => `${element.numeroDocumento}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(55, 'serie', 'Série', (element: Insights) => `${element.serie}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(60, 'id', 'Nº item', (element: Insights) => `${element.id}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(130, 'codProdItem', 'Código Produto', (element: Insights) => `${element.codProdItem}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(350, 'descricaoItem', 'Descrição Produto', (element: Insights) => `${element.descricaoItem}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(150, 'descricaoError', 'Ocorrência', (element: Insights) => `${element.descricaoError}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(100, 'codErrors', 'Código Atual', (element: Insights) => `${element.original}`));
    this.auditsdetailGridColumns.push(CreateNewGridColumn(100, 'sugestaoError', 'Sugestão Auditoria', (element: Insights) => `${element.sugestaoError}`));

    if (this.loginService.hasRole(Role.ACEITE_INSIGHT)) {
      this.auditsdetailGridColumns.push(CreateNewGridColumn(0, 'action', 'Insights Tributários', (element: Insights) => `${element.actions}`, false, true));
    }
  }

  filterDate(): void {//0=ontem,1=Hoje,2=Ultimos 7 dias, 3= Ultimos 30 dias e 4 = Por Periodo
    let param = this.filter.GetFilterData();
    this.typeFilter = param.typeFilter;

    this.GetInsightsTrib(param.dateIn, param.dateOut);
    this.hiddenCheckboxOptions = true;
  }

  GetInsightsTrib(_dateIni: Date, _dateFin: Date) {
    this.StartDataClicked();

    let request: InsightsRQ = {
      dateIn: _dateIni,
      dateOut: _dateFin,
      CNPJ: [],
      typeDocument: null,
      StatusInsight: StatusInsightType.Pendente
    }

    if (this.typeDocument) {
      request.typeDocument = Number(this.typeDocument);
    }

    request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });

    this.insightsService.GetInsightsTrib(request).subscribe(data => {
      this.InsightsData = [];
      this.InsightsResponse = data;

      if (this.InsightsResponse != undefined && this.InsightsResponse.vaccines.length > 0) {
        this.InsightsResponse.vaccines.forEach(dataItem => {
          dataItem.actions = [];
          var disabledCheckbox = true;

          if (this.loginService.hasRole(Role.ACEITE_INSIGHT)) {
            if (dataItem.aceiteauditoria == 1) {
              dataItem.actions.push(CreateGridAction('thumb-up', "", CreateNewIcon('thumb-up', '../assets/svg/circle_grey.svg'), 'mat-icon icon-design', 'Aguardando integração.'));
            }
            else if (dataItem.aceiteauditoria == 2) {
              dataItem.actions.push(CreateGridAction('thumb-up', "", CreateNewIcon('thumb-up', '../assets/svg/circle_green.svg'), 'mat-icon icon-design', "Aceita do insight realizado com sucesso."));
            }
            else if (dataItem.aceiteauditoria == 3) {
              dataItem.actions.push(CreateGridAction('circle-red', "", CreateNewIcon('circle-red', '../assets/svg/circle_red.svg'), 'mat-icon icon-design', "Insight recusado."));
            }
            else if (dataItem.aceiteauditoria == 4) {
              dataItem.actions.push(CreateGridAction('thumb-up', "", CreateNewIcon('thumb-up', '../assets/svg/circle_black.svg'), 'mat-icon icon-design', "Realize o processo manual"));
            }
            else {
              disabledCheckbox = false;
              dataItem.actions = dataItem.actions.concat(this.CreateDefaultGridActions());
            }
          }

          dataItem.checkbox = CreateNewCheckBox(false, false, LabelPositionEnum.after, disabledCheckbox, 'text')
          this.InsightsData.push(dataItem);
        })
      }

      this.gridaudits.refreshDateSource(this.InsightsData);
      this.FinishDataClicked();
    },
      error => {
        this.FinishDataClicked();
        if (error.erros) {
          this.toastr.info(error.erros[0].descricao, "");
        } else if (error.message) {
          if (error.name === "HttpErrorResponse") {
            this.toastr.info(DomeDictionary.ErroConexaoServidor, "");
          } else {
            this.toastr.info(error.message, "");
          }
        }
      });
  }

  CreateIcons() {
    this.matIconRegistry.addSvgIcon(
      'refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/refresh.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'thumb-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/thumb_up.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'thumb-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/thumb_down.svg')
    );
  }

  RefreshGridClicked(): void {
    this.filterDate();
    this.gridaudits.refreshDateSource(this.InsightsData);
  }

  CreateDefaultGridActions(): Actions[] {
    return [
      CreateGridAction('thumb-up', "AcceptClicked", CreateNewIcon('thumb-up', '../assets/svg/thumb_up.svg'), 'mat-icon icon-design', 'Aceitar Insight'),
      CreateGridAction('thumb-down', "RejeitClicked", CreateNewIcon('thumb-down', '../assets/svg/thumb_down.svg'), 'mat-icon icon-design', 'Recusar Insight')
    ]
  }

  StartDataClicked(): void {
    this.ngxLoader.start();
  }

  FinishDataClicked(): void {
    this.ngxLoader.stop();
  }

  SetInsight(insightItem: any, insightAceito: boolean) {
    let request: SetInsightRQ = {
      ChaveNfe: insightItem.data.chave,
      CodigoProduto: insightItem.data.codProdItem,
      IdErro: insightItem.data.codError,
      IdProduto: insightItem.data.id,
      MensagemErro: insightItem.data.descricaoError,
      SugestaoCorrecao: insightItem.data.sugestaoError,
      Cnpj: insightItem.data.cnpj,
      InsightAceito: insightAceito
    }

    this.StartDataClicked();
    this.UpdateTooltipOnAccept(insightItem.data.chave, insightItem.data.codProdItem);
    insightItem.data.checkbox.checked = false;
    insightItem.data.checkbox.disabled = true;

    this.insightsService.SetInsight(request).subscribe(
      data => {
        this.TratarSucessoRequest(data);
      },
      error => {
        this.TratarFalhaRequest(error);
      });
  }

  SetInsightList(selectedInsights: Insights[], insightAceito: boolean) {
    var request: SetInsightRQ[] = [];
    console.log(selectedInsights);
    for (var i = 0; i < selectedInsights.length; i++) {
      var insightItem = selectedInsights[i];

      let insightRQ: SetInsightRQ = {
        ChaveNfe: insightItem.chave,
        CodigoProduto: insightItem.codProdItem,
        IdErro: insightItem.codError,
        IdProduto: insightItem.id,
        MensagemErro: insightItem.descricaoError,
        SugestaoCorrecao: insightItem.sugestaoError,
        Cnpj: insightItem.cnpj,
        InsightAceito: insightAceito
      }
      request.push(insightRQ);

      this.UpdateTooltipOnAccept(insightItem.chave, insightItem.codProdItem);
      insightItem.checkbox.checked = false;
      insightItem.checkbox.disabled = true;
    }

    this.StartDataClicked();

    this.insightsService.SetInsightList(request).subscribe(
      data => {
        this.TratarSucessoRequest(data);
        this.RefreshCheckBoxes();
      },
      error => {
        this.TratarFalhaRequest(error);
        this.RefreshCheckBoxes();
      });
  }

  RefreshCheckBoxes() {
    this.hiddenCheckboxOptions = true;
    this.gridaudits.cleanCheckLine();
  }

  TratarSucessoRequest(data: DefaultResponse) {
    this.FinishDataClicked();

    if (!data.sucesso) {
      this.toastr.info(DomeDictionary.ErroEnviarAceiteInsight);
    }
  }

  TratarFalhaRequest(error: any) {
    this.FinishDataClicked();

    if (error.erros) {
      this.toastr.info(error.erros[0].descricao, "");
    } else if (error.message) {
      if (error.name === "HttpErrorResponse") {
        this.toastr.info(DomeDictionary.ErroConexaoServidor, "");
      } else {
        this.toastr.info(error.message, "");
      }
    }
  }

  ConfirmAction(message: string, action) {
    this.coolDialogs.confirm(message)
      .subscribe(response => {
        if (response) {
          action();
        }
      });
  }

  ShowManifestationActionBarAlert() {
    this.coolDialogs.alert(DomeDictionary.ExistemInsightsSelecionados, {
      okButtonText: 'OK',
    });
  }

  AcceptClicked(p: any): void {
    if (this.GetSelectedCheckBoxCount() > 1) {
      this.ShowManifestationActionBarAlert();
      return;
    }

    if (!p) {
      this.toastr.info(DomeDictionary.NotaSemChaveAcesso, "");
      return;
    }

    this.ConfirmAction(DomeDictionary.ConfirmaAceiteInsight, () => this.SetInsight(p, true));
  }

  UpdateTooltipOnAccept(chave: string, codigoProduto: string): void {
    this.InsightsData
      .find(w => w.chave == chave && w.codProdItem == codigoProduto)
      .actions = [
        CreateGridAction('thumb-up', "", CreateNewIcon('thumb-up', '../assets/svg/circle_grey.svg'), 'mat-icon icon-design', 'Solicitação em andamento.')
      ];
  }

  RejectClicked(p: any): void {
    if (this.GetSelectedCheckBoxCount() > 1) {
      this.ShowManifestationActionBarAlert();
      return;
    }

    if (!p) {
      this.toastr.info(DomeDictionary.NotaSemChaveAcesso, "");
      return;
    }

    this.ConfirmAction(DomeDictionary.ConfirmaRecusaInsight, () => this.SetInsight(p, false));
  }

  GetSelectedRegisters(): Insights[] {
    var checkeds = this.GetCheckBoxSelected();

    var insights: Insights[] = [];

    for (var i = 0; i < checkeds.length; i++) {
      if (checkeds[i]) {
        insights.push(this.InsightsData[i]);
      }
    }
    return insights;
  }

  GetCheckBoxSelected(): any {
    return this.gridaudits.GetCheckLine().listCheckBox;
  }

  SetManifestationBarVisibility() {
    this.hiddenCheckboxOptions = !(this.GetSelectedCheckBoxCount() > 1);
  }

  GetSelectedCheckBoxCount(): Number {
    return this.GetCheckBoxSelected().filter(value => value).length;
  }

  AcceptInsightList() {
    var dataChecked = this.GetSelectedRegisters();

    this.ConfirmAction(DomeDictionary.ConfirmaAceiteInsightLote(this.GetSelectedCheckBoxCount()), () => this.SetInsightList(dataChecked, true));
  }

  RefuseInsightList() {
    var dataChecked = this.GetSelectedRegisters();

    this.ConfirmAction(DomeDictionary.ConfirmaRecusaInsightLote(this.GetSelectedCheckBoxCount()), () => this.SetInsightList(dataChecked, false));
  }
}
