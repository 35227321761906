import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/shared/modal/modal.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { CreateNewGridColumn, CreateGridAction, CreateNewIcon } from 'src/app/shared/grid/grid.helper';
import { GetAccountantIntegrationRQ } from '../../DTO/GetAccountantIntegrationRQ.';
import { AccountantIntegrationService } from './../../accountantintegration.service';
import { GridComponent } from 'src/app/shared/grid/grid.component';

@Component({
  selector: 'app-inconsistency-manifestation-details',
  templateUrl: './inconsistency-manifestation-details.component.html',
  styleUrls: ['./inconsistency-manifestation-details.component.scss']
})
export class InconsistencyManifestationDetailsComponent implements OnInit {

  @Input() filter: GetAccountantIntegrationRQ;
  @ViewChild('gridmanifestations') gridmanifestations: GridComponent;

  gridColumns: GridColumn[] = [];
  gridValues = [];

  constructor(private modalService: ModalService,
    private ngxLoader: NgxUiLoaderService,
    private accountantIntegrationService: AccountantIntegrationService) { }

  ngOnInit() {
    this.gridColumns.push(CreateNewGridColumn(100, 'numero_documento', 'Nota Fiscal', (element: any) => `${element.numero_documento}`));
    this.gridColumns.push(CreateNewGridColumn(300, 'suppliername', 'Fornecedor', (element: any) => `${element.suppliername}`));
    this.gridColumns.push(CreateNewGridColumn(100, 'qtd_itens', 'Qtde Itens', (element: any) => `${element.qtd_itens}`));
    this.gridColumns.push(CreateNewGridColumn(150, 'totalamount', 'Valor Total', (element: any) => `${element.totalamount.toFixed(2)}`));
    this.gridColumns.push(CreateNewGridColumn(100, 'manifestation', 'Manifestação', (element: any) => CreateGridAction('circle-yellow', null, CreateNewIcon('circle-yellow', '../assets/svg/circle_yellow.svg', 'Operação Pendente'), 'circle-yellow', 'Operação Pendente'), true));
  }

  public ShowDetail() {
    this.ngxLoader.start();

    this.accountantIntegrationService.GetPurchasePending(this.filter).subscribe(
      data => {
        this.gridValues = data;
        this.gridmanifestations.refreshDateSource(this.gridValues);
        this.OpenModal('manifestation-detail-modal');
        this.ngxLoader.stop();
      });
  }

  private OpenModal(id: string) {
    this.modalService.open(id);
  }

  private CloseModal(id: string) {
    this.modalService.close(id);
  }

  FinishDataClicked(): void {
    this.ngxLoader.stop();
  }

  StartDataClicked(): void {
    this.ngxLoader.start();
  }
}
