import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Chart } from 'angular-highcharts';
import { ChatsConfig } from './DTO/chatsconfig.model';
import { initDomAdapter } from '@angular/platform-browser/src/browser';

@Component({
    selector: 'app-chart',
    templateUrl: './charts.component.html',
    styleUrls: ['./charts.component.scss']
  })

export class ChartsComponent implements OnInit {
    @Input() chartsconfig : ChatsConfig;

    chart: Chart;
    @Output() eventRefreshFinish= new EventEmitter();

    constructor(){
      
    }

    ngOnInit() {
        this.init();
    }

    init() {
      this.chart = (this.chartsconfig!=undefined? new Chart({
        chart:(this.chartsconfig.chartConfig!=undefined? this.chartsconfig.chartConfig:{}),
        tooltip:(this.chartsconfig.tooltipConfig!= undefined?this.chartsconfig.tooltipConfig:{}),
        title: (this.chartsconfig.titleConfig != undefined ?this.chartsconfig.titleConfig:{}),
        credits:(this.chartsconfig.creditsConfig!=undefined? this.chartsconfig.creditsConfig:{}),
        subtitle:(this.chartsconfig.subtitleConfig != undefined? this.chartsconfig.subtitleConfig:{}),
        xAxis: (this.chartsconfig.xAxisConfig!= undefined?this.chartsconfig.xAxisConfig:{}),
        yAxis: (this.chartsconfig.yAxisConfig != undefined ?this.chartsconfig.yAxisConfig:{}),  
        plotOptions: (this.chartsconfig.plotOptionsConfig != undefined?this.chartsconfig.plotOptionsConfig:{}),
        series:(this.chartsconfig.seriesConfig!=undefined?this.chartsconfig.seriesConfig:[]),       
        legend: (this.chartsconfig.legendConfig != undefined?this.chartsconfig.legendConfig:{}),
      }):new Chart({}));
      
    }

    refreshDateSource( _chartsconfig : ChatsConfig)
    {
      this.chartsconfig = _chartsconfig;
      this.init();
      this.eventRefreshFinish.emit();
    }
}