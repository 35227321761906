import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../../shared/modal/modal.service';
import { GetDataTaxRulesRS, GetDataTaxRules } from '../DTO/getDataTaxRulesRS';

@Component({
  selector: 'app-insights-consultas-calculo',
  templateUrl: './insights-consultas-calculo.component.html',
  styleUrls: ['./insights-consultas-calculo.component.scss']
})
export class InsightsConsultasCalculoComponent implements OnInit {
  @Input() idModal: string;
  @Input() itemTaxRules: GetDataTaxRules;

  constructor(private modalService: ModalService) { }

  ngOnInit() {
  }

  public openModal() {
    this.modalService.open(this.idModal);
  }

  public closeModal() {
    this.modalService.close(this.idModal);
  }
}
