import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { DashboardService } from "./dashboard.service";
import { TimelineElement } from "../shared/Horizontal-timeline/DTO/timeline-element";
import { GetCalendarsEventsRQ } from "../conformity/DTO/GetCalendarsEventsRQ.model";
import { DashBoardCalendarRQ } from "../modelstransport/dashboardcalendarRQ";
import { GetCalendarsEventsRS, CalendarEventModel, StatusEventoCalendario } from "../conformity/DTO/GetCalendarsEventsRS.model";
import { ModalService } from "../shared/modal/modal.service";
import { Role } from "../_models/enums";
import { LoginService } from "../login/login.service";
import { DashboardModel } from './DTO/dashboard.model';
import { UserService } from "../user/user.service";

@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
	@ViewChild("dwnldLnk") dwnldLnk: ElementRef;

	calendarsFull: GetCalendarsEventsRS;
	mathContent: string;
	positionCalendar: number = 0;

	dateInCalendar = new FormControl(new Date());
	dateFinCalendar = new FormControl(
		new Date(new Date().setDate(new Date().getDate() + 1))
	);

	returnUrl: string;
	indexModal: number = 0;
	isPurchaseRole: boolean = false;
	isInsightsRole: boolean = false;
	isSaleRole: boolean = false;
	isTimeLineRole: boolean = false;
	isAuditRole: boolean = false;

	@ViewChild("textFaixa") textFaixa: ElementRef;
	@ViewChild("textFaixaICMS") textFaixaICMS: ElementRef;

	timeline: TimelineElement[] = [];
	dashboardData: DashboardModel;
	user: any = {};

	constructor(
		private route: ActivatedRoute,
		private ngxLoader: NgxUiLoaderService,
		private dashboardService: DashboardService,
		private router: Router,
		private modalService: ModalService,
		private loginService: LoginService,
		private userService: UserService
	) { }

	async ngOnInit() {
		this.ngxLoader.start();
		await this.loginService.CheckRolebyUserSync(Role.DASHBOARD);
		this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/fiscal";
		let roles = this.loginService.GetUserLogin().roles;
		this.ngxLoader.stop();
		this.GetDashboardData(roles);
		this.GetTimeLine();
		this.getUser();
		this.FinishDataClicked();
	}

	RedirectActCalendar(_date: Date): void {
		let request: DashBoardCalendarRQ = {
			viewDate: _date
		};

		sessionStorage.setItem(
			"DashBoardCalendarParameter",
			JSON.stringify(request)
		);
		this.router.navigate([this.returnUrl]);
	}

	StartDataClicked(): void {
		this.ngxLoader.startLoader('loader-timeline');
	}
	FinishDataClicked(): void {
		this.ngxLoader.stopLoader('loader-timeline');
	}

	GetDashboardData(roles: string[]) {
		this.isPurchaseRole = roles.filter(x => x == Role.NOTA_DE_ENTRADA).length > 0;
		this.isInsightsRole = roles.filter(x => x == Role.INSIGHTS).length > 0;
		this.isSaleRole = roles.filter(x => x == Role.NOTA_DE_SAIDA).length > 0;
		this.isAuditRole = roles.filter(x => x == Role.DASHBOARD_AUDITOR).length > 0;


		let cnpj = this.loginService.getCnpj();
		this.dashboardService.GetDashboardData(cnpj)
			.subscribe(data => {
				this.dashboardData = data;
			});
	}

	GetTimeLine(forward?: boolean) {
		this.StartDataClicked();

		this.timeline = [];
		var timelineAux: TimelineElement[] = [];

		if (forward != undefined) {
			if (forward) {
				this.dateInCalendar = new FormControl(
					new Date(
						this.dateInCalendar.value.getFullYear(),
						this.dateInCalendar.value.getMonth() + 1,
						1
					)
				);
				this.dateFinCalendar = new FormControl(
					new Date(
						this.dateFinCalendar.value.getFullYear(),
						this.dateFinCalendar.value.getMonth() + 2,
						0
					)
				);
			} else {
				this.dateInCalendar = new FormControl(
					new Date(
						this.dateInCalendar.value.getFullYear(),
						this.dateInCalendar.value.getMonth() - 1,
						1
					)
				);
				this.dateFinCalendar = new FormControl(
					new Date(
						this.dateFinCalendar.value.getFullYear(),
						this.dateFinCalendar.value.getMonth(),
						0
					)
				);
			}
		} else {
			this.dateInCalendar = new FormControl(
				new Date(new Date().getFullYear(), new Date().getMonth(), 1)
			);
			this.dateFinCalendar = new FormControl(
				new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
			);
		}

		var request: GetCalendarsEventsRQ = {
			datein: this.dateInCalendar.value,
			dateout: this.dateFinCalendar.value,
			CNPJ: []
		};

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) {
			return n.cnpjCpf;
		});

		//Utilizado para não redimensionar a altura da TimeLine ao carregar próximo período
		var timeLineHiddenElement: TimelineElement = {
			caption: '',
			content: '',
			date: new Date(),
			title: '',
			class_color: '',
			documentoBase64: '',
			idDocumento: '',
			tipoDocumento: '',
			formatoDocumento: '',
			payEnabled: true,
			titleObs: "",
			helpEnabled: false,
			hidden: true
		};

		timelineAux = [];
		timelineAux.push(timeLineHiddenElement);
		this.timeline = timelineAux;

		setTimeout(() => {
			this.dashboardService.GetCalendarsEvents(request).subscribe(data => {
				if (data.calendarsEvents != null && data.calendarsEvents.length > 0) {
				}

				var i = 0;
				data.calendarsEvents = data.calendarsEvents.filter(
					(thing, index, arr) => {
						return (
							arr.indexOf(
								arr.find(
									t =>
										new Date(t.dataOcorrencia).getDate() ===
										new Date(thing.dataOcorrencia).getDate()
								)
							) === index
						);
					}
				);

				if (
					data.calendarsEvents != undefined &&
					data.calendarsEvents.length > 0
				) {
					this.calendarsFull = data;
					data.calendarsEvents.forEach(dados => {
						var response = this.GetTypeEvent(dados);
						if (response != null) {
							var event: TimelineElement = {
								caption:
									new Date(dados.dataOcorrencia).getDate().toString() +
									" " +
									new Date(dados.dataOcorrencia)
										.toLocaleDateString("pt-br", { month: "short" })
										.toUpperCase(),
								content: '',
								date: new Date(dados.dataOcorrencia),
								title: dados.tipoDocumento.toUpperCase(),
								class_color: response.class_color,
								documentoBase64: dados.documentoBase64,
								idDocumento: dados.idDocumento,
								tipoDocumento: dados.tipoDocumento,
								formatoDocumento: dados.formatoDocumento,
								titleObs: "",
								payEnabled: dados.pago,
								hidden: false,
								helpEnabled:
									dados.tipoDocumento.toUpperCase() == "SINTEGRA" ||
										dados.tipoDocumento.toUpperCase() == "DESTDA" ||
										(dados.memoriaDeCalculoDAS != undefined &&
											dados.memoriaDeCalculoDAS) ||
										(dados.memoriaDeCalculoICMS != undefined &&
											dados.memoriaDeCalculoICMS)
										? true
										: false
							};
							if (i == 0) {
								i = 1;
								event.selected = true;
							}

							timelineAux.push(event);
						}
					});
				} else {
					var event: TimelineElement = {
						caption:
							new Date(this.dateInCalendar.value).getDate().toString() +
							" " +
							new Date(this.dateInCalendar.value).toLocaleDateString("pt-br", {
								month: "short"
							}).toUpperCase(),
						content: '',
						date: new Date(this.dateInCalendar.value),
						title: "Não existem informações no período",
						class_color: "",
						titleObs: "",
						payEnabled: false,
						helpEnabled: false,
						hidden: false
					};
					event.selected = true;

					timelineAux.push(event);
				}

				//Removendo o elemento auxiliar utilizado para não redimensionar a altura da TimeLine
				timelineAux = timelineAux.splice(1, timelineAux.length);

				this.timeline = timelineAux;
				this.FinishDataClicked();
			});
		}, 2000);
	}

	GetTypeEvent(item: CalendarEventModel) {
		if (item.pago || item.statusEvento == StatusEventoCalendario.EFETIVADO)
			return {
				class_color: "success"
			};

		if (new Date(item.dataOcorrencia) < new Date())
			return {
				class_color: "danger"
			};

		return {
			class_color: "pendent"
		};
	}


	public getUser() {
		let request = {
			CNPJ: [],
			IdUsuario: ''
		}

		request.CNPJ = this.loginService.user.economicGroup.emitters.map(function (n) { return n.cnpjCpf });
		request.IdUsuario = this.loginService.user.idUsuario;

		this.userService.GetUsers(request).subscribe(
			data => {
				this.user = data;
				const token = localStorage.getItem("domeToken")
				if(!this.user.users[0].tokenApi && !token) {
					// this.openModal('dome-cloud');
				}
			});
	}

	private openModal(id: string) {
		this.modalService.open(id);
	}

	private closeModal(id: string) {
		this.modalService.close(id);
	}

	private delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}
