import { Component, OnInit,  Input,EventEmitter, Output} from "@angular/core";
import { MatSlideToggleChange } from '@angular/material';
import { InputToggleModel } from './DTO/input-toggle.model';
import { TypeAlignText } from './DTO/enumInputToogle';

@Component({
    selector: 'input-toggle',
    templateUrl: './input-toggle.component.html',
    styleUrls: ['./input-toggle.component.scss'],
})

export class InputToggleComponent implements OnInit {

    @Input() index:number;
    @Input() titleLabel:string;
    @Input() color:string = 'accent';
    @Input() checked:boolean = false;
    @Input() disabled:boolean = false;
    @Output() eventChange = new EventEmitter<InputToggleModel>();
    @Input() typeAlignLabel:TypeAlignText = TypeAlignText.right; //0 = left e 1= right
    @Input() font_SizeText:string="small";
    @Input() font_Family:string="";
    @Input() controlMarginLeftOrRight:string="5px";
    @Input() controlMarginTop:string="5px";
    @Input() classTitle:string="";
    classToggleRight:string;

    ngOnInit()
    {
        if(this.classTitle!="")
        {
            if(this.titleLabel && this.titleLabel.length>=29)
                this.classTitle = this.typeAlignLabel==0?"col title-left titleFormatterLeft " + this.classTitle:"titleFormatterRight " + this.classTitle;        
            else
            {
                this.classTitle = this.typeAlignLabel==0?"col title-left margin-right49 titleFormatterLeft " + this.classTitle:"titleFormatterRight " + this.classTitle;        
            }
        }
        else
        {
            if(this.titleLabel && this.titleLabel.length>=29)
                this.classTitle = this.typeAlignLabel==0?"col title-left titleFormatterLeft":"titleFormatterRight";   
            else
                this.classTitle = this.typeAlignLabel==0?"col title-left margin-right49 titleFormatterLeft":"titleFormatterRight";   
        }
        this.classToggleRight =this.typeAlignLabel==0?"col width-10":"";
    }

    ChangeStatus(event: MatSlideToggleChange)
    {
        this.checked = event.checked;
        let response: InputToggleModel={
            index:this.index,
            checked:this.checked
        }
        this.eventChange.emit(response)
    }
}