import { Router } from '@angular/router';
import { GerenciarPerfisService } from './gerenciar-perfis.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '../shared/grid/grid.component';
import { CreateGridAction, CreateNewCheckBoxColumn, CreateNewGridColumn, CreateNewGridColumnCheckbox, CreateNewIcon } from '../shared/grid/grid.helper';
import { ModalService } from '../shared/modal/modal.service';
import { NgxCoolDialogsService } from 'ngx-cool-dialogs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gerenciar-perfis',
  templateUrl: './gerenciar-perfis.component.html',
  styleUrls: ['./gerenciar-perfis.component.scss']
})
export class GerenciarPerfisComponent implements OnInit {

  titleEnabled: boolean;
  classRefresh: string;
  titleRefresh: string;
  enabledRefresh: boolean;
  hiddenCheckboxOptions: boolean = true;
  gridcolumns = [];
  profiles = [];
  allModulesActions = [];
  selectedProfile: any = {};

  @ViewChild('gridProfiles') gridProfiles: GridComponent;

  constructor(
    private gerenciarPerfisService: GerenciarPerfisService,
    private modalService: ModalService,
    private coolDialogs: NgxCoolDialogsService,
    private ngxLoader: NgxUiLoaderService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.fetchData();
    this.gridcolumns.push(CreateNewGridColumn(250, 'perfil', 'Perfil', (element: any) => `${element.nome}`));
    this.gridcolumns.push(CreateNewGridColumn(350, 'descricao', 'Descrição', (element: any) => `${element.descricao}`));
    this.gridcolumns.push(CreateNewGridColumn(300, 'data', 'Data da última atualização', (element: any) => `${element.dataUltimaAtualizacao}`));
    this.gridcolumns.push(CreateNewGridColumn(200, 'action', 'Ação', (element: any) => `${element.actions}`, false, true));
  }

  handleNewProfile() {
    this.selectedProfile = {
      nome: '',
      descricao: '',
      moduloAcoes: []
    }

    this.modalService.open('open-profile');
  }

  fetchData() {
    this.ngxLoader.start();

    this.gerenciarPerfisService.getAllModules().subscribe((d: any) => {
      this.allModulesActions = d.registro;
      console.log(this.allModulesActions);
    }, (error) => {
      this.toastr.error('Acesso Negado.');
      this.router.navigate(['Dashboard']);
    });

    this.gerenciarPerfisService.getProfiles().subscribe((d: any) => {
      this.profiles = d.registro.map(perfil => ({
        ...perfil,
        dataUltimaAtualizacao: new Date(perfil.dataUltimaAtualizacao).toLocaleDateString(),
        actions: [
          CreateGridAction('pencil', 'editar', CreateNewIcon('pencil', '../assets/svg/pencil.svg'), 'mat-icon icon-design', 'Editar Perfil'),
          CreateGridAction('clear_red', 'excluir', CreateNewIcon('clear_red', '../assets/svg/clear_red.svg'), 'mat-icon icon-design', 'Excluir Perfil'),
        ]
      }));
      this.gridProfiles.refreshDateSource(this.profiles, this.gridcolumns);
      this.ngxLoader.stop();
    });
  }

  handleUserClick(e) {

    if (e.action == 'editar') {
      return this.handleOpenEditProfile(e.data);
    }

    this.handleRemoveProfile(e.data);

  }

  handleRemoveProfile(data) {
    this.confirmAction('Deseja realmente excluir este perfil?', () => {
      this.ngxLoader.start();
      this.gerenciarPerfisService.deleteProfile(data.id).subscribe(
        d => {
          this.fetchData();
        },
        (err) => {
          this.extractErrors(err.errors)
          this.fetchData();
        });
    });
  }

  handleOpenEditProfile(data) {
    this.selectedProfile = data;
    this.modalService.open('open-profile');
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  confirmAction(message: string, action) {
    this.coolDialogs.confirm(message)
      .subscribe(response => {
        if (response) {
          action();
        }
      });
  }

  containsAction(id) {
    if (this.selectedProfile.moduloAcoes) {
      return this.selectedProfile.moduloAcoes.findIndex(idAcao => idAcao == id) >= 0;
    }
  }

  checkIfModuleIsChecked(module) {
    if (this.selectedProfile.moduloAcoes) {
      return module.modulosAcoes.every(acao => this.selectedProfile.moduloAcoes.includes(acao.idModuloAcao));
    }
  }

  addActionToProfile(id) {
    if (this.selectedProfile.moduloAcoes.includes(id)) {
      const index = this.selectedProfile.moduloAcoes.findIndex(idAcao => idAcao == id);
      return this.selectedProfile.moduloAcoes.splice(index, 1);
    }

    return this.selectedProfile.moduloAcoes.push(id);
  }

  handleCheckModule(module) {
    if (module.modulosAcoes.every(acao => this.selectedProfile.moduloAcoes.includes(acao.idModuloAcao))) {
      return module.modulosAcoes.forEach(mdAcao => {
        const index = this.selectedProfile.moduloAcoes.findIndex(idAcao => idAcao == mdAcao.idModuloAcao);
        this.selectedProfile.moduloAcoes.splice(index, 1);
      });
    }

    module.modulosAcoes.forEach(mdAcao => {
      const index = this.selectedProfile.moduloAcoes.findIndex(idAcao => idAcao == mdAcao.idModuloAcao);
      if (index == -1) {
        this.selectedProfile.moduloAcoes.push(mdAcao.idModuloAcao);
      }
    });
  }

  handleSaveProfile() {

    if (!this.selectedProfile.nome) {
      this.toastr.error('Digite o nome do perfil para continuar.');
      return;
    }

    if (!this.selectedProfile.descricao) {
      this.toastr.error('Digite a descrição do perfil para continuar.');
      return;
    }

    if (this.selectedProfile.moduloAcoes.length == 0) {
      this.toastr.error('Selecione ao menos uma Ação para continuar.');
      return;
    }

    const payload: any = {
      nome: this.selectedProfile.nome,
      descricao: this.selectedProfile.descricao,
      moduloAcoes: this.selectedProfile.moduloAcoes,
    }

    this.ngxLoader.start();

    if (this.selectedProfile.id) {
      payload.id = this.selectedProfile.id;
      return this.gerenciarPerfisService.updateProfile(payload).subscribe(
        d => {
          this.closeModal('open-profile')
          this.fetchData();
        },
        (err) => {
          this.extractErrors(err.errors);
          this.fetchData();
        });
    }

    this.gerenciarPerfisService.createProfile(payload).subscribe(
      d => {
        this.closeModal('open-profile')
        this.fetchData();
      },
      (err) => {
        this.extractErrors(err.errors);
        this.fetchData();
      });
  }

  extractErrors(errors) {
    let erros: any = [];
    for (let key in errors) {
      erros.push(errors[key]);
    }
    erros = erros.flat();
    this.toastr.error(erros.join('  '));
  }

}
