import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-emission-data',
	templateUrl: './emission-data.component.html',
	styleUrls: ['./emission-data.component.scss']
})
export class EmissionDataComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
