import {
  Injectable,
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
} from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "./../login/login.service";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/from";
import { NavMenuConfigList } from "./DTO/nav-menuConfig";
import { UserService } from "../user/user.service";
import { differenceInDays, format, isBefore } from "date-fns";
import { EventsService, MessageType } from "../_helpers/events.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"]
})
@Injectable()
export class NavMenuComponent implements OnInit {
  @Input() configMenu: NavMenuConfigList;
  @ViewChild("divSidebar") divSidebar: ElementRef;
  @ViewChild("divLogo") divLogo: ElementRef;
  opensidebar: string = "open";
  currentRoute: string;
  init: Function;
  lastEmitter = '';
  certificateMessage: string;
  certificateIcon = {
    icon: '',
    color: ''
  };
  formattedDate: string = '';
  license: string = '0';

  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UserService,
    private events: EventsService
  ) {
    this.events.subscribe(MessageType.ATUALIZA_HEADER, () => {
      this.GetCertificateStatus();
      this.getStatusLicense();
    });
  }

  getStatusLicense() {
    this.license = sessionStorage.getItem("licenca");
  }

  getCorIcone(path: string): string {
    if (this.router.url.startsWith(path)) {
      return "#F0462D";
    } else {
      return "#5B2E90";
    }
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
  }

  GetNome(): string {
    let emitter = this.loginService.user.economicGroup.emitters[0];
    if (this.lastEmitter != emitter.cnpjCpf) {
      this.lastEmitter = emitter.cnpjCpf;
      this.GetCertificateStatus();
    }
    return `${emitter.razaoSocialNomeCompleto} - ${emitter.cnpjCpf}`;
  }

  GetNomeFantasia(): string {
    let emitter = this.loginService.user.economicGroup.emitters[0];
    return `${emitter.nomeFantasia}`;
  }

  GetCertificateStatus() {
    console.log(this.lastEmitter);
    this.userService.GetCompleteEmitterRegistration(this.lastEmitter).toPromise().then(d => {
      const date = d.dataExpiracaoCertificado.split(' ')[0];

      if (!date) {
        return;
      }

      this.formattedDate = format(date, 'DD/MM/YYYY');

      const isExpired = isBefore(new Date(date), new Date());
      const daysDiff = differenceInDays(new Date(date), new Date())

      if (isExpired) {
        this.certificateMessage = `O seu certificado digital está expirado, para renovar com a Linx , <a href="https://mkt.linx.com.br/certificado-digital" target="_blank">clique aqui.</a>`;
        this.certificateIcon = {
          icon: '../../assets/svg/Unaware_Operation.svg',
          color: 'red'
        }
        return;
      }

      if (daysDiff > 30) {
        this.certificateMessage = `Certificado Válido: ${format(date, 'DD/MM/YYYY')}`;
        this.certificateIcon = {
          icon: '../../assets/svg/done_green.svg',
          color: 'green'
        }
        return;
      }

      this.certificateMessage = `O seu certificado digital vai expirar em ${daysDiff} dias, para renovar com a Linx , <a href="https://mkt.linx.com.br/certificado-digital" target="_blank">clique aqui.</a>`
      this.certificateIcon = {
        icon: '../../assets/svg/priority_high.svg',
        color: 'orange'
      }
    });
  }

  logout() {
    // reset login status
    this.loginService.logout();
  }

  hasRole(role) {
    return this.loginService.hasRole(role);
  }

  openSidebar(): void {
    if (this.opensidebar === "open") {
      this.divLogo.nativeElement.className = "logo-login logo-login-linx-menu";
      this.opensidebar = "close";
    } else {
      this.divLogo.nativeElement.className =
        "logo-login logo-login-linx-menunotsidebar";
      this.opensidebar = "open";
    }
  }

  closeSidebar(): void {
    if (this.opensidebar === "close") {
      this.divSidebar.nativeElement.click();
    }
  }
}
