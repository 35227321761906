import { Component, OnInit,Input, Output, EventEmitter, ElementRef} from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry} from '@angular/material';
import { IconGrid } from '../grid/DTO/icongrid.model';
import { MatIconModule } from '@angular/material/icon';
import { Menu } from '../../sales/DTO/actions.model';
import { GridComponent } from '../grid/grid.component';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})

export class IconComponent implements OnInit {
    
	@Output() eventClick = new EventEmitter();
	@Output() eventAcceptClick = new EventEmitter();
	@Output() eventRejectClick = new EventEmitter();
	@Output() eventOpenClick = new EventEmitter<string>();
	@Output() eventDownloadClick = new EventEmitter<any>();
	@Output() eventUnawareOperationClick = new EventEmitter();
	@Output() eventRefreshGridClick = new EventEmitter();
	@Output() eventRefreshFinish = new EventEmitter();
    @Output() eventDownloadDanfeClick = new EventEmitter<any>();

    @Input() iconGrid:IconGrid;
    @Input() svgIcon:string;
    @Input() visibled:boolean=true;
    @Input() ColumnAction:boolean=false;
    @Input() disabled:boolean=false;
    @Input() classComponent:string;
	@Input() tooltipText: string = "";
	@Input() menu: Menu = null;
	@Input() hasMenu: boolean = false;
    
    constructor(private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer)
    {}

    ngOnInit()
    {
        if(this.iconGrid && this.svgIcon==undefined)
        {
            this.svgIcon = this.iconGrid.name;
        }
        
        if(this.iconGrid && this.iconGrid.url!="")
        {
            this.matIconRegistry.addSvgIcon(
                this.iconGrid.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconGrid.url)
            );  

            if(this.ColumnAction)
                this.classComponent ="grid-mat-icon mat-icon icon-design";
            else
                this.classComponent ="grid-mat-icon mat-icon";
        }
        else
        {
            this.classComponent ="icon " + this.classComponent;
        }

	}

	callClickFunction(func: String, p: any): void {
		switch (func) {
			case 'AcceptClicked':
				this.AcceptClicked();
				break;
			case 'RejeitClicked':
				this.RejeitClicked();
				break;
			case 'UnawareOperationClicked':
				this.UnawareOperationClicked();
				break;
            case 'DownloadDanfeClicked':
				this.DownloadDanfeClicked();
				break;
            case 'DownloadClicked':
                this.DownloadClicked();
                break;
		}
	}

	AcceptClicked(): void {
		this.eventAcceptClick.emit();
	}

	RejeitClicked(): void {
		this.eventRejectClick.emit();
	}

	UnawareOperationClicked(): void {
		this.eventUnawareOperationClick.emit();
	}

    DownloadDanfeClicked(){
        this.eventDownloadDanfeClick.emit();
    }

    DownloadClicked(){
        this.eventDownloadClick.emit();
    }

    clicked():void{
        this.eventClick.emit();
    }
}
