import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DocumentDetails } from '../DTO/sales.model';

@Component({
    selector: 'app-salesubject',
    templateUrl: './salesubject.component.html',
    styleUrls: ['./salesubject.component.scss']
  })

  export class SaleSubjectComponent implements OnInit {

    @Input() dataSource: DocumentDetails;
    @Output() eventRefreshFinish= new EventEmitter();

    constructor(){ }

    ngOnInit() {
    }

    refreshDateSource(_dataSource:DocumentDetails)
    {
        this.dataSource = _dataSource;
        this.FinishDataClicked();
    }
    
    FinishDataClicked():void{
      this.eventRefreshFinish.emit();
    }
  }