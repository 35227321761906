import { Component, OnInit, ViewChild } from '@angular/core';
import { GridColumn } from '../../shared/grid/DTO/gridColumn.model';
import { CreateNewGridColumn } from '../../shared/grid/grid.helper';
import { GridComponent } from '../../shared/grid/grid.component';
import { UserHistoryRS, UserHistoryData } from '../DTO/userHistoryRS';
import { UserHistoryRQ } from '../DTO/userHistoryRQ';
import { ManageUsersService } from '../manage-users.service';
import { UserDocument } from '../DTO/UserDocument';
import { DatePipe } from '@angular/common';
import { GetEnumKeyByEnumValue } from '../../_helpers/enums.helper';
import { DbAction } from '../../_models/enums';

@Component({
	selector: 'app-manageUsersChangeHistory',
	templateUrl: './manageUsersChangeHistory.component.html',
	styleUrls: ['./manageUsersChangeHistory.component.scss']
})
export class ManageUsersChangeHistoryComponent implements OnInit {
	historyGridColumns: GridColumn[] = [];
	UserHistoryDataResponse: UserHistoryRS;
	UserHistoryData: UserHistoryData[];
	currentUser: UserDocument;
	@ViewChild('gridHistory') gridHistory: GridComponent;

	constructor(private manageUsersService: ManageUsersService,
		private datePipe: DatePipe,) { }

	ngOnInit() {
		this.createGrid();
	}

	createGrid() {
		this.historyGridColumns.push(CreateNewGridColumn(300, 'username', 'Usuário', (element: UserHistoryData) => `${element.usuario}`, false, false));
		this.historyGridColumns.push(CreateNewGridColumn(200, 'acao', 'Ação', (element: UserHistoryData) => `${element.acao}`, false, false));
		this.historyGridColumns.push(CreateNewGridColumn(300, 'executor', 'Executor', (element: UserHistoryData) => `${element.executor}`, false, false));
		this.historyGridColumns.push(CreateNewGridColumn(200, 'data', 'Data', (element: UserHistoryData) => `${element.data}`, false, false));
	}

	GetUserHistoryAsync() {
		this.currentUser = this.manageUsersService.GetCurrentUser();

		var request: UserHistoryRQ = {
			userChanged: this.currentUser.usuario
		};

		this.manageUsersService.GetUserHistoryAsync(request).subscribe(data => {
			this.UserHistoryDataResponse = data;
			this.UserHistoryData = [];

			this.UserHistoryDataResponse.history.forEach(dataItem => {
				dataItem.acao = GetEnumKeyByEnumValue(DbAction, dataItem.acao);
				this.UserHistoryData.push(dataItem);


				dataItem.data = this.datePipe.transform(dataItem.data, "dd/MM/yyyy hh:MM:ss")
			});
			this.gridHistory.refreshDateSource(this.UserHistoryData);
		})

		err => { console.log(err); }
	}
}

