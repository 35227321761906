import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, filter, map } from 'rxjs/operators'
import { Config } from '../config';
import { LoginResponse } from './DTO/login-response';
import { Router, NavigationEnd } from '@angular/router';
import { LoginRequest } from './DTO/loginRQ';
import { Role } from '../_models/enums';
import { ToastrService } from 'ngx-toastr';
import { DomeDictionary } from '../dictionary';
import { EventsService, MessageType } from '../_helpers/events.service';
import { ModalService } from '../shared/modal/modal.service';

@Injectable()
export class LoginService {

	user: LoginResponse;
	lastUrl: string;
	dadosEmpresaPendente = {
		inscricaoMunicipal: null,
		regimeTributario: null,
		cnae: null,
		substitutoTributarioICMS: null,
		tipoAtividade: null
	};

	constructor(
		private http: HttpClient,
		private route: Router,
		private toastr: ToastrService,
		private eventsService: EventsService,
		private modalService: ModalService,
		private router: Router
	) {
		this.route.events.pipe(filter(e => e instanceof NavigationEnd))
			.subscribe(
				(e: NavigationEnd) => {
					this.lastUrl = e.url
				});
	}

	getCnpj(): string {
		return this.user.economicGroup.emitters[0].cnpjCpf;
	}

	getMatrizCnpj(): string {
		return this.user.cnpjMatriz;
	}

	async checkLicense() {
		const data = await this.http.get(`${Config.DOMECloud.ValidaLicenca}/${this.getCnpj()}`).toPromise();
		return this.handleLicenseActions(data);
	}

	handleLicenseActions(data) {
		sessionStorage.setItem('licenca', JSON.stringify(data.status));
		sessionStorage.setItem('licencaMensagem', JSON.stringify(data.licencaMensagemResponseDto));
		this.eventsService.broadcast(MessageType.ATUALIZAR_MENU);
		this.eventsService.broadcast(MessageType.ATUALIZA_HEADER);

		if (data.status === 1) {
			this.modalService.modalsData.next(data.licencaMensagemResponseDto);
			this.modalService.open('license-status');
			return true;
		}

		if (data.status === 2) {
			this.router.navigate(['licenca']);
			return false;
		}

		return true;
	}

	unblockAccess() {
		return this.http.post(`${Config.DOMECloud.DesbloqueiaAcesso}/${this.getCnpj()}`, null);
	}

	isLoggedIn(): boolean {
		if (sessionStorage.currentUser) {
			const authData = JSON.parse(sessionStorage.currentUser);
			if (authData)
				this.user = authData;
		}

		return this.user !== undefined;
	}

	GetUserLogin(): LoginResponse {
		if (this.user)
			return this.user;

		if (sessionStorage.currentUser) {
			const authData = JSON.parse(sessionStorage.currentUser);
			if (authData)
				this.user = authData;
		}

		return this.user;
	}

	CheckRolebyUser(role: string) {
		if (sessionStorage.currentUser) {
			const authData = JSON.parse(sessionStorage.currentUser);
			if (authData)
				this.user = authData;
		}
		this.http.get(Config.DOMECloud.ConsultaModulos).subscribe((res: any) => {
			this.user.roles = res.registro.map((modulo: any) => modulo.acoes).flat();
			this.user.roles = this.user.roles.filter((este, i) => {
				return this.user.roles.indexOf(este) === i;
			});
			this.updateUser(this.user);
			if (this.user.roles.filter(x => x == role).length == 0) {
				this.toastr.error(DomeDictionary.SemPermissao);
				this.route.navigate(['/dashboard']);
			}
			this.eventsService.broadcast(MessageType.ATUALIZAR_MENU);
		});
	}


	hasRoleByName(role: string) {
		return this.user.roles.filter(x => x == role).length > 0;
	}

	hasRole(role: Role | string) {
		return this.user.roles.filter(x => x == role).length > 0;
	}

	hasForceChangePassword(): boolean {
		if (sessionStorage.currentUser) {
			const authData = JSON.parse(sessionStorage.currentUser);
			if (authData)
				this.user = authData;
		}

		return this.user.forceChangePassword;
	}

	hasForceCompleRegistration(): boolean {
		if (sessionStorage.currentUser) {
			const authData = JSON.parse(sessionStorage.currentUser);
			if (authData)
				this.user = authData;
		}

		return this.user.forceCompleteRegistration;
	}

	disableForceChangePassword() {
		const authData = JSON.parse(sessionStorage.currentUser);
		authData.forceChangePassword = false;

		this.updateUser(authData);
	}

	disableForceCompleRegistration() {
		const authData = JSON.parse(sessionStorage.currentUser);
		authData.forceCompleteRegistration = false;

		this.updateUser(authData);
	}

	login(request: LoginRequest) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			})
		};

		return this.http
			.post<LoginResponse>(Config.LoginApi.Login, request, httpOptions)
			.pipe(
				tap(data => {
					if (!data.accessToken) return data;
					let user = data;
					this.updateUser(user);
					if (user && user.accessToken) {
						this.http.get(Config.DOMECloud.ConsultaModulos).subscribe((res: any) => {
							this.user.roles = res.registro.map((modulo: any) => modulo.acoes).flat();
							this.user.roles = this.user.roles.filter((este, i) => {
								return this.user.roles.indexOf(este) === i;
							});
							this.eventsService.broadcast(MessageType.ATUALIZAR_MENU);
							this.updateUser(user);
						});
					}
					return data;
				})
			);
	}

	logout() {
		sessionStorage.removeItem("currentUser");
		localStorage.clear();
		sessionStorage.clear();
		this.user = undefined;
		this.handleLogin();
	}

	handleLogin(path: string = this.lastUrl) {
		this.route.navigate(['/login'])
	}

	updateUser(user: LoginResponse) {
		this.user = user;
		sessionStorage.setItem("currentUser", JSON.stringify(this.user));
	}

	getUser(): LoginResponse {
		return this.user;
	}

	tokenExpired(): boolean {
		return new Date(this.user.expiration) < new Date() ? true : false;
	}

	GetAccesses() {
		return this.user.acessos;
	}

	getEmitterData(cnpj: string) {
		return this.http.get(Config.DOMEApi.ConsultaEmpresaPendente + '?cnpj=' + cnpj).pipe(tap((data: any) => { this.dadosEmpresaPendente = data; console.log(this.dadosEmpresaPendente) }));
	}

	updateEmitterData() {
		return this.http.post(Config.DOMEApi.SalvaEmpresaPendente, { ...this.dadosEmpresaPendente, cnpj: this.user.economicGroup.emitters[0].cnpjCpf });
	}

	CheckRolebyUserSync(role: string) {
		return new Promise((resolve, reject) => {
			if (sessionStorage.currentUser) {
				const authData = JSON.parse(sessionStorage.currentUser);
				if (authData)
					this.user = authData;
			}
			this.http.get(Config.DOMECloud.ConsultaModulos).subscribe((res: any) => {
				this.user.roles = res.registro.map((modulo: any) => modulo.acoes).flat();
				this.user.roles = this.user.roles.filter((este, i) => {
					return this.user.roles.indexOf(este) === i;
				});
				this.updateUser(this.user);
				if (this.user.roles.filter(x => x == role).length == 0) {
					this.toastr.error(DomeDictionary.SemPermissao);
					this.route.navigate(['/dashboard']);
				}
				this.eventsService.broadcast(MessageType.ATUALIZAR_MENU);
				resolve(true);
			});
		}).catch(() => true);
	}
}
