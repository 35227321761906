import { Component, OnInit, Input, ViewChild, EventEmitter, Output, ElementRef } from "@angular/core";
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { PurchaseItenDetail } from './DTO/purchaseitensdetail.model';
import { IconGrid } from './../../shared/grid/DTO/icongrid.model';
import { PurchaseitemdetailmodalComponent } from '../purchaseitemdetailmodal/purchaseitemdetailmodal.component';
import { ModalService } from '../../shared/modal/modal.service';
import { PurchaseItenDetailModal } from '../purchaseitemdetailmodal/DTO/purchaseitensdetailmodal.model';
import { PurchaseAuditsDetailComponent } from '../purchaseauditsdetail/purchaseauditsdetail.component';
import { CreateNewGridColumn } from '../../shared/grid/grid.helper';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TaxAuditDetail, PurchaseDetailsDataService } from '../DTO/purchaseData.model';
import { PurchaseService } from "../purchase.service";
import { ToastrService } from "ngx-toastr";
import { LoginService } from "src/app/login/login.service";
import { Role } from "src/app/_models/enums";

@Component({
	selector: 'app-purchaseitensdetail',
	templateUrl: './purchaseitensdetail.component.html',
	styleUrls: ['./purchaseitensdetail.component.scss'],
})

export class PurchaseItensDetailComponent implements OnInit {

	@Input() dataSource: PurchaseItenDetail[];
	@Input() purchaseDetails: PurchaseDetailsDataService;
	@Input() typeDocument: number;
	@Input() dataSourceLocal: PurchaseItenDetailModal;
	@Input() itensdetailGridColumns: GridColumn[] = [];
	@Input() idModal: string;


	@ViewChild('griditems') griditems: GridComponent;
	@ViewChild('itensdetailmodal') itensDetailModal: PurchaseitemdetailmodalComponent;
	@ViewChild('auditsdetail') auditsDetail: PurchaseAuditsDetailComponent;
	@Output() eventRefreshFinish = new EventEmitter();
	@Output() openFornecedor = new EventEmitter();

	icons: IconGrid[] = [];
	purchaseAuditsGridColumns: GridColumn[] = [];

	public Role = Role;

	constructor(private modalService: ModalService,
		private ngxLoader: NgxUiLoaderService,
		private purchaseService: PurchaseService,
		private toastr: ToastrService,
		private loginService: LoginService
	) { }

	ngOnInit() {
		this.icons.push(this.createNewIcon('done-green', '../assets/svg/done_green.svg', 'Item sem ocorrência'));
		this.icons.push(this.createNewIcon('priority-high', '../assets/svg/priority_high.svg', 'Item possui ocorrências de advertências'));
		this.icons.push(this.createNewIcon('clear-red', '../assets/svg/clear_red.svg', 'Item possui ocorrências de erros'));
		this.icons.push(this.createNewIcon('circle-black', '../assets/svg/circle_black.svg', 'Ocorreu uma falha'));
		this.icons.push(this.createNewIcon('circle-yellow', '../assets/svg/circle_yellow.svg', 'Operação Pendente'));

		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(600, 'descricaoErrors', 'Ocorrência', (element: any) => `${element.descricaoError}`));
		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(300, 'codErrors', 'Código Atual', (element: any) => `${element.original}`));
		this.purchaseAuditsGridColumns.push(CreateNewGridColumn(300, 'sugestaoErrors', 'Sugestão Auditoria', (element: any) => `${element.sugestaoError}`));
	}

	createNewIcon(_name: string, _url: string, _tooltip: string = ''): IconGrid {
		return {
			name: _name,
			url: _url,
			tooltip: _tooltip
		}
	}

	hasRole(role: string): boolean {
		return this.loginService.hasRole(role);
	}

	refreshDateSource(_dataSource: PurchaseItenDetail[]) {
		this.dataSource = _dataSource;

		this.griditems.refreshDateSource(this.dataSource);
	}

	openNewFornecedor(cnpj) {
		this.openFornecedor.emit(cnpj);
	}

	RefreshFinishClicked(): void {
		this.eventRefreshFinish.emit();
	}

	OpenClicked(p: any): void {
		this.dataSourceLocal = p.data;
		this.itensDetailModal.refreshDateSource(this.dataSourceLocal);
		if (this.hasRole(Role.VACINA_NOTA)) {
			this.auditsDetail.RefreshDataSource(this.purchaseDetails.taxAuditDetails.vaccines, this.dataSourceLocal.numero);
		}
		this.modalService.open('purchase-item-detail-modal');
	}

	OpenModal(id: string) {
		this.modalService.open(id);
	}

	CloseModal(id: string) {
		this.modalService.close(id);
	}

	FinishDataClicked(): void {
		this.ngxLoader.stop();
	}

	remanifestation() {
		const payload = {
			headers: {},
			body: {
				cnpj: this.purchaseDetails.cnpjcomprador,
				xmlKey: this.purchaseDetails.nota.chave_acesso
			}
		}

		if (this.purchaseDetails.emProcessamento) {
			this.displayErrorToast('Esta nota já está sendo reprocessada, aguarde alguns minutos e realize nova consulta da nota.');
			return;
		}

		this.ngxLoader.start();

		this.purchaseService.remanifestation(payload).subscribe((data) => {
			if (data.sucesso) {
				this.toastr.success('Nota enviada para reprocessamento com sucesso, aguarde alguns minutos e realize nova consulta da nota.');
				this.purchaseDetails.emProcessamento = true;
			} else {
				this.displayErrorToast(data.mensagem);
			}
			this.ngxLoader.stop();
		}, (err) => {
			this.displayErrorToast(err.mensagem);
			this.ngxLoader.stop();
		});
	}

	displayErrorToast(message: string) {
		this.toastr.error(message || 'Ocorreu um erro ao enviar nota para reprocessamento, por favor tente novamente mais tarde.');
	}
}