export enum TipoManifestacao {
	Accept = 0,
	Reject = 1,
	UnrealizedOperation = 2,
	Waiting = 3,
	Aware = 4
}

export enum StatusManifestacao {
	Pendente = 3,
	Manifestado = 4,
	OperacaoRealizada = 0,
	OperacaoNaoRealizada = 2,
	DesconhecimentoDaOperacao = 2,
	Erro = 5,
	Desacordo = 1
}

export enum UFs {
	AC = 1,
	AL = 2,
	AM = 3,
	AP = 4,
	BA = 5,
	CE = 6,
	DF = 7,
	ES = 8,
	GO = 9,
	MA = 10,
	MG = 11,
	MS = 12,
	MT = 13,
	PA = 14,
	PB = 15,
	PE = 16,
	PI = 17,
	PR = 18,
	RJ = 19,
	RN = 20,
	RO = 21,
	RR = 22,
	RS = 23,
	SC = 24,
	SE = 25,
	SP = 26,
	TO = 27
}


export enum RejectionTreatmentLinkShare {
	REJECTION_203 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=153687582" target="_blank">Saiba Mais</a>',
	REJECTION_217 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=189609591" target="_blank">Saiba Mais</a>',
	REJECTION_237 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=189609598" target="_blank">Saiba Mais</a>',
	REJECTION_241 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=189609584" target="_blank">Saiba Mais</a>',
	REJECTION_391 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=189609606" target="_blank">Saiba Mais</a>',
	REJECTION_590 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=189609614" target="_blank">Saiba Mais</a>',
	REJECTION_750 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=189609621" target="_blank">Saiba Mais</a>',
	REJECTION_778 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=189609625" target="_blank">Saiba Mais</a>',
	REJECTION_781 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=153687565" target="_blank">Saiba Mais</a>',
	REJECTION_883 = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=189609629" target="_blank">Saiba Mais</a>',
	REJECTION_DEFAULT = '<a href="https://share.linx.com.br/pages/viewpage.action?pageId=153687564" target="_blank">Saiba Mais</a>'
}

export enum IdPerfil {

	"Admin" = 1,
	"Suporte" = 2,
	"Master" = 3,
	"Contador" = 4,
	"Analista" = 5,
	"Operacional Compras" = 6,
	"Operacional Vendas" = 7

}

export enum DbAction {
	"Create" = 1,
	"Read" = 2,
	"Update" = 3,
	"Delete" = 4
}

export enum Role {
	CALENDARIO = 'CALENDARIO',
	DECLARACOES_FISCAIS = 'DECLARACOES_FISCAIS',
	GUIA_IMPOSTO = 'GUIA_IMPOSTO',
	NOTA_DE_SAIDA = 'NOTA_DE_SAIDA',
	NOTA_DE_ENTRADA = 'NOTA_DE_ENTRADA',
	DASHBOARD = 'DASHBOARD',
	INSIGHTS = 'INSIGHTS',
	FAQ = 'FAQ',
	INTEGRACAO_CONTADOR = 'INTEGRACAO_CONTADOR',
	DOWNLOAD_FISCAL = 'DOWNLOAD_FISCAL',
	MEMORIA_CALCULO = 'MEMORIA_CALCULO',
	ALTERAR_MANIFESTACAO = 'ALTERAR_MANIFESTACAO',
	ACEITE_INSIGHT = 'ACEITE_INSIGHT',
	GERENCIAR_USUARIOS = 'GERENCIAR_USUARIOS',
	EDITAR_USUARIOS = 'EDITAR_USUARIOS',
	VISUALIZAR_DETALHES_USUARIOS = 'VISUALIZAR_DETALHES_USUARIOS',
	CONSULTAR_USUARIOS = 'CONSULTAR_USUARIOS',
	CRIAR_USUARIOS = 'CRIAR_USUARIOS',
	DASHBOARD_ENTRADAS = 'DASHBOARD_ENTRADAS',
	DASHBOARD_SAIDAS = 'DASHBOARD_SAIDAS',
	DASHBOARD_AUDITOR = 'DASHBOARD_AUDITOR',
	DASHBOARD_INSIGHTS = 'DASHBOARD_INSIGHTS',
	DASHBOARD_MANIFESTACAO = 'DASHBOARD_MANIFESTACAO',
	CALENDARIO_EVENTOS = 'CALENDARIO_EVENTOS',
	CALENDARIO_EXTRACAO = 'CALENDARIO_EXTRACAO',
	MANIFESTAR = 'MANIFESTAR',
	EXTRACAO_RELATORIO = 'EXTRACAO_RELATORIO',
	SOLICITAR_RELATORIO = 'SOLICITAR_RELATORIO',
	DOWNLOAD_NOTA = 'DOWNLOAD_NOTA',
	EFETUAR_CONVERSAO = 'EFETUAR_CONVERSAO',
	CONSULTA_CONVERSAO = 'CONSULTA_CONVERSAO',
	VER_FORNECEDOR_FINALIDADE = 'VER_FORNECEDOR_FINALIDADE',
	VER_CFOP = 'VER_CFOP',
	EDIT_FORNECEDOR_FINALIDADE = 'EDIT_FORNECEDOR_FINALIDADE',
	EDITAR_CFOP = 'EDITAR_CFOP',
	VENDAS_REJEICOES = 'VENDAS_REJEICOES',
	VENDAS_EMISSOES = 'VENDAS_EMISSOES',
	OBRIGACOES_FISCAIS = 'OBRIGACOES_FISCAIS',
	VENDAS_CONTINGENCIA = 'VENDAS_CONTINGENCIA',
	VENDAS_INUTILIZACOES = 'VENDAS_INUTILIZACOES',
	VENDAS_DOWNLOAD = 'VENDAS_DOWNLOAD',
	VISAO_SAIDA = 'VISAO_SAIDA',
	INCONSISTENCIAS_ENTRADAS = 'INCONSISTENCIAS_ENTRADAS',
	VACINA_NOTA = 'VACINA_NOTA',
	COL_AUDITOR = 'COL_AUDITOR',
}

export enum NFeMod{
	NFe = 55,
	NFCe = 65,
	CFe = 59
}