import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ExportMateralModule } from '../exportmateralmodule';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridComponent } from './grid/grid.component';
import { IconComponent } from './icon/icon.component';
import { MonthCalendarComponent } from './calendar/month/month-calendar.component';
import MonthCalendarDateFormatter from './calendar/customs/custom-date-formatter';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ChartsComponent } from './charts/charts.component';
import { ChartModule } from 'angular-highcharts';
import { HorizontalTimelineComponent } from './Horizontal-timeline/horizontal-timeline.component';
import { LoginService } from '../login/login.service';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';
import { LoggedInGuard } from '../login/loggedin.guard';
import { Authinterceptor } from '../login/auth.intercept';
import { FilterComponent } from './filter/filter.component';
import { PercentualBarComponent } from './percentual-bar/percentual-bar.component';
import { InputToggleComponent } from './input-toggle/input-toggle.component';
import { MathjaxComponent } from './mathjax/mathjax.component';
import { ModalComponent } from './modal/modal.component';
import { ConformityFilterComponent } from '../conformity/conformityfilter/conformityfilter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountantIntegrationFilterComponent } from '../accountantintegration/accountantintegrationfilter/accountantintegrationfilter.component';
import { TimelineMarkerComponent } from './Horizontal-timeline/timeline-marker/timeline-marker.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { CurrentDateComponent } from './current-date/current-date.component';
import { PurchaseDocumentTypeFilterComponent } from './purchase-document-type-filter/purchase-document-type-filter.component';

@NgModule({
    declarations: [
        GridComponent,
        ChartsComponent,
        MonthCalendarComponent,
        IconComponent,
        HorizontalTimelineComponent,
        FilterComponent,
        PercentualBarComponent,
        InputToggleComponent,
        MathjaxComponent,
        ModalComponent,
        ConformityFilterComponent,
        AccountantIntegrationFilterComponent,
        TimelineMarkerComponent,
        BreadcrumbComponent,
        CurrentDateComponent,
        PurchaseDocumentTypeFilterComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ExportMateralModule,
        BrowserModule,
        BrowserAnimationsModule,
        ChartModule,
        RouterModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        MatNativeDateModule,
        NgbModule
    ],
    exports: [
        GridComponent,
        ChartsComponent,
        MonthCalendarComponent,
        IconComponent,
        HorizontalTimelineComponent,
        FilterComponent,
        PercentualBarComponent,
        InputToggleComponent,
        MathjaxComponent,
        ModalComponent,
        ConformityFilterComponent,
        AccountantIntegrationFilterComponent,
        CurrentDateComponent,
        BreadcrumbComponent,
        PurchaseDocumentTypeFilterComponent
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: CalendarDateFormatter, useClass: MonthCalendarDateFormatter },
        DatePipe,
        LoggedInGuard,
        { provide: HTTP_INTERCEPTORS, useClass: Authinterceptor, multi: true }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],

})

export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                LoginService,
                LoggedInGuard
            ]
        }
    }
}