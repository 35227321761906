import { Component, OnInit, Input } from '@angular/core';
import {FormGroup, FormBuilder, Validators, AbstractControl, FormControl} from '@angular/forms'
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-usersignaturedetail',
    templateUrl: './usersignaturedetail.component.html',
    styleUrls: ['./usersignaturedetail.component.scss']
  })

export class UserSignatureDetailComponent implements OnInit {
    dataSource:any;
    signatureForm:FormGroup;

    constructor(private formBuilder:FormBuilder,
        private datePipe: DatePipe,) { }

    ngOnInit() {
        this.signatureForm = this.formBuilder.group({
            produto: this.formBuilder.control(''),
            activationDate: this.formBuilder.control(''),
          })
    }
    refreshDateSource(_dataSource:any)
    {
        this.dataSource=_dataSource;

        this.signatureForm.controls.produto.setValue(this.dataSource.modules[0].module.SubscriptionName);
        this.signatureForm.controls.activationDate.setValue(this.datePipe.transform(this.dataSource.users[0].activationDate, "dd/MM/yyyy hh:MM:ss"));

    }
}