import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, ChangeDetectorRef } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatCheckbox } from '@angular/material';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { GridColumn } from './DTO/gridColumn.model';
import { IconGrid } from './DTO/icongrid.model';
import { IconComponent } from '../icon/icon.component';
import { stringify } from 'querystring';

@Component({
	selector: 'app-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
})

export class GridComponent implements OnInit {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	@ViewChild('gridicon') gridicon: IconComponent;

	@Input() gridcolumns: GridColumn[];
	@Input() datagrid: any[];

	@Input() title: string;
	@Input() titleRefresh: string;
	@Input() textoComplementarBotaoAtualizar: string;
	@Input() enabledRefresh: boolean = false;
	@Input() classRefresh: string;

	@Input() icons: IconGrid[];
	@Input() pageSize: number

	@Output() eventAcceptClick = new EventEmitter();
	@Output() eventRejectClick = new EventEmitter();

	@Output() eventOpenClick = new EventEmitter<string>();
	@Output() eventDownloadClick = new EventEmitter<any>();
	@Output() eventUnawareOperationClick = new EventEmitter();
	@Output() eventDownloadDanfeClick = new EventEmitter<any>();

	@Output() eventRefreshGridClick = new EventEmitter();
	@Output() eventRefreshFinish = new EventEmitter();

	@Output() eventOpenDeclarationCalcClicked = new EventEmitter();
	@Output() eventCheckBoxFullClick = new EventEmitter();
	@Output() eventCheckBoxLineClick = new EventEmitter();

	@Input() classTitle: string;
	//@ViewChild('chkFull') chkFull: MatCheckbox;
	@Input() enabledPaginator: boolean = true;
	@Input() enabledFilterGrid: boolean = true;
	@Input() classGridMain: string = "grid-main";
	@Input() classGridMainCuston: string = null;

	checkTodos: boolean = false;
	lstCheckLine: boolean[] = [];
	displayedColumns: string[];
	dataSource: MatTableDataSource<any>;
	resultsLength: number = 0;

	constructor(private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		if (this.pageSize == null)
			this.pageSize = 25

		if (this.classGridMainCuston != null) {
			this.classGridMain = this.classGridMainCuston;
		}

		if (this.icons != undefined && this.icons != null) {
			this.icons.forEach(element => {
				this.matIconRegistry.addSvgIcon(
					element.name,
					this.domSanitizer.bypassSecurityTrustResourceUrl(element.url)
				);
			});
		}
		this.displayedColumns = this.gridcolumns.map(c => c.columnDef);

		if (this.datagrid == null)
			this.datagrid = [];

		this.resultsLength = (this.datagrid != undefined && this.datagrid != null ? this.datagrid.length : 0);

		this.dataSource = new MatTableDataSource(this.datagrid);
		this.changeDetector.detectChanges();
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;


		if (this.datagrid.length > 0 && this.datagrid[0].checkbox && !this.datagrid.some(x => x.checkbox.checked) && (this.lstCheckLine == undefined || (this.lstCheckLine && this.lstCheckLine.length === 0))) {
			this.initCheck();
		}

		this.FinishDataClicked();
	}

	initCheck(): void {
		this.lstCheckLine = [];
		for (let i = 0; i <= this.datagrid.length - 1; i++) {
			this.lstCheckLine.push(false);
			this.datagrid[i].checkbox.checked = false;
		}
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	refreshDateSource(_datagrid: any[], _gridcolumns: GridColumn[] = []) {
		this.cleanCheckLine();
		if (_gridcolumns != [] && _gridcolumns.length > 0) {
			this.gridcolumns = [];
			this.gridcolumns = _gridcolumns;
			this.displayedColumns = this.gridcolumns.map(c => c.columnDef);
		}

		if (this.datagrid == null)
			this.datagrid = [];

		this.datagrid = _datagrid;

		this.resultsLength = (this.datagrid != undefined && this.datagrid != null ? this.datagrid.length : 0);

		this.dataSource = new MatTableDataSource(this.datagrid);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		if (this.datagrid && this.datagrid.length > 0 && this.datagrid[0].checkbox && !this.datagrid.some(x => x.checkbox.checked) && (this.lstCheckLine == undefined || (this.lstCheckLine && this.lstCheckLine.length === 0))) {
			this.initCheck();
		}
		this.FinishDataClicked();
	}

	cleanCheckLine() {
		this.lstCheckLine = this.lstCheckLine.fill(false);
	}

	getIconTooltip(value: string): string {
		if (this.icons) {
			let icon = this.icons.find(i => i.name == value);
			if (icon)
				return icon.tooltip;
		}

		return '';
	}

	getIconClassComponent(value: string): string {
		if (this.icons) {
			let icon = this.icons.find(i => i.name == value);
			if (icon && icon.classComponente != undefined)
				return icon.classComponente + " grid-mat-icon";
			else
				return "grid-mat-icon";
		}

		return '';
	}

	getIcon(value: string): IconGrid {
		if (this.icons) {
			let icon = this.icons.find(i => i.name == value);
			if (icon)
				return icon;
		}

		return undefined;
	}

	callClickFunction(func: String, eventRow: any): void {
		switch (func) {
			case 'AcceptClicked':
				this.AcceptClicked(eventRow);
				break;
			case 'RejeitClicked':
				this.RejeitClicked(eventRow);
				break;
			case 'DownloadClicked':
				this.DownloadClicked(eventRow);
				break;
			case 'DownloadDanfeClicked':
				this.DownloadDanfeClicked(eventRow);
				break;
			case 'UnawareOperationClicked':
				this.UnawareOperationClicked(eventRow);
				break;
			case 'SelectedItem':
				this.SelectedItem(eventRow);
				break;
			case 'HasMenu':
				break;
			default:
				eventRow.action = func;
				this.OpenClicked(eventRow);
				break;
		}
	}

	AcceptClicked(eventRow: any): void {
		this.eventAcceptClick.emit(eventRow);
	}
	RejeitClicked(eventRow: any): void {
		this.eventRejectClick.emit(eventRow);
	}
	OpenClicked(eventRow: any): void {
		this.eventOpenClick.emit(eventRow);
	}

	SelectedItem(eventRow: any): void {
		this.eventOpenClick.emit(eventRow);
	}
	DownloadClicked(eventRow: any): void {
		this.eventDownloadClick.emit(eventRow);
	}
	DownloadDanfeClicked(eventRow: any): void {
		this.eventDownloadDanfeClick.emit(eventRow);
	}
	UnawareOperationClicked(eventRow: any): void {
		this.eventUnawareOperationClick.emit(eventRow);
	}
	RefreshGridClicked(): void {
		this.eventRefreshGridClick.emit();
	}

	FinishDataClicked(): void {
		this.eventRefreshFinish.emit();
	}
	OpenDeclarationCalcClicked(): void {
		this.eventOpenDeclarationCalcClicked.emit();
	}

	checkFullLine(p: any): void {
		this.checkTodos = p.checked;
		if (this.datagrid.length > 0 && this.datagrid[0].checkbox && !this.datagrid.some(x => x.checkbox.checked) && (this.lstCheckLine == undefined || (this.lstCheckLine && this.lstCheckLine.length === 0))) {
			this.initCheck();
		}
		for (let i = 0; i <= this.datagrid.length - 1; i++) {
			if (!this.datagrid[i].checkbox.disabled) {
				this.datagrid[i].checkbox.checked = p.checked;
				this.lstCheckLine[i] = p.checked;
			}
		}

		this.eventCheckBoxFullClick.emit();
	}

	checkLine(p: any, index: number, row: any): void {
		this.checkTodos = false;
		if (this.datagrid.length > 0 && this.datagrid[0].checkbox && !this.datagrid.some(x => x.checkbox.checked) && (this.lstCheckLine == undefined || (this.lstCheckLine && this.lstCheckLine.length === 0))) {
			this.initCheck();
		}
		row.checkbox.checked = p.checked;
		this.lstCheckLine[index] = p.checked;

		this.eventCheckBoxLineClick.emit();
	}

	GetCheckLine(): any {
		return {
			blnFullCheckBox: this.checkTodos,
			listCheckBox: this.lstCheckLine
		}


	}
	GetCheckedObject(): any {
		let row = this.datagrid.filter(r => r.checkbox.checked);
		console.log(row);
		return row
	}



	private isString(x: any): x is string {
		return typeof x === "string";
	}
}
