import { Injectable } from '@angular/core';
import { FaqResponse } from './DTO/faq.model';
import { Config } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpClient) {
  }

  GetFaq(): Observable<FaqResponse[]> {
    return this.http
      .get<FaqResponse[]>(Config.DOMEApi.GetFaq)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}

