export function isNullOrEmpty(value: string) {
    /*
    Because the following values are always false
    false (the boolean false)
    0 (the number zero)
    '' or "" (an empty string)
    null
    undefined
    NaN
    */
    if (!value) {
        return true;
    }
    return false;
};