import { OnInit, Component, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry, MatSelect } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ConformityFilterModel } from './DTO/conformityfilter.model';
import { MonthsModel, MonthList } from 'src/app/_helpers/dateTime.helper';

@Component({
    selector: 'app-conformityfilter',
    templateUrl: './conformityfilter.component.html',
    styleUrls: ['./conformityfilter.component.scss']
})
export class ConformityFilterComponent implements OnInit {
    selectMonths = new FormControl();
    @ViewChild('selectMonth') selectMonth: MatSelect;
    @Input() enabledStatusPayment: boolean = false;
    @Input() enabledBtnSendEmail: boolean = false;
    @Input() enabledBtnSearch: boolean = true;
    @Input() enabledBtnExport: boolean = false;
    @Input() enabledBtnRequestFileExtraction: boolean = false;
    @Input() showLabel: boolean = false;
    @Input() label: string = '';

    @Input() enabledMultiple: boolean = false;
    @Input() enabledTypeDocument: boolean = false;
    @Input() enabledTypeFlow: boolean = false;
    @Input() enabledReportType: boolean = false;
    @Input() enabledPeriod: boolean = false;
    @Input() hideMonthYearPeriod: boolean = false;

    strYearOld: string = (new Date().getFullYear() - 1).toString();
    strYear: string = new Date().getFullYear().toString();
    strMonths: string = (new Date().getMonth() + 1).toString();

    //seta posição do dropdown
    SelectedCompentencyYear: string = this.strYear;
    SelectedCompentencyMonth: string = this.strMonths;
    SelectedStatus: string = "0";
    SelectedTypeDocument: string = "1";
    SelectedTypeFlow: string = "1";

    CompentencyYears: string[] = [this.strYearOld, this.strYear];
    CompentencyMonths: MonthsModel[] = MonthList;

    selectedItems: any;

    AllowedStatus: any[] = [
        {
            label: "Todos",
            value: "0",
            select: true,
        },
        {
            label: "Não realizado",
            value: "1",
            select: false
        },
        {
            label: "Realizado",
            value: "2",
            select: false
        }
    ];

    TypeFlow: any[] = [

        {
            label: "Xml de Entrada",
            value: "0",
            select: false,
        },
        {
            label: "Xml de Saída",
            value: "1",
            select: true
        }
    ];

	TypeReport: any[] = [
        {
            label: "Livro de Entradas P1",
            value: "0",
            select: false,
        },
        {
            label: "Livro de Saídas P2",
            value: "1",
            select: true
        }
    ];

    TypeDocument: any[] = [
        {
            label: "Todos",
            value: "0",
            type: 0,
            select: true,
        },
        {
            label: "Todos",
            value: "99",
            type: 1,
            select: true,
        },
        {
            label: "NF-e",
            value: "1",
            type: 0,
            select: true,
        },
        {
            label: "NF-e",
            value: "3",
            type: 1,
            select: false,
        },
        {
            label: "CF-e",
            value: "5",
            type: 1,
            select: true,
        },
        {
            label: "NFC-e",
            value: "4",
            type: 1,
            select: false,
        },
    ];

    dataTypeDocument: any[] = [];
	SelectedTypeReport: string;

    @Input() dateIn = new FormControl(new Date());
    @Input() dateOut = new FormControl(new Date());

    @Input() typeFilter: number;

    @Output() eventSearchClick = new EventEmitter();
    @Output() eventStartLoading = new EventEmitter();
    @Output() eventExportClick = new EventEmitter();
    @Output() eventSendEmailTransmClick = new EventEmitter();
    @Output() eventRequestFileExtraction = new EventEmitter();

    filterDateIn = (d: Date): boolean => {
        return d <= this.dateIn.value;
    }

    filterDateFin = (d: Date): boolean => {
        return d >= this.dateOut.value;
    }

    constructor(private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
        if (this.enabledMultiple) {
            this.selectedItems = [];
            for (var i = new Date().getMonth() - 1; i <= new Date().getMonth() + 1; i++) {
                this.selectedItems.push(i.toString());
            }
        }
        else {
            this.selectedItems = "";
            this.selectedItems = (new Date().getMonth() + 1).toString();
        }
        this.getTypeDocument();
        this.filterDate();

        this.matIconRegistry.addSvgIcon(
            'search',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/search.svg')
        );
    }

    GetFilterData(): ConformityFilterModel {
        return {
            dateIn: this.dateIn.value,
            dateOut: this.dateOut.value,
            statusPayment: Number(this.SelectedStatus),
            typeDocument: Number(this.SelectedTypeDocument),
        };
    }


    filterDate(): void {
        if (this.enabledBtnSearch) {
            this.startLoading();
        }
        if (this.enabledMultiple) {
            if (this.selectedItems.length == 1) {
                this.dateIn = new FormControl(new Date(Number(this.SelectedCompentencyYear), (Number(this.selectedItems[0]) - 1), 1));
                this.dateOut = new FormControl(new Date(Number(this.SelectedCompentencyYear), Number(this.selectedItems[0]), 0));
            }
            else {
                this.dateIn = new FormControl(new Date(Number(this.SelectedCompentencyYear), (Number(this.selectedItems[0]) - 1), 1));
                this.dateOut = new FormControl(new Date(Number(this.SelectedCompentencyYear), Number(this.selectedItems[this.selectedItems.length - 1]), 0));
            }
        }
        else {
            this.dateIn = new FormControl(new Date(Number(this.SelectedCompentencyYear), (Number(this.selectedItems) - 1), 1));
            this.dateOut = new FormControl(new Date(Number(this.SelectedCompentencyYear), Number(this.selectedItems), 0));
        }

        if (this.enabledBtnSearch) {
            this.eventSearchClick.emit();
        }
    }

    startLoading(): void {
        this.eventStartLoading.emit();
    }

    GetMonthText(month: string): string {
        let itemmonth = this.CompentencyMonths.filter(x => x.value == month);
        return itemmonth && itemmonth.length > 0 ? itemmonth[0].label : '';
    }

    exportClicked(): void {
        this.eventExportClick.emit();
    }
    sendEmailTransmClicked(): void {
        this.eventSendEmailTransmClick.emit();
    }
    requestClicked(): void {
        this.eventRequestFileExtraction.emit();
    }

    getTypeDocument() {
        if (this.SelectedTypeFlow == "0") {
            this.SelectedTypeDocument = "0";
        }
        else {
            this.SelectedTypeDocument = "99";
        }
        this.dataTypeDocument = this.TypeDocument.filter(x => x.type == this.SelectedTypeFlow);
    }

	getTypeReport() {
        if (this.SelectedTypeReport == "0") {
            this.SelectedTypeDocument = "0";
        }
        else {
            this.SelectedTypeDocument = "1";
        }
        this.dataTypeDocument = this.TypeDocument.filter(x => x.type == this.SelectedTypeReport);
    }
}
