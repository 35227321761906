import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';

@Injectable({
  providedIn: 'root'
})
export class GerenciarPerfisService {

  constructor(private http: HttpClient) { }

  getProfiles() {
    return this.http.get(Config.DOMECloud.ConsultaPerfis);
  }

  getAllModules() {
    return this.http.get(Config.DOMECloud.ConsultaTodosModulos);
  }

  updateProfile(data) {
    return this.http.put(Config.DOMECloud.ConsultaPerfis, data);
  }

  createProfile(data) {
    return this.http.post(Config.DOMECloud.ConsultaPerfis, data);
  }

  deleteProfile(id) {
    return this.http.delete(Config.DOMECloud.ConsultaPerfis + '?idPerfil=' + id);
  }


}
