import { Component, OnInit, Input} from "@angular/core";
import { tabs } from './DTO/tabs.model';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})

export class TabsComponent implements OnInit {

    @Input() dynamicTabs: tabs[]=[];
    dynamicTabsLocal: tabs[]=[];
    selected = new FormControl(0);
    constructor(){}

    ngOnInit() {
        /*if (this.dynamicTabs!=undefined)
            this.dynamicTabsLocal = this.dynamicTabs;
        else
            this.dynamicTabsLocal =[];
        if (this.dynamicTabsLocal !=null && this.dynamicTabsLocal.length>0)
            this.selected.setValue(0);*/
    }

    refresh(_dynamicTabs: tabs[])
    {
        //this.dynamicTabsLocal = _dynamicTabs;
        //this.dynamicTabs = _dynamicTabs;
        this.dynamicTabsLocal=[];
        for(let tab of _dynamicTabs)
        {
            this.addTab(tab);
        }

        if (this.dynamicTabsLocal !=null && this.dynamicTabsLocal.length>0)
            this.selected.setValue(0);
    }
    addTab(tab:tabs) {
        this.dynamicTabsLocal.push(tab)
    }
    SetTabIndex(index:number)
    {
        this.selected.setValue(index);
    }
}