import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InsightsRQ } from './DTO/insightsRQ';
import { Config } from '../config';
import { InsightsRS } from './DTO/insightsRS';
import { ConsultaRQ } from './DTO/consultasRQ';
import { ConsultaRS } from './DTO/consultasRS';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { SetInsightRQ } from './DTO/setInsightRQ.model';
import { DefaultResponse } from '../_models/default-response';
import { GetDataTaxRulesRS } from './DTO/getDataTaxRulesRS';
import { GetDataTaxRulesRQ } from './DTO/getDataTaxRulesRQ';

@Injectable({
  providedIn: "root"
})
export class InsightsService {

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  GetInsightsTrib(_request: InsightsRQ): Observable<InsightsRS> {
    return this.http
      .post<InsightsRS>(Config.DOMEApi.GetInsightsTrib, _request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  SetInsight(request: SetInsightRQ): Observable<DefaultResponse> {

    return this.http
      .put<DefaultResponse>(Config.DOMEApi.SetInsight, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  SetInsightList(request: SetInsightRQ[]): Observable<DefaultResponse> {

    return this.http
      .put<DefaultResponse>(Config.DOMEApi.SetInsightList, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetTaxRulesAsync(request: ConsultaRQ): Observable<ConsultaRS> {
    return this.http
      .post<ConsultaRS>(Config.DOMEApi.GetTaxRules, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  GetDataTaxRulesAsync(request: GetDataTaxRulesRQ): Observable<GetDataTaxRulesRS> {
    return this.http
      .post<GetDataTaxRulesRS>(Config.DOMEApi.GetDataTaxRules, request)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
