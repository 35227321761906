import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { IconGrid } from 'src/app/shared/grid/DTO/icongrid.model';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { ConformityTaxesModel } from '../DTO/conformity.model';
import { ModalService } from 'src/app/shared/modal/modal.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CreateNewGridColumn, CreateLabel, CreateNewIcon } from 'src/app/shared/grid/grid.helper';
import { GetDataForFileName } from 'src/app/_helpers/dateTime.helper';
import { CalculoDASComponent } from '../calculo-das/calculo-das.component';
import { memoriaDeCalculoDAS } from './../DTO/GetTaxCalendarsEventsRS.model';
import { CreateGridAction } from '../../shared/grid/grid.helper';
import { ConformityService } from '../conformity.service';
import { GenerateDocumentModel } from '../DTO/GenerateDocument.model';
import { DomeDictionary } from 'src/app/dictionary';
import { UserActionRQ } from '../../common/logger/DTO/UserActionRQ';
import { CheckboxColumn } from 'src/app/shared/grid/DTO/checkboxColumn.model';

@Component({
  selector: 'app-conformitytaxguide',
  templateUrl: './conformitytaxguide.component.html',
  styleUrls: ['./conformitytaxguide.component.scss']
})

export class ConformityTaxGuideComponent implements OnInit {
  icons: IconGrid[] = [];
  ConformityTaxesGridColumns: GridColumn[] = [];
  @ViewChild('gridconformitytaxes') gridconformitytaxes: GridComponent;
  ConformityTaxes: ConformityTaxesModel[] = [];
  @ViewChild('dwnldLnk') dwnldLnk: ElementRef;
  @ViewChild('textFaixaTax') textFaixaTax: ElementRef;
  @ViewChild('textFaixaICMSTax') textFaixaICMSTax: ElementRef;
  @ViewChild('dascalculationn') dascalculation: CalculoDASComponent;
  @Output() eventRefreshGrid = new EventEmitter();
  mathContent: string;
  positionTaxCalendar: number = 0;
  memoriaDeCalculoDAS: memoriaDeCalculoDAS;
  enabledRefresh: boolean;
  classRefresh: string;
  titleRefresh: string;

  constructor(private ngxLoader: NgxUiLoaderService,
    private modalService: ModalService,
    private conformityService: ConformityService,
    private toastr: ToastrService) {
    this.ConformityTaxesGridColumns.push(this.createNewGridColumn(200, 'tipo', 'Tipo', (element: any) => `${element.tipo}`, false, false, false, null, "", 200));
    this.ConformityTaxesGridColumns.push(this.createNewGridColumn(300, 'nomeDocumento', 'Nome', (element: any) => `${element.nomeDocumento}`, false, false, false, null, "", 300));
    this.ConformityTaxesGridColumns.push(this.createNewGridColumn(300, 'action', 'Ação', (element: any) => `${element.actions}`, false, true, false, null, "iconaction-align-start"));
    this.enabledRefresh = true;
    this.titleRefresh = "Atualização";
    this.classRefresh = "div-refresh";
  }

  ngOnInit() {
  }

  refreshDataSource(_dataSource: any) {
    if (_dataSource.length > 0) {
      this.ConformityTaxes = _dataSource;
    }
    else {
      this.ConformityTaxes = [];
    }

    this.gridconformitytaxes.refreshDateSource(this.ConformityTaxes);
  }

  createNewGridColumn(_width: number,
    _columnDef: string,
    _header: string,
    _cell: any,
    _enabledIcon: boolean = false,
    _enabledAction: boolean = false,
    _enabledCheckbox: boolean = false,
    _checkbox: CheckboxColumn = null,
    _classAlignAction: string = "",
    _widthmax: number = 0,
  ): GridColumn {
    return {
      width: _width,
      columnDef: _columnDef,
      header: _header,
      cell: _cell,
      checkbox: _checkbox,
      enabledCheckbox: _enabledCheckbox,
      enabledIcon: _enabledIcon,
      enabledAction: _enabledAction,
      classAlignAction: _classAlignAction,
      widthmax: _widthmax
    }
  }

  GenerateDocument(p: any): void {
    var request: GenerateDocumentModel = { idCalendarDocument: p.data.id };
    var dayOfMonth = new Date().getDate();

    if (dayOfMonth >= 15 && dayOfMonth <= 20) {
      this.conformityService.GenerateDocument(request).subscribe(
        data => {
          let action = p.data.actions.find(x => x.iconclass === "generate-das");

          p.data.actions.splice(p.data.actions.indexOf(action), 1);
          p.data.actions.push(CreateGridAction('download', "DownloadClicked", CreateNewIcon('download', ''), 'icon-lx-arrow-circle-down mat-icon icon-design action-icon', 'Solicitação em andamento.'));
          p.data.documentoPendente = true;
        },
        err => {
          this.toastr.info(DomeDictionary.FalhaSolicitacaoDAS, "");
        }
      );
    }
    else {
      this.toastr.info(DomeDictionary.ArquivoIndisponivel, "");
    }
  }

  ClickHelp(p: any): void {
    (async () => {
      if (p.data.tipoDocumento) {
        this.positionTaxCalendar = p.data.index;
        const item = this.ConformityTaxes[this.positionTaxCalendar];

        switch (p.data.tipoDocumento.toUpperCase()) {
          case 'ICMS_AVULSO':
            {
              if (item.memoriaDeCalculoICMS != null) {
                this.StartDataClicked();
                this.textFaixaICMSTax.nativeElement.innerHTML = (item.memoriaDeCalculoICMS != null ? item.memoriaDeCalculoICMS.textoLivre : "");
                this.mathContent = (item.memoriaDeCalculoICMS != null ? item.memoriaDeCalculoICMS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcICMSModal");
              }
              break;
            }
          case 'DAS':
            {
              this.memoriaDeCalculoDAS = item.memoriaDeCalculoDAS;
              if (item.memoriaDeCalculoDAS != null) {
                this.StartDataClicked();
                this.mathContent = (item.memoriaDeCalculoDAS != null ? item.memoriaDeCalculoDAS.textoLivre : "");
                await this.delay(1000);
                this.dascalculation.openModal();
              }
              break;
            }
          case 'ICMS':
            {
              if (item.memoriaDeCalculoICMS != null) {
                this.StartDataClicked();
                this.textFaixaICMSTax.nativeElement.innerHTML = (item.memoriaDeCalculoICMS != null ? item.memoriaDeCalculoICMS.textoLivre : "");
                this.mathContent = (item.memoriaDeCalculoICMS != null ? item.memoriaDeCalculoICMS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcICMSModal");
              }
              break;
            }
          case 'ICMS_ST':
            {
              if (item.memoriaDeCalculoICMS != null) {
                this.StartDataClicked();
                this.textFaixaICMSTax.nativeElement.innerHTML = (item.memoriaDeCalculoICMS != null ? item.memoriaDeCalculoICMS.textoLivre : "");
                this.mathContent = (item.memoriaDeCalculoICMS != null ? item.memoriaDeCalculoICMS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcICMSModal");
              }
              break;
            }
          case 'ICMS_ST_AVULSO':
            {
              if (item.memoriaDeCalculoICMS != null) {
                this.StartDataClicked();
                this.textFaixaICMSTax.nativeElement.innerHTML = (item.memoriaDeCalculoICMS != null ? item.memoriaDeCalculoICMS.textoLivre : "");
                this.mathContent = (item.memoriaDeCalculoICMS != null ? item.memoriaDeCalculoICMS.textoLivre : "");
                await this.delay(1000);
                this.openModal("OpenTaxCalcICMSModal");
              }
              break;
            }
        }
        this.FinishDataClicked();
      }

    })();
  }

  downloadFile(e: any) {
    const document = e.data;
    this.conformityService.downloadDocument('simples', document.nomeDocumento).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.dwnldLnk.nativeElement.href = window.URL.createObjectURL(blob);
      this.dwnldLnk.nativeElement.download = `${document.nomeDocumento}.${document.extensao}`;
      this.dwnldLnk.nativeElement.click();
    });
  }

  SetFilePath(data: any, extrato: boolean) {
    const dataFileName = GetDataForFileName(data.dataOcorrencia);

    if (extrato) {
      this.dwnldLnk.nativeElement.href = "data:application/pdf;base64," + data.extratoBase64;
      this.dwnldLnk.nativeElement.download = 'ExtratoDAS_' + data.idDocumento + '_' + dataFileName + '.pdf';
    }
    else if (data.tipoDownload == 0)//0=Download Guia(ICMS/DAS) e 1 Help Download de documento (Sintegra e DesTDA)
    {
      if ((data.formatoDocumento && data.formatoDocumento == 'PDF') || (data.documentoBase64 && atob(data.documentoBase64).split('-')[0] == '%PDF')) {
        if (data.documentoBase64 != null) {
          this.dwnldLnk.nativeElement.href = "data:application/pdf;base64," + data.documentoBase64;
          this.dwnldLnk.nativeElement.download = data.tipoDocumento + '_' + data.idDocumento + '_' + dataFileName + '.pdf';
        }
      }
      else {
        if (data.formatoDocumento && data.formatoDocumento == 'TEXT' && data.documentoBase64 && data.documentoBase64 != null) {
          this.dwnldLnk.nativeElement.href = "data:application/text;base64," + data.documentoBase64;
          this.dwnldLnk.nativeElement.download = data.idDocumento + '.txt';
        }
      }
    }
    else {
      if (data.tipoDocumento == "SINTEGRA") {
        this.dwnldLnk.nativeElement.href = "../assets/file/pdf/HELP_SINTEGRA.pdf";
        this.dwnldLnk.nativeElement.download = 'HelpSintegra_' + '_' + data.idDocumento + '_' + dataFileName + '.pdf';
      }
      else
        if (data.tipoDocumento == "DESTDA") {
          this.dwnldLnk.nativeElement.href = "../assets/file/pdf/HELP_DESTDA.pdf";
          this.dwnldLnk.nativeElement.download = 'HelpDesTDA_' + '_' + data.idDocumento + '_' + dataFileName + '.pdf';
        }
    }
  }

  SaveLog(action: string) {
    let logUserActionRQ: UserActionRQ = { action: action };

    this.conformityService.SetLogUserAction(logUserActionRQ);
  }

  GetTypeMessage(tipoDocumento: string, type: number): string //type=0 Pagamento , 1= Download
  {
    if (type == 0) {
      switch (tipoDocumento.toUpperCase()) {
        case 'ICMS_AVULSO':
          {
            return DomeDictionary.ConfirmacaoPagamento;
          }
        case 'DAS':
          {
            return DomeDictionary.ConfirmacaoPagamento;
          }
        case 'ICMS':
          {
            return DomeDictionary.ConfirmacaoPagamento;
          }
        case 'ICMS_ST':
          {
            return DomeDictionary.ConfirmacaoPagamento;
          }
        case 'SINTEGRA':
          {
            return DomeDictionary.ConfirmacaoTransmissao;
          }
        case 'DESTDA':
          {
            return DomeDictionary.ConfirmacaoTransmissao;
          }
      }
    }
    else
      return DomeDictionary.DownloadRealizado;
  }

  StartDataClicked(): void {
    this.ngxLoader.start();
  }
  FinishDataClicked(): void {
    this.ngxLoader.stop();
  }

  private GetType(type: string): string {
    let response: string = "";
    if (type == undefined) {
      return "";
    }

    switch (type) {
      case "ST":
        response = "Substituição Tributária";
        break;
      case "DA":
        response = "Diferencial de Alíquotas";
        break;
      default:
        response = "";
        break;
    }
    return response;
  }

  private GetTypeOperacao(operacao: string): string {
    let response: string = "";
    if (operacao == undefined) {
      return "";
    }

    switch (operacao) {
      case "COMPRA":
        response = "Compra";
        break;
      case "VENDA":
        response = "Venda";
        break;
      default:
        response = "";
        break;
    }
    return response;
  }

  private GetSubType(sub: string): string {
    let response: string = "";
    if (sub == undefined) {
      return "";
    }
    switch (sub) {
      case "ESTOQUE":
        response = "Estoque";
        break;
      case "ATIVO":
        response = "Ativo";
        break;
      case "ANTECIPACAO":
        response = "Antecipação";
        break;
      case "USO_CONSUMO":
        response = "Uso e Consumo";
        break;
      default:
        response = "";
        break;
    }
    return response;
  }

  private openModal(id: string) {
    this.modalService.open(id);
  }

  private closeModal(id: string) {
    this.modalService.close(id);
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  RefreshGridClicked(): void {
    this.eventRefreshGrid.emit();
  }
}
