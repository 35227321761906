import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-percentual-bar',
  templateUrl: './percentual-bar.component.html',
  styleUrls: ['./percentual-bar.component.scss']
})
export class PercentualBarComponent implements OnInit {

  @Input() redTitle: string;
  @Input() greenTitle: string;
  @Input() greenValue: number;
  @Input() redValue: number;

  constructor() { }

  ngOnInit() {
  }
}
